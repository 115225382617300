import React, { Component } from "react";
//import { Link } from "react-router-dom";
import axios from "axios";
//import Moment from "moment";
import Drawer from "../Drawer/Drawer.js";
import Select from "react-select";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

//Funções Auxiliares:
import {
  mostra_mensagem_erro,
  //ve_resultados,
  Trata_Validacoes,
  isEmptyObject
} from "../Funcoes_Auxiliares/Funcoes_Auxiliares.js";

let REACT_APP_API_URL;

if (process.env.REACT_APP_ESTADO === "Deploy") {
  REACT_APP_API_URL = process.env.REACT_APP_API_URL_PROD;
} //Development
else {
  REACT_APP_API_URL = process.env.REACT_APP_API_URL_DEV;
}

const opcoes_select_tipo_user = [
  { value: "Admin", label: "Administrador" },
  { value: "Gestor", label: "Gestor" }
];

class Formulario_Adicionar_Utilizador_Registado extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //Variaveis dos Props Recebidos:
      Link_Menu_Anterior: null,

      Organizacoes_Lidas: undefined,

      //Variaveis dos Dados do Formulário:
      nome: null,
      email: null,
      login: null,
      password: null,
      tipo_utilizador: null,
      id_organizacao: null,

      //Variavel Controlo de Erro na Leitura dos Props:
      Mostra_Erro: false,

      //Variavel Para Controlar o Redirect:
      Inserido_Sucesso: false, //false

      //Lista Para os Erros de Validação:
      errors: {}
    };

    if (props.location.state === undefined) {
      this.state.Mostra_Erro = true;
    } else {
      if (props.location.state.Link_Menu_Anterior === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Link_Menu_Anterior = props.location.state.Link_Menu_Anterior;
      }
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleChangeSelectTipoUser = tipo_utilizador => {
    this.setState({ tipo_utilizador });
  };

  handleChangeSelectOrgUser = id_organizacao => {
    this.setState({ id_organizacao });
  };

  handleValidation() {
    let errors = {};
    let formIsValid = false; //true;

    //Para Cada Campo Invocar o Trata_Validacoes
    errors = Trata_Validacoes(
      this.state.nome,
      "utilizadores_registados/nome",
      errors
    );
    errors = Trata_Validacoes(
      this.state.email,
      "utilizadores_registados/email",
      errors
    );
    errors = Trata_Validacoes(
      this.state.login,
      "utilizadores_registados/login",
      errors
    );
    errors = Trata_Validacoes(
      this.state.password,
      "utilizadores_registados/password",
      errors
    );
    errors = Trata_Validacoes(
      this.state.tipo_utilizador,
      "utilizadores_registados/tipo_utilizador",
      errors
    );
    errors = Trata_Validacoes(
      this.state.id_organizacao,
      "utilizadores_registados/id_organizacao",
      errors
    );

    //if (errors.length > 0) {
    if (isEmptyObject(errors)) {
      formIsValid = true; //false;
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.handleValidation()) {
      /*
      var Tipo_Utilizador_Escolhido = null;
      Tipo_Utilizador_Escolhido = this.state.tipo_utilizador.value;
      
      if (localStorage.getItem("Tipo") === "Admin") {
        Tipo_Utilizador_Escolhido = this.state.tipo_utilizador.value;
      } else {
        Tipo_Utilizador_Escolhido = "Gestor";
      }
      */

      const Token_Lido = localStorage.getItem("Token");
      axios
        .post(
          REACT_APP_API_URL + "/insere_utilizador_sistema",
          {
            nome: this.state.nome,
            email: this.state.email,
            login: this.state.login,
            password: this.state.password,
            tipo_utilizador: this.state.tipo_utilizador.value, //Tipo_Utilizador_Escolhido,
            id_organizacao: this.state.id_organizacao.value //this.state.tipo_utilizador.value //this.state.tipo_utilizador
          },
          {
            headers: { authorization: "Bearer " + Token_Lido }
          }
        )
        .then(res => {
          //console.log(res);
          this.setState({ Inserido_Sucesso: res.data.success });
        });
    }
  }

  Vai_Buscar_Dados() {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .get(REACT_APP_API_URL + "/devolve_todas_organizacoes", {
        headers: { authorization: "Bearer " + Token_Lido }
      })
      .then(res => {
        //console.log(res);
        this.setState({ Organizacoes_Lidas: res.data });
      });
  }

  Devolve_Select_Tipo_Utilizadores() {
    //if (localStorage.getItem("Tipo") === "Admin") {
    const border_erro = "1px solid #ff0000";
    return (
      <div>
        <div className="espaco_span" />
        <label>
          Tipo Utilizador:
          <span className="bolder" style={{ color: "#e60000" }}>
            {" (*)"}
          </span>
        </label>
        <div
          style={{
            border: this.state.errors["utilizadores_registados/tipo_utilizador"]
              ? border_erro
              : ""
          }}
        >
          <Select
            value={this.state.tipo_utilizador}
            name="tipo_utilizador"
            placeholder="Tipo Utilizador"
            onChange={this.handleChangeSelectTipoUser}
            options={opcoes_select_tipo_user}
          />
        </div>
        <span className="bolder" style={{ color: "#e60000" }}>
          {this.state.errors["utilizadores_registados/tipo_utilizador"]}
        </span>
      </div>
    );
    //}
  }

  componentDidMount() {
    if (localStorage.getItem("Token") !== null) {
      this.Vai_Buscar_Dados();
    }
  } //Fecha Procedimento Que Acontece Depois do Output do Componente Foi Rendered

  render() {
    if (localStorage.getItem("Token") === null) {
      this.props.history.push({
        pathname: "/"
      });
    }

    if (this.state.Inserido_Sucesso === true) {
      this.props.history.push({
        pathname: "/ver_utilizadores",
        state: {
          Fez_Inscricao_Utilizador: true,
          //Fez_Update_Utilizador: false,
          Faz_Refresh: true,
          Fez_Desactivacao_Utilizador: false
        }
      });
    }

    //Verifica Se Existe Erro. Se Houver Mostra Mensagem de Erro
    if (this.state.Mostra_Erro === true) {
      return (
        <div>
          <Drawer
            Titulo_Menu="" //"Erro"
            //Link_Menu_Anterior={this.state.Link_Menu_Anterior}
            history={this.props.history}
          />
          {mostra_mensagem_erro()}
        </div>
      );
    } else {
      if (this.state.Organizacoes_Lidas === undefined) {
        return (
          <div className="centra_loading">
            <h1>Loading...</h1>
          </div>
        );
      } else {
        var opcoes_select_org_user = [];
        this.state.Organizacoes_Lidas.forEach(function(linha2) {
          var Linha2 = {
            value: linha2.id,
            label: linha2.name
          };
          opcoes_select_org_user.push(Linha2);
        });

        const border_erro = "1px solid #ff0000";

        return (
          <div>
            <Drawer
              Titulo_Menu="Inserir Novo Utilizador"
              Link_Menu_Anterior={this.state.Link_Menu_Anterior}
              history={this.props.history}
            />
            <div className="novas_margens_volta_novos_forms">
              <div className="centra_novos_forms">
                <form className="login-form" onSubmit={this.handleSubmit}>
                  <div className="centra_titulos_tabs">
                    <Tabs>
                      <TabList>
                        <Tab
                          style={{
                            border:
                              this.state.errors[
                                "utilizadores_registados/login"
                              ] ||
                              this.state.errors[
                                "utilizadores_registados/password"
                              ] ||
                              this.state.errors[
                                "utilizadores_registados/id_organizacao"
                              ] ||
                              this.state.errors[
                                "utilizadores_registados/tipo_utilizador"
                              ]
                                ? border_erro
                                : ""
                          }}
                        >
                          Dados do Utilizador
                        </Tab>
                        <Tab
                          style={{
                            border:
                              this.state.errors[
                                "utilizadores_registados/nome"
                              ] ||
                              this.state.errors["utilizadores_registados/email"]
                                ? border_erro
                                : ""
                          }}
                        >
                          Extras
                        </Tab>
                        <button type="submit" style={{ marginLeft: "10px" }}>
                          Adicionar Utilizador Registado
                        </button>
                      </TabList>
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      <TabPanel>
                        <div className="form">
                          <div>
                            <label>
                              Username
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              >
                                {" (*)"}
                              </span>
                            </label>
                            <input
                              style={{
                                border: this.state.errors[
                                  "utilizadores_registados/login"
                                ]
                                  ? border_erro
                                  : "",
                                textAlign: "center"
                              }}
                              type="text"
                              name="login"
                              placeholder="Username"
                              onChange={this.handleChange}
                              defaultValue={this.state.login}
                            />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {
                                this.state.errors[
                                  "utilizadores_registados/login"
                                ]
                              }
                            </span>
                            <div className="espaco_span" />
                            <label>
                              Password
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              >
                                {" (*)"}
                              </span>
                            </label>
                            <div>
                              <input
                                style={{
                                  border: this.state.errors[
                                    "utilizadores_registados/password"
                                  ]
                                    ? border_erro
                                    : "",
                                  textAlign: "center"
                                }}
                                type="password"
                                name="password"
                                placeholder="Password"
                                onChange={this.handleChange}
                                defaultValue={this.state.password}
                              />
                            </div>
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {
                                this.state.errors[
                                  "utilizadores_registados/password"
                                ]
                              }
                            </span>
                            {this.Devolve_Select_Tipo_Utilizadores()}
                            <div className="espaco_span" />
                            <label>
                              Organização Utilizador:
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              >
                                {" (*)"}
                              </span>
                            </label>
                            <div
                              style={{
                                border: this.state.errors[
                                  "utilizadores_registados/id_organizacao"
                                ]
                                  ? border_erro
                                  : ""
                              }}
                            >
                              <Select
                                value={this.state.id_organizacao}
                                name="id_organizacao"
                                placeholder="Organização"
                                onChange={this.handleChangeSelectOrgUser}
                                options={opcoes_select_org_user}
                              />
                            </div>
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {
                                this.state.errors[
                                  "utilizadores_registados/id_organizacao"
                                ]
                              }
                            </span>
                            <div className="espaco_span" />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {" (*)"}
                            </span>{" "}
                            <span className="bolder"> Campos Obrigatórios</span>
                          </div>
                        </div>

                        {/*}
                        <div className="form">
                          <button type="submit">
                            Adicionar Utilizador Registado
                          </button>
                        </div>
                              */}
                      </TabPanel>
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      <TabPanel>
                        <div className="form">
                          <div>
                            <label>
                              Nome do Utilizador
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              >
                                {" (*)"}
                              </span>
                            </label>
                            {/*<div style={{ border: border_nome }}>*/}
                            <input
                              style={{
                                border: this.state.errors[
                                  "utilizadores_registados/nome"
                                ]
                                  ? border_erro
                                  : "",
                                textAlign: "center"
                              }}
                              type="text"
                              name="nome"
                              placeholder="Nome do Utilizador"
                              onChange={this.handleChange}
                              defaultValue={this.state.nome}
                            />
                            {/*</div>*/}
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {
                                this.state.errors[
                                  "utilizadores_registados/nome"
                                ]
                              }
                            </span>
                            <div className="espaco_span">
                              <label>
                                Email
                                <span
                                  className="bolder"
                                  style={{ color: "#e60000" }}
                                >
                                  {" (*)"}
                                </span>
                              </label>
                              <input
                                style={{
                                  border: this.state.errors[
                                    "utilizadores_registados/email"
                                  ]
                                    ? border_erro
                                    : "",
                                  textAlign: "center"
                                }}
                                type="text"
                                name="email"
                                placeholder="Email"
                                onChange={this.handleChange}
                                defaultValue={this.state.email}
                              />
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              >
                                {
                                  this.state.errors[
                                    "utilizadores_registados/email"
                                  ]
                                }
                              </span>
                            </div>
                            <div className="espaco_span" />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {" (*)"}
                            </span>{" "}
                            <span className="bolder"> Campos Obrigatórios</span>
                          </div>
                        </div>

                        {/*}
                        <div className="form">
                          <button type="submit">
                            Adicionar Utilizador Registado
                          </button>
                        </div>
                              */}
                      </TabPanel>
                    </Tabs>
                  </div>
                </form>
              </div>
            </div>
          </div>
        );
      }
    }
  }
}

export default Formulario_Adicionar_Utilizador_Registado;
