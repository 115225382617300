import React, { Component } from "react";
import Main from "./Componentes_Apus_Final/Main.js";
//import { Switch, Route, Router } from "react-router-dom";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      isLoaded: false
      //sideDrawerOpen: false
    };
  } //Fecha Construtor da Class

  render() {
    return <Main />;
  } //Fecha Render
} //Fecha Class

export default App;
