import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import ReactPaginate from "react-paginate";

//Imports Para Drawer e Snackbar:
import Drawer from "../Drawer/Drawer.js";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import classNames from "classnames";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import green from "@material-ui/core/colors/green";
import amber from "@material-ui/core/colors/amber";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import WarningIcon from "@material-ui/icons/Warning";

//imports Para o Dialog de Logout
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
//import DialogContent from '@material-ui/core/DialogContent';
//import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from "@material-ui/core/DialogTitle";

//Funções Auxiliares:
import {
  mostra_mensagem_erro,
  ve_resultados
} from "../Funcoes_Auxiliares/Funcoes_Auxiliares.js";

let REACT_APP_API_URL;

if (process.env.REACT_APP_ESTADO === "Deploy") {
  REACT_APP_API_URL = process.env.REACT_APP_API_URL_PROD;
} //Development
else {
  REACT_APP_API_URL = process.env.REACT_APP_API_URL_DEV;
}

//Constantes Para Drawer e Snackbar:
const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const styles1 = theme => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.dark
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit
  },
  message: {
    display: "flex",
    alignItems: "center"
  }
});

MySnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(["success", "warning", "error", "info"]).isRequired
};

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

//Funções Para Drawer e Snackbar:
function MySnackbarContent(props) {
  const { classes, className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>
      ]}
      {...other}
    />
  );
}

class Tabela_Lista_Checkpoints_Prova extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Link_Menu_Anterior: null,
      ID_Evento: null,
      ID_Prova: null,
      items: undefined, //[],
      Headers_Colunas: [],
      Nome_Evento: null,
      Nome_Prova: null,

      //Variaveis Para a Paginação:
      total_items: 0,
      offset: 0,
      perPage: 10,
      pageCount: null,

      //Variavel Controlo de Erro na Leitura dos Props
      Mostra_Erro: false,

      //Variavel de Controlo Para a Mensagem de Inscrição Com Sucesso na Snackbar:
      Fez_Insercao_Checkpoint_Prova: false,
      Fez_Edicao_Checkpoint_Prova: false,
      Fez_Eliminacao_Checkpoint_Prova: false,
      Erro_Fez_Eliminacao_Checkpoint_Prova: false,

      //Variaveis de Controlo dos Dialogs:
      open_dialog_elimina_checkpoint: false,

      //Dados do Checkpoint a Mostrar:
      local: null,
      coordinate: null,
      altitude: null,
      abertura: null,
      previsao_rapido: null,
      previsao_lento: null,
      fecho: null,
      tipo_controlo: null,
      abastecimento: null,

      //Variavel de Controlo Para Evitar o Bug da Paginação Não Aparecer
      Faz_Refresh: false,

      //Fez_Eliminacao_Checkpoint: false,
      ID_Competition_Checkpoint_Eliminar: null
    };

    if (props.location.state === undefined) {
      this.state.Mostra_Erro = true;
    } else {
      if (props.location.state.Link_Menu_Anterior === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Link_Menu_Anterior = props.location.state.Link_Menu_Anterior;
      }
      if (props.location.state.ID_Evento === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.ID_Evento = props.location.state.ID_Evento;
      }
      if (props.location.state.ID_Prova === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.ID_Prova = props.location.state.ID_Prova;
      }

      if (props.location.state.Fez_Insercao_Checkpoint_Prova === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Fez_Insercao_Checkpoint_Prova =
          props.location.state.Fez_Insercao_Checkpoint_Prova;
      }
      if (props.location.state.Fez_Edicao_Checkpoint_Prova === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Fez_Edicao_Checkpoint_Prova =
          props.location.state.Fez_Edicao_Checkpoint_Prova;
      }
      if (props.location.state.Faz_Refresh === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Faz_Refresh = props.location.state.Faz_Refresh;
      }
      if (props.location.state.Nome_Evento === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Nome_Evento = props.location.state.Nome_Evento;
      }
      if (props.location.state.Nome_Prova === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Nome_Prova = props.location.state.Nome_Prova;
      }
    }

    this.handlePageClick = this.handlePageClick.bind(this);
    this.handleChange = this.handleChange.bind(this);

    //Metodos Para Ordenações da Tabela
    this.compareBy.bind(this);
    this.sortBy.bind(this);
    this.compareByNumber.bind(this);

    this.Mostra_Dados.bind(this);
  } //Fecha Construtor da Class

  compareBy(key) {
    return function(a, b) {
      if (a[key] < b[key]) return -1;
      if (a[key] > b[key]) return 1;
      return 0;
    };
  }

  compareByNumber(key) {
    return function(a, b) {
      return parseInt(a[key]) - parseInt(b[key]);
    };
  }

  sortBy(key) {
    let arrayCopy = [...this.state.items];
    if (
      key === "id_utilizador" ||
      key === "eliminado" ||
      key === "inscrito_por_utilizador_id"
    ) {
      arrayCopy.sort(this.compareByNumber(key));
    } else {
      arrayCopy.sort(this.compareBy(key));
    }
    this.setState({ items: arrayCopy });
  }

  handlePageClick = data => {
    let selected1 = data.selected;
    let offset1 = Math.ceil(selected1 * this.state.perPage);

    this.setState({ offset: offset1 }, () => {
      this.Vai_Buscar_Dados();
    });
  };

  handleClickOpenDialogEliminaCheckpoint = () => {
    this.setState({ open_dialog_elimina_checkpoint: true });
  };

  handleCloseDialogEliminaCheckpoint = () => {
    this.setState({ open_dialog_elimina_checkpoint: false });
  };

  handleEliminaCheckpoint = () => {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .delete(
        REACT_APP_API_URL +
          "/delete_competition_checkpoint2/" +
          this.state.ID_Competition_Checkpoint_Eliminar,
        //{},
        {
          headers: { authorization: "Bearer " + Token_Lido }
        }
      )
      .then(res => {
        if (res.data.success === true) {
          this.setState({
            open_dialog_elimina_checkpoint: false,
            Faz_Refresh: true,
            Fez_Eliminacao_Checkpoint_Prova: true
          });
        } else if (res.data.success === false) {
          this.setState({
            open_dialog_elimina_checkpoint: false,
            Faz_Refresh: true,
            Erro_Fez_Eliminacao_Checkpoint_Prova: true
          });
        }
      });
  };

  Mostra_Dados(Array_Dados_Mostrar) {
    this.setState({
      local: Array_Dados_Mostrar[0],
      coordinate: Array_Dados_Mostrar[1],
      altitude: Array_Dados_Mostrar[2],
      abertura: Array_Dados_Mostrar[3],
      previsao_rapido: Array_Dados_Mostrar[4],
      previsao_lento: Array_Dados_Mostrar[5],
      fecho: Array_Dados_Mostrar[6],
      tipo_controlo: Array_Dados_Mostrar[7],
      abastecimento: Array_Dados_Mostrar[8]
    });
  }

  Vai_Buscar_Dados() {
    const Token_Lido = localStorage.getItem("Token");
    if (this.state.perPage > 0) {
      axios
        .get(
          REACT_APP_API_URL +
            "/lista_checkpoints_prova/" +
            this.state.ID_Prova +
            "/" +
            this.state.perPage +
            "/" +
            this.state.offset,
          {
            headers: { authorization: "Bearer " + Token_Lido }
          }
        )
        .then(res => {
          console.log(res);
          this.setState({ items: res.data });
          this.setState({
            pageCount: Math.ceil(this.state.total_items / this.state.perPage)
          });
        });
    } else {
      axios
        .get(
          REACT_APP_API_URL + "/lista_checkpoints_prova/" + this.state.ID_Prova,
          {
            headers: { authorization: "Bearer " + Token_Lido }
          }
        )
        .then(res => {
          console.log(res);
          this.setState({ items: res.data });
          this.setState({ pageCount: 1 });
        });
    }
  }

  Vai_Buscar_Numero_Checkpoints_Prova() {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .get(
        REACT_APP_API_URL +
          "/devolve_numero_checkpoints_prova/" +
          this.state.ID_Prova,
        {
          headers: { authorization: "Bearer " + Token_Lido }
        }
      )
      .then(res => {
        console.log(res);
        this.setState({ total_items: res.data[0].Total });
      });
  }
  /*
Procedimento Com Axios */
  componentDidMount() {
    if (localStorage.getItem("Token") !== null) {
      this.Vai_Buscar_Numero_Checkpoints_Prova();
      this.Vai_Buscar_Dados();
    }
  } //Fecha Procedimento Que Acontece Depois do Output do Componente Foi Rendered

  //Actualiza os Dados Depois de Acontecer um Redirect Para Este Componente
  componentDidUpdate() {
    //nextProps) {
    //if (nextProps.location.pathname !== this.props.location.pathname) {
    if (this.state.Faz_Refresh) {
      this.Vai_Buscar_Numero_Checkpoints_Prova();
      this.Vai_Buscar_Dados();
      this.setState({ Faz_Refresh: false });
    }
  }

  /*
  //Actualiza os Dados Depois de Acontecer um Redirect Para Esta Tabela
  componentDidUpdate() {
    this.Vai_Buscar_Numero_Checkpoints_Prova();
    this.Vai_Buscar_Dados();
  }
  */

  //Funcoes Para a Mensagem de Login Com Sucesso na Snackbar
  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ Fez_Insercao_Checkpoint_Prova: false });
  };

  //Funcoes Para a Mensagem de Login Com Sucesso na Snackbar
  handleCloseEdicao_Checkpoint = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ Fez_Edicao_Checkpoint_Prova: false });
  };

  //Funcoes Para a Mensagem de Login Com Sucesso na Snackbar
  handleCloseEliminacao_Checkpoint = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ Fez_Eliminacao_Checkpoint_Prova: false });
  };

  //Funcoes Para a Mensagem de Login Com Sucesso na Snackbar
  handleClose_Erro_Eliminacao_Checkpoint = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ Erro_Fez_Eliminacao_Checkpoint_Prova: false });
  };

  Mostra_Mensagem_Inscricao_Sucesso = () => {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={this.state.Fez_Insercao_Checkpoint_Prova}
        autoHideDuration={3000}
        onClose={this.handleClose}
      >
        <MySnackbarContentWrapper
          onClose={this.handleClose}
          variant="success"
          message="Checkpoint Adicionado à Prova Com Sucesso!"
        />
      </Snackbar>
    );
  };

  Mostra_Mensagem_Edicao_Sucesso = () => {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={this.state.Fez_Edicao_Checkpoint_Prova}
        autoHideDuration={3000}
        onClose={this.handleCloseEdicao_Checkpoint}
      >
        <MySnackbarContentWrapper
          onClose={this.handleCloseEdicao_Checkpoint}
          variant="success"
          message="Checkpoint da Prova Editado Com Sucesso!"
        />
      </Snackbar>
    );
  };

  Mostra_Mensagem_Eliminacao_Sucesso = () => {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={this.state.Fez_Eliminacao_Checkpoint_Prova}
        autoHideDuration={3000}
        onClose={this.handleCloseEliminacao_Checkpoint}
      >
        <MySnackbarContentWrapper
          onClose={this.handleCloseEliminacao_Checkpoint}
          variant="success"
          message="Checkpoint da Prova Eliminado Com Sucesso!"
        />
      </Snackbar>
    );
  };

  Mostra_Mensagem_Eliminacao_Sucesso = () => {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={this.state.Erro_Fez_Eliminacao_Checkpoint_Prova}
        autoHideDuration={3000}
        onClose={this.handleClose_Erro_Eliminacao_Checkpoint}
      >
        <MySnackbarContentWrapper
          onClose={this.handleClose_Erro_Eliminacao_Checkpoint}
          variant="error"
          message="Erro: Checkpoint da Prova Tem Tempos Associados"
        />
      </Snackbar>
    );
  };

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value }, () => {
      this.Vai_Buscar_Dados();
    });
  }

  render() {
    if (localStorage.getItem("Token") === null) {
      this.props.history.push({
        pathname: "/"
      });
    }

    //Verifica Se Existe Erro. Se Houver Mostra Mensagem de Erro
    if (this.state.Mostra_Erro === true) {
      return (
        <div>
          <Drawer
            Titulo_Menu="" //"Erro"
            history={this.props.history}
          />
          {mostra_mensagem_erro()}
        </div>
      );
    } else {
      if (
        this.state.items === undefined ||
        this.state.total_items === undefined
      ) {
        return (
          <div className="centra_loading">
            <h1>Loading...</h1>
          </div>
        );
      } else {
        if (this.state.items.length > 0) {
          return (
            <div>
              <Dialog
                open={this.state.open_dialog_elimina_checkpoint}
                onClose={this.handleCloseDialogEliminaCheckpoint}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Tem a Certeza Que Deseja Eliminar o Checkpoint?"}
                </DialogTitle>
                <DialogActions>
                  <Button
                    onClick={this.handleCloseDialogEliminaCheckpoint}
                    color="primary"
                  >
                    Cancelar
                  </Button>
                  <Button
                    onClick={this.handleEliminaCheckpoint}
                    color="primary"
                    autoFocus
                  >
                    Sim
                  </Button>
                </DialogActions>
              </Dialog>

              <Drawer
                Titulo_Menu="Lista Checkpoints Prova"
                Link_Menu_Anterior={this.state.Link_Menu_Anterior}
                Link_Tabela_Anterior={this.props.location.pathname} //{this.state.Link_Tabela_Anterior}
                ID_Evento={this.state.ID_Evento}
                ID_Prova={this.state.ID_Prova}
                Nome_Evento={this.state.Nome_Evento}
                Nome_Prova={this.state.Nome_Prova}
                history={this.props.history}
              />
              <div
                className="esquerda_tabelas2" //"centra_tabelas"
              >
                <tabela>
                  <thead>
                    <tr>
                      <th colSpan="3">
                        Número Total de Checkpoints da Prova:{" "}
                        {this.state.total_items}
                      </th>
                    </tr>
                  </thead>
                  <thead>
                    <tr>
                      <th onClick={() => this.sortBy("checkpoint_order")}>
                        Numero{" "}
                        <i
                          className="fa fa-angle-down fa-sm" /*fa fa-sort fa-sm*/
                        />
                      </th>
                      <th onClick={() => this.sortBy("local")}>
                        Nome Checkpoint{" "}
                        <i
                          className="fa fa-angle-down fa-sm" /*fa fa-sort fa-sm*/
                        />
                      </th>
                      <th>Acção</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.items.map(linha2 => {
                      const Array_Dados_Mostrar = [
                        linha2.local,
                        linha2.coordinate,
                        linha2.altitude,
                        linha2.abertura,
                        linha2.previsao_rapido,
                        linha2.previsao_lento,
                        linha2.fecho,
                        linha2.tipo_controlo,
                        linha2.abastecimento
                      ];

                      return (
                        <tr
                          onClick={() => this.Mostra_Dados(Array_Dados_Mostrar)}
                        >
                          <td>{linha2.checkpoint_order}</td>
                          <td>{linha2.local}</td>
                          <td
                            onClick={() =>
                              this.setState({
                                ID_Competition_Checkpoint_Eliminar:
                                  linha2.competition_checkpoint_id
                              })
                            }
                          >
                            <Link
                              to={{
                                pathname: "/lista_resultados_checkpoints",
                                state: {
                                  //ID_Checkpoint: linha2.checkpoint_id,
                                  ID_Competition_Checkpoint:
                                    linha2.competition_checkpoint_id,
                                  Link_Tabela_Anterior: this.props.location
                                    .pathname,
                                  Link_Menu_Anterior: this.state
                                    .Link_Menu_Anterior,
                                  ID_Evento: this.state.ID_Evento,
                                  ID_Prova: this.state.ID_Prova,
                                  Fez_Edicao_Resultado: false,
                                  Faz_Refresh: true,
                                  Nome_Evento: this.state.Nome_Evento,
                                  Nome_Prova: this.state.Nome_Prova,
                                  //Nome_Checkpoint: this.state.Nome_Checkpoint
                                  Nome_Checkpoint: linha2.local
                                }
                              }}
                              title="Resultados"
                            >
                              <i className="fa fa-list-ul fa-lg espaco_icons" />
                            </Link>{" "}
                            <Link
                              to={{
                                pathname: "/editar_checkpoint_prova",
                                state: {
                                  //ID_Checkpoint: linha2.checkpoint_id,
                                  ID_Competition_Checkpoint:
                                    linha2.competition_checkpoint_id,
                                  Link_Tabela_Anterior: this.props.location
                                    .pathname,
                                  Link_Menu_Anterior: this.state
                                    .Link_Menu_Anterior,
                                  ID_Evento: this.state.ID_Evento,
                                  ID_Prova: this.state.ID_Prova,
                                  Faz_Refresh: false, //true,
                                  Nome_Evento: this.state.Nome_Evento,
                                  Nome_Prova: this.state.Nome_Prova,
                                  Nome_Checkpoint: linha2.local
                                }
                              }}
                              title="Editar"
                            >
                              <i className="fa fa-edit fa-lg espaco_icons" />
                            </Link>{" "}
                            <span
                              title="Remover Checkpoint"
                              onClick={
                                this.handleClickOpenDialogEliminaCheckpoint
                              }
                            >
                              <i className="fa fa-trash fa-lg" />
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </tabela>
                {
                  <div style={{ marginTop: "10px" }} align="center">
                    <label>Resultados Por Página</label>{" "}
                    <select
                      name="perPage"
                      id="perPage"
                      onChange={this.handleChange}
                    >
                      <option value="10"> 10 </option>
                      <option value="25"> 25 </option>
                      <option value="50"> 50 </option>
                      <option value="100"> 100 </option>
                      <option value="0">Todos </option>
                    </select>
                  </div>
                }
                <div align="center">
                  <ReactPaginate
                    previousLabel={"Anterior"}
                    nextLabel={"Próximo"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
              {this.Mostra_Mensagem_Inscricao_Sucesso()}
              {this.Mostra_Mensagem_Edicao_Sucesso()}
              {this.Mostra_Mensagem_Eliminacao_Sucesso()}

              <div className="direita_form">
                <label>
                  Checkpoint:
                  <font color="white"> {this.state.local /*Bardo*/}</font>
                </label>
                {/*<font color="white">32.765106, -16.920157234</font>*/}
                {/*}
                <input
                                    style={{
                                      backgroundColor: "#b3b3cc"
                                    }}
                  type="text"
                  defaultValue="32.765106, -16.920157234"
                  readOnly
                  />*/}
                <div className="espaco_span" />

                <label>
                  Coordenadas:
                  <font color="white">
                    {" "}
                    {this.state.coordinate /*32.765106, -16.920157234*/}
                  </font>
                </label>
                {/*<font color="white">32.765106, -16.920157234</font>*/}
                {/*}
                <input
                                    style={{
                                      backgroundColor: "#b3b3cc"
                                    }}
                  type="text"
                  defaultValue="32.765106, -16.920157234"
                  readOnly
                  />*/}
                <div className="espaco_span" />

                <label>
                  Altitude:
                  <font color="white"> {this.state.altitude /*1752*/}</font>
                </label>
                {/*}
                <input type="text" defaultValue="1752" readOnly />
                */}
                <div className="espaco_span" />

                <label>
                  Data Abertura:
                  <font color="white">
                    {" "}
                    {this.state.abertura /*08-12-2018 23:20:00*/}
                  </font>
                </label>
                {/*}
                <input
                                    style={{
                                      backgroundColor: "#b3b3cc"
                                    }}
                  type="text"
                  defaultValue="08-12-2018 23:20:00"
                  readOnly
                />*/}
                <div className="espaco_span" />

                <label>
                  Previsão 1º Atleta:
                  <font color="white">
                    {" "}
                    {this.state.previsao_rapido /*08-12-2018 23:20:00*/}
                  </font>
                </label>
                {/*}
                <input
                                    style={{
                                      backgroundColor: "#b3b3cc"
                                    }}
                  type="text"
                  defaultValue="08-12-2018 23:20:00"
                  readOnly
                />*/}
                <div className="espaco_span" />

                <label>
                  Previsão Último Atleta:
                  <font color="white">
                    {" "}
                    {this.state.previsao_lento /*08-12-2018 23:20:00*/}
                  </font>
                </label>
                {/*}
                <input
                                    style={{
                                      backgroundColor: "#b3b3cc"
                                    }}
                  type="text"
                  defaultValue="08-12-2018 23:20:00"
                  readOnly
                />*/}
                <div className="espaco_span" />
                <label>
                  Data Fecho:
                  <font color="white">
                    {" "}
                    {this.state.fecho /*08-12-2018 23:20:00*/}
                  </font>
                </label>
                {/*}
                <input
                                    style={{
                                      backgroundColor: "#b3b3cc"
                                    }}
                  type="text"
                  defaultValue="08-12-2018 23:20:00"
                  readOnly
                />*/}
                <div className="espaco_span" />

                <label>
                  Tipo Controlo:
                  <font color="white"> {this.state.tipo_controlo /*C/A*/}</font>
                </label>
                {/*}
                <input type="text" defaultValue="C/A" readOnly />*/}
                <div className="espaco_span" />

                <label>
                  Abastecimento:
                  <font color="white">
                    {" "}
                    {this.state.abastecimento /*S,L,A + Medico*/}
                  </font>
                </label>
                {/*}
                <input
                                    style={{
                                      backgroundColor: "#b3b3cc"
                                    }}
                  type="text"
                  defaultValue="S,L,A + Medico + asdadasdadsdsads"
                  readOnly
                />*/}

                <div className="espaco_span" />
                <label>
                  <font color="white">
                    {" "}
                    {this.state.local
                      ? ""
                      : "Clique Num Checkpoint Para Mais Informações"}
                  </font>
                </label>
              </div>
            </div>
          );
        } else {
          return (
            <div>
              <Drawer
                Titulo_Menu="Lista Checkpoints Prova"
                Link_Menu_Anterior={this.state.Link_Menu_Anterior}
                Link_Tabela_Anterior={this.props.location.pathname} //{this.state.Link_Tabela_Anterior}
                ID_Evento={this.state.ID_Evento}
                ID_Prova={this.state.ID_Prova}
                Nome_Evento={this.state.Nome_Evento}
                Nome_Prova={this.state.Nome_Prova}
                history={this.props.history}
              />
              {ve_resultados()}
            </div>
          );
        }
      }
    }
  }
}

export default Tabela_Lista_Checkpoints_Prova;
