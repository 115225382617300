import React, { Component } from "react";
import Drawer from "../Drawer/Drawer.js";

//Funções Auxiliares:
import {
  mostra_mensagem_erro
  //ve_resultados
} from "../Funcoes_Auxiliares/Funcoes_Auxiliares.js";

class Definicoes_Notificacoes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Link_Menu_Anterior: null,
      Link_Tabela_Anterior: null,
      local_checkpoint: null,
      coordenadas: null,
      altitude: null,

      //Variavel Controlo de Erro na Leitura dos Props
      Mostra_Erro: false,

      //Variavel Para Controlar o Redirect
      Inserido_Sucesso: false,

      //Variaveis do localStorage:
      mostrar_notificacoes_atletas_adiantados:
        localStorage.getItem("Mostrar_Notificacoes_Atletas_Adiantados") ===
        "true"
          ? true
          : false,
      mostrar_notificacoes_atletas_atrasados:
        localStorage.getItem("Mostrar_Notificacoes_Atletas_Atrasados") ===
        "true"
          ? true
          : false,
      mostrar_notificacoes_ordem_checkpoints:
        localStorage.getItem("Mostrar_Notificacoes_Ordem_Checkpoints") ===
        "true"
          ? true
          : false,
      mostrar_notificacoes_tempos_depois_desistencia_atletas:
        localStorage.getItem(
          "Mostrar_Notificacoes_Tempos_Depois_Desistencia_Atletas"
        ) === "true"
          ? true
          : false,

      mostrar_notificacoes_podio_atletas_regionais:
        localStorage.getItem("Mostrar_Notificacoes_Podio_Atletas_Regionais") ===
        "true"
          ? true
          : false,
      mostrar_notificacoes_podio_atletas_portugal:
        localStorage.getItem("Mostrar_Notificacoes_Podio_Atletas_Portugal") ===
        "true"
          ? true
          : false,
      mostrar_notificacoes_podio_atletas_mundo:
        localStorage.getItem("Mostrar_Notificacoes_Podio_Atletas_Mundo") ===
        "true"
          ? true
          : false
    };

    if (props.location.state === undefined) {
      this.state.Mostra_Erro = true;
    } else {
      if (props.location.state.Link_Menu_Anterior === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Link_Menu_Anterior = props.location.state.Link_Menu_Anterior;
      }
      if (props.location.state.Link_Tabela_Anterior === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Link_Tabela_Anterior =
          props.location.state.Link_Tabela_Anterior;
      }
      if (props.location.state.ID_Evento === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.ID_Evento = props.location.state.ID_Evento;
      }
      if (props.location.state.ID_Prova === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.ID_Prova = props.location.state.ID_Prova;
      }
      if (props.location.state.Nome_Evento === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Nome_Evento = props.location.state.Nome_Evento;
      }
      if (props.location.state.Nome_Prova === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Nome_Prova = props.location.state.Nome_Prova;
      }
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.checked });
  }

  handleSubmit(event) {
    event.preventDefault();

    localStorage.setItem(
      "Mostrar_Notificacoes_Atletas_Adiantados",
      this.state.mostrar_notificacoes_atletas_adiantados
    );
    localStorage.setItem(
      "Mostrar_Notificacoes_Atletas_Atrasados",
      this.state.mostrar_notificacoes_atletas_atrasados
    );
    localStorage.setItem(
      "Mostrar_Notificacoes_Ordem_Checkpoints",
      this.state.mostrar_notificacoes_ordem_checkpoints
    );
    localStorage.setItem(
      "Mostrar_Notificacoes_Tempos_Depois_Desistencia_Atletas",
      this.state.mostrar_notificacoes_tempos_depois_desistencia_atletas
    );

    localStorage.setItem(
      "Mostrar_Notificacoes_Podio_Atletas_Regionais",
      this.state.mostrar_notificacoes_podio_atletas_regionais
    );
    localStorage.setItem(
      "Mostrar_Notificacoes_Podio_Atletas_Portugal",
      this.state.mostrar_notificacoes_podio_atletas_portugal
    );
    localStorage.setItem(
      "Mostrar_Notificacoes_Podio_Atletas_Mundo",
      this.state.mostrar_notificacoes_podio_atletas_mundo
    );

    this.setState({ Inserido_Sucesso: true });
  }

  render() {
    if (localStorage.getItem("Token") === null) {
      this.props.history.push({
        pathname: "/"
      });
    }

    if (this.state.Inserido_Sucesso === true) {
      this.props.history.push({
        pathname: this.state.Link_Tabela_Anterior,
        state: {
          Link_Menu_Anterior: this.state.Link_Menu_Anterior,
          ID_Evento: this.state.ID_Evento,
          ID_Prova: this.state.ID_Prova,
          Nome_Evento: this.state.Nome_Evento,
          Nome_Prova: this.state.Nome_Prova
        }
      });
    }

    //Verifica Se Existe Erro. Se Houver Mostra Mensagem de Erro
    if (this.state.Mostra_Erro === true) {
      return (
        <div>
          <Drawer
            Titulo_Menu="" //"Erro"
            history={this.props.history}
          />
          {mostra_mensagem_erro()}
        </div>
      );
    } else {
      return (
        <div>
          <Drawer
            Titulo_Menu="Alterar Definições Notificações"
            //Link_Tabela_Anterior={this.state.Link_Tabela_Anterior}
            Link_Menu_Anterior={this.state.Link_Menu_Anterior}
            ID_Evento={this.state.ID_Evento}
            ID_Prova={this.state.ID_Prova}
            Nome_Evento={this.state.Nome_Evento}
            Nome_Prova={this.state.Nome_Prova}
            history={this.props.history}
          />
          <div>
            <div className="form_notificacoes">
              <form className="login-form" onSubmit={this.handleSubmit}>
                <div>
                  <label style={{ color: "white" }}>Definições</label>
                  <div className="espaco_span" />
                  <div className="labels_notificacoes">
                    <label>Mostrar Checkpoints Com 1º Atleta Adiantado:</label>{" "}
                    <div className="checkboxes_notificacoes">
                      <input
                        type="checkbox"
                        name="mostrar_notificacoes_atletas_adiantados"
                        checked={
                          this.state.mostrar_notificacoes_atletas_adiantados
                        }
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="espaco_span" />
                    <label>
                      Mostrar Checkpoints Com 1º Atleta Atrasado:
                    </label>{" "}
                    <div className="checkboxes_notificacoes">
                      <input
                        type="checkbox"
                        name="mostrar_notificacoes_atletas_atrasados"
                        checked={
                          this.state.mostrar_notificacoes_atletas_atrasados
                        }
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="espaco_span" />
                    <label>
                      Mostrar Atletas Com Erros na Ordem dos Resutlados:
                    </label>{" "}
                    <div className="checkboxes_notificacoes">
                      <input
                        type="checkbox"
                        name="mostrar_notificacoes_ordem_checkpoints"
                        checked={
                          this.state.mostrar_notificacoes_ordem_checkpoints
                        }
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="espaco_span" />
                    <label>
                      Mostrar Atletas Com Tempos Depois de Desistir:
                    </label>{" "}
                    <div className="checkboxes_notificacoes">
                      <input
                        type="checkbox"
                        name="mostrar_notificacoes_tempos_depois_desistencia_atletas"
                        checked={
                          this.state
                            .mostrar_notificacoes_tempos_depois_desistencia_atletas
                        }
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="espaco_span" />
                    <label>Mostrar Pódios Atletas Regionais:</label>{" "}
                    <div className="checkboxes_notificacoes">
                      <input
                        type="checkbox"
                        name="mostrar_notificacoes_podio_atletas_regionais"
                        checked={
                          this.state
                            .mostrar_notificacoes_podio_atletas_regionais
                        }
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="espaco_span" />
                    <label>Mostrar Pódios Atletas Portugal:</label>{" "}
                    <div className="checkboxes_notificacoes">
                      <input
                        type="checkbox"
                        name="mostrar_notificacoes_podio_atletas_portugal"
                        checked={
                          this.state.mostrar_notificacoes_podio_atletas_portugal
                        }
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="espaco_span" />
                    <label>Mostrar Pódios Atletas Mundo:</label>{" "}
                    <div className="checkboxes_notificacoes">
                      <input
                        type="checkbox"
                        name="mostrar_notificacoes_podio_atletas_mundo"
                        checked={
                          this.state.mostrar_notificacoes_podio_atletas_mundo
                        }
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div className="espaco_span" />
                </div>
                <button type="submit">Alterar</button>
              </form>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default Definicoes_Notificacoes;
