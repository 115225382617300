import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import ReactPaginate from "react-paginate";

//Imports Para Drawer e Snackbar:
import Drawer from "../Drawer/Drawer.js";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import classNames from "classnames";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import green from "@material-ui/core/colors/green";
import amber from "@material-ui/core/colors/amber";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import WarningIcon from "@material-ui/icons/Warning";

//imports Para o Dialog de Logout
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
//import DialogContent from '@material-ui/core/DialogContent';
//import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from "@material-ui/core/DialogTitle";

//Funções Auxiliares:
import {
  mostra_mensagem_erro,
  ve_resultados
} from "../Funcoes_Auxiliares/Funcoes_Auxiliares.js";

let REACT_APP_API_URL;

if (process.env.REACT_APP_ESTADO === "Deploy") {
  REACT_APP_API_URL = process.env.REACT_APP_API_URL_PROD;
} //Development
else {
  REACT_APP_API_URL = process.env.REACT_APP_API_URL_DEV;
}

//Constantes Para Drawer e Snackbar:
const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const styles1 = theme => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.dark
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit
  },
  message: {
    display: "flex",
    alignItems: "center"
  }
});

MySnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(["success", "warning", "error", "info"]).isRequired
};

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

//Funções Para Drawer e Snackbar:
function MySnackbarContent(props) {
  const { classes, className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>
      ]}
      {...other}
    />
  );
}

class Tabela_Lista_Checkpoints extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Link_Menu_Anterior: null,
      items: undefined, //[],
      Headers_Colunas: [],

      //Variavel de Controlo Para a Mensagem de Inscrição Com Sucesso na Snackbar:
      Fez_Insercao_Checkpoint_Sistema: false,
      Fez_Edicao_Checkpoint_Sistema: false,

      //Variaveis Para a Paginação:
      total_items: 0,
      offset: 0,
      perPage: 10,
      pageCount: null,

      //Variavel Controlo de Erro na Leitura dos Props
      Mostra_Erro: false,

      //Variaveis de Controlo dos Dialogs:
      open_dialog_elimina_checkpoint: false,

      //Variavel de Controlo Para Evitar o Bug da Paginação Não Aparecer
      Faz_Refresh: false
    };

    if (props.location.state === undefined) {
      this.state.Mostra_Erro = true;
    } else {
      if (props.location.state.Link_Menu_Anterior === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Link_Menu_Anterior = props.location.state.Link_Menu_Anterior;
      }
      if (props.location.state.Fez_Insercao_Checkpoint_Sistema === undefined) {
        this.state.Fez_Insercao_Checkpoint_Sistema = false;
      } else {
        this.state.Fez_Insercao_Checkpoint_Sistema =
          props.location.state.Fez_Insercao_Checkpoint_Sistema;
      }
      if (props.location.state.Fez_Edicao_Checkpoint_Sistema === undefined) {
        this.state.Fez_Edicao_Checkpoint_Sistema = false;
      } else {
        this.state.Fez_Edicao_Checkpoint_Sistema =
          props.location.state.Fez_Edicao_Checkpoint_Sistema;
      }
      if (props.location.state.Faz_Refresh === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Faz_Refresh = props.location.state.Faz_Refresh;
      }
    }

    this.handlePageClick = this.handlePageClick.bind(this);

    //Metodos Para Ordenações da Tabela
    this.compareBy.bind(this);
    this.sortBy.bind(this);
    this.compareByNumber.bind(this);
  } //Fecha Construtor da Class

  compareBy(key) {
    return function(a, b) {
      if (a[key] < b[key]) return -1;
      if (a[key] > b[key]) return 1;
      return 0;
    };
  }

  compareByNumber(key) {
    return function(a, b) {
      return parseInt(a[key]) - parseInt(b[key]);
    };
  }

  sortBy(key) {
    let arrayCopy = [...this.state.items];
    //if (key === "altitude") {
    //arrayCopy.sort(this.compareByNumber(key));
    //} else {
    arrayCopy.sort(this.compareBy(key));
    //}
    this.setState({ items: arrayCopy });
  }

  handlePageClick = data => {
    let selected1 = data.selected;
    let offset1 = Math.ceil(selected1 * this.state.perPage);

    this.setState({ offset: offset1 }, () => {
      this.Vai_Buscar_Dados();
    });
  };

  handleClickOpenDialogEliminaCheckpoint = () => {
    this.setState({ open_dialog_elimina_checkpoint: true });
  };

  handleCloseDialogEliminaCheckpoint = () => {
    this.setState({ open_dialog_elimina_checkpoint: false });
  };

  handleEliminaCheckpoint = () => {
    this.setState({
      open_dialog_elimina_checkpoint: false //, Logout_Realizado: true
    });
  };

  Vai_Buscar_Dados() {
    /*
    axios.get(REACT_APP_API_URL+"/devolve_checkpoints_sistema").then(res => {
      console.log(res);
      this.setState({ items: res.data });
    });
    */

    const Token_Lido = localStorage.getItem("Token");
    axios
      .get(
        REACT_APP_API_URL +
          "/devolve_checkpoints_sistema/" +
          this.state.perPage +
          "/" +
          this.state.offset,
        {
          headers: { authorization: "Bearer " + Token_Lido }
        }
      )
      .then(res => {
        console.log(res);
        this.setState({ items: res.data });
        this.setState({
          pageCount: Math.ceil(this.state.total_items / this.state.perPage)
        });
      });
  }

  Vai_Buscar_Numero_Checkpoints_Sistema() {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .get(REACT_APP_API_URL + "/devolve_numero_checkpoints_sistema", {
        headers: { authorization: "Bearer " + Token_Lido }
      })
      .then(res => {
        console.log(res);
        this.setState({ total_items: res.data[0].Total });
      });
  }

  componentDidMount() {
    if (localStorage.getItem("Token") !== null) {
    this.Vai_Buscar_Numero_Checkpoints_Sistema();
    this.Vai_Buscar_Dados();}
  } //Fecha Procedimento Que Acontece Depois do Output do Componente Foi Rendered

  //Actualiza os Dados Depois de Acontecer um Redirect Para Este Componente
  componentDidUpdate() {
    //nextProps) {
    //if (nextProps.location.pathname !== this.props.location.pathname) {
    if (this.state.Faz_Refresh) {
      this.Vai_Buscar_Numero_Checkpoints_Sistema();
      this.Vai_Buscar_Dados();
      this.setState({ Faz_Refresh: false });
    }
  }

  /*
  //Actualiza os Dados Depois de Acontecer um Redirect Para Esta Tabela
  componentDidUpdate() {
    this.Vai_Buscar_Numero_Checkpoints_Sistema();
    this.Vai_Buscar_Dados();
  }
  */

  //Funcoes Para a Mensagem de Login Com Sucesso na Snackbar
  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ Fez_Insercao_Checkpoint_Sistema: false });
  };

  //Funcoes Para a Mensagem de Login Com Sucesso na Snackbar
  handleCloseEdicao_Checkpoint = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ Fez_Edicao_Checkpoint_Sistema: false });
  };

  Mostra_Mensagem_Inscricao_Sucesso = () => {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={this.state.Fez_Insercao_Checkpoint_Sistema}
        autoHideDuration={3000}
        onClose={this.handleClose}
      >
        <MySnackbarContentWrapper
          onClose={this.handleClose}
          variant="success"
          message="Checkpoint Inserido Com Sucesso!"
        />
      </Snackbar>
    );
  };

  Mostra_Mensagem_Edicao_Sucesso = () => {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={this.state.Fez_Edicao_Checkpoint_Sistema}
        autoHideDuration={3000}
        onClose={this.handleCloseEdicao_Checkpoint}
      >
        <MySnackbarContentWrapper
          onClose={this.handleCloseEdicao_Checkpoint}
          variant="success"
          message="Checkpoint Editado Com Sucesso!"
        />
      </Snackbar>
    );
  };

  render() {
    if (localStorage.getItem("Token") === null) {
      this.props.history.push({
        pathname: "/"
      });
    }

    //Verifica Se Existe Erro. Se Houver Mostra Mensagem de Erro
    if (this.state.Mostra_Erro === true) {
      return (
        <div>
          <Drawer
            Titulo_Menu="" //"Erro"
            history={this.props.history}
          />
          {mostra_mensagem_erro()}
        </div>
      );
    } else {
      if (
        this.state.items === undefined ||
        this.state.total_items === undefined
      ) {
        return (
          <div className="centra_loading">
            <h1>Loading...</h1>
          </div>
        );
      } else {
        if (this.state.items.length > 0) {
          return (
            <div>
              <Dialog
                open={this.state.open_dialog_elimina_checkpoint}
                onClose={this.handleCloseDialogEliminaCheckpoint}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Tem a Certeza Que Deseja Eliminar o Checkpoint?"}
                </DialogTitle>
                <DialogActions>
                  <Button
                    onClick={this.handleCloseDialogEliminaCheckpoint}
                    color="primary"
                  >
                    Cancelar
                  </Button>
                  <Button
                    onClick={this.handleEliminaCheckpoint}
                    color="primary"
                    autoFocus
                  >
                    Sair
                  </Button>
                </DialogActions>
              </Dialog>

              <Drawer
                Titulo_Menu="Lista Checkpoints Sistema"
                Link_Menu_Anterior={this.state.Link_Menu_Anterior}
                Link_Tabela_Anterior={this.props.location.pathname} //{this.state.Link_Tabela_Anterior}
                history={this.props.history}
              />
              <div className="centra_tabelas">
                <tabela>
                  <thead>
                    <tr>
                      <th colSpan="4">
                        Número Total de Checkpoints no Sistema:{" "}
                        {this.state.total_items}
                      </th>
                    </tr>
                  </thead>
                  <thead>
                    <tr>
                      <th onClick={() => this.sortBy("local")}>
                        Local Checkpoint{" "}
                        <i
                          className="fa fa-angle-down fa-sm" /*fa fa-sort fa-sm*/
                        />
                      </th>
                      <th onClick={() => this.sortBy("coordinate")}>
                        Coordenadas{" "}
                        <i
                          className="fa fa-angle-down fa-sm" /*fa fa-sort fa-sm*/
                        />
                      </th>
                      <th>Altitude </th>
                      {/*
                      <th onClick={() => this.sortBy("altitude")}>
                        Altitude{" "}
                        <i
                          className="fa fa-angle-down fa-sm" /*fa fa-sort fa-sm
                        />
                      </th>
                      */}
                      <th>Acção</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.items.map(linha2 => {
                      return (
                        <tr>
                          <td>{linha2.local}</td>
                          <td>{linha2.coordinate}</td>
                          <td>{linha2.altitude}</td>
                          <td>
                            <Link
                              to={{
                                pathname: "/editar_checkpoint",
                                state: {
                                  ID_Checkpoint: linha2.checkpoint_id,
                                  Link_Tabela_Anterior: this.props.location
                                    .pathname,
                                  Link_Menu_Anterior: this.state
                                    .Link_Menu_Anterior,
                                  Faz_Refresh: false //true
                                }
                              }}
                              title="Editar"
                            >
                              <i className="fa fa-edit fa-lg espaco_icons" />
                            </Link>{" "}
                            <span
                              title="Remover Checkpoint"
                              onClick={
                                this.handleClickOpenDialogEliminaCheckpoint
                              }
                            >
                              <i className="fa fa-trash fa-lg" />
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </tabela>
                <div>
                  <ReactPaginate
                    previousLabel={"Anterior"}
                    nextLabel={"Próximo"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
              {this.Mostra_Mensagem_Inscricao_Sucesso()}
              {this.Mostra_Mensagem_Edicao_Sucesso()}
            </div>
          );
        } else {
          return (
            <div>
              <Drawer
                Titulo_Menu="Lista Checkpoints Sistema"
                Link_Menu_Anterior={this.state.Link_Menu_Anterior}
                Link_Tabela_Anterior={this.props.location.pathname} //{this.state.Link_Tabela_Anterior}
                history={this.props.history}
              />
              {ve_resultados()}
            </div>
          );
        }
      }
    }
  }
}

export default Tabela_Lista_Checkpoints;
