import React, { Component } from "react";
//import { Link } from "react-router-dom";
import axios from "axios";
import ReactPaginate from "react-paginate";
import Moment from "moment";

//Imports Para Drawer e Snackbar:
import Drawer from "../Drawer/Drawer.js";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import classNames from "classnames";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import green from "@material-ui/core/colors/green";
import amber from "@material-ui/core/colors/amber";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import WarningIcon from "@material-ui/icons/Warning";

//imports Para o Dialog de Logout
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
//import DialogContent from '@material-ui/core/DialogContent';
//import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from "@material-ui/core/DialogTitle";

//Funções Auxiliares:
import {
  //mostra_mensagem_erro,
  ve_resultados
} from "../Funcoes_Auxiliares/Funcoes_Auxiliares.js";

let REACT_APP_API_URL;

if (process.env.REACT_APP_ESTADO === "Deploy") {
  REACT_APP_API_URL = process.env.REACT_APP_API_URL_PROD;
} //Development
else {
  REACT_APP_API_URL = process.env.REACT_APP_API_URL_DEV;
}

//Constantes Para Drawer e Snackbar:
const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const styles1 = theme => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.dark
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit
  },
  message: {
    display: "flex",
    alignItems: "center"
  }
});

MySnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(["success", "warning", "error", "info"]).isRequired
};

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

//Funções Para Drawer e Snackbar:
function MySnackbarContent(props) {
  const { classes, className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>
      ]}
      {...other}
    />
  );
}

class Tabela_Lista_Utilizadores_Registados extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: undefined, //[],
      Headers_Colunas: [],

      //Variavel de Controlo Para a Mensagem de Inscrição Com Sucesso na Snackbar:
      Fez_Inscricao_Utilizador: false,
      Fez_Desactivacao_Utilizador: false,
      //Fez_Update_Utilizador: false,

      //Variaveis Para a Paginação:
      total_items: 0,
      offset: 0,
      perPage: 10,
      pageCount: null,

      //Variavel Controlo de Erro na Leitura dos Props
      Mostra_Erro: false,

      //Variaveis de Controlo dos Dialogs:
      open_dialog_elimina_utilizador: false,
      nome_utilizador_eliminar: null,

      //Variavel de Controlo Para Evitar o Bug da Paginação Não Aparecer
      Faz_Refresh: false,

      //Desactivado_Sucesso: false,
      ID_Utilizador_Desactivar: null
    };

    if (props.location.state === undefined) {
      this.state.Fez_Inscricao_Utilizador = false;
    } else {
      if (props.location.state.Fez_Inscricao_Utilizador === undefined) {
        this.state.Fez_Inscricao_Utilizador = false;
      } else {
        this.state.Fez_Inscricao_Utilizador =
          props.location.state.Fez_Inscricao_Utilizador;
      }
      if (props.location.state.Faz_Refresh === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Faz_Refresh = props.location.state.Faz_Refresh;
      }
      if (props.location.state.Fez_Desactivacao_Utilizador === undefined) {
        this.state.Fez_Desactivacao_Utilizador = false;
      } else {
        this.state.Fez_Desactivacao_Utilizador =
          props.location.state.Fez_Desactivacao_Utilizador;
      }

      /*
      if (props.location.state.Fez_Update_Utilizador === undefined) {
        this.state.Fez_Update_Utilizador = false;
      } else {
        this.state.Fez_Update_Utilizador =
          props.location.state.Fez_Update_Utilizador;
      }*/
    }

    this.handlePageClick = this.handlePageClick.bind(this);
    this.handleChange = this.handleChange.bind(this);

    //Metodos Para Ordenações da Tabela
    this.compareBy.bind(this);
    this.sortBy.bind(this);
    this.compareByNumber.bind(this);
  } //Fecha Construtor da Class

  compareBy(key) {
    return function(a, b) {
      if (a[key] < b[key]) return -1;
      if (a[key] > b[key]) return 1;
      return 0;
    };
  }

  compareByNumber(key) {
    return function(a, b) {
      return parseInt(a[key]) - parseInt(b[key]);
    };
  }

  sortBy(key) {
    let arrayCopy = [...this.state.items];
    if (
      key === "id_utilizador" ||
      key === "eliminado" ||
      key === "inscrito_por_utilizador_id"
    ) {
      arrayCopy.sort(this.compareByNumber(key));
    } else {
      arrayCopy.sort(this.compareBy(key));
    }
    this.setState({ items: arrayCopy });
  }

  handlePageClick = data => {
    let selected1 = data.selected;
    let offset1 = Math.ceil(selected1 * this.state.perPage);

    this.setState({ offset: offset1 }, () => {
      this.Vai_Buscar_Dados();
    });
  };

  handleClickOpenDialogEliminaUtilizador = () => {
    //= nome_utilizador => {
    this.setState({
      open_dialog_elimina_utilizador: true
      //nome_utilizador_eliminar: nome_utilizador
    });
  };

  handleCloseDialogEliminaUtilizador = () => {
    this.setState({ open_dialog_elimina_utilizador: false });
  };

  handleEliminaUtilizador = () => {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .patch(
        REACT_APP_API_URL +
          "/desactiva_utilizador/" +
          this.state.ID_Utilizador_Desactivar,
        {},
        {
          headers: { authorization: "Bearer " + Token_Lido }
        }
      )
      .then(res => {
        this.setState({
          open_dialog_elimina_utilizador: false,
          //Desactivado_Sucesso: res.data.success,
          Faz_Refresh: true,
          Fez_Desactivacao_Utilizador: true
        });
      });

    /*
    this.setState({
      open_dialog_elimina_utilizador: false //, Logout_Realizado: true
    });
    */
  };

  Vai_Buscar_Dados() {
    const Token_Lido = localStorage.getItem("Token");
    if (this.state.perPage > 0) {
      axios
        .get(
          REACT_APP_API_URL +
            "/devolve_utilizadores_registados" +
            "/" +
            this.state.perPage +
            "/" +
            this.state.offset,
          {
            headers: { authorization: "Bearer " + Token_Lido }
          }
        )
        .then(res => {
          console.log(res);
          this.setState({ items: res.data });
          this.setState({
            pageCount: Math.ceil(this.state.total_items / this.state.perPage)
          });
        });
    } else {
      axios
        .get(REACT_APP_API_URL + "/devolve_utilizadores_registados/", {
          headers: { authorization: "Bearer " + Token_Lido }
        })
        .then(res => {
          console.log(res);
          this.setState({ items: res.data });
          this.setState({ pageCount: 1 });
        });
    }
  }

  Vai_Buscar_Numero_Utilizadores_Registados() {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .get(REACT_APP_API_URL + "/devolve_numero_utilizadores_registados", {
        headers: { authorization: "Bearer " + Token_Lido }
      })
      .then(res => {
        console.log(res);
        this.setState({ total_items: res.data[0].Total });
      });
  }

  componentDidMount() {
    if (localStorage.getItem("Token") !== null) {
      this.Vai_Buscar_Numero_Utilizadores_Registados();
      this.Vai_Buscar_Dados();
    }
  } //Fecha Procedimento Que Acontece Depois do Output do Componente Foi Rendered

  //Actualiza os Dados Depois de Acontecer um Redirect Para Este Componente
  componentDidUpdate() {
    //nextProps) {
    //if (nextProps.location.pathname !== this.props.location.pathname) {
    if (this.state.Faz_Refresh) {
      this.Vai_Buscar_Numero_Utilizadores_Registados();
      this.Vai_Buscar_Dados();
      this.setState({ Faz_Refresh: false });
    }
  }

  /*
  //Actualiza os Dados Depois de Acontecer um Redirect Para Esta Tabela
  componentDidUpdate() {
    this.Vai_Buscar_Dados();
  }
  */

  //Funcoes Para a Mensagem de Login Com Sucesso na Snackbar
  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ Fez_Inscricao_Utilizador: false });
  };

  handleCloseDesactivacao = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ Fez_Desactivacao_Utilizador: false });
  };

  Mostra_Mensagem_Inscricao_Sucesso = () => {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={this.state.Fez_Inscricao_Utilizador}
        autoHideDuration={3000}
        onClose={this.handleClose}
      >
        <MySnackbarContentWrapper
          onClose={this.handleClose}
          variant="success"
          message="Utilizador Registado Com Sucesso!"
        />
      </Snackbar>
    );
  };

  Mostra_Mensagem_Desactivacao_Sucesso = () => {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={this.state.Fez_Desactivacao_Utilizador}
        autoHideDuration={3000}
        onClose={this.handleCloseDesactivacao}
      >
        <MySnackbarContentWrapper
          onClose={this.handleCloseDesactivacao}
          variant="success"
          message="Utilizador Desactivado Com Sucesso!"
        />
      </Snackbar>
    );
  };

  handleChange(event) {
    /*
    this.setState({ [event.target.name]: event.target.value });
    this.Vai_Buscar_Dados();
    */
    this.setState({ [event.target.name]: event.target.value }, () => {
      this.Vai_Buscar_Dados();
    });
  }

  render() {
    if (localStorage.getItem("Token") === null) {
      this.props.history.push({
        pathname: "/"
      });
    }

    /*
    if (this.state.Desactivado_Sucesso === true) {
      this.props.history.push({
        pathname: "/ver_utilizadores",
        state: {
          Fez_Inscricao_Utilizador: false,
          Faz_Refresh: true
        }
      });
    }
    */

    if (
      this.state.items === undefined ||
      this.state.total_items === undefined
    ) {
      return (
        <div className="centra_loading">
          <h1>Loading...</h1>
        </div>
      );
    } else {
      if (this.state.items.length > 0) {
        return (
          <div>
            <Dialog
              open={this.state.open_dialog_elimina_utilizador}
              onClose={this.handleCloseDialogEliminaUtilizador}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {
                  "Tem a Certeza Que Deseja Desactivar o Utilizador?"
                  /*"Tem a Certeza Que Deseja Eliminar a Inscrição do Atleta: " +
                  this.state.nome_utilizador_eliminar +
        "?"*/
                }
              </DialogTitle>
              <DialogActions>
                <Button
                  onClick={this.handleCloseDialogEliminaUtilizador}
                  color="primary"
                >
                  Cancelar
                </Button>
                <Button
                  onClick={this.handleEliminaUtilizador}
                  color="primary"
                  autoFocus
                >
                  Sim
                </Button>
              </DialogActions>
            </Dialog>

            <Drawer
              Titulo_Menu="Ver Utilizadores Sistema"
              Link_Menu_Anterior={this.props.location.pathname}
              history={this.props.history}
            />
            <div className="novo_centra_tabelas">
              <tabela>
                <thead>
                  <tr>
                    <th colSpan="8">
                      Número Total de Utilizadores Registados:{" "}
                      {this.state.total_items}
                    </th>
                  </tr>
                </thead>
                <thead>
                  <tr>
                    <th onClick={() => this.sortBy("id_utilizador")}>
                      Numero{" "}
                      <i
                        className="fa fa-angle-down fa-sm" /*fa fa-sort fa-sm*/
                      />
                    </th>
                    <th onClick={() => this.sortBy("nome")}>
                      Nome Utilizador{" "}
                      <i
                        className="fa fa-angle-down fa-sm" /*fa fa-sort fa-sm*/
                      />
                    </th>
                    <th onClick={() => this.sortBy("login")}>
                      Username{" "}
                      <i
                        className="fa fa-angle-down fa-sm" /*fa fa-sort fa-sm*/
                      />
                    </th>
                    <th onClick={() => this.sortBy("tipo_utilizador")}>
                      Tipo Utilizador{" "}
                      <i
                        className="fa fa-angle-down fa-sm" /*fa fa-sort fa-sm*/
                      />
                    </th>
                    <th onClick={() => this.sortBy("eliminado")}>
                      Activo{" "}
                      <i
                        className="fa fa-angle-down fa-sm" /*fa fa-sort fa-sm*/
                      />
                    </th>
                    <th onClick={() => this.sortBy("data_inscricao")}>
                      Data Inscrição{" "}
                      <i
                        className="fa fa-angle-down fa-sm" /*fa fa-sort fa-sm*/
                      />
                    </th>
                    <th
                      onClick={() => this.sortBy("organizacoes_id_organizacao")}
                    >
                      Organização{" "}
                      <i
                        className="fa fa-angle-down fa-sm" /*fa fa-sort fa-sm*/
                      />
                    </th>
                    {/*}
                    <th
                      onClick={() => this.sortBy("inscrito_por_utilizador_id")}
                    >
                      Adicionado Por{" "}
                      <i
                        className="fa fa-angle-down fa-sm" //fa fa-sort fa-sm
                      />
                    </th>*/}
                    <th>Acção</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.items.map(linha2 => {
                    return (
                      <tr
                        onClick={() =>
                          this.setState({
                            ID_Utilizador_Desactivar: linha2.id_utilizador
                          })
                        }
                      >
                        <td>{linha2.id_utilizador}</td>
                        <td style={{ width: "250px" }}>{linha2.nome}</td>
                        <td style={{ width: "200px" }}>{linha2.login}</td>
                        <td>
                          {
                            linha2.tipo_utilizador /*=== "admin"
                            ? "Gestor"
                            : linha2.tipo_utilizador === "super_admin"
                            ? "Administrador"
                    : "*"*/
                          }
                        </td>
                        <td>{linha2.eliminado ? "Não" : "Sim"}</td>
                        <td>
                          {Moment(linha2.data_inscricao).format("DD-MM-YYYY")}
                        </td>
                        <td>{linha2.organizacoes_id_organizacao}</td>
                        {/*<td>{linha2.inscrito_por_utilizador_id}</td>*/}
                        <td>
                          {/*
                          <Link
                            to={{
                              pathname: "/editar_utilizador",
                              state: {
                                Link_Menu_Anterior: this.props.location
                                  .pathname,
                                ID_Utilizador: linha2.id_utilizador,
              Faz_Refresh: true
                              }
                            }}
                            title="Editar"
                          >
                            <i className="fa fa-edit fa-lg espaco_icons" />
                          </Link>{" "} 
                          */}
                          <span
                            title="Remover Utilizador"
                            onClick={
                              this.handleClickOpenDialogEliminaUtilizador //(linha2.nome)
                            }
                          >
                            <i className="fa fa-trash fa-lg" />
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </tabela>
              {
                <div style={{ marginTop: "10px" }}>
                  <label>Resultados Por Página</label>{" "}
                  <select
                    name="perPage"
                    id="perPage"
                    onChange={this.handleChange}
                  >
                    <option value="10"> 10 </option>
                    <option value="25"> 25 </option>
                    <option value="50"> 50 </option>
                    <option value="100"> 100 </option>
                    <option value="0">Todos </option>
                  </select>
                </div>
              }
              <div>
                <ReactPaginate
                  previousLabel={"Anterior"}
                  nextLabel={"Próximo"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              </div>
            </div>
            {this.Mostra_Mensagem_Inscricao_Sucesso()}
            {this.Mostra_Mensagem_Desactivacao_Sucesso()}
          </div>
        );
      } else {
        return (
          <div>
            <Drawer
              Titulo_Menu="Ver Utilizadores Sistema"
              Link_Menu_Anterior={this.props.location.pathname}
              history={this.props.history}
            />
            {ve_resultados()}
          </div>
        );
      }
    }
  }
}

export default Tabela_Lista_Utilizadores_Registados;
