import React, { Component } from "react";
//import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import Drawer from "../Drawer/Drawer.js";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

//Funções Auxiliares:
import {
  mostra_mensagem_erro,
  //ve_resultados,
  Trata_Validacoes,
  isEmptyObject
} from "../Funcoes_Auxiliares/Funcoes_Auxiliares.js";

let REACT_APP_API_URL;

if (process.env.REACT_APP_ESTADO === "Deploy") {
  REACT_APP_API_URL = process.env.REACT_APP_API_URL_PROD;
} //Development
else {
  REACT_APP_API_URL = process.env.REACT_APP_API_URL_DEV;
}

class Formulario_Adicionar_Checkpoint_Sistema extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Link_Menu_Anterior: null,
      Link_Tabela_Anterior: null,
      local_checkpoint: null,
      coordenadas: null,
      altitude: null,

      //Variavel Controlo de Erro na Leitura dos Props
      Mostra_Erro: false,

      //Variavel Para Controlar o Redirect
      Inserido_Sucesso: false,

      //Lista Para os Erros de Validação:
      errors: {}
    };

    if (props.location.state === undefined) {
      this.state.Mostra_Erro = true;
    } else {
      if (props.location.state.Link_Menu_Anterior === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Link_Menu_Anterior = props.location.state.Link_Menu_Anterior;
      }
      if (props.location.state.Link_Tabela_Anterior === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Link_Tabela_Anterior =
          props.location.state.Link_Tabela_Anterior;
      }
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  /*
  componentDidMount() {
  } //Fecha Procedimento Que Acontece Depois do Output do Componente Foi Rendered
  */

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleValidation() {
    let errors = {};
    let formIsValid = false; //true;

    //Para Cada Campo Invocar o Trata_Validacoes
    errors = Trata_Validacoes(
      this.state.local_checkpoint,
      "checkpoint/local",
      errors
    );
    errors = Trata_Validacoes(
      this.state.coordenadas,
      "checkpoint/coordinate",
      errors
    );
    errors = Trata_Validacoes(
      this.state.altitude,
      "checkpoint/altitude",
      errors
    );

    //if (errors.length > 0) {
    if (isEmptyObject(errors)) {
      formIsValid = true; //false;
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.handleValidation()) {
      const Token_Lido = localStorage.getItem("Token");
      axios
        .post(
          REACT_APP_API_URL + "/insere_checkpoint_sistema",
          {
            local_checkpoint: this.state.local_checkpoint,
            coordenadas: this.state.coordenadas,
            altitude: this.state.altitude
          },
          {
            headers: { authorization: "Bearer " + Token_Lido }
          }
        )
        .then(res => {
          this.setState({ Inserido_Sucesso: res.data.success });
        });
    }
  }

  render() {
    if (localStorage.getItem("Token") === null) {
      this.props.history.push({
        pathname: "/"
      });
    }

    if (this.state.Inserido_Sucesso === true) {
      this.props.history.push({
        pathname: this.state.Link_Tabela_Anterior,
        state: {
          Link_Menu_Anterior: this.state.Link_Menu_Anterior,
          Fez_Insercao_Checkpoint_Sistema: true,
          Fez_Edicao_Checkpoint_Sistema: false,
          Faz_Refresh: true
        }
      });
    }

    //Verifica Se Existe Erro. Se Houver Mostra Mensagem de Erro
    if (this.state.Mostra_Erro === true) {
      return (
        <div>
          <Drawer
            Titulo_Menu="" //"Erro"
            history={this.props.history}
          />
          {mostra_mensagem_erro()}
        </div>
      );
    } else {
      const border_erro = "1px solid #ff0000";

      return (
        <div>
          <Drawer
            Titulo_Menu="Inserir Novo Checkpoint Sistema"
            Link_Tabela_Anterior={this.state.Link_Tabela_Anterior}
            Link_Menu_Anterior={this.state.Link_Menu_Anterior}
            history={this.props.history}
          />
          <div className="margens_volta_novos_forms">
            <div className="centra_novos_forms">
              <form className="login-form" onSubmit={this.handleSubmit}>
                <div className="centra_titulos_tabs">
                  <Tabs>
                    <TabList>
                      <Tab>Informação do Checkpoint</Tab><button type="submit" style={{ marginLeft: "10px" }}>Adicionar Checkpoint</button>
                    </TabList>
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    <TabPanel>
                      <div className="form">
                        <div>
                          <label>
                            Local do Checkpoint
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {" (*)"}
                            </span>
                          </label>
                          <input
                            style={{
                              border: this.state.errors["checkpoint/local"]
                                ? border_erro
                                : "",
                              textAlign: "center"
                            }}
                            type="text"
                            name="local_checkpoint"
                            placeholder="Local do Checkpoint"
                            onChange={this.handleChange}
                            defaultValue={this.state.local_checkpoint}
                          />
                          <span className="bolder" style={{ color: "#e60000" }}>
                            {this.state.errors["checkpoint/local"]}
                          </span>
                          <div className="espaco_span" />

                          <label>
                            Coordenadas
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {" (*)"}
                            </span>
                          </label>
                          <input
                            style={{
                              border: this.state.errors["checkpoint/coordinate"]
                                ? border_erro
                                : "",
                              textAlign: "center"
                            }}
                            type="text"
                            name="coordenadas"
                            placeholder="Coordenadas"
                            onChange={this.handleChange}
                            defaultValue={this.state.coordenadas}
                          />
                          <span className="bolder" style={{ color: "#e60000" }}>
                            {this.state.errors["checkpoint/coordinate"]}
                          </span>
                          <div className="espaco_span" />

                          <label>
                            Altitude
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {" (*)"}
                            </span>
                          </label>
                          <input
                            style={{
                              border: this.state.errors["checkpoint/altitude"]
                                ? border_erro
                                : "",
                              textAlign: "center"
                            }}
                            type="text"
                            name="altitude"
                            placeholder="Altitude"
                            onChange={this.handleChange}
                            defaultValue={this.state.altitude}
                          />
                          <span className="bolder" style={{ color: "#e60000" }}>
                            {this.state.errors["checkpoint/altitude"]}
                          </span>
                        </div>
                        <div className="espaco_span">
                          <span className="bolder" style={{ color: "#e60000" }}>
                            {" (*)"}
                          </span>{" "}
                          <span className="bolder"> Campos Obrigatórios</span>
                        </div>
                      </div>
                        {/*}
                      <div className="form">
                        <button type="submit">Adicionar Checkpoint</button>
                      </div>
                              */}
                    </TabPanel>
                  </Tabs>
                </div>
              </form>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default Formulario_Adicionar_Checkpoint_Sistema;
