import React, { Component } from "react";
//import { Link } from "react-router-dom";
import axios from "axios";
import Moment from "moment";
import Drawer from "../Drawer/Drawer.js";
import Select from "react-select";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

//Funções Auxiliares:
import {
  mostra_mensagem_erro,
  //ve_resultados,
  Trata_Validacoes,
  isEmptyObject
} from "../Funcoes_Auxiliares/Funcoes_Auxiliares.js";

let REACT_APP_API_URL;

if (process.env.REACT_APP_ESTADO === "Deploy") {
  REACT_APP_API_URL = process.env.REACT_APP_API_URL_PROD;
} //Development
else {
  REACT_APP_API_URL = process.env.REACT_APP_API_URL_DEV;
}

const opcoes_select_activo = [
  { value: 1, label: "Sim" },
  { value: 0, label: "Não" }
];

class Formulario_Adicionar_Competicao extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Link_Menu_Anterior: null,
      ID_Evento: null,
      Nome_Evento: null,

      nome_competicao: null,
      data_inicio: null,
      data_fim: null,
      distancia_total: null,
      activo: null,
      codigo_cor: null,
      kml_file_name: null,
      //id_evento: null,
      nome_completo_competicao: null,
      numero_maximo_inscricoes: null,
      //certificado: null,
      //cert_tpl: null,
      //cert_code: null,
      alterar_atletas: null,

      //Variavel Controlo de Erro na Leitura dos Props
      Mostra_Erro: false,

      //Variavel Para Controlar o Redirect
      Inserido_Sucesso: false,

      //Lista Para os Erros de Validação:
      errors: {}
    };

    if (props.location.state === undefined) {
      this.state.Mostra_Erro = true;
    } else {
      if (props.location.state.Link_Menu_Anterior === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Link_Menu_Anterior = props.location.state.Link_Menu_Anterior;
      }
      if (props.location.state.ID_Evento === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.ID_Evento = props.location.state.ID_Evento;
      }
      if (props.location.state.Nome_Evento === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Nome_Evento = props.location.state.Nome_Evento;
      }
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  Vai_Buscar_Nome_Evento() {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .get(REACT_APP_API_URL + "/devolve_nome_evento/" + this.state.ID_Evento, {
        headers: { authorization: "Bearer " + Token_Lido }
      })
      .then(res => {
        console.log(res);
        this.setState({ Nome_Evento: res.data[0].name });
      });
  }

  componentDidMount() {
    if (localStorage.getItem("Token") !== null) {
      this.Vai_Buscar_Nome_Evento();
    }
  } //Fecha Procedimento Que Acontece Depois do Output do Componente Foi Rendered

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleChangeSelectActivo = activo => {
    this.setState({ activo });
  };

  handleChangeSelectCertificado = certificado => {
    this.setState({ certificado });
  };

  handleChangeSelectAlterarAtletas = alterar_atletas => {
    this.setState({ alterar_atletas });
  };

  handleValidation() {
    let errors = {};
    let formIsValid = false; //true;

    let Data_Inicio = Moment(this.state.data_inicio);
    let Data_Fim = Moment(this.state.data_fim);

    //Para Cada Campo Invocar o Trata_Validacoes
    errors = Trata_Validacoes(
      this.state.nome_completo_competicao,
      "competition/long_name",
      errors
    );
    errors = Trata_Validacoes(
      this.state.nome_competicao,
      "competition/name",
      errors
    );
    errors = Trata_Validacoes(
      this.state.data_inicio,
      "competition/init_date",
      errors
    );
    errors = Trata_Validacoes(
      this.state.data_fim,
      "competition/end_date",
      errors
    );
    errors = Trata_Validacoes(
      this.state.distancia_total,
      "competition/total_distance",
      errors
    );
    errors = Trata_Validacoes(this.state.activo, "competition/active", errors);
    //errors = Trata_Validacoes(this.state.codigo_cor,"competition/color_code",errors);
    //errors = Trata_Validacoes(this.state.kml_file_name,"competition/kml_file_name",errors);
    errors = Trata_Validacoes(
      this.state.numero_maximo_inscricoes,
      "competition/max_inscriptions",
      errors
    );
    /*
    errors = Trata_Validacoes(
      this.state.certificado,
      "competition/certification",
      errors
    );
    errors = Trata_Validacoes(
      this.state.cert_tpl,
      "competition/cert_tpl",
      errors
    );
    errors = Trata_Validacoes(
      this.state.cert_code,
      "competition/cert_code",
      errors
    );
    */

    errors = Trata_Validacoes(
      this.state.alterar_atletas,
      "competition/alterar_atletas",
      errors
    );

    //Verifica Coerência das Datas
    if (
      this.state.data_inicio &&
      this.state.data_fim &&
      //Moment(this.state.data_inicio).isSameorAfter(this.state.data_inicio)

      Data_Inicio.diff(Data_Fim) >= 0 //, "days"); //Negativo, Data_Inicio<Data_Fim
    ) {
      formIsValid = false;
      errors["competition/end_date"] = "*Data de Fim de Competição Inválida";
    }

    //if (errors.length > 0) {
    if (isEmptyObject(errors)) {
      formIsValid = true; //false;
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.handleValidation()) {
      const Token_Lido = localStorage.getItem("Token");
      axios
        .post(
          REACT_APP_API_URL + "/inserir_competicao/",
          {
            nome_competicao: this.state.nome_competicao,
            //data_inicio: Moment(this.state.data_inicio, "YYYY-MM-DD HH:mm:ss"), //this.state.data_inicio,
            //data_fim: Moment(this.state.data_fim, "YYYY-MM-DD HH:mm:ss"), //this.state.data_fim,
            data_inicio: this.state.data_inicio,
            data_fim: this.state.data_fim,
            distancia_total: this.state.distancia_total,
            activo: this.state.activo.value,
            codigo_cor: this.state.codigo_cor,
            kml_file_name: this.state.kml_file_name,
            id_evento: this.state.ID_Evento,
            nome_completo_competicao: this.state.nome_completo_competicao,
            numero_maximo_inscricoes: this.state.numero_maximo_inscricoes,
            //certificado: this.state.certificado.value,
            //cert_tpl: this.state.cert_tpl,
            //cert_code: this.state.cert_code,
            alterar_atletas: this.state.alterar_atletas.value
          },
          {
            headers: { authorization: "Bearer " + Token_Lido }
          }
        )
        .then(res => {
          //console.log(res);
          this.setState({ Inserido_Sucesso: res.data.success });
        });
    }
  }

  render() {
    if (localStorage.getItem("Token") === null) {
      this.props.history.push({
        pathname: "/"
      });
    }

    if (this.state.Inserido_Sucesso === true) {
      this.props.history.push({
        pathname: this.state.Link_Menu_Anterior,
        state: {
          ID_Evento: this.state.ID_Evento,
          Fez_Insercao_Competicao: true,
          Fez_Edicao_Competicao: false,
          Faz_Refresh: true,
          Nome_Evento: this.state.Nome_Evento,
          Fez_Eliminacao_Competicao: false
        }
      });
    }

    //Verifica Se Existe Erro. Se Houver Mostra Mensagem de Erro
    if (this.state.Mostra_Erro === true) {
      return (
        <div>
          <Drawer
            Titulo_Menu="" //"Erro"
            history={this.props.history}
          />
          {mostra_mensagem_erro()}
        </div>
      );
    } else {
      const border_erro = "1px solid #ff0000";

      return (
        <div>
          <Drawer
            //Titulo_Menu="Inserir Nova Competição"
            Titulo_Menu="Inserir Nova Prova"
            Link_Menu_Anterior={this.state.Link_Menu_Anterior}
            ID_Evento={this.state.ID_Evento}
            Nome_Evento={this.state.Nome_Evento}
            history={this.props.history}
          />
          <div className="novas_margens_volta_novos_forms">
            <div className="centra_novos_forms">
              <form className="login-form" onSubmit={this.handleSubmit}>
                <div className="centra_titulos_tabs">
                  <Tabs>
                    <TabList>
                      <Tab
                        style={{
                          border:
                            this.state.errors["competition/long_name"] ||
                            this.state.errors["competition/name"] ||
                            this.state.errors["competition/total_distance"] ||
                            this.state.errors["competition/max_inscriptions"]
                              ? border_erro
                              : ""
                        }}
                      >
                        Informações Competição
                      </Tab>
                      <Tab
                        style={{
                          border:
                            this.state.errors["competition/init_date"] ||
                            this.state.errors["competition/end_date"]
                              ? border_erro
                              : ""
                        }}
                      >
                        Datas
                      </Tab>
                      <Tab
                        style={{
                          border:
                            this.state.errors["competition/active"] ||
                            this.state.errors["competition/alterar_atletas"]
                              ? border_erro
                              : ""
                        }}
                      >
                        Diversos
                      </Tab>
                      {/*<Tab>Certificados</Tab>*/}
                      <button type="submit" style={{ marginLeft: "10px" }}>
                        Adicionar Competição
                      </button>
                    </TabList>
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    <TabPanel>
                      <div className="form">
                        <div>
                          <label>
                            Nome Competição
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {" (*)"}
                            </span>
                          </label>
                          <input
                            style={{
                              border: this.state.errors["competition/long_name"]
                                ? border_erro
                                : "",
                              textAlign: "center"
                            }}
                            type="text"
                            name="nome_completo_competicao"
                            placeholder="Nome Completo Competicao"
                            onChange={this.handleChange}
                            defaultValue={this.state.nome_completo_competicao}
                            //required
                          />
                          <span className="bolder" style={{ color: "#e60000" }}>
                            {this.state.errors["competition/long_name"]}
                          </span>
                          <div className="espaco_span" />
                          <label>
                            Abreviatura Competição
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {" (*)"}
                            </span>
                          </label>
                          <input
                            style={{
                              border: this.state.errors["competition/name"]
                                ? border_erro
                                : "",
                              textAlign: "center"
                            }}
                            type="text"
                            name="nome_competicao"
                            placeholder="Nome Competição"
                            onChange={this.handleChange}
                            defaultValue={this.state.nome_competicao}
                            //required
                          />
                          <span className="bolder" style={{ color: "#e60000" }}>
                            {this.state.errors["competition/name"]}
                          </span>
                          <div className="espaco_span" />
                          <label>
                            Distância Total
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {" (*)"}
                            </span>
                          </label>
                          <input
                            style={{
                              border: this.state.errors[
                                "competition/total_distance"
                              ]
                                ? border_erro
                                : "",
                              textAlign: "center"
                            }}
                            type="text"
                            name="distancia_total"
                            placeholder="Distância Total"
                            onChange={this.handleChange}
                            defaultValue={this.state.distancia_total}
                          />
                          <span className="bolder" style={{ color: "#e60000" }}>
                            {this.state.errors["competition/total_distance"]}
                          </span>
                          <div className="espaco_span" />
                          <label>
                            Numero Máximo Inscrições
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {" (*)"}
                            </span>
                          </label>
                          <input
                            style={{
                              border: this.state.errors[
                                "competition/max_inscriptions"
                              ]
                                ? border_erro
                                : "",
                              textAlign: "center"
                            }}
                            type="number"
                            name="numero_maximo_inscricoes"
                            placeholder="Numero Máximo Inscrições"
                            onChange={this.handleChange}
                            defaultValue={this.state.numero_maximo_inscricoes}
                            min="0"
                          />
                          <span className="bolder" style={{ color: "#e60000" }}>
                            {this.state.errors["competition/max_inscriptions"]}
                          </span>
                          <div className="espaco_span" />
                          <span className="bolder" style={{ color: "#e60000" }}>
                            {" (*)"}
                          </span>{" "}
                          <span className="bolder"> Campos Obrigatórios</span>
                        </div>
                      </div>
                      {/*}
                      <div className="form">
                        <button type="submit">Adicionar Competição</button>
                      </div>
                      */}
                    </TabPanel>
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    <TabPanel>
                      <div className="form">
                        <div>
                          <label>
                            Data Inicio
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {" (*)"}
                            </span>
                          </label>
                          <input
                            style={{
                              border: this.state.errors["competition/init_date"]
                                ? border_erro
                                : "",
                              textAlign: "center"
                            }}
                            type="datetime-local"
                            step="1"
                            name="data_inicio"
                            onChange={this.handleChange}
                            defaultValue={
                              String(
                                Moment(this.state.data_inicio).format(
                                  "YYYY-MM-DD"
                                )
                              ) +
                              "T" +
                              String(
                                Moment(this.state.data_inicio).format(
                                  "HH:mm:ss"
                                )
                              )
                            }
                            //required
                          />
                          <span className="bolder" style={{ color: "#e60000" }}>
                            {this.state.errors["competition/init_date"]}
                          </span>
                          <div className="espaco_span" />
                          <label>
                            Data Fim
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {" (*)"}
                            </span>
                          </label>
                          <input
                            style={{
                              border: this.state.errors["competition/end_date"]
                                ? border_erro
                                : "",
                              textAlign: "center"
                            }}
                            type="datetime-local"
                            step="1"
                            name="data_fim"
                            onChange={this.handleChange}
                            defaultValue={
                              String(
                                Moment(this.state.data_fim).format("YYYY-MM-DD")
                              ) +
                              "T" +
                              String(
                                Moment(this.state.data_fim).format("HH:mm:ss")
                              )
                            }
                            //required
                          />
                          <span className="bolder" style={{ color: "#e60000" }}>
                            {this.state.errors["competition/end_date"]}
                          </span>
                          <div className="espaco_span" />
                          <span className="bolder" style={{ color: "#e60000" }}>
                            {" (*)"}
                          </span>{" "}
                          <span className="bolder"> Campos Obrigatórios</span>
                        </div>
                      </div>
                      {/*}
                      <div className="form">
                        <button type="submit">Adicionar Competição</button>
                      </div>
                      */}
                    </TabPanel>
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    <TabPanel>
                      <div className="form">
                        <div>
                          <label>
                            Código Cor
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {
                                //" (*)"
                              }
                            </span>
                          </label>
                          <input
                            style={{
                              border: this.state.errors[
                                "competition/color_code"
                              ]
                                ? border_erro
                                : "",
                              textAlign: "center"
                            }}
                            type="text"
                            name="codigo_cor"
                            placeholder="Código Cor"
                            onChange={this.handleChange}
                            defaultValue={this.state.codigo_cor}
                          />
                          <span className="bolder" style={{ color: "#e60000" }}>
                            {this.state.errors["competition/color_code"]}
                          </span>
                          <div className="espaco_span" />
                          <label>
                            Nome Ficheiro kml
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {
                                //" (*)"
                              }
                            </span>
                          </label>
                          <input
                            style={{
                              border: this.state.errors[
                                "competition/kml_file_name"
                              ]
                                ? border_erro
                                : "",
                              textAlign: "center"
                            }}
                            type="text"
                            name="kml_file_name"
                            placeholder="Nome Ficheiro kml"
                            onChange={this.handleChange}
                            defaultValue={this.state.kml_file_name}
                          />
                          <span className="bolder" style={{ color: "#e60000" }}>
                            {this.state.errors["competition/kml_file_name"]}
                          </span>
                          <div className="espaco_span" />
                          <label>
                            Competição Activa:
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {" (*)"}
                            </span>
                          </label>
                          <div
                            style={{
                              border: this.state.errors["competition/active"]
                                ? border_erro
                                : ""
                            }}
                          >
                            <Select
                              value={this.state.activo}
                              name="activo"
                              placeholder="Activo"
                              onChange={this.handleChangeSelectActivo}
                              options={opcoes_select_activo}
                            />
                          </div>
                          <span className="bolder" style={{ color: "#e60000" }}>
                            {this.state.errors["competition/active"]}
                          </span>
                          <div className="espaco_span" />
                          <label>
                            Permitir Alterar Atletas:
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {" (*)"}
                            </span>
                          </label>
                          <div
                            style={{
                              border: this.state.errors[
                                "competition/alterar_atletas"
                              ]
                                ? border_erro
                                : ""
                            }}
                          >
                            <Select
                              value={this.state.alterar_atletas}
                              name="alterar_atletas"
                              placeholder="Alterar Atletas"
                              onChange={this.handleChangeSelectAlterarAtletas}
                              options={opcoes_select_activo}
                            />
                          </div>
                          <span className="bolder" style={{ color: "#e60000" }}>
                            {this.state.errors["competition/alterar_atletas"]}
                          </span>
                          <div className="espaco_span" />
                          <span className="bolder" style={{ color: "#e60000" }}>
                            {" (*)"}
                          </span>{" "}
                          <span className="bolder"> Campos Obrigatórios</span>
                        </div>
                      </div>
                      {/*}
                      <div className="form">
                        <button type="submit">Adicionar Competição</button>
                      </div>
                              */}
                    </TabPanel>
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    {/*
                    <TabPanel>
                      <div className="form">
                        <div>
                          <label>
                            Certificado
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {" (*)"}
                            </span>
                          </label>
                          <div
                            style={{
                              border: this.state.errors[
                                "competition/certification"
                              ]
                                ? border_erro
                                : ""
                            }}
                          >
                            <Select
                              value={this.state.certificado}
                              name="certificado"
                              placeholder="Certificado"
                              onChange={this.handleChangeSelectCertificado}
                              options={opcoes_select_activo}
                            />
                          </div>
                          <span className="bolder" style={{ color: "#e60000" }}>
                            {this.state.errors["competition/certification"]}
                          </span>
                          <div className="espaco_span" />

                          <div />
                          <label>
                            Template Certificado
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {" (*)"}
                            </span>
                          </label>
                          <input
                            style={{
                              border: this.state.errors["competition/cert_tpl"]
                                ? border_erro
                                : "",
                              textAlign: "center"
                            }}
                            type="number"
                            name="cert_tpl"
                            placeholder="Template Certificado"
                            onChange={this.handleChange}
                            min="1"
                            defaultValue={this.state.cert_tpl}
                            //required
                          />
                          <span className="bolder" style={{ color: "#e60000" }}>
                            {this.state.errors["competition/cert_tpl"]}
                          </span>
                          <div className="espaco_span" />

                          <label>
                            Código Certificado
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {" (*)"}
                            </span>
                          </label>
                          <input
                            style={{
                              border: this.state.errors["competition/cert_code"]
                                ? border_erro
                                : "",
                              textAlign: "center"
                            }}
                            type="text"
                            name="cert_code"
                            placeholder="Código Certificado"
                            onChange={this.handleChange}
                            defaultValue={this.state.cert_code}
                            //required
                          />
                          <span className="bolder" style={{ color: "#e60000" }}>
                            {this.state.errors["competition/cert_code"]}
                          </span>
                        </div>
                        <div className="espaco_span">
                          <span className="bolder" style={{ color: "#e60000" }}>
                            {" (*)"}
                          </span>{" "}
                          <span className="bolder"> Campos Obrigatórios</span>
                        </div>
                      </div>
                    </TabPanel>
                    */}
                  </Tabs>
                </div>
              </form>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default Formulario_Adicionar_Competicao;
