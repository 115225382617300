import React, { Component } from "react";
import axios from "axios";
import Drawer from "../Drawer/Drawer.js";
import Select from "react-select";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

//Funções Auxiliares:
import {
  mostra_mensagem_erro,
  //ve_resultados,
  //Trata_Validacoes,
  isEmptyObject
} from "../Funcoes_Auxiliares/Funcoes_Auxiliares.js";

let REACT_APP_API_URL;

if (process.env.REACT_APP_ESTADO === "Deploy") {
  REACT_APP_API_URL = process.env.REACT_APP_API_URL_PROD;
} //Development
else {
  REACT_APP_API_URL = process.env.REACT_APP_API_URL_DEV;
}

const opcoes_select_desqualificacao = [
  { value: 1, label: "Sim" },
  { value: 0, label: "Não" }
];

class Formulario_Lancamento_Desistencia_Atleta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Checkpoints_Atleta_Passou_Lidos: undefined,

      Link_Menu_Anterior: null,
      ID_Evento: null,
      ID_Prova: null,
      ID_Atleta: null,
      Nome_Evento: null,
      Nome_Prova: null,
      Nome_Atleta: null,

      Checkpoint_Escolhido: null,
      desqualificacao: null,

      Dorsal_Atleta: null,
      Escalao_Atleta: null,
      Clube_Atleta: null,

      //Variavel Controlo de Erro na Leitura dos Props
      Mostra_Erro: false,

      //Variavel Para Controlar o Redirect
      Inserido_Sucesso: false,

      //Lista Para os Erros de Validação:
      errors: {}
    };

    if (props.location.state === undefined) {
      this.state.Mostra_Erro = true;
    } else {
      if (props.location.state.Link_Tabela_Anterior === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Link_Tabela_Anterior =
          props.location.state.Link_Tabela_Anterior;
      }
      if (props.location.state.Link_Menu_Anterior === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Link_Menu_Anterior = props.location.state.Link_Menu_Anterior;
      }
      if (props.location.state.ID_Evento === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.ID_Evento = props.location.state.ID_Evento;
      }
      if (props.location.state.ID_Prova === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.ID_Prova = props.location.state.ID_Prova;
      }
      if (props.location.state.ID_Atleta === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.ID_Atleta = props.location.state.ID_Atleta;
      }
      if (props.location.state.Nome_Evento === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Nome_Evento = props.location.state.Nome_Evento;
      }
      if (props.location.state.Nome_Prova === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Nome_Prova = props.location.state.Nome_Prova;
      }
      if (props.location.state.Nome_Atleta === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Nome_Atleta = props.location.state.Nome_Atleta;
      }
      if (props.location.state.id_inscricao === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.id_inscricao = props.location.state.id_inscricao;
      }

      if (props.location.state.Dorsal_Atleta === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Dorsal_Atleta = props.location.state.Dorsal_Atleta;
      }
      if (props.location.state.Escalao_Atleta === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Escalao_Atleta = props.location.state.Escalao_Atleta;
      }
      if (props.location.state.Clube_Atleta === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Clube_Atleta = props.location.state.Clube_Atleta;
      }

      /*
    if (props.location.state.Link_Tabela_Anterior === undefined) {
      this.state.Mostra_Erro = true;
    } else {
      this.state.Link_Tabela_Anterior =
        props.location.state.Link_Tabela_Anterior;
    }
    */
    }

    //this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  Vai_Buscar_Dados() {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .get(
        REACT_APP_API_URL +
          "/devolve_todos_checkpoints_competicao_atleta_passou/" +
          this.state.ID_Prova +
          "/" +
          this.state.ID_Atleta,
        {
          headers: { authorization: "Bearer " + Token_Lido }
        }
      )
      .then(res => {
        //console.log(res);
        this.setState({ Checkpoints_Atleta_Passou_Lidos: res.data });
      });
  }
  /*
Procedimento Com Axios */
  componentDidMount() {
    if (localStorage.getItem("Token") !== null) {
      this.Vai_Buscar_Dados();
    }
  } //Fecha Procedimento Que Acontece Depois do Output do Componente Foi Rendered

  handleChangeSelectCheckpoint = Checkpoint_Escolhido => {
    this.setState({ Checkpoint_Escolhido });
  };

  handleChangeSelectDesqualificacao = desqualificacao => {
    this.setState({ desqualificacao });
  };

  handleValidation() {
    let errors = {};
    let formIsValid = false; //true;

    if (!this.state.Checkpoint_Escolhido) {
      errors["checkpoint_escolhido"] = "*Escolha um Checkpoint";
    }

    if (!this.state.desqualificacao) {
      errors["desistencias/desqualificacao"] =
        "*Indique se o Atleta Foi Desqualificado";
    }

    //if (errors.length > 0) {
    if (isEmptyObject(errors)) {
      formIsValid = true; //false;
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.handleValidation()) {
      const Token_Lido = localStorage.getItem("Token");
      /*
      let Novo_Time_Status = "";
      if (Number(this.state.Checkpoint_Escolhido.order) > 0) {
        Novo_Time_Status = "DNF";
      } else {
        Novo_Time_Status = "DNS";
      }
      */

      let Novo_Time_Status = "";
      if (this.state.desqualificacao.value > 0) {
        Novo_Time_Status = "DSQ";
      } else {
        if (Number(this.state.Checkpoint_Escolhido.order) > 0) {
          Novo_Time_Status = "DNF";
        } else {
          Novo_Time_Status = "DNS";
        }
      }

      axios
        .patch(
          REACT_APP_API_URL +
            "/lanca_desistencia_atleta/" +
            this.state.ID_Prova +
            "/" +
            this.state.ID_Atleta +
            "/" +
            this.state.Checkpoint_Escolhido.value +
            "/" +
            Novo_Time_Status,
          {
            //Checkpoint_Escolhido: this.state.Checkpoint_Escolhido
          },
          {
            headers: { authorization: "Bearer " + Token_Lido }
          }
        )
        .then(res => {
          this.setState({ Inserido_Sucesso: res.data.success });
        });
    }
  }

  render() {
    if (localStorage.getItem("Token") === null) {
      this.props.history.push({
        pathname: "/"
      });
    }

    if (this.state.Inserido_Sucesso === true) {
      this.props.history.push({
        pathname: this.state.Link_Tabela_Anterior, //this.state.Link_Tabela_Anterior2,
        state: {
          Link_Tabela_Anterior: this.state.Link_Tabela_Anterior,
          Link_Menu_Anterior: this.state.Link_Menu_Anterior,

          id_inscricao: this.state.id_inscricao,
          ID_Evento: this.state.ID_Evento,
          ID_Prova: this.state.ID_Prova,
          Nome_Evento: this.state.Nome_Evento,
          Nome_Prova: this.state.Nome_Prova,
          Nome_Atleta: this.state.Nome_Atleta,

          Fez_Insercao_Inscricao_Atleta_Sistema: false,
          Fez_Edicao_Dados_Atleta_Sistema: false,
          Fez_Edicao_Inscricao_Atleta_Sistema: false,
          Faz_Refresh: true,
          Fez_Lancamento_Desistencia_Sucesso: true,
          Fez_Eliminacao_Inscricao_Sucesso: false
        }
      });
    }

    //Verifica Se Existe Erro. Se Houver Mostra Mensagem de Erro
    if (this.state.Mostra_Erro === true) {
      return (
        <div>
          <Drawer
            Titulo_Menu="" //"Erro"
            history={this.props.history}
          />
          {mostra_mensagem_erro()}
        </div>
      );
    } else {
      if (this.state.Checkpoints_Atleta_Passou_Lidos === undefined) {
        return (
          <div className="centra_loading">
            <h1>Loading...</h1>
          </div>
        );
      } else {
        var Opcoes_Checkpoints = [];
        this.state.Checkpoints_Atleta_Passou_Lidos.forEach(function(linha2) {
          var Linha2 = {
            value: linha2.ID_Checkpoint,
            label: "" + linha2.local + " (" + linha2.checkpoint_order + ")",
            order: linha2.checkpoint_order
          };
          Opcoes_Checkpoints.push(Linha2);
        });

        const border_erro = "1px solid #ff0000";

        return (
          <div>
            <Drawer
              Titulo_Menu="Lançamento Desistência Atleta"
              /*
              Link_Menu_Anterior={this.state.Link_Menu_Anterior}
              Link_Tabela_Anterior={this.state.Link_Tabela_Anterior}
              ID_Evento={this.state.ID_Evento}
              Nome_Evento={this.state.Nome_Evento}
              */

              //Link_Tabela_Anterior2={this.state.Link_Tabela_Anterior2}
              Link_Tabela_Anterior={this.state.Link_Tabela_Anterior}
              Link_Menu_Anterior={this.state.Link_Menu_Anterior}
              id_inscricao={this.state.id_inscricao}
              ID_Evento={this.state.ID_Evento}
              ID_Prova={this.state.ID_Prova}
              ID_Atleta={this.state.ID_Atleta}
              Nome_Evento={this.state.Nome_Evento}
              Nome_Prova={this.state.Nome_Prova}
              Nome_Atleta={this.state.Nome_Atleta}
              history={this.props.history}
            />
            <div className="novas_margens_volta_novos_forms">
              <div className="centra_novos_forms">
                <form className="login-form" onSubmit={this.handleSubmit}>
                  <div className="centra_titulos_tabs">
                    <Tabs>
                      <TabList>
                        <Tab
                          style={{
                            border: this.state.errors["checkpoint_escolhido"]
                              ? border_erro
                              : ""
                          }}
                        >
                          Informação da Desistência
                        </Tab>
                        <button type="submit" style={{ marginLeft: "10px" }}>
                          Lançar Desistência
                        </button>
                      </TabList>
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      <TabPanel>
                        <div className="form">
                          <div>
                            <label>
                              Local do Checkpoint
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              >
                                {" (*)"}
                              </span>
                            </label>

                            <div
                              style={{
                                border: this.state.errors[
                                  "checkpoint_escolhido"
                                ]
                                  ? border_erro
                                  : ""
                              }}
                            >
                              <Select
                                value={this.state.Checkpoint_Escolhido}
                                name="Checkpoint_Escolhido"
                                placeholder="Checkpoint Onde Desistiu"
                                onChange={this.handleChangeSelectCheckpoint}
                                options={Opcoes_Checkpoints}
                              />
                            </div>
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {this.state.errors["checkpoint_escolhido"]}
                            </span>
                          </div>
                          <div className="espaco_span" />
                          <label>
                            Desqualificado?
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {" (*)"}
                            </span>
                          </label>
                          <div
                            style={{
                              border: this.state.errors[
                                "desistencias/desqualificacao"
                              ]
                                ? border_erro
                                : ""
                            }}
                          >
                            <Select
                              value={this.state.desqualificacao}
                              name="desqualificacao"
                              placeholder="Desqualificado?"
                              onChange={this.handleChangeSelectDesqualificacao}
                              options={opcoes_select_desqualificacao}
                            />
                          </div>
                          <span className="bolder" style={{ color: "#e60000" }}>
                            {this.state.errors["desistencias/desqualificacao"]}
                          </span>
                          <div className="espaco_span">
                            <label>Dorsal</label>
                            <input
                              style={{
                                backgroundColor: "#b3b3cc",
                                textAlign: "center"
                              }}
                              type="text"
                              placeholder="Dorsal"
                              defaultValue={this.state.Dorsal_Atleta}
                              readOnly
                            />
                            <div className="espaco_span" />
                            <label>Escalão</label>
                            <input
                              style={{
                                backgroundColor: "#b3b3cc",
                                textAlign: "center"
                              }}
                              type="text"
                              placeholder="Escalão"
                              defaultValue={this.state.Escalao_Atleta}
                              readOnly
                            />
                            <div className="espaco_span" />
                            <label>Clube</label>
                            <input
                              style={{
                                backgroundColor: "#b3b3cc",
                                textAlign: "center"
                              }}
                              type="text"
                              placeholder="Clube"
                              defaultValue={this.state.Clube_Atleta}
                              readOnly
                            />
                            <div className="espaco_span" />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {" (*)"}
                            </span>{" "}
                            <span className="bolder"> Campos Obrigatórios</span>
                          </div>
                        </div>
                      </TabPanel>
                    </Tabs>
                  </div>
                </form>
              </div>
            </div>
          </div>
        );
      }
    }
  }
}

export default Formulario_Lancamento_Desistencia_Atleta;
