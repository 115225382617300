import React, { Component } from "react";
//import { Link } from "react-router-dom";
import axios from "axios";
import Moment from "moment";
import Drawer from "../Drawer/Drawer.js";
import Select from "react-select";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

//Funções Auxiliares:
import {
  mostra_mensagem_erro,
  //ve_resultados,
  Trata_Validacoes,
  isEmptyObject
} from "../Funcoes_Auxiliares/Funcoes_Auxiliares.js";

let REACT_APP_API_URL;

if (process.env.REACT_APP_ESTADO === "Deploy") {
  REACT_APP_API_URL = process.env.REACT_APP_API_URL_PROD;
} //Development
else {
  REACT_APP_API_URL = process.env.REACT_APP_API_URL_DEV;
}

class Formulario_Adicionar_Resultado_Atleta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dados_auxiliares: undefined, //[],
      Checkpoints_Competicao_Lidos: undefined, //[],
      Time_Status_Lidos: undefined,

      Link_Tabela_Anterior2: null,
      Link_Tabela_Anterior: null,
      Link_Menu_Anterior: null,
      ID_Evento: null,
      ID_Prova: null,
      ID_Atleta: null,
      Nome_Evento: null,
      Nome_Prova: null,
      Nome_Atleta: null,

      //ID_Primeiro_Checkpoint: null,
      nome_atleta: null,
      dorsal: null,
      escalao: null,
      nome_evento: null,
      competicao: null,

      checkpoint_id: null,
      //id_competicao:null,
      time_stamp: null,
      acumulated_time: null,
      data_passagem_checkpoint: null,
      time_penalty: null,
      time_status: null,

      //Variavel Controlo de Erro na Leitura dos Props
      Mostra_Erro: false,

      //Variavel Para Controlar o Redirect
      Inserido_Sucesso: false,

      //Lista Para os Erros de Validação:
      errors: {}
    };

    if (props.location.state === undefined) {
      this.state.Mostra_Erro = true;
    } else {
      if (props.location.state.Link_Tabela_Anterior2 === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Link_Tabela_Anterior2 =
          props.location.state.Link_Tabela_Anterior2;
      }
      if (props.location.state.Link_Tabela_Anterior === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Link_Tabela_Anterior =
          props.location.state.Link_Tabela_Anterior;
      }
      if (props.location.state.Link_Menu_Anterior === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Link_Menu_Anterior = props.location.state.Link_Menu_Anterior;
      }
      if (props.location.state.ID_Evento === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.ID_Evento = props.location.state.ID_Evento;
      }
      if (props.location.state.ID_Prova === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.ID_Prova = props.location.state.ID_Prova;
      }
      if (props.location.state.ID_Atleta === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.ID_Atleta = props.location.state.ID_Atleta;
      }
      if (props.location.state.Nome_Evento === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Nome_Evento = props.location.state.Nome_Evento;
      }
      if (props.location.state.Nome_Prova === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Nome_Prova = props.location.state.Nome_Prova;
      }
      if (props.location.state.Nome_Atleta === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Nome_Atleta = props.location.state.Nome_Atleta;
      }
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  Vai_Buscar_Dados_Auxiliares() {
    const Token_Lido = localStorage.getItem("Token");

    axios
      .get(
        REACT_APP_API_URL +
          "/devolve_dados_auxiliares_adicionar_resultado/" +
          this.state.ID_Prova +
          "/" +
          this.state.ID_Atleta,
        {
          headers: { authorization: "Bearer " + Token_Lido }
        }
      )
      .then(res => {
        console.log(res);
        this.setState({ dados_auxiliares: res.data });
        const Dados_Lidos = res.data;
        this.setState({
          nome_atleta: Dados_Lidos[0].athlete_name,
          dorsal: Dados_Lidos[0].dorsal_number,
          escalao: Dados_Lidos[0].category,
          nome_evento: Dados_Lidos[0].name,
          competicao: Dados_Lidos[0].long_name
        });
      });
  }

  Vai_Buscar_Dados_Checkpoints_Competicao() {
    const Token_Lido = localStorage.getItem("Token");

    axios
      .get(
        //REACT_APP_API_URL + "/lista_checkpoints_prova/" + this.state.ID_Prova,
        REACT_APP_API_URL +
          "/lista_checkpoints_prova_utilizador_nao_passou/" +
          this.state.ID_Prova +
          "/" +
          this.state.ID_Atleta,
        {
          headers: { authorization: "Bearer " + Token_Lido }
        }
      )
      .then(res => {
        console.log(res);
        this.setState({
          Checkpoints_Competicao_Lidos: res.data
          //ID_Primeiro_Checkpoint: res.data[0].checkpoint_id
        });
      });
  }

  Vai_Buscar_Time_Status() {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .get(REACT_APP_API_URL + "/devolve_estados_inscricao", {
        headers: { authorization: "Bearer " + Token_Lido }
      })
      .then(res => {
        console.log(res);
        this.setState({ Time_Status_Lidos: res.data });
      });
  }

  /*
Procedimento Com Axios */
  componentDidMount() {
    if (localStorage.getItem("Token") !== null) {
      this.Vai_Buscar_Time_Status();
      this.Vai_Buscar_Dados_Checkpoints_Competicao();
      this.Vai_Buscar_Dados_Auxiliares();
    }
  } //Fecha Procedimento Que Acontece Depois do Output do Componente Foi Rendered

  //Actualiza os Dados Depois de Acontecer um Redirect Para Esta Tabela
  componentDidUpdate() {
    //this.Vai_Buscar_Dados_Checkpoints_Competicao();
    //this.Vai_Buscar_Dados_Auxiliares();
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleChangeSelectCheckpoint = checkpoint_id => {
    this.setState({ checkpoint_id });
  };

  handleChangeSelectTimeStatus = time_status => {
    this.setState({ time_status });
  };

  handleValidation() {
    let errors = {};
    let formIsValid = false; //true;

    //Para Cada Campo Invocar o Trata_Validacoes

    /*
    errors = Trata_Validacoes(
      this.state.time_stamp,
      "time_checkpoint/competition_checkpoint_checkpoint_checkpoint_id",
      errors
    );
    */
    if (this.state.checkpoint_id === null) {
      errors[
        "time_checkpoint/competition_checkpoint_checkpoint_checkpoint_id"
      ] = "*Escolha um Checkpoint";
    }

    errors = Trata_Validacoes(
      this.state.time_stamp,
      "time_checkpoint/time_stamp",
      errors
    );
    errors = Trata_Validacoes(
      this.state.acumulated_time,
      "time_checkpoint/acumulated_time",
      errors
    );
    errors = Trata_Validacoes(
      this.state.data_passagem_checkpoint,
      "time_checkpoint/checkpoint_point_time",
      errors
    );
    errors = Trata_Validacoes(
      this.state.time_penalty,
      "time_checkpoint/time_penalty",
      errors
    );
    errors = Trata_Validacoes(
      this.state.time_status,
      "time_checkpoint/time_status",
      errors
    );

    //if (errors.length > 0) {
    if (isEmptyObject(errors)) {
      formIsValid = true; //false;
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.handleValidation()) {
      const Token_Lido = localStorage.getItem("Token");
      axios
        .post(
          REACT_APP_API_URL + "/insere_resultado_atleta/",
          {
            checkpoint_id: this.state.checkpoint_id.value, //this.state.checkpoint_id,
            id_competicao: this.state.ID_Prova,
            time_stamp: this.state.time_stamp,
            acumulated_time: this.state.acumulated_time,
            data_passagem_checkpoint: this.state.data_passagem_checkpoint,
            id_atleta: this.state.ID_Atleta,
            time_penalty: this.state.time_penalty,
            time_status: this.state.time_status.value
          },
          {
            headers: { authorization: "Bearer " + Token_Lido }
          }
        )
        .then(res => {
          //console.log(res);
          this.setState({ Inserido_Sucesso: res.data.success });
        });
    }
  }

  render() {
    if (localStorage.getItem("Token") === null) {
      this.props.history.push({
        pathname: "/"
      });
    }

    if (this.state.Inserido_Sucesso === true) {
      this.props.history.push({
        pathname: this.state.Link_Tabela_Anterior2, //this.state.Link_Tabela_Anterior,
        state: {
          Link_Menu_Anterior: this.state.Link_Menu_Anterior,
          Link_Tabela_Anterior: this.state.Link_Tabela_Anterior,
          ID_Evento: this.state.ID_Evento,
          ID_Prova: this.state.ID_Prova,
          ID_Atleta: this.state.ID_Atleta,
          Fez_Insercao_Resultado: true,
          Fez_Edicao_Resultado: false,
          Faz_Refresh: true,
          Nome_Evento: this.state.Nome_Evento,
          Nome_Prova: this.state.Nome_Prova,
          Nome_Atleta: this.state.Nome_Atleta
        }
      });
    }

    //Verifica Se Existe Erro. Se Houver Mostra Mensagem de Erro
    if (this.state.Mostra_Erro === true) {
      return (
        <div>
          <Drawer
            Titulo_Menu="" //"Erro"
            //Link_Menu_Anterior={this.props.location.pathname}
            history={this.props.history}
          />
          {mostra_mensagem_erro()}
        </div>
      );
    } else {
      if (
        this.state.dados_auxiliares === undefined ||
        this.state.Checkpoints_Competicao_Lidos === undefined ||
        this.state.Time_Status_Lidos === undefined
      ) {
        return (
          <div className="centra_loading">
            <h1>Loading...</h1>
          </div>
        );
      } else {
        var Opcoes_Time_Status = [];
        //this.state.Estados_Inscricao_Lidos.map(linha2 => {
        this.state.Time_Status_Lidos.forEach(function(linha2) {
          var Linha = {
            value: linha2.code,
            label: linha2.description + " (" + linha2.code + ")"
          };
          Opcoes_Time_Status.push(Linha);
        });
        /*
        let Hora_Passagem_Checkpoint;
        let Data_Passagem;
        let Aux1;
        let Aux2;
        let Aux3;
        let Aux4;
        */

        var Opcoes_Checkpoints = [];
        //this.state.Checkpoints_Competicao_Lidos.map(linha2 => {
        this.state.Checkpoints_Competicao_Lidos.forEach(function(linha2) {
          var Linha = {
            value: linha2.checkpoint_id,
            label: linha2.local + " (" + linha2.checkpoint_order + ")"
          };
          Opcoes_Checkpoints.push(Linha);
        });

        const border_erro = "1px solid #ff0000";
        return (
          <div>
            <Drawer
              Titulo_Menu="Inserir Novo Resultado Atleta"
              Link_Tabela_Anterior2={this.state.Link_Tabela_Anterior2}
              Link_Tabela_Anterior={this.state.Link_Tabela_Anterior}
              Link_Menu_Anterior={this.state.Link_Menu_Anterior}
              ID_Evento={this.state.ID_Evento}
              ID_Prova={this.state.ID_Prova}
              ID_Atleta={this.state.ID_Atleta}
              Nome_Evento={this.state.Nome_Evento}
              Nome_Prova={this.state.Nome_Prova}
              Nome_Atleta={this.state.Nome_Atleta}
              history={this.props.history}
            />
            <div className="novas_margens_volta_novos_forms">
              <div className="centra_novos_forms">
                <form className="login-form" onSubmit={this.handleSubmit}>
                  <div className="centra_titulos_tabs">
                    <Tabs>
                      <TabList>
                        <Tab>Dados Atleta</Tab>
                        <Tab
                          style={{
                            border:
                              this.state.errors[
                                "time_checkpoint/competition_checkpoint_checkpoint_checkpoint_id"
                              ] ||
                              this.state.errors["time_checkpoint/time_stamp"] ||
                              this.state.errors[
                                "time_checkpoint/acumulated_time"
                              ] ||
                              this.state.errors[
                                "time_checkpoint/checkpoint_point_time"
                              ] ||
                              this.state.errors[
                                "time_checkpoint/time_penalty"
                              ] ||
                              this.state.errors["time_checkpoint/time_status"]
                                ? border_erro
                                : ""
                          }}
                        >
                          Resultado
                        </Tab>
                        <button type="submit" style={{ marginLeft: "10px" }}>
                          Adicionar Resultado
                        </button>
                      </TabList>
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      <TabPanel>
                        <div className="form">
                          <div>
                            {/*}
                            <label>Nome Atleta</label>
                            <input
                              style={{
                                backgroundColor: "#b3b3cc",
                                textAlign: "center"
                              }}
                              type="text"
                              name="nome_atleta"
                              placeholder="Nome"
                              defaultValue={this.state.nome_atleta}
                              readOnly
                            />
                            <div className="espaco_span" />
                            */}

                            <label>Dorsal</label>
                            <input
                              style={{
                                backgroundColor: "#b3b3cc",
                                textAlign: "center"
                              }}
                              type="number"
                              name="dorsal"
                              placeholder="Dorsal"
                              defaultValue={this.state.dorsal}
                              readOnly
                            />
                            <div className="espaco_span" />

                            <label>Escalão</label>
                            <input
                              style={{
                                backgroundColor: "#b3b3cc",
                                textAlign: "center"
                              }}
                              type="text"
                              name="escalao"
                              placeholder="Escalao"
                              defaultValue={this.state.escalao}
                              readOnly
                            />
                            <div className="espaco_span" />
                          </div>
                        </div>
                        {/*}
                        <div className="form">
                          <button type="submit">Adicionar Resultado</button>
                        </div>
                              */}
                      </TabPanel>
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}

                      <TabPanel>
                        <div className="form">
                          <div>
                            <label>
                              Checkpoint
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              >
                                {" (*)"}
                              </span>
                            </label>
                            {
                              //Este Campo Vai Ser Select. Vai Mostrar Todos os Checkpoints Desta Prova
                            }
                            <div
                              style={{
                                border: this.state.errors[
                                  "time_checkpoint/competition_checkpoint_checkpoint_checkpoint_id"
                                ]
                                  ? border_erro
                                  : ""
                              }}
                            >
                              <Select
                                name="checkpoint_id"
                                placeholder="Checkpoint"
                                onChange={this.handleChangeSelectCheckpoint}
                                options={Opcoes_Checkpoints}
                                defaultValue={{
                                  value: this.state.checkpoint_id
                                    ? this.state.checkpoint_id.value
                                    : null,
                                  label: this.state.checkpoint_id
                                    ? this.state.checkpoint_id.label
                                    : ""
                                }}
                              />
                            </div>
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {
                                this.state.errors[
                                  "time_checkpoint/competition_checkpoint_checkpoint_checkpoint_id"
                                ]
                              }
                            </span>
                            <div className="espaco_span" />
                            <label>
                              Time Stamp
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              >
                                {" (*)"}
                              </span>
                            </label>
                            <input
                              style={{
                                border: this.state.errors[
                                  "time_checkpoint/time_stamp"
                                ]
                                  ? border_erro
                                  : "",
                                textAlign: "center"
                              }}
                              type="datetime-local"
                              step="1"
                              name="time_stamp"
                              placeholder="Time Stamp"
                              onChange={this.handleChange}
                              defaultValue={
                                //No "datetime-local" Nao Consegue Ler Formatos de Data Sem o "T" Entre Dia e Hora e Nao Pode Ter o "Z" no Final
                                String(
                                  Moment(this.state.time_stamp).format(
                                    "YYYY-MM-DD"
                                  )
                                ) +
                                "T" +
                                String(
                                  Moment(this.state.time_stamp).format(
                                    "HH:mm:ss"
                                  )
                                )
                              }
                            />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {this.state.errors["time_checkpoint/time_stamp"]}
                            </span>
                            <div className="espaco_span" />
                            <label>
                              Acumulated Time
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              >
                                {" (*)"}
                              </span>
                            </label>
                            <input
                              style={{
                                border: this.state.errors[
                                  "time_checkpoint/acumulated_time"
                                ]
                                  ? border_erro
                                  : "",
                                textAlign: "center"
                              }}
                              type="time" //type="text" //type="time"
                              step="1"
                              name="acumulated_time"
                              placeholder="Acumulated Time"
                              onChange={this.handleChange}
                              defaultValue={this.state.acumulated_time}
                            />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {
                                this.state.errors[
                                  "time_checkpoint/acumulated_time"
                                ]
                              }
                            </span>
                            <div className="espaco_span" />

                            <label>
                              Data de Passagem
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              >
                                {" (*)"}
                              </span>
                            </label>
                            <input
                              style={{
                                border: this.state.errors[
                                  "time_checkpoint/checkpoint_point_time"
                                ]
                                  ? border_erro
                                  : "",
                                textAlign: "center"
                              }}
                              type="datetime-local"
                              step="1"
                              //type="text" //type="date"
                              name="data_passagem_checkpoint"
                              placeholder="Data de Passagem"
                              onChange={this.handleChange}
                              defaultValue={
                                //No "datetime-local" Nao Consegue Ler Formatos de Data Sem o "T" Entre Dia e Hora e Nao Pode Ter o "Z" no Final
                                String(
                                  Moment(
                                    this.state.data_passagem_checkpoint
                                  ).format("YYYY-MM-DD")
                                ) +
                                "T" +
                                String(
                                  Moment(
                                    this.state.data_passagem_checkpoint
                                  ).format("HH:mm:ss")
                                )
                              }
                            />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {
                                this.state.errors[
                                  "time_checkpoint/checkpoint_point_time"
                                ]
                              }
                            </span>
                            <div className="espaco_span" />

                            <label>
                              Time Penalty
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              >
                                {" (*)"}
                              </span>
                            </label>
                            <input
                              style={{
                                border: this.state.errors[
                                  "time_checkpoint/time_penalty"
                                ]
                                  ? border_erro
                                  : "",
                                textAlign: "center"
                              }}
                              type="time" //type="text" //type="time"
                              step="1"
                              name="time_penalty"
                              placeholder="Tempo de Prova"
                              onChange={this.handleChange}
                              defaultValue={this.state.time_penalty}
                            />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {
                                this.state.errors[
                                  "time_checkpoint/time_penalty"
                                ]
                              }
                            </span>
                            <div className="espaco_span" />
                            {/*}
                            <label>
                              Time Status
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              >
                                {" (*)"}
                              </span>
                            </label>
                            <input
                              style={{
                                border: this.state.errors[
                                  "time_checkpoint/time_status"
                                ]
                                  ? border_erro
                                  : "",
                                textAlign: "center"
                              }}
                              type="text"
                              name="time_status"
                              placeholder="Time Status"
                              onChange={this.handleChange}
                              defaultValue={this.state.time_status}
                            />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {this.state.errors["time_checkpoint/time_status"]}
                            </span>
                            */}

                            <label>
                              Time Status
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              >
                                {" (*)"}
                              </span>
                            </label>
                            <div
                              style={{
                                border: this.state.errors[
                                  "time_checkpoint/time_status"
                                ]
                                  ? border_erro
                                  : ""
                              }}
                            >
                              <Select
                                value={
                                  this.state.time_status //Opcao_Escolhida_Select_Codigo_Estado_Inscricao
                                }
                                name="time_status"
                                placeholder="Time Status"
                                onChange={this.handleChangeSelectTimeStatus}
                                options={Opcoes_Time_Status}
                              />
                            </div>
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {this.state.errors["time_checkpoint/time_status"]}
                            </span>
                          </div>

                          <div className="espaco_span">
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {" (*)"}
                            </span>{" "}
                            <span className="bolder"> Campos Obrigatórios</span>
                          </div>
                        </div>
                        {/*}
                        <div className="form">
                          <button type="submit">Adicionar Resultado</button>
                        </div>
                              */}
                      </TabPanel>
                    </Tabs>
                  </div>
                </form>
              </div>
            </div>
          </div>
        );
      }
    }
  }
}

export default Formulario_Adicionar_Resultado_Atleta;
