import React, { Component } from "react";
//import "./Componentes_Eventos_Competicoes.css";
import { Link } from "react-router-dom";
import axios from "axios";

//Imports Para as Datas:
import Moment from "moment";
import "moment/locale/pt";

//Imports Para a Paginação:
import ReactPaginate from "react-paginate";

//Imports Para Drawer e Snackbar:
import Drawer from "../Drawer/Drawer.js";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import classNames from "classnames";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import green from "@material-ui/core/colors/green";
import amber from "@material-ui/core/colors/amber";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import WarningIcon from "@material-ui/icons/Warning";

//Funções Auxiliares:
import {
  mostra_mensagem_erro,
  ve_resultados
} from "../Funcoes_Auxiliares/Funcoes_Auxiliares.js";

let REACT_APP_API_URL;

if (process.env.REACT_APP_ESTADO === "Deploy") {
  REACT_APP_API_URL = process.env.REACT_APP_API_URL_PROD;
} //Development
else {
  REACT_APP_API_URL = process.env.REACT_APP_API_URL_DEV;
}

// https://material-ui.com/demos/snackbars/

//Constantes Para Drawer e Snackbar:
const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const styles1 = theme => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.dark
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit
  },
  message: {
    display: "flex",
    alignItems: "center"
  }
});

MySnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(["success", "warning", "error", "info"]).isRequired
};

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

//Funções Para Drawer e Snackbar:
function MySnackbarContent(props) {
  const { classes, className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>
      ]}
      {...other}
    />
  );
}

//Class Principal do Componente "Eventos"
class Competicoes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dados: undefined,
      ID_Evento: null,
      Data_Inicio_Evento: undefined, //null,
      Data_Fim_Evento: undefined, //null,
      Nome_Evento: null,

      //Variavel de Controlo Para a Mensagem de Login Com Sucesso na Snackbar:
      Fez_Insercao_Competicao: false,
      Fez_Edicao_Competicao: false,
      Fez_Eliminacao_Competicao: false,
      Erro_Fez_Eliminacao_Competicao: false,

      //Variaveis Para a Paginação:
      total_items: undefined,
      offset: 0,
      perPage: 10,
      pageCount: null,

      //Variavel de Controlo de Erro na Leitura dos Props:
      Mostra_Erro: false,

      //Variavel de Controlo Para Evitar o Bug da Paginação Não Aparecer
      Faz_Refresh: false
    };

    // Verifica Se Existe Erro ao Receber os Props (props.location.state === undefined).
    // Depois Verifica Cada Prop Individulamente (props.location.state.ID_Evento === undefined)
    // Se Houver Algum Problema Mostra Mensagem de Erro. Senão Continua Normalmente

    //const { ID_Evento } = props.location.state;
    //this.state.ID_Evento = ID_Evento;

    if (props.location.state === undefined) {
      this.state.Mostra_Erro = true;
    } else {
      if (props.location.state.ID_Evento === undefined) {
        //Se Nao Receber o Prop "ID_Evento" da Página Anterior, Activa Erro
        this.state.Mostra_Erro = true;
      } else {
        this.state.ID_Evento = props.location.state.ID_Evento;
      }
      if (props.location.state.Fez_Insercao_Competicao === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Fez_Insercao_Competicao =
          props.location.state.Fez_Insercao_Competicao;
      }
      if (props.location.state.Fez_Edicao_Competicao === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Fez_Edicao_Competicao =
          props.location.state.Fez_Edicao_Competicao;
      }
      if (props.location.state.Faz_Refresh === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Faz_Refresh = props.location.state.Faz_Refresh;
      }
      if (props.location.state.Nome_Evento === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Nome_Evento = props.location.state.Nome_Evento;
      }
      if (props.location.state.Fez_Eliminacao_Competicao === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        if (props.location.state.Fez_Eliminacao_Competicao === true) {
          this.state.Fez_Eliminacao_Competicao = true;
          this.state.Erro_Fez_Eliminacao_Competicao = false;
        } else if (props.location.state.Fez_Eliminacao_Competicao === "erro") {
          this.state.Fez_Eliminacao_Competicao = false;
          this.state.Erro_Fez_Eliminacao_Competicao = true;
        } else {
          this.state.Fez_Eliminacao_Competicao = false;
          this.state.Erro_Fez_Eliminacao_Competicao = false;
        }
      }
    }

    //Nota: No Caso das Variaveis de Controlo da Snackbar, Se Não Forem Enviadas Pelos Outros Componentes, Assume se Que São False.
    //Neste Caso Não é Activada a Mensagem de Erro

    this.handlePageClick = this.handlePageClick.bind(this);
    this.handleChange = this.handleChange.bind(this);

    //Metodos Para Ordenações da Tabela
    this.compareBy.bind(this);
    this.sortBy.bind(this);
    this.compareByNumber.bind(this);
  } //Fecha Construtor da Class

  compareBy(key) {
    return function(a, b) {
      if (a[key] < b[key]) return -1;
      if (a[key] > b[key]) return 1;
      return 0;
    };
  }

  compareByNumber(key) {
    return function(a, b) {
      return parseInt(a[key]) - parseInt(b[key]);
    };
  }

  //Ordena Data do Mais Antigo ao Mais Recente
  compareByDate(key) {
    return function(a, b) {
      if (a[key] < b[key]) return -1;
      if (a[key] > b[key]) return 1;
      return 0;
    };
  }

  sortBy(key) {
    let arrayCopy = [...this.state.dados];
    if (key === "init_date" || key === "end_date") {
      arrayCopy.sort(this.compareByDate(key));
    } else {
      arrayCopy.sort(this.compareBy(key));
    }
    this.setState({ dados: arrayCopy });
  }

  //Funções Para a Paginação:
  handlePageClick = data => {
    let selected1 = data.selected;
    let offset1 = Math.ceil(selected1 * this.state.perPage);

    this.setState({ offset: offset1 }, () => {
      this.Vai_Buscar_Dados();
    });
  };

  //Funções Para Extrair Dados da BD:
  Vai_Buscar_Dados_Evento() {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .get(REACT_APP_API_URL + "/dados_evento/" + this.state.ID_Evento, {
        headers: { authorization: "Bearer " + Token_Lido }
      })
      .then(res => {
        console.log(res);
        this.setState({
          Data_Inicio_Evento: res.data[0].init_date,
          Data_Fim_Evento: res.data[0].end_date
        });
      });
  }

  Vai_Buscar_Dados_Competicoes() {
    const Token_Lido = localStorage.getItem("Token");
    if (this.state.perPage > 0) {
      axios
        .get(
          REACT_APP_API_URL +
            "/competicoes/" +
            this.state.ID_Evento +
            "/" +
            this.state.perPage +
            "/" +
            this.state.offset,
          {
            headers: { authorization: "Bearer " + Token_Lido }
          }
        )
        .then(res => {
          console.log(res);
          this.setState({ dados: res.data });
          this.setState({
            pageCount: Math.ceil(this.state.total_items / this.state.perPage)
          });
        });
    } else {
      axios
        .get(REACT_APP_API_URL + "/competicoes/" + this.state.ID_Evento, {
          headers: { authorization: "Bearer " + Token_Lido }
        })
        .then(res => {
          console.log(res);
          this.setState({ items: res.data });
          this.setState({ pageCount: 1 });
        });
    }
  }

  Vai_Buscar_Numero_Competicoes_Evento() {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .get(
        REACT_APP_API_URL +
          "/devolve_numero_competicoes_evento/" +
          this.state.ID_Evento,
        {
          headers: { authorization: "Bearer " + Token_Lido }
        }
      )
      .then(res => {
        console.log(res);
        this.setState({ total_items: res.data[0].Total });
      });
  }

  componentDidMount() {
    if (localStorage.getItem("Token") !== null) {
      this.Vai_Buscar_Numero_Competicoes_Evento();
      this.Vai_Buscar_Dados_Competicoes(); //Comentar Esta Funcao Para Buscar o Tempo do 1º Checkpoint do Atleta em Vez da Data de Inicio da Prova
      this.Vai_Buscar_Dados_Evento();
    }
  } //Fecha Procedimento Que Acontece Depois do Output do Componente Foi Rendered

  //Actualiza os Dados Depois de Acontecer um Redirect Para Este Componente
  componentDidUpdate() {
    //nextProps) {
    //if (nextProps.location.pathname !== this.props.location.pathname) {
    if (this.state.Faz_Refresh) {
      this.Vai_Buscar_Numero_Competicoes_Evento();
      this.Vai_Buscar_Dados_Competicoes(); //Comentar Esta Funcao Para Buscar o Tempo do 1º Checkpoint do Atleta em Vez da Data de Inicio da Prova
      this.Vai_Buscar_Dados_Evento();
      this.setState({ Faz_Refresh: false });
    }
  }

  //Funcoes Para a Mensagem de Login Com Sucesso na Snackbar
  handleClose_Edicao = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ Fez_Edicao_Competicao: false });
  };

  //Funcoes Para a Mensagem de Login Com Sucesso na Snackbar
  handleClose_Insercao = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ Fez_Insercao_Competicao: false });
  };

  //Funcoes Para a Mensagem de Login Com Sucesso na Snackbar
  handleClose_Eliminacao = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ Fez_Eliminacao_Competicao: false });
  };

  //Funcoes Para a Mensagem de Login Com Sucesso na Snackbar
  handleClose_Erro_Eliminacao = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ Erro_Fez_Eliminacao_Competicao: false });
  };

  Mostra_Mensagem_Edicao_Sucesso = () => {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={this.state.Fez_Edicao_Competicao}
        autoHideDuration={3000}
        onClose={this.handleClose_Edicao}
      >
        <MySnackbarContentWrapper
          onClose={this.handleClose_Edicao}
          variant="success"
          message="Prova Editada Com Sucesso!"
        />
      </Snackbar>
    );
  };

  Mostra_Mensagem_Insercao_Sucesso = () => {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={this.state.Fez_Insercao_Competicao}
        autoHideDuration={3000}
        onClose={this.handleClose_Insercao}
      >
        <MySnackbarContentWrapper
          onClose={this.handleClose_Insercao}
          variant="success"
          message="Prova Inserida Com Sucesso!"
        />
      </Snackbar>
    );
  };

  Mostra_Mensagem_Eliminacao_Sucesso = () => {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={this.state.Fez_Eliminacao_Competicao}
        autoHideDuration={3000}
        onClose={this.handleClose_Eliminacao}
      >
        <MySnackbarContentWrapper
          onClose={this.handleClose_Eliminacao}
          variant="success"
          message="Prova Eliminada Com Sucesso!"
        />
      </Snackbar>
    );
  };

  Mostra_Mensagem_Erro_Eliminacao_Sucesso = () => {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={this.state.Erro_Fez_Eliminacao_Competicao}
        autoHideDuration={3000}
        onClose={this.handleClose_Erro_Eliminacao}
      >
        <MySnackbarContentWrapper
          onClose={this.handleClose_Erro_Eliminacao}
          variant="error"
          message="Erro: Prova Tem Checkpoints Associados"
        />
      </Snackbar>
    );
  };

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value }, () => {
      this.Vai_Buscar_Dados_Competicoes();
    });
  }

  render() {
    if (localStorage.getItem("Token") === null) {
      this.props.history.push({
        pathname: "/"
      });
    }
    //Verifica Se Existe Erro. Se Houver Mostra Mensagem de Erro e "Back" Para a Pagina "Eventos"
    if (this.state.Mostra_Erro === true) {
      return (
        <div>
          <Drawer
            Titulo_Menu="" //"Erro"
            history={this.props.history}
          />
          {mostra_mensagem_erro()}
        </div>
      );
    } else {
      if (
        this.state.dados === undefined ||
        this.state.Data_Inicio_Evento === undefined ||
        this.state.Data_Fim_Evento === undefined
      ) {
        return (
          <div>
            <div className="centra_loading">
              <h1>Loading...</h1>
            </div>
          </div>
        );
      } else {
        if (this.state.dados.length > 0) {
          return (
            <div>
              <Drawer
                Titulo_Menu="Provas"
                Link_Menu_Anterior={this.props.location.pathname}
                ID_Evento={this.state.ID_Evento}
                Nome_Evento={this.state.Nome_Evento}
                history={this.props.history}
              />
              <div
                className="novo_centra_tabelas" /*"centra_tabelas1000" /*centra_tabelas*/
              >
                <tabela>
                  <thead>
                    <tr>
                      <th colSpan="4">
                        Número Total de Provas: {this.state.total_items}
                      </th>
                    </tr>
                  </thead>
                  <thead>
                    <tr>
                      <th onClick={() => this.sortBy("long_name")}>
                        Nome Prova{" "}
                        <i
                          className="fa fa-angle-down fa-sm" /*fa fa-sort fa-sm*/
                        />
                      </th>
                      <th onClick={() => this.sortBy("init_date")}>
                        Data Inicio{" "}
                        <i
                          className="fa fa-angle-down fa-sm" /*fa fa-sort fa-sm*/
                        />
                      </th>
                      <th onClick={() => this.sortBy("end_date")}>
                        Data Fim{" "}
                        <i
                          className="fa fa-angle-down fa-sm" /*fa fa-sort fa-sm*/
                        />
                      </th>
                      <th>Acção</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Visão Geral Provas</td>
                      <td>
                        {Moment(this.state.Data_Inicio_Evento) //.format("DD-MM-YYYY HH:mm")
                          .locale("pt")
                          .format("LLLL")}
                      </td>
                      <td>
                        {Moment(this.state.Data_Fim_Evento) //.format("DD-MM-YYYY HH:mm")
                          .locale("pt")
                          .format("LLLL")}
                      </td>
                      <td>
                        {/*
                        <Link
                          to={{
                            pathname: "/dashboard_provas",
                            state: {
                              //Neste Caso, Como Sao Todas as Provas, Apenas Envia o ID_Evento!=NULL
                              Link_Menu_Anterior: this.props.location.pathname,
                              ID_Evento: this.state.ID_Evento,
                              ID_Prova: null,
                              Faz_Refresh: false, //true,
                              Nome_Evento: this.state.Nome_Evento
                            }
                          }}
                          title="Dashboard Provas"
                        >
                          <i className="fa fa-list fa-lg espaco_icons" />
                        </Link>
                        */}
                        <Link
                          to={{
                            pathname: "/lista_atletas_inscritos_provas",
                            state: {
                              Link_Menu_Anterior: this.props.location.pathname,
                              ID_Evento: this.state.ID_Evento,
                              Fez_Edicao_Dados_Atleta_Sistema: false,
                              Fez_Edicao_Inscricao_Atleta_Sistema: false,
                              Faz_Refresh: true,
                              Fez_Lancamento_Desistencia_Sucesso: false,
                              Fez_Eliminacao_Inscricao_Sucesso: false,
                              Nome_Evento: this.state.Nome_Evento
                            }
                          }}
                          title="Atletas Inscritos Todas Provas"
                        >
                          <i className="fa fa-users fa-lg espaco_icons" />
                        </Link>
                        <Link
                          to={{
                            pathname: "/lista_checkpoints_provas",
                            state: {
                              Link_Menu_Anterior: this.props.location.pathname,
                              ID_Evento: this.state.ID_Evento,
                              Fez_Edicao_Checkpoint_Prova: false,
                              Faz_Refresh: true,
                              Nome_Evento: this.state.Nome_Evento
                            }
                          }}
                          title="Checkpoints Todas Provas"
                        >
                          <i className="fa fa-map-marker fa-lg espaco_icons" />
                        </Link>
                      </td>
                    </tr>

                    {this.state.dados.map(linha => {
                      return (
                        <tr>
                          <td>{linha.long_name}</td>
                          <td>
                            {Moment(linha.init_date) //.format("DD-MM-YYYY HH:mm")
                              .locale("pt")
                              .format("LLLL")}
                          </td>
                          <td>
                            {Moment(linha.end_date) //.format("DD-MM-YYYY HH:mm")
                              .locale("pt")
                              .format("LLLL")}
                          </td>
                          <td>
                            {/*
                            <Link
                              to={{
                                pathname: "/dashboard_prova",
                                state: {
                                  //Neste Caso, Como É Apenas 1 Prova, Apenas Envia o ID_Prova!=NULL
                                  //Link Menu Anterior Nao É Preciso (Do Dashboard da Prova So Pode Voltar Para as Competicoes)
                                  //Link_Menu_Anterior: this.props.location.pathname,
                                  ID_Prova: linha.competition_id,
                                  ID_Evento: this.state.ID_Evento,
                                  Faz_Refresh: false, //true,
                                  Nome_Evento: this.state.Nome_Evento,
                                  Nome_Prova: linha.long_name
                                }
                              }}
                              title="Dashboard Prova"
                            >
                              <i className="fa fa-list fa-lg espaco_icons" />
                            </Link>
                            */}
                            <Link
                              to={{
                                pathname: "/lista_atletas_inscritos_prova",
                                state: {
                                  Link_Menu_Anterior: this.props.location
                                    .pathname,
                                  ID_Evento: this.state.ID_Evento,
                                  ID_Prova: linha.competition_id,
                                  Fez_Insercao_Inscricao_Atleta_Sistema: false,
                                  Fez_Edicao_Dados_Atleta_Sistema: false,
                                  Fez_Edicao_Inscricao_Atleta_Sistema: false,
                                  Faz_Refresh: true,
                                  Fez_Lancamento_Desistencia_Sucesso: false,
                                  Fez_Eliminacao_Inscricao_Sucesso: false,
                                  Nome_Evento: this.state.Nome_Evento,
                                  Nome_Prova: linha.long_name
                                }
                              }}
                              title="Atletas Inscritos"
                            >
                              <i className="fa fa-users fa-lg espaco_icons" />
                            </Link>
                            <Link
                              to={{
                                pathname: "/lista_checkpoints_prova",
                                state: {
                                  Link_Menu_Anterior: this.props.location
                                    .pathname,
                                  ID_Evento: this.state.ID_Evento,
                                  ID_Prova: linha.competition_id,
                                  Fez_Insercao_Checkpoint_Prova: false,
                                  Fez_Edicao_Checkpoint_Prova: false,
                                  Faz_Refresh: true,
                                  Nome_Evento: this.state.Nome_Evento,
                                  Nome_Prova: linha.long_name
                                }
                              }}
                              title="Checkpoints"
                            >
                              <i className="fa fa-map-marker fa-lg espaco_icons" />
                            </Link>
                            <Link
                              to={{
                                pathname: "/importar_dados",
                                state: {
                                  ID_Prova: linha.competition_id,
                                  Link_Menu_Anterior: this.props.location
                                    .pathname,
                                  ID_Evento: this.state.ID_Evento,
                                  Link_Tabela_Anterior: null,
                                  //Fez_Insercao_Ficheiro: false,
                                  Faz_Refresh: false, //true,
                                  Nome_Evento: this.state.Nome_Evento,
                                  Nome_Prova: linha.long_name
                                }
                              }}
                              title="Importar Dados Inscrições"
                            >
                              <i className="fa fa-upload fa-lg espaco_icons" />
                            </Link>

                            <Link
                              to={{
                                pathname: "/lista_notificacoes_prova",
                                state: {
                                  ID_Prova: linha.competition_id,
                                  ID_Evento: this.state.ID_Evento,
                                  Link_Menu_Anterior: this.props.location
                                    .pathname,
                                  Nome_Evento: this.state.Nome_Evento,
                                  Nome_Prova: linha.long_name
                                }
                              }}
                              title="Notificações da Prova"
                            >
                              <i className="fa fa-exclamation-circle fa-lg espaco_icons" />
                            </Link>
                            <Link
                              to={{
                                pathname: "/editar_competicao",
                                state: {
                                  ID_Prova: linha.competition_id,
                                  Link_Menu_Anterior: this.props.location
                                    .pathname,
                                  ID_Evento: this.state.ID_Evento,
                                  Faz_Refresh: false, //true,
                                  Nome_Evento: this.state.Nome_Evento,
                                  Nome_Prova: linha.long_name
                                }
                              }}
                              title="Editar Prova"
                            >
                              <i className="fa fa-cog fa-lg espaco_icons" />
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </tabela>

                <div style={{ marginTop: "10px" }}>
                  {/*<div align="right" style={{ marginRight: "10px" }}>*/}
                  <label>Resultados Por Página</label>{" "}
                  <select
                    name="perPage"
                    id="perPage"
                    onChange={this.handleChange}
                  >
                    <option value="10"> 10 </option>
                    <option value="25"> 25 </option>
                    <option value="50"> 50 </option>
                    <option value="100"> 100 </option>
                    <option value="0">Todos </option>
                  </select>
                </div>

                <div>
                  <ReactPaginate
                    previousLabel={"Anterior"}
                    nextLabel={"Próximo"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
              {this.Mostra_Mensagem_Edicao_Sucesso()}
              {this.Mostra_Mensagem_Insercao_Sucesso()}
              {this.Mostra_Mensagem_Eliminacao_Sucesso()}
              {this.Mostra_Mensagem_Erro_Eliminacao_Sucesso()}
            </div>
          );
        } else {
          return (
            <div>
              <Drawer
                Titulo_Menu="Provas"
                Link_Menu_Anterior={this.props.location.pathname}
                ID_Evento={this.state.ID_Evento}
                Nome_Evento={this.state.Nome_Evento}
                history={this.props.history}
              />
              {ve_resultados()}
            </div>
          );
        }
      }
    }
  }
}

export default Competicoes;
