import React, { Component } from "react";
//import { Link } from "react-router-dom";
import axios from "axios";
//Imports Para as Datas:
import Moment from "moment";
import Select from "react-select";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

//Funções Auxiliares:
import {
  //mostra_mensagem_erro,
  //ve_resultados,
  Trata_Validacoes,
  isEmptyObject
} from "../Funcoes_Auxiliares/Funcoes_Auxiliares.js";

//Imports Para Drawer e Snackbar:
import Drawer from "../Drawer/Drawer.js";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import classNames from "classnames";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import green from "@material-ui/core/colors/green";
import amber from "@material-ui/core/colors/amber";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import WarningIcon from "@material-ui/icons/Warning";

let REACT_APP_API_URL;

if (process.env.REACT_APP_ESTADO === "Deploy") {
  REACT_APP_API_URL = process.env.REACT_APP_API_URL_PROD;
} //Development
else {
  REACT_APP_API_URL = process.env.REACT_APP_API_URL_DEV;
}

//Constantes Para Drawer e Snackbar:
const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const styles1 = theme => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.dark
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit
  },
  message: {
    display: "flex",
    alignItems: "center"
  }
});

MySnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(["success", "warning", "error", "info"]).isRequired
};

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

//Funções Para Drawer e Snackbar:
function MySnackbarContent(props) {
  const { classes, className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>
      ]}
      {...other}
    />
  );
}

class Formulario_Copia_Evento extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //Recebe:
      dados_eventos_lidos: undefined,
      dados_evento_copiar: undefined,
      dados_competicoes_evento_copiar: undefined,
      dados_checkpoints_evento_copiar: undefined,

      //Enviar:
      id_evento_copiar: null,
      nome_evento_novo: null,
      data_inicio_evento_novo: null,
      data_fim_evento_novo: null,

      id_evento_novo: null,
      //asdasd: null,
      ids_competicoes_antigas: undefined, //null,
      ids_competicoes_novas: undefined, //null

      //Depois de Copiar Cada Parte:
      Evento_Copiado: false,
      Competicoes_Copiadas: false, //"qweqwewe", //false,
      Checkpoints_Copiados: false,

      //Lista Para os Erros de Validação:
      errors: {},

      //Variavel de Controlo Para a Mensagem de Login Com Sucesso na Snackbar:
      Fez_Copia_Evento: false,
      Fez_Copia_Competicoes: false,
      Fez_Copia_Checkpoints: false,

      Erro_Fez_Copia_Competicoes: false,
      Erro_Fez_Copia_Checkpoints: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleCopiaEvento = this.handleCopiaEvento.bind(this);
    this.handleCopiaCompeticoes = this.handleCopiaCompeticoes.bind(this);
    this.handleCopiaCheckpoints = this.handleCopiaCheckpoints.bind(this);
  }

  Vai_Buscar_Eventos_Sistema() {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .get(REACT_APP_API_URL + "/eventos", {
        headers: { authorization: "Bearer " + Token_Lido }
      })
      .then(res => {
        console.log(res);
        this.setState({ dados_eventos_lidos: res.data }); //.data });
      });
  }

  Vai_Buscar_Checkpoints_Evento_Copiar() {
    if (this.handleValidation()) {
      const Token_Lido = localStorage.getItem("Token");
      axios
        .post(
          REACT_APP_API_URL +
            "/copiar_checkpoint2/" +
            this.state.id_evento_copiar.value,
          { id_evento_novo: this.state.id_evento_novo },
          {
            headers: { authorization: "Bearer " + Token_Lido }
          }
        )
        //.then(res => { //Para Usar Esta Linha, é Preciso Fazer Return de Algo na API
        .then(res => {
          if (res.data.success === true) {
            this.setState({
              Checkpoints_Copiados: res.data.success, //true
              Fez_Copia_Checkpoints: true
            });
          } else {
            this.setState({
              Checkpoints_Copiados: false, //res.data.success, //true
              Erro_Fez_Copia_Checkpoints: true
            });
          }
        });
    }
  }

  Vai_Buscar_Competicoes_Evento_Copiar() {
    if (this.handleValidation()) {
      const Token_Lido = localStorage.getItem("Token");
      axios
        .post(
          REACT_APP_API_URL +
            "/copiar_competicao2/" +
            this.state.id_evento_copiar.value,
          { id_evento_novo: this.state.id_evento_novo },
          {
            headers: { authorization: "Bearer " + Token_Lido }
          }
        )

        //.then(res => { //Para Usar Esta Linha, é Preciso Fazer Return de Algo na API
        .then(res => {
          if (res.data.success === true) {
            this.setState({
              Competicoes_Copiadas: res.data.success, //true
              Fez_Copia_Competicoes: true
            });
          } else {
            this.setState({
              Competicoes_Copiadas: false, //res.data.success, //true
              Erro_Fez_Copia_Competicoes: true
            });
          }
        });
    }
  }

  Vai_Buscar_Dados_Evento() {
    if (this.handleValidation()) {
      const Token_Lido = localStorage.getItem("Token");

      axios
        .post(
          REACT_APP_API_URL +
            "/copiar_evento2/" +
            this.state.id_evento_copiar.value,
          {
            nome_evento_novo: this.state.nome_evento_novo,
            data_inicio_evento_novo: Moment(
              this.state.data_inicio_evento_novo,
              "YYYY-MM-DD HH:mm:ss"
            ),
            data_fim_evento_novo: Moment(
              this.state.data_fim_evento_novo,
              "YYYY-MM-DD HH:mm:ss"
            )
          },
          {
            headers: { authorization: "Bearer " + Token_Lido }
          }
        )
        .then(res => {
          console.log(res);
          this.setState({
            id_evento_novo: res.data.ID_Evento_Inserido,
            Evento_Copiado: true,
            Competicoes_Copiadas: false,
            Checkpoints_Copiados: false,
            Fez_Copia_Evento: true
          });
        });
    }
  }

  componentDidMount() {
    if (localStorage.getItem("Token") !== null) {
      this.Vai_Buscar_Eventos_Sistema();
    }
  } //Fecha Procedimento Que Acontece Depois do Output do Componente Foi Rendered

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleChangeSelectID_Evento_Copiar = id_evento_copiar => {
    this.setState({ id_evento_copiar });
  };

  //Funcoes Para a Mensagem de Login Com Sucesso na Snackbar
  handleClose_Copia_Evento = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ Fez_Copia_Evento: false });
  };

  //Funcoes Para a Mensagem de Login Com Sucesso na Snackbar
  handleClose_Copia_Competicoes = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ Fez_Copia_Competicoes: false });
  };

  //Funcoes Para a Mensagem de Login Com Sucesso na Snackbar
  handleClose_Copia_Checkpoints = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ Fez_Copia_Checkpoints: false });
  };

  //Funcoes Para a Mensagem de Login Com Sucesso na Snackbar
  handleClose_Erro_Copia_Competicoes = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ Erro_Fez_Copia_Competicoes: false });
  };

  //Funcoes Para a Mensagem de Login Com Sucesso na Snackbar
  handleClose_Erro_Copia_Checkpoints = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ Erro_Fez_Copia_Checkpoints: false });
  };

  Mostra_Mensagem_Copia_Evento_Sucesso = () => {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={this.state.Fez_Copia_Evento}
        autoHideDuration={3000}
        onClose={this.handleClose_Copia_Evento}
      >
        <MySnackbarContentWrapper
          onClose={this.handleClose_Copia_Evento}
          variant="success"
          message="Evento Copiado Com Sucesso!"
        />
      </Snackbar>
    );
  };

  Mostra_Mensagem_Copia_Competicoes_Sucesso = () => {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={this.state.Fez_Copia_Competicoes}
        autoHideDuration={3000}
        onClose={this.handleClose_Copia_Competicoes}
      >
        <MySnackbarContentWrapper
          onClose={this.handleClose_Copia_Competicoes}
          variant="success"
          message="Competições Copiadas Com Sucesso!"
        />
      </Snackbar>
    );
  };

  Mostra_Mensagem_Copia_Checkpoints_Sucesso = () => {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={this.state.Fez_Copia_Checkpoints}
        autoHideDuration={3000}
        onClose={this.handleClose_Copia_Checkpoints}
      >
        <MySnackbarContentWrapper
          onClose={this.handleClose_Copia_Checkpoints}
          variant="success"
          message="Checkpoints Copiados Com Sucesso!"
        />
      </Snackbar>
    );
  };

  Mostra_Mensagem_Erro_Copia_Competicoes_Sucesso = () => {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={this.state.Erro_Fez_Copia_Competicoes}
        autoHideDuration={3000}
        onClose={this.handleClose_Erro_Copia_Competicoes}
      >
        <MySnackbarContentWrapper
          onClose={this.handleClose_Erro_Copia_Competicoes}
          variant="error"
          message="Erro: Não Existem Competições Para Copiar"
        />
      </Snackbar>
    );
  };

  Mostra_Mensagem_Erro_Copia_Checkpoints_Sucesso = () => {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={this.state.Erro_Fez_Copia_Checkpoints}
        autoHideDuration={3000}
        onClose={this.handleClose_Erro_Copia_Checkpoints}
      >
        <MySnackbarContentWrapper
          onClose={this.handleClose_Erro_Copia_Checkpoints}
          variant="error"
          message="Erro: Não Existem Checkpoints Para Copiar"
        />
      </Snackbar>
    );
  };

  handleValidation() {
    let errors = {};
    let formIsValid = false; //true;

    let Data_Inicio = Moment(this.state.data_inicio_evento_novo);
    let Data_Fim = Moment(this.state.data_fim_evento_novo);

    //Para Cada Campo Invocar o Trata_Validacoes
    errors = Trata_Validacoes(
      this.state.id_evento_copiar,
      "trail_event/linha_evento",
      errors
    );
    errors = Trata_Validacoes(
      this.state.nome_evento_novo,
      "trail_event/name",
      errors
    );
    errors = Trata_Validacoes(
      this.state.data_inicio_evento_novo,
      "trail_event/init_date",
      errors
    );
    errors = Trata_Validacoes(
      this.state.data_fim_evento_novo,
      "trail_event/end_date",
      errors
    );

    //Verifica Coerência das Datas
    if (
      this.state.data_inicio_evento_novo &&
      this.state.data_fim_evento_novo &&
      //Moment(this.state.data_inicio).isSameorAfter(this.state.data_inicio)

      Data_Inicio.diff(Data_Fim) >= 0 //, "days"); //Negativo, Data_Inicio<Data_Fim
    ) {
      formIsValid = false;
      errors["trail_event/end_date"] = "*Data de Fim de Evento Inválida";
    }

    //if (errors.length > 0) {
    if (isEmptyObject(errors)) {
      formIsValid = true; //false;
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleCopiaEvento(event) {
    event.preventDefault();
    this.Vai_Buscar_Dados_Evento();
  }
  handleCopiaCompeticoes(event) {
    event.preventDefault();
    this.Vai_Buscar_Competicoes_Evento_Copiar();
  }
  handleCopiaCheckpoints(event) {
    event.preventDefault();
    this.Vai_Buscar_Checkpoints_Evento_Copiar();
  }

  Devolve_Buttons() {
    if (
      this.state.Evento_Copiado === true &&
      this.state.Competicoes_Copiadas === false
    ) {
      return (
        <div>
          <button onClick={this.handleCopiaEvento}>Copiar Evento</button>
          <div className="espaco_span" />
          <button onClick={this.handleCopiaCompeticoes}>
            Copiar Competição
          </button>
          <div className="espaco_span" />
          <button
            onClick={this.handleCopiaCheckpoints}
            disabled
            style={{ color: "grey" }}
          >
            Copiar Checkpoints
          </button>
        </div>
      );
    } else if (
      this.state.Evento_Copiado === true &&
      this.state.Competicoes_Copiadas === true &&
      this.state.Checkpoints_Copiados === false
    ) {
      return (
        <div>
          <button onClick={this.handleCopiaEvento}>Copiar Evento</button>
          <div className="espaco_span" />
          <button
            onClick={this.handleCopiaCompeticoes}
            disabled
            style={{ color: "grey" }}
          >
            Copiar Competição
          </button>
          <div className="espaco_span" />
          <button onClick={this.handleCopiaCheckpoints}>
            Copiar Checkpoints
          </button>
        </div>
      );
    } else if (
      (this.state.Evento_Copiado === false &&
        this.state.Competicoes_Copiadas === false) ||
      this.state.Checkpoints_Copiados === true
    ) {
      return (
        <div>
          <button onClick={this.handleCopiaEvento}>Copiar Evento</button>
          <div className="espaco_span" />
          <button
            onClick={this.handleCopiaCompeticoes}
            disabled
            style={{ color: "grey" }}
          >
            Copiar Competição
          </button>
          <div className="espaco_span" />
          <button
            onClick={this.handleCopiaCheckpoints}
            disabled
            style={{ color: "grey" }}
          >
            Copiar Checkpoints
          </button>
        </div>
      );
    }
  }

  render() {
    if (localStorage.getItem("Token") === null) {
      this.props.history.push({
        pathname: "/"
      });
    }
    if (this.state.dados_eventos_lidos === undefined) {
      return (
        <div className="centra_loading">
          <h1>Loading...</h1>
        </div>
      );
    } else {
      var Opcoes_Select_Eventos = [];
      //this.state.dados_eventos_lidos.map(linha2 => {
      this.state.dados_eventos_lidos.forEach(function(linha2) {
        var Linha = {
          value: linha2.trail_event_id,
          label:
            linha2.name +
            " (" +
            Moment(linha2.init_date).format("DD-MM-YYYY") +
            ")"
        };
        Opcoes_Select_Eventos.push(Linha);
      });

      const border_erro = "1px solid #ff0000";

      return (
        <div>
          <Drawer Titulo_Menu="Copiar Evento" history={this.props.history} />
          <div className="novas_margens_volta_novos_forms">
            <div className="centra_novos_forms">
              <form className="login-form">
                <div className="centra_titulos_tabs">
                  <Tabs>
                    <TabList>
                      <Tab
                        style={{
                          border:
                            this.state.errors["trail_event/linha_evento"] ||
                            this.state.errors["trail_event/name"]
                              ? border_erro
                              : ""
                        }}
                      >
                        Dados do Evento
                      </Tab>
                      <Tab
                        style={{
                          border:
                            this.state.errors["trail_event/init_date"] ||
                            this.state.errors["trail_event/end_date"]
                              ? border_erro
                              : ""
                        }}
                      >
                        Datas
                      </Tab>
                    </TabList>
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    <TabPanel>
                      <div className="form">
                        <div>
                          <label>
                            Evento a Copiar
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {" (*)"}
                            </span>
                          </label>
                          <div
                            style={{
                              border: this.state.errors[
                                "trail_event/linha_evento"
                              ]
                                ? border_erro
                                : ""
                            }}
                          >
                            <Select
                              value={this.state.id_evento_copiar}
                              name="id_evento_copiar"
                              placeholder="Escolha um Evento"
                              onChange={this.handleChangeSelectID_Evento_Copiar}
                              options={Opcoes_Select_Eventos}
                            />
                          </div>
                          <span className="bolder" style={{ color: "#e60000" }}>
                            {this.state.errors["trail_event/linha_evento"]}
                          </span>
                          <div className="espaco_span" />

                          <label>
                            Nome do Novo Evento
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {" (*)"}
                            </span>
                          </label>
                          <div>
                            <input
                              style={{
                                border: this.state.errors["trail_event/name"]
                                  ? border_erro
                                  : "",
                                textAlign: "center"
                              }}
                              type="text"
                              name="nome_evento_novo"
                              placeholder="Nome Novo Evento"
                              onChange={this.handleChange}
                              defaultValue={this.state.nome_evento_novo}
                              //required
                            />
                          </div>
                          <span className="bolder" style={{ color: "#e60000" }}>
                            {this.state.errors["trail_event/name"]}
                          </span>
                          <div className="espaco_span" />
                          <span className="bolder" style={{ color: "#e60000" }}>
                            {" (*)"}
                          </span>
                          <span className="bolder"> Campos Obrigatórios</span>
                        </div>
                      </div>
                      <div className="form">{this.Devolve_Buttons()}</div>
                    </TabPanel>
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    <TabPanel>
                      <div className="form">
                        <div>
                          <label>
                            Data de Inicio do Novo Evento
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {" (*)"}
                            </span>
                          </label>
                          <div>
                            <input
                              style={{
                                border: this.state.errors[
                                  "trail_event/init_date"
                                ]
                                  ? border_erro
                                  : "",
                                textAlign: "center"
                              }}
                              //type="text" //"datetime-local"step="0.001" //"text"
                              type="datetime-local"
                              //step="0.001"
                              name="data_inicio_evento_novo"
                              placeholder="Data Inicio Novo Evento"
                              onChange={this.handleChange}
                              defaultValue={
                                String(
                                  Moment(
                                    this.state.data_inicio_evento_novo
                                  ).format("YYYY-MM-DD")
                                ) +
                                "T" +
                                String(
                                  Moment(
                                    this.state.data_inicio_evento_novo
                                  ).format("HH:mm:ss")
                                )
                              }
                              //required
                            />
                          </div>
                          <span className="bolder" style={{ color: "#e60000" }}>
                            {this.state.errors["trail_event/init_date"]}
                          </span>
                          <div className="espaco_span" />

                          <label>
                            Data de Fim do Novo Evento
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {" (*)"}
                            </span>
                          </label>
                          <div>
                            <input
                              style={{
                                border: this.state.errors[
                                  "trail_event/end_date"
                                ]
                                  ? border_erro
                                  : "",
                                textAlign: "center"
                              }}
                              //type="text" //"datetime-local"step="0.001" //"text"
                              type="datetime-local"
                              //step="0.001"
                              name="data_fim_evento_novo"
                              placeholder="Data Fim Novo Evento"
                              onChange={this.handleChange}
                              defaultValue={
                                String(
                                  Moment(
                                    this.state.data_fim_evento_novo
                                  ).format("YYYY-MM-DD")
                                ) +
                                "T" +
                                String(
                                  Moment(
                                    this.state.data_fim_evento_novo
                                  ).format("HH:mm:ss")
                                )
                              }
                              //required
                            />
                          </div>
                          <span className="bolder" style={{ color: "#e60000" }}>
                            {this.state.errors["trail_event/end_date"]}
                          </span>
                        </div>
                        <div className="espaco_span" />
                        <span className="bolder" style={{ color: "#e60000" }}>
                          {" (*)"}
                        </span>{" "}
                        <span className="bolder"> Campos Obrigatórios</span>
                      </div>
                      <div className="form">{this.Devolve_Buttons()}</div>
                    </TabPanel>
                  </Tabs>
                </div>
              </form>
            </div>
          </div>
          {this.Mostra_Mensagem_Copia_Evento_Sucesso()}
          {this.Mostra_Mensagem_Copia_Competicoes_Sucesso()}
          {this.Mostra_Mensagem_Copia_Checkpoints_Sucesso()}
          {this.Mostra_Mensagem_Erro_Copia_Competicoes_Sucesso()}
          {this.Mostra_Mensagem_Erro_Copia_Checkpoints_Sucesso()}
        </div>
      );
    }
  }
}

export default Formulario_Copia_Evento;
