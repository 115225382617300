import React, { Component } from "react";
import axios from "axios";
//import { Link } from "react-router-dom";
import {
  FilePond //, File, registerPlugin
} from "react-filepond";
//import FilePondPluginTypeValidation from "filepond-plugin-file-validate-type";
import "filepond/dist/filepond.min.css";

//Imports Para Drawer e Snackbar:
import Drawer from "../Drawer/Drawer.js";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import classNames from "classnames";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import green from "@material-ui/core/colors/green";
import amber from "@material-ui/core/colors/amber";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import WarningIcon from "@material-ui/icons/Warning";

//import "filepond/dist/filepond.min.css";

//registerPlugin(FilePondPluginTypeValidation);

//Funções Auxiliares:
import {
  mostra_mensagem_erro
  //ve_resultados
} from "../Funcoes_Auxiliares/Funcoes_Auxiliares.js";

let REACT_APP_API_URL;

if (process.env.REACT_APP_ESTADO === "Deploy") {
  REACT_APP_API_URL = process.env.REACT_APP_API_URL_PROD;
} //Development
else {
  REACT_APP_API_URL = process.env.REACT_APP_API_URL_DEV;
}

//Constantes Para Drawer e Snackbar:
const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const styles1 = theme => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.dark
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit
  },
  message: {
    display: "flex",
    alignItems: "center"
  }
});

MySnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(["success", "warning", "error", "info"]).isRequired
};

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

//Funções Para Drawer e Snackbar:
function MySnackbarContent(props) {
  const { classes, className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>
      ]}
      {...other}
    />
  );
}

class Importar_Dados_Excel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Link_Menu_Anterior: null, //this.props.location.pathname,
      ID_Prova: null, //linha.competition_id,
      ID_Evento: null, //this.state.ID_Evento
      Link_Tabela_Anterior: null,
      Nome_Evento: null,
      Nome_Prova: null,

      //Variavel Controlo de Erro na Leitura dos Props
      Mostra_Erro: false,

      //Variavel de Controlo Para a Mensagem de Inscrição Com Sucesso na Snackbar:
      Fez_Insercao_Ficheiro: false,
      Fez_Leitura_Ficheiro: false,
      Progresso: 0,
      Controlo: false
    };

    if (props.location.state === undefined) {
      this.state.Mostra_Erro = true;
    } else {
      if (props.location.state.Link_Tabela_Anterior === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Link_Tabela_Anterior =
          props.location.state.Link_Tabela_Anterior;
      }
      if (props.location.state.Link_Menu_Anterior === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Link_Menu_Anterior = props.location.state.Link_Menu_Anterior;
      }
      if (props.location.state.ID_Evento === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.ID_Evento = props.location.state.ID_Evento;
      }
      if (props.location.state.ID_Prova === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.ID_Prova = props.location.state.ID_Prova;
      }
      if (props.location.state.Nome_Evento === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Nome_Evento = props.location.state.Nome_Evento;
      }
      if (props.location.state.Nome_Prova === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Nome_Prova = props.location.state.Nome_Prova;
      }
    }
    this.handleLeFicheiro = this.handleLeFicheiro.bind(this);
  }

  handleLeFicheiro() {
    //event.preventDefault();
    const Token_Lido = localStorage.getItem("Token");

    axios
      .get(REACT_APP_API_URL + "/le_ficheiro_excel/" + "asd", {
        headers: { authorization: "Bearer " + Token_Lido }
      })
      .then(res => {
        console.log(res);
        this.setState({ Fez_Leitura_Ficheiro: true });
      });
  }

  //Funcoes Para a Mensagem de Login Com Sucesso na Snackbar
  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ Fez_Insercao_Ficheiro: false });
  };

  //Funcoes Para a Mensagem de Login Com Sucesso na Snackbar
  handleCloseLeitura = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ Fez_Leitura_Ficheiro: false });
  };

  Mostra_Mensagem_Insercao_Sucesso = () => {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={this.state.Fez_Insercao_Ficheiro}
        autoHideDuration={3000}
        onClose={this.handleClose}
      >
        <MySnackbarContentWrapper
          onClose={this.handleClose}
          variant="success"
          message="Ficheiro Inserido Com Sucesso!"
        />
      </Snackbar>
    );
  };

  Mostra_Mensagem_Leitura_Sucesso = () => {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={this.state.Fez_Leitura_Ficheiro}
        autoHideDuration={3000}
        onClose={this.handleCloseLeitura}
      >
        <MySnackbarContentWrapper
          onClose={this.handleCloseLeitura}
          variant="success"
          message="Ficheiro Lido Com Sucesso!"
        />
      </Snackbar>
    );
  };

  render() {
    if (localStorage.getItem("Token") === null) {
      this.props.history.push({
        pathname: "/"
      });
    }

    /*
    //if (this.state.Fez_Insercao_Ficheiro === true) {
      if(this.state.Fez_Leitura_Ficheiro===true){
      this.props.history.push({
        pathname: this.props.Link_Tabela_Anterior,
        state: {
          Link_Menu_Anterior: this.props.Link_Menu_Anterior,
          ID_Evento: this.props.ID_Evento,
          ID_Prova: this.props.ID_Prova,
          Faz_Refresh: true,
          Nome_Evento: this.state.Nome_Evento,
          Nome_Prova: this.state.Nome_Prova
        }
      });
    }
    */

    //Verifica Se Existe Erro. Se Houver Mostra Mensagem de Erro
    if (this.state.Mostra_Erro === true) {
      return (
        <div>
          <Drawer Titulo_Menu="" history={this.props.history} />
          {mostra_mensagem_erro()}
        </div>
      );
    } else {
      var String_Caminho =
        REACT_APP_API_URL + "/teste_update_ficheiro/" + this.state.ID_Prova;

      const Token_Lido = localStorage.getItem("Token");
      return (
        <div>
          <Drawer
            Titulo_Menu="Importar Dados Excel"
            Link_Tabela_Anterior={this.state.Link_Tabela_Anterior}
            Link_Menu_Anterior={this.state.Link_Menu_Anterior}
            ID_Evento={this.state.ID_Evento}
            ID_Prova={this.state.ID_Prova}
            Nome_Evento={this.state.Nome_Evento}
            Nome_Prova={this.state.Nome_Prova}
            history={this.props.history}
          />
          <div
            className="novo_form_insercao_excel" //"form" //"form_importar_dados"
          >
            <FilePond
              allowMultiple={false}
              name="ficheiro_uploaded"
              acceptedFileTypes={["xlsx", "xls"]}
              server={{
                url: String_Caminho,
                process: {
                  headers: { authorization: "Bearer " + Token_Lido },
                  onload: response =>
                    this.setState({ Fez_Insercao_Ficheiro: response })
                }
              }}
            />
            <div className="espaco_span2" />
            <div>
              <button onClick={this.handleLeFicheiro}>Ler Ficheiro</button>
            </div>
          </div>
          {this.Mostra_Mensagem_Insercao_Sucesso()}
          {this.Mostra_Mensagem_Leitura_Sucesso()}
        </div>
      );
    }
  }
}

export default Importar_Dados_Excel;
