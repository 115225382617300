// https://material-ui.com/demos/dialogs/

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
//import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
//import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
//import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
//import InboxIcon from "@material-ui/icons/MoveToInbox";
//import MailIcon from "@material-ui/icons/Mail";

//imports Para o Dialog de Logout
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
//import DialogContent from '@material-ui/core/DialogContent';
//import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from "@material-ui/core/DialogTitle";

import "./Drawer.css";
import { Link } from "react-router-dom";
import axios from "axios";

let REACT_APP_API_URL;

if (process.env.REACT_APP_ESTADO === "Deploy") {
  REACT_APP_API_URL = process.env.REACT_APP_API_URL_PROD;
} //Development
else {
  REACT_APP_API_URL = process.env.REACT_APP_API_URL_DEV;
}

// https://stackoverflow.com/questions/51265838/material-ui-drawer-set-background-color

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: "flex"
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 20
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,
    //Alterar Aqui a Cor do Background (Originalmente Nao Tem Background)
    //background: "blue"
    background: "#d9d9d9"
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
    justifyContent: "flex-end"
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: -drawerWidth
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  }
});

class PersistentDrawerLeft extends React.Component {
  state = {
    open: true, //false,
    open_dialog_logout: false,
    open_dialog_lancar_desistencia: false,
    open_dialog_eliminar_inscricao: false,

    open_dialog_eliminar_evento: false,
    open_dialog_eliminar_competicao: false,

    Logout_Realizado: false,
    Lancamento_Desistencia_Realizada: false,
    Eliminar_Inscricao_Realizado: false,
    Eliminar_Evento_Realizado: false,
    Eliminar_Competicao_Realizado: false
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  handleClickOpenDialogLogout = () => {
    this.setState({ open_dialog_logout: true });
  };

  handleCloseDialogLogout = () => {
    this.setState({ open_dialog_logout: false });
  };

  handleClickOpenDialogLancarDesistencia = () => {
    this.setState({ open_dialog_lancar_desistencia: true });
  };

  handleCloseDialogLancarDesistencia = () => {
    this.setState({ open_dialog_lancar_desistencia: false });
  };

  handleClickOpenDialogEliminarInscricao = () => {
    this.setState({ open_dialog_eliminar_inscricao: true });
  };

  handleCloseDialogEliminarInscricao = () => {
    this.setState({ open_dialog_eliminar_inscricao: false });
  };

  handleClickOpenDialogEliminarEvento = () => {
    this.setState({ open_dialog_eliminar_evento: true });
  };

  handleCloseDialogEliminarEvento = () => {
    this.setState({ open_dialog_eliminar_evento: false });
  };

  handleClickOpenDialogEliminarCompeticao = () => {
    this.setState({ open_dialog_eliminar_competicao: true });
  };

  handleCloseDialogEliminarCompeticao = () => {
    this.setState({ open_dialog_eliminar_competicao: false });
  };

  handleLogout = () => {
    localStorage.removeItem("Token");
    localStorage.removeItem("Tipo");
    //localStorage.removeItem("ID");
    /*
    localStorage.removeItem("Login");
    localStorage.removeItem("Mostrar_Notificacoes_Atletas_Adiantados");
    localStorage.removeItem("Mostrar_Notificacoes_Atletas_Atrasados");
    localStorage.removeItem("Mostrar_Notificacoes_Ordem_Checkpoints");
    localStorage.removeItem("Mostrar_Notificacoes_Tempos_Depois_Desistencia_Atletas");
    localStorage.removeItem("Mostrar_Notificacoes_Podio_Atletas_Regionais");
    localStorage.removeItem("Mostrar_Notificacoes_Podio_Atletas_Portugal");
    localStorage.removeItem("Mostrar_Notificacoes_Podio_Atletas_Mundo");
    */

    this.setState({ open_dialog_logout: false, Logout_Realizado: true });

    /*
    this.setState({ open_dialog_logout: false, Logout_Realizado: true }, () => {
      localStorage.removeItem("Token");
    });
    */
  };

  handleLancarDesistencia = () => {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .patch(
        REACT_APP_API_URL +
          "/lancamento_desistencia_atleta/" +
          this.props.ID_Atleta +
          "/" +
          this.props.ID_Prova,
        {}, //Mesmo Que Nao Sejam Enviados Parametros no Body, Tem de Ser Incluido "{},"
        {
          headers: { authorization: "Bearer " + Token_Lido }
        }
      )
      .then(res => {
        this.setState({
          //open_dialog_desistencia: false,
          //Lancamento_Desistencia_Sucesso: res.data.success,
          open_dialog_lancar_desistencia: false,
          Lancamento_Desistencia_Realizada: res.data.success
        });
      });

    /*
    this.setState({
      open_dialog_lancar_desistencia: false,
      Lancamento_Desistencia_Realizada: true
    });
    */
  };

  handleEliminarInscricao = () => {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .delete(
        REACT_APP_API_URL +
          "/delete_inscricao2/" +
          this.props.id_inscricao +
          "/" +
          this.props.ID_Atleta,
        //{},
        {
          headers: { authorization: "Bearer " + Token_Lido }
        }
      )
      .then(res => {
        if (res.data.success === true) {
          this.setState({
            open_dialog_eliminar_inscricao: false,
            Eliminar_Inscricao_Realizado: true
          });
        } else if (res.data.success === false) {
          this.setState({
            open_dialog_eliminar_inscricao: false,
            Eliminar_Inscricao_Realizado: "erro"
          });
        }
      });

    /*
    this.setState({
      open_dialog_eliminar_inscricao: false,
      Eliminar_Inscricao_Realizado: true
    });
    */
  };

  handleEliminarEvento = () => {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .delete(
        REACT_APP_API_URL + "/delete_evento2/" + this.props.ID_Evento,
        //{},
        {
          headers: { authorization: "Bearer " + Token_Lido }
        }
      )
      .then(res => {
        if (res.data.success === true) {
          this.setState({
            open_dialog_eliminar_evento: false,
            //Faz_Refresh: true,
            Eliminar_Evento_Realizado: true
          });
        } else if (res.data.success === false) {
          this.setState({
            open_dialog_eliminar_evento: false,
            //Faz_Refresh: true,
            Eliminar_Evento_Realizado: "erro"
          });
        }
      });
  };

  handleEliminarCompeticao = () => {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .delete(
        REACT_APP_API_URL + "/delete_competicao2/" + this.props.ID_Prova,
        //{},
        {
          headers: { authorization: "Bearer " + Token_Lido }
        }
      )
      .then(res => {
        if (res.data.success === true) {
          this.setState({
            open_dialog_eliminar_competicao: false,
            //Faz_Refresh: true,
            Eliminar_Competicao_Realizado: true
          });
        } else if (res.data.success === false) {
          this.setState({
            open_dialog_eliminar_competicao: false,
            //Faz_Refresh: true,
            Eliminar_Competicao_Realizado: "erro"
          });
        }
      });
  };

  Mostra_Titulo_Drawer() {
    let Texto_Drawer = this.props.Titulo_Menu;

    if (
      this.props.Nome_Evento === undefined &&
      this.props.Nome_Prova === undefined &&
      this.props.Nome_Checkpoint === undefined &&
      this.props.Nome_Atleta === undefined
    ) {
      return Texto_Drawer;
    }
    if (
      this.props.Nome_Evento !== undefined &&
      this.props.Nome_Prova === undefined &&
      this.props.Nome_Checkpoint === undefined &&
      this.props.Nome_Atleta === undefined
    ) {
      //Texto_Drawer = Texto_Drawer + ": " + this.props.Nome_Evento;

      Texto_Drawer = Texto_Drawer + ": ";

      return (
        <div>
          {Texto_Drawer}
          <Link
            style={{ color: "white" }}
            to={{
              pathname: "/eventos",
              state: {
                Fez_Login: false,
                Fez_Insercao_Evento: false,
                Fez_Edicao_Evento: false,
                Fez_Copia_Evento: false,
                Faz_Refresh: true,
                Fez_Eliminacao_Evento: false
              }
            }}
          >
            <u>{this.props.Nome_Evento}</u>
          </Link>
        </div>
      );

      //return Texto_Drawer;
    }
    /*
    if (
      this.props.Nome_Evento === undefined &&
      this.props.Nome_Prova !== undefined &&
      this.props.Nome_Checkpoint === undefined &&
      this.props.Nome_Atleta === undefined
    ) {
      //Not Possible
    }
    if (
      this.props.Nome_Evento === undefined &&
      this.props.Nome_Prova === undefined &&
      this.props.Nome_Checkpoint !== undefined &&
      this.props.Nome_Atleta === undefined
    ) {
      //Not Possible
    }
    if (
      this.props.Nome_Evento === undefined &&
      this.props.Nome_Prova === undefined &&
      this.props.Nome_Checkpoint === undefined &&
      this.props.Nome_Atleta !== undefined
    ) {
      //Not Possible
    }
    */
    if (
      this.props.Nome_Evento !== undefined &&
      this.props.Nome_Prova !== undefined &&
      this.props.Nome_Checkpoint === undefined &&
      this.props.Nome_Atleta === undefined
    ) {
      Texto_Drawer = Texto_Drawer + ": ";

      return (
        <div>
          {Texto_Drawer}
          <Link
            style={{ color: "white" }}
            to={{
              pathname: "/competicoes",
              state: {
                ID_Evento: this.props.ID_Evento,
                Fez_Insercao_Competicao: false,
                Fez_Edicao_Competicao: false,
                Faz_Refresh: true,
                Nome_Evento: this.props.Nome_Evento,
                Fez_Eliminacao_Competicao: false
              }
            }}
          >
            <u>{this.props.Nome_Evento}</u>
          </Link>
          {" > " + this.props.Nome_Prova}
        </div>
      );
    }
    if (
      this.props.Nome_Evento !== undefined &&
      this.props.Nome_Prova === undefined &&
      this.props.Nome_Checkpoint !== undefined &&
      this.props.Nome_Atleta === undefined
    ) {
      Texto_Drawer = Texto_Drawer + ": ";

      return (
        <div>
          {Texto_Drawer}
          <Link
            style={{ color: "white" }}
            to={{
              pathname: "/competicoes",
              state: {
                ID_Evento: this.props.ID_Evento,
                Fez_Insercao_Competicao: false,
                Fez_Edicao_Competicao: false,
                Faz_Refresh: true,
                Nome_Evento: this.props.Nome_Evento,
                Fez_Eliminacao_Competicao: false
              }
            }}
          >
            <u>{this.props.Nome_Evento}</u>
          </Link>
          {" > " + this.props.Nome_Checkpoint}
        </div>
      );
    }
    if (
      this.props.Nome_Evento !== undefined &&
      this.props.Nome_Prova === undefined &&
      this.props.Nome_Checkpoint === undefined &&
      this.props.Nome_Atleta !== undefined
    ) {
      Texto_Drawer = Texto_Drawer + ": ";

      return (
        <div>
          {Texto_Drawer}
          <Link
            style={{ color: "white" }}
            to={{
              pathname: "/competicoes",
              state: {
                ID_Evento: this.props.ID_Evento,
                Fez_Insercao_Competicao: false,
                Fez_Edicao_Competicao: false,
                Faz_Refresh: true,
                Nome_Evento: this.props.Nome_Evento,
                Fez_Eliminacao_Competicao: false
              }
            }}
          >
            <u>{this.props.Nome_Evento}</u>
          </Link>
          {" > " + this.props.Nome_Atleta}
        </div>
      );
    }

    if (
      this.props.Nome_Evento !== undefined &&
      this.props.Nome_Prova !== undefined &&
      this.props.Nome_Checkpoint !== undefined &&
      this.props.Nome_Atleta === undefined
    ) {
      Texto_Drawer = Texto_Drawer + ": ";

      return (
        <div>
          {Texto_Drawer}
          <Link
            style={{ color: "white" }}
            to={{
              pathname: "/competicoes",
              state: {
                ID_Evento: this.props.ID_Evento,
                Fez_Insercao_Competicao: false,
                Fez_Edicao_Competicao: false,
                Faz_Refresh: true,
                Nome_Evento: this.props.Nome_Evento,
                Fez_Eliminacao_Competicao: false
              }
            }}
          >
            <u>{this.props.Nome_Evento}</u>
          </Link>

          {" > "}
          <Link
            style={{ color: "white" }}
            to={{
              pathname: "/lista_checkpoints_prova",
              state: {
                ID_Evento: this.props.ID_Evento,
                ID_Prova: this.props.ID_Prova,
                Link_Menu_Anterior: this.props.Link_Menu_Anterior,
                Fez_Insercao_Checkpoint_Prova: false,
                Fez_Edicao_Checkpoint_Prova: false,
                Faz_Refresh: true,
                Nome_Evento: this.props.Nome_Evento,
                Nome_Prova: this.props.Nome_Prova
              }
            }}
          >
            <u>{this.props.Nome_Prova}</u>
          </Link>
          {" > " + this.props.Nome_Checkpoint}
        </div>
      );
    }
    if (
      this.props.Nome_Evento !== undefined &&
      this.props.Nome_Prova !== undefined &&
      this.props.Nome_Checkpoint === undefined &&
      this.props.Nome_Atleta !== undefined
    ) {
      Texto_Drawer = Texto_Drawer + ": ";

      return (
        <div>
          {Texto_Drawer}
          <Link
            style={{ color: "white" }}
            to={{
              pathname: "/competicoes",
              state: {
                ID_Evento: this.props.ID_Evento,
                Fez_Insercao_Competicao: false,
                Fez_Edicao_Competicao: false,
                Faz_Refresh: true,
                Nome_Evento: this.props.Nome_Evento,
                Fez_Eliminacao_Competicao: false
              }
            }}
          >
            <u>{this.props.Nome_Evento}</u>
          </Link>

          {" > "}
          <Link
            style={{ color: "white" }}
            to={{
              pathname: "/lista_atletas_inscritos_prova",
              state: {
                Link_Menu_Anterior: this.props.Link_Menu_Anterior,
                ID_Evento: this.props.ID_Evento,
                ID_Prova: this.props.ID_Prova,
                Nome_Evento: this.props.Nome_Evento,
                Nome_Prova: this.props.Nome_Prova,
                Fez_Insercao_Inscricao_Atleta_Sistema: false,
                Fez_Edicao_Dados_Atleta_Sistema: false,
                Fez_Edicao_Inscricao_Atleta_Sistema: false,
                Faz_Refresh: true,
                Fez_Lancamento_Desistencia_Sucesso: false,
                Fez_Eliminacao_Inscricao_Sucesso: false
              }
            }}
          >
            <u>{this.props.Nome_Prova}</u>
          </Link>
          {" > " + this.props.Nome_Atleta}
        </div>
      );
    }
    if (
      this.props.Nome_Evento !== undefined &&
      this.props.Nome_Prova !== undefined &&
      this.props.Nome_Checkpoint !== undefined &&
      this.props.Nome_Atleta !== undefined
    ) {
      Texto_Drawer = Texto_Drawer + ": ";

      return (
        <div>
          {Texto_Drawer}
          <Link
            style={{ color: "white" }}
            to={{
              pathname: "/competicoes",
              state: {
                ID_Evento: this.props.ID_Evento,
                Fez_Insercao_Competicao: false,
                Fez_Edicao_Competicao: false,
                Faz_Refresh: true,
                Nome_Evento: this.props.Nome_Evento,
                Fez_Eliminacao_Competicao: false
              }
            }}
          >
            <u>{this.props.Nome_Evento}</u>
          </Link>

          {" > "}
          <Link
            style={{ color: "white" }}
            to={{
              pathname: "/lista_checkpoints_prova",
              state: {
                ID_Evento: this.props.ID_Evento,
                ID_Prova: this.props.ID_Prova,
                Link_Menu_Anterior: this.props.Link_Menu_Anterior,
                Fez_Insercao_Checkpoint_Prova: false,
                Fez_Edicao_Checkpoint_Prova: false,
                Faz_Refresh: true,
                Nome_Evento: this.props.Nome_Evento,
                Nome_Prova: this.props.Nome_Prova
              }
            }}
          >
            <u>{this.props.Nome_Prova}</u>
          </Link>

          {" > "}
          <Link
            style={{ color: "white" }}
            to={{
              pathname: "/lista_resultados_checkpoints",
              state: {
                Link_Menu_Anterior: this.props.Link_Menu_Anterior,
                Link_Tabela_Anterior: this.props.Link_Tabela_Anterior,
                ID_Evento: this.props.ID_Evento,
                ID_Prova: this.props.ID_Prova,
                ID_Atleta: this.props.ID_Atleta,
                ID_Competition_Checkpoint: this.props.ID_Competition_Checkpoint,
                Fez_Edicao_Resultado: false,
                Fez_Insercao_Resultado: false,
                Faz_Refresh: true,
                Nome_Evento: this.props.Nome_Evento,
                Nome_Prova: this.props.Nome_Prova,
                Nome_Checkpoint: this.props.Nome_Checkpoint
              }
            }}
          >
            <u>{this.props.Nome_Checkpoint}</u>
          </Link>
          {" > " + this.props.Nome_Atleta}
        </div>
      );
    }
  }

  Mostra_Botao_Logout_Drawer = classes => {
    return (
      <div>
        <ListItem button onClick={this.handleClickOpenDialogLogout}>
          <ListItemIcon
            className={classNames(classes.icon, "fa fa-sign-out fa-2x")} //fa-2x
          />

          <ListItemText primary={"Logout"} />
        </ListItem>

        <Dialog
          open={this.state.open_dialog_logout}
          onClose={this.handleCloseDialogLogout}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Tem a Certeza Que Deseja Sair?"}
          </DialogTitle>
          {/*<DialogContent>
            <DialogContentText id="alert-dialog-description">
              Let Google help apps determine location. This means sending anonymous location data to
              Google, even when no apps are running.
            </DialogContentText>
          </DialogContent>*/}
          <DialogActions>
            <Button onClick={this.handleCloseDialogLogout} color="primary">
              Cancelar
            </Button>
            <Button onClick={this.handleLogout} color="primary" autoFocus>
              Sair
            </Button>
          </DialogActions>
        </Dialog>

        <Divider />
      </div>
    );
  };

  Mostra_Botoes_Basicos_Drawer = classes => {
    return (
      <div>
        <Link
          style={{ textDecoration: "none" }}
          to={{
            //pathname: "/", //Se For Para o "/eventos", Pode Ficar em Loop Infinito em Caso de Erro de Props
            pathname: "/", //pathname: "/eventos",
            state: {
              Fez_Login: false,
              Fez_Insercao_Evento: false,
              Fez_Edicao_Evento: false,
              Faz_Refresh: true,
              Fez_Eliminacao_Evento: false
            }
          }}
        >
          <ListItem button>
            <ListItemIcon
              className={classNames(classes.icon, "fa fa-home fa-2x")} //fa-2x
            />

            <ListItemText primary={"Ver Eventos"} />
          </ListItem>
        </Link>

        <Divider />
      </div>
    );
  };

  //Serao Definidos Aqui os Botoes a Mostrar no Drawer, Dependendo do Titulo Que For Recebido
  Mostra_Botoes_Extra_Drawer = classes => {
    ///////////////////////////////////////////////////////////////////////////////////////////////////
    //                                       Botoes do Ecra "Adicionar_Atleta" e "Editar_Atleta"                                //
    //                                       Botoes do Ecra "Adicionar_Atleta" e "Editar_Atleta"                                //
    //                                       Botoes do Ecra "Adicionar_Atleta" e "Editar_Atleta"                                //
    //                                       Botoes do Ecra "Adicionar_Atleta" e "Editar_Atleta"                                //
    //                                       Botoes do Ecra "Adicionar_Atleta" e "Editar_Atleta"                                //
    //                                       Botoes do Ecra "Adicionar_Atleta" e "Editar_Atleta"                                //
    ///////////////////////////////////////////////////////////////////////////////////////////////////
    if (
      this.props.Titulo_Menu === "Inserir Novo Atleta" ||
      this.props.Titulo_Menu === "Editar Atleta"
    ) {
      return (
        <div>
          <Link
            style={{ textDecoration: "none" }}
            to={{
              pathname: this.props.Link_Tabela_Anterior, //"/ver_atletas", //this.props.Link_Tabela_Anterior,
              state: {
                Link_Menu_Anterior: this.props.Link_Menu_Anterior,
                Fez_Insercao_Atleta_Sistema: false,
                Fez_Edicao_Atleta_Sistema: false,
                Faz_Refresh: true,

                ID_Evento: this.props.ID_Evento,
                ID_Prova: this.props.ID_Prova,
                Nome_Evento: this.props.Nome_Evento,
                Nome_Prova: this.props.Nome_Prova,

                Fez_Insercao_Inscricao_Atleta_Sistema: false,
                Fez_Edicao_Dados_Atleta_Sistema: false,
                Fez_Edicao_Inscricao_Atleta_Sistema: false,
                Fez_Lancamento_Desistencia_Sucesso: false,
                Fez_Eliminacao_Inscricao_Sucesso: false
              }
            }}
          >
            <ListItem button>
              <ListItemIcon
                className={classNames(classes.icon, "fa fa-arrow-left fa-2x")} //fa-3x
              />
              <ListItemText primary={"Voltar"} />
            </ListItem>
          </Link>
          <Divider />
        </div>
      );
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////
    //                                       Botoes do Ecra "Ver_Atletas"                                //
    //                                       Botoes do Ecra "Ver_Atletas"                                //
    //                                       Botoes do Ecra "Ver_Atletas"                                //
    //                                       Botoes do Ecra "Ver_Atletas"                                //
    //                                       Botoes do Ecra "Ver_Atletas"                                //
    //                                       Botoes do Ecra "Ver_Atletas"                                //
    ///////////////////////////////////////////////////////////////////////////////////////////////////
    else if (this.props.Titulo_Menu === "Lista de Atletas") {
      return (
        <div>
          <Link
            style={{ textDecoration: "none" }}
            to={{
              pathname: "/adicionar_atleta",
              state: {
                Link_Menu_Anterior: this.props.Link_Menu_Anterior,
                Link_Tabela_Anterior: this.props.Link_Tabela_Anterior,
                Faz_Refresh: false //true
              }
            }}
          >
            <ListItem button>
              <ListItemIcon
                className={classNames(classes.icon, "fa fa-plus-circle fa-2x")} //fa-3x
              />
              <ListItemText primary={"Adicionar Atleta ao Sistema"} />
            </ListItem>
          </Link>

          <Divider />
          <Link
            style={{ textDecoration: "none" }}
            to={{
              pathname: this.props.Link_Menu_Anterior,
              state: {
                Fez_Login: false,
                Fez_Insercao_Evento: false,
                Fez_Edicao_Evento: false,
                Faz_Refresh: true,
                Fez_Copia_Evento: false,
                Fez_Eliminacao_Evento: false
              }
            }}
          >
            <ListItem button>
              <ListItemIcon
                className={classNames(classes.icon, "fa fa-arrow-left fa-2x")} //fa-3x
              />
              <ListItemText primary={"Voltar"} />
            </ListItem>
          </Link>
          <Divider />
        </div>
      );
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////
    //                                       Botoes do Ecra "Adicionar_Inscricao_Atleta" e ""                                //
    //                                       Botoes do Ecra "Adicionar_Inscricao_Atleta" e ""                                //
    //                                       Botoes do Ecra "Adicionar_Inscricao_Atleta" e ""                                //
    //                                       Botoes do Ecra "Adicionar_Inscricao_Atleta" e ""                                //
    //                                       Botoes do Ecra "Adicionar_Inscricao_Atleta" e ""                                //
    //                                       Botoes do Ecra "Adicionar_Inscricao_Atleta" e ""                                //
    ///////////////////////////////////////////////////////////////////////////////////////////////////
    else if (this.props.Titulo_Menu === "Inserir Inscrição Atleta") {
      return (
        <div>
          <Link
            style={{ textDecoration: "none" }}
            to={{
              pathname: this.props.Link_Tabela_Anterior,
              state: {
                Link_Menu_Anterior: this.props.Link_Menu_Anterior,
                ID_Evento: this.props.ID_Evento,
                ID_Prova: this.props.ID_Prova,
                Fez_Insercao_Inscricao_Atleta_Sistema: false,
                Fez_Edicao_Dados_Atleta_Sistema: false,
                Fez_Edicao_Inscricao_Atleta_Sistema: false,
                Faz_Refresh: true,
                Fez_Lancamento_Desistencia_Sucesso: false,
                Fez_Eliminacao_Inscricao_Sucesso: false,
                Nome_Evento: this.props.Nome_Evento,
                Nome_Prova: this.props.Nome_Prova
              }
            }}
          >
            <ListItem button>
              <ListItemIcon
                className={classNames(classes.icon, "fa fa-arrow-left fa-2x")} //fa-3x
              />
              <ListItemText primary={"Voltar"} />
            </ListItem>
          </Link>
          <Divider />
        </div>
      );
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////
    //                                       Botoes do Ecra "" e "Editar_Inscricao_Atleta"                                //
    //                                       Botoes do Ecra "" e "Editar_Inscricao_Atleta"                                //
    //                                       Botoes do Ecra "" e "Editar_Inscricao_Atleta"                                //
    //                                       Botoes do Ecra "" e "Editar_Inscricao_Atleta"                                //
    //                                       Botoes do Ecra "" e "Editar_Inscricao_Atleta"                                //
    //                                       Botoes do Ecra "" e "Editar_Inscricao_Atleta"                                //
    ///////////////////////////////////////////////////////////////////////////////////////////////////
    else if (this.props.Titulo_Menu === "Editar Inscrição Atleta") {
      if (this.props.ID_Prova === null) {
        return (
          <div>
            <ListItem
              button
              onClick={this.handleClickOpenDialogEliminarInscricao}
            >
              <ListItemIcon
                className={classNames(classes.icon, "fa fa-trash fa-2x")} //fa-2x
              />

              <ListItemText primary={"Eliminar Inscrição"} />
            </ListItem>

            <Dialog
              open={this.state.open_dialog_eliminar_inscricao}
              onClose={this.handleCloseDialogEliminarInscricao}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Tem a Certeza Que Deseja Eliminar a Inscrição?"}
              </DialogTitle>
              <DialogActions>
                <Button
                  onClick={this.handleCloseDialogEliminarInscricao}
                  color="primary"
                >
                  Cancelar
                </Button>
                <Button
                  onClick={this.handleEliminarInscricao}
                  color="primary"
                  autoFocus
                >
                  Sim
                </Button>
              </DialogActions>
            </Dialog>

            <Divider />

            <Link
              style={{ textDecoration: "none" }}
              to={{
                pathname: this.props.Link_Tabela_Anterior,
                state: {
                  Link_Menu_Anterior: this.props.Link_Menu_Anterior,
                  ID_Evento: this.props.ID_Evento,
                  ID_Prova: this.props.ID_Prova,
                  Nome_Evento: this.props.Nome_Evento,
                  Nome_Prova: this.props.Nome_Prova,
                  Fez_Insercao_Inscricao_Atleta_Sistema: false,
                  Fez_Edicao_Dados_Atleta_Sistema: false,
                  Fez_Edicao_Inscricao_Atleta_Sistema: false,
                  Faz_Refresh: true,
                  Fez_Lancamento_Desistencia_Sucesso: false,
                  Fez_Eliminacao_Inscricao_Sucesso: false
                }
              }}
            >
              <ListItem button>
                <ListItemIcon
                  className={classNames(classes.icon, "fa fa-arrow-left fa-2x")} //fa-3x
                />
                <ListItemText primary={"Voltar"} />
              </ListItem>
            </Link>
            <Divider />
          </div>
        );
      } else {
        return (
          <div>
            <ListItem
              button
              onClick={this.handleClickOpenDialogEliminarInscricao}
            >
              <ListItemIcon
                className={classNames(classes.icon, "fa fa-trash fa-2x")} //fa-2x
              />

              <ListItemText primary={"Eliminar Inscrição"} />
            </ListItem>
            <Dialog
              open={this.state.open_dialog_eliminar_inscricao}
              onClose={this.handleCloseDialogEliminarInscricao}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Tem a Certeza Que Deseja Eliminar a Inscrição?"}
              </DialogTitle>
              <DialogActions>
                <Button
                  onClick={this.handleCloseDialogEliminarInscricao}
                  color="primary"
                >
                  Cancelar
                </Button>
                <Button
                  onClick={this.handleEliminarInscricao}
                  color="primary"
                  autoFocus
                >
                  Sim
                </Button>
              </DialogActions>
            </Dialog>
            <Divider />
            <Link
              style={{ textDecoration: "none" }}
              to={{
                pathname: this.props.Link_Tabela_Anterior,
                state: {
                  Link_Menu_Anterior: this.props.Link_Menu_Anterior,
                  ID_Evento: this.props.ID_Evento,
                  ID_Prova: this.props.ID_Prova,
                  Nome_Evento: this.props.Nome_Evento,
                  Nome_Prova: this.props.Nome_Prova,
                  Fez_Insercao_Inscricao_Atleta_Sistema: false,
                  Fez_Edicao_Dados_Atleta_Sistema: false,
                  Fez_Edicao_Inscricao_Atleta_Sistema: false,
                  Faz_Refresh: true,
                  Fez_Lancamento_Desistencia_Sucesso: false,
                  Fez_Eliminacao_Inscricao_Sucesso: false
                }
              }}
            >
              <ListItem button>
                <ListItemIcon
                  className={classNames(classes.icon, "fa fa-arrow-left fa-2x")} //fa-3x
                />
                <ListItemText primary={"Voltar"} />
              </ListItem>
            </Link>
            <Divider />
          </div>
        );
      }
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////
    //                                       Botoes do Ecra "Ver_Atletas_Insritos_Prova"                                //
    //                                       Botoes do Ecra "Ver_Atletas_Insritos_Prova"                                //
    //                                       Botoes do Ecra "Ver_Atletas_Insritos_Prova"                                //
    //                                       Botoes do Ecra "Ver_Atletas_Insritos_Prova"                                //
    //                                       Botoes do Ecra "Ver_Atletas_Insritos_Prova"                                //
    //                                       Botoes do Ecra "Ver_Atletas_Insritos_Prova"                                //
    ///////////////////////////////////////////////////////////////////////////////////////////////////
    else if (this.props.Titulo_Menu === "Lista de Atletas Inscritos na Prova") {
      return (
        <div>
          <Link
            style={{ textDecoration: "none" }}
            to={{
              pathname: "/adicionar_inscricao_atleta",
              state: {
                ID_Evento: this.props.ID_Evento,
                ID_Prova: this.props.ID_Prova,
                Link_Tabela_Anterior: this.props.Link_Tabela_Anterior,
                Link_Menu_Anterior: this.props.Link_Menu_Anterior,
                Faz_Refresh: false, //true,
                Nome_Evento: this.props.Nome_Evento,
                Nome_Prova: this.props.Nome_Prova
                //Nome_Atleta: this.props.Nome_Atleta
              }
            }}
          >
            <ListItem button>
              <ListItemIcon
                className={classNames(classes.icon, "fa fa-plus-circle fa-2x")} //fa-3x
              />
              <ListItemText primary={"Adicionar Inscrição"} />
            </ListItem>
          </Link>
          <Divider />
          {/*

          <Link
            style={{ textDecoration: "none" }}
            to={{
              pathname: "/importar_dados",
              state: {
                ID_Prova: this.props.ID_Prova,
                Link_Menu_Anterior: this.props.Link_Menu_Anterior,
                ID_Evento: this.props.ID_Evento,
                Link_Tabela_Anterior: this.props.Link_Tabela_Anterior,
                //Fez_Insercao_Ficheiro: false,
                Faz_Refresh: false, //true,
                Nome_Evento: this.props.Nome_Evento,
                Nome_Prova: this.props.Nome_Prova
              }
            }}
          >
            <ListItem button>
              <ListItemIcon
                className={classNames(classes.icon, "fa fa-upload fa-2x")} //fa-3x
              />
              <ListItemText primary={"Importar Dados Inscrições"} />
            </ListItem>
          </Link>
          <Divider />

          <Link
            style={{ textDecoration: "none" }}
            to={{
              pathname: "/ver_erros_inscricoes",
              state: {
                Link_Tabela_Anterior: this.props.Link_Tabela_Anterior,
                Link_Menu_Anterior: this.props.Link_Menu_Anterior,
                ID_Prova: this.props.ID_Prova,
                ID_Evento: this.props.ID_Evento,
                Faz_Refresh: true,
                Nome_Evento: this.props.Nome_Evento,
                Nome_Prova: this.props.Nome_Prova
              }
            }}
          >
            <ListItem button>
              <ListItemIcon
                className={classNames(classes.icon, "fa fa-list-ul fa-2x")} //fa-3x
              />
              <ListItemText primary={"Ver Erros Inscrições"} />
            </ListItem>
          </Link>
          <Divider />
          */}

          <Link
            style={{ textDecoration: "none" }}
            to={{
              pathname: this.props.Link_Menu_Anterior,
              state: {
                ID_Evento: this.props.ID_Evento,
                ID_Prova: this.props.ID_Prova,
                Faz_Refresh: true,
                Fez_Insercao_Competicao: false,
                Fez_Edicao_Competicao: false,
                Nome_Evento: this.props.Nome_Evento,
                Nome_Prova: this.props.Nome_Prova,
                Fez_Eliminacao_Competicao: false
              }
            }}
          >
            <ListItem button>
              <ListItemIcon
                className={classNames(classes.icon, "fa fa-arrow-left fa-2x")} //fa-3x
              />
              <ListItemText primary={"Voltar"} />
            </ListItem>
          </Link>
          <Divider />
        </div>
      );
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////
    //                                       Botoes do Ecra "Ver_Atletas_Insritos_Todas_Provas"                                //
    //                                       Botoes do Ecra "Ver_Atletas_Insritos_Todas_Provas"                                //
    //                                       Botoes do Ecra "Ver_Atletas_Insritos_Todas_Provas"                                //
    //                                       Botoes do Ecra "Ver_Atletas_Insritos_Todas_Provas"                                //
    //                                       Botoes do Ecra "Ver_Atletas_Insritos_Todas_Provas"                                //
    //                                       Botoes do Ecra "Ver_Atletas_Insritos_Todas_Provas"                                //
    ///////////////////////////////////////////////////////////////////////////////////////////////////
    else if (this.props.Titulo_Menu === "Lista Atletas Inscritos Evento") {
      if (this.props.Link_Menu_Anterior === "/competicoes") {
        //if (this.props.Link_Menu_Anterior === "/eventos") { //if(==="/competicoes")
        return (
          <div>
            <Link
              style={{ textDecoration: "none" }}
              to={{
                pathname: "/competicoes", //this.props.Link_Menu_Anterior,
                state: {
                  ID_Evento: this.props.ID_Evento,
                  Fez_Insercao_Competicao: false,
                  Fez_Edicao_Competicao: false,
                  Faz_Refresh: true,
                  Nome_Evento: this.props.Nome_Evento,
                  Fez_Eliminacao_Competicao: false
                }
              }}
            >
              <ListItem button>
                <ListItemIcon
                  className={classNames(classes.icon, "fa fa-arrow-left fa-2x")} //fa-3x
                />
                <ListItemText primary={"Voltar"} />
              </ListItem>
            </Link>
            <Divider />
          </div>
        );
      } else {
        //if (this.props.Link_Menu_Anterior === "/eventos")
        return (
          <div>
            <Link
              style={{ textDecoration: "none" }}
              to={{
                pathname: "/eventos",
                state: {
                  Fez_Login: false,
                  Fez_Insercao_Evento: false,
                  Fez_Edicao_Evento: false,
                  Fez_Copia_Evento: false,
                  Faz_Refresh: true,
                  Fez_Eliminacao_Evento: false
                }
              }}
            >
              <ListItem button>
                <ListItemIcon
                  className={classNames(classes.icon, "fa fa-arrow-left fa-2x")} //fa-3x
                />
                <ListItemText primary={"Voltar"} />
              </ListItem>
            </Link>
            <Divider />
          </div>
        );
      }
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////
    //                                       Botoes do Ecra "Adicionar_Checkpoint_Prova"                                //
    //                                       Botoes do Ecra "Adicionar_Checkpoint_Prova"                                //
    //                                       Botoes do Ecra "Adicionar_Checkpoint_Prova"                                //
    //                                       Botoes do Ecra "Adicionar_Checkpoint_Prova"                                //
    //                                       Botoes do Ecra "Adicionar_Checkpoint_Prova"                                //
    //                                       Botoes do Ecra "Adicionar_Checkpoint_Prova"                                //
    ///////////////////////////////////////////////////////////////////////////////////////////////////
    else if (this.props.Titulo_Menu === "Inserir Novo Checkpoint Prova") {
      return (
        <div>
          <Link
            style={{ textDecoration: "none" }}
            to={{
              pathname: this.props.Link_Tabela_Anterior,
              state: {
                Link_Menu_Anterior: this.props.Link_Menu_Anterior,
                ID_Evento: this.props.ID_Evento,
                ID_Prova: this.props.ID_Prova,
                Fez_Insercao_Checkpoint_Prova: false,
                Fez_Edicao_Checkpoint_Prova: false,
                Faz_Refresh: true,
                Nome_Evento: this.props.Nome_Evento,
                Nome_Prova: this.props.Nome_Prova
              }
            }}
          >
            <ListItem button>
              <ListItemIcon
                className={classNames(classes.icon, "fa fa-arrow-left fa-2x")} //fa-3x
              />
              <ListItemText primary={"Voltar"} />
            </ListItem>
          </Link>
          <Divider />
        </div>
      );
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////
    //                                       Botoes do Ecra "Adicionar_Checkpoint"                                //
    //                                       Botoes do Ecra "Adicionar_Checkpoint"                                //
    //                                       Botoes do Ecra "Adicionar_Checkpoint"                                //
    //                                       Botoes do Ecra "Adicionar_Checkpoint"                                //
    //                                       Botoes do Ecra "Adicionar_Checkpoint"                                //
    //                                       Botoes do Ecra "Adicionar_Checkpoint"                                //
    ///////////////////////////////////////////////////////////////////////////////////////////////////
    else if (this.props.Titulo_Menu === "Inserir Novo Checkpoint Sistema") {
      return (
        <div>
          <Link
            style={{ textDecoration: "none" }}
            to={{
              pathname: this.props.Link_Tabela_Anterior,
              state: {
                Link_Menu_Anterior: this.props.Link_Menu_Anterior,
                Fez_Insercao_Checkpoint_Sistema: false,
                Fez_Edicao_Checkpoint_Sistema: false,
                Faz_Refresh: true
              }
            }}
          >
            <ListItem button>
              <ListItemIcon
                className={classNames(classes.icon, "fa fa-arrow-left fa-2x")} //fa-3x
              />
              <ListItemText primary={"Voltar"} />
            </ListItem>
          </Link>
          <Divider />
        </div>
      );
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////
    //                                       Botoes do Ecra "Editar_Checkpoint_Prova"                                //
    //                                       Botoes do Ecra "Editar_Checkpoint_Prova"                                //
    //                                       Botoes do Ecra "Editar_Checkpoint_Prova"                                //
    //                                       Botoes do Ecra "Editar_Checkpoint_Prova"                                //
    //                                       Botoes do Ecra "Editar_Checkpoint_Prova"                                //
    //                                       Botoes do Ecra "Editar_Checkpoint_Prova"                                //
    ///////////////////////////////////////////////////////////////////////////////////////////////////
    else if (this.props.Titulo_Menu === "Editar Checkpoint Prova") {
      return (
        <div>
          <Link
            style={{ textDecoration: "none" }}
            to={{
              pathname: this.props.Link_Tabela_Anterior,
              state: {
                Link_Menu_Anterior: this.props.Link_Menu_Anterior,
                ID_Evento: this.props.ID_Evento,
                ID_Prova: this.props.ID_Prova,
                Fez_Insercao_Checkpoint_Prova: false,
                Fez_Edicao_Checkpoint_Prova: false,
                Faz_Refresh: true,
                Nome_Evento: this.props.Nome_Evento,
                Nome_Prova: this.props.Nome_Prova
              }
            }}
          >
            <ListItem button>
              <ListItemIcon
                className={classNames(classes.icon, "fa fa-arrow-left fa-2x")} //fa-3x
              />
              <ListItemText primary={"Voltar"} />
            </ListItem>
          </Link>
          <Divider />
        </div>
      );
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////
    //                                       Botoes do Ecra "Editar_Checkpoint"                                //
    //                                       Botoes do Ecra "Editar_Checkpoint"                                //
    //                                       Botoes do Ecra "Editar_Checkpoint"                                //
    //                                       Botoes do Ecra "Editar_Checkpoint"                                //
    //                                       Botoes do Ecra "Editar_Checkpoint"                                //
    //                                       Botoes do Ecra "Editar_Checkpoint"                                //
    ///////////////////////////////////////////////////////////////////////////////////////////////////
    else if (this.props.Titulo_Menu === "Editar Checkpoint Sistema") {
      return (
        <div>
          <Link
            style={{ textDecoration: "none" }}
            to={{
              pathname: this.props.Link_Tabela_Anterior,
              state: {
                Link_Menu_Anterior: this.props.Link_Menu_Anterior,
                Fez_Insercao_Checkpoint_Sistema: false,
                Fez_Edicao_Checkpoint_Sistema: false,
                Faz_Refresh: true
              }
            }}
          >
            <ListItem button>
              <ListItemIcon
                className={classNames(classes.icon, "fa fa-arrow-left fa-2x")} //fa-3x
              />
              <ListItemText primary={"Voltar"} />
            </ListItem>
          </Link>
          <Divider />
        </div>
      );
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////
    //                                       Botoes do Ecra "Ver_Checkpoints_Prova"                                //
    //                                       Botoes do Ecra "Ver_Checkpoints_Prova"                                //
    //                                       Botoes do Ecra "Ver_Checkpoints_Prova"                                //
    //                                       Botoes do Ecra "Ver_Checkpoints_Prova"                                //
    //                                       Botoes do Ecra "Ver_Checkpoints_Prova"                                //
    //                                       Botoes do Ecra "Ver_Checkpoints_Prova"                                //
    ///////////////////////////////////////////////////////////////////////////////////////////////////
    else if (this.props.Titulo_Menu === "Lista Checkpoints Prova") {
      return (
        <div>
          <Link
            style={{ textDecoration: "none" }}
            to={{
              pathname: "/adicionar_checkpoint_prova",
              state: {
                Link_Menu_Anterior: this.props.Link_Menu_Anterior,
                Link_Tabela_Anterior: this.props.Link_Tabela_Anterior,
                ID_Evento: this.props.ID_Evento,
                ID_Prova: this.props.ID_Prova,
                Faz_Refresh: false, //true,
                Nome_Evento: this.props.Nome_Evento,
                Nome_Prova: this.props.Nome_Prova
              }
            }}
          >
            <ListItem button>
              <ListItemIcon
                className={classNames(classes.icon, "fa fa-plus-circle fa-2x")} //fa-3x
              />
              <ListItemText primary={"Adicionar Checkpoint à Prova"} />
            </ListItem>
          </Link>

          <Divider />
          <Link
            style={{ textDecoration: "none" }}
            to={{
              pathname: this.props.Link_Menu_Anterior,
              state: {
                ID_Evento: this.props.ID_Evento,
                Fez_Insercao_Competicao: false,
                Fez_Edicao_Competicao: false,
                Faz_Refresh: true,
                Nome_Evento: this.props.Nome_Evento,
                Fez_Eliminacao_Competicao: false
              }
            }}
          >
            <ListItem button>
              <ListItemIcon
                className={classNames(classes.icon, "fa fa-arrow-left fa-2x")} //fa-3x
              />
              <ListItemText primary={"Voltar"} />
            </ListItem>
          </Link>
          <Divider />
        </div>
      );
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////
    //                                       Botoes do Ecra "Ver_Checkpoints_Todas_Provas"                                //
    //                                       Botoes do Ecra "Ver_Checkpoints_Todas_Provas"                                //
    //                                       Botoes do Ecra "Ver_Checkpoints_Todas_Provas"                                //
    //                                       Botoes do Ecra "Ver_Checkpoints_Todas_Provas"                                //
    //                                       Botoes do Ecra "Ver_Checkpoints_Todas_Provas"                                //
    //                                       Botoes do Ecra "Ver_Checkpoints_Todas_Provas"                                //
    ///////////////////////////////////////////////////////////////////////////////////////////////////
    else if (this.props.Titulo_Menu === "Lista Checkpoints Evento") {
      return (
        <div>
          <Link
            style={{ textDecoration: "none" }}
            to={{
              pathname: this.props.Link_Menu_Anterior,
              state: {
                ID_Evento: this.props.ID_Evento,
                Fez_Insercao_Competicao: false,
                Fez_Edicao_Competicao: false,
                Faz_Refresh: true,
                Nome_Evento: this.props.Nome_Evento,
                Fez_Eliminacao_Competicao: false
              }
            }}
          >
            <ListItem button>
              <ListItemIcon
                className={classNames(classes.icon, "fa fa-arrow-left fa-2x")} //fa-3x
              />
              <ListItemText primary={"Voltar"} />
            </ListItem>
          </Link>
          <Divider />
        </div>
      );
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////
    //                                       Botoes do Ecra "Ver_Checkpoints"                                //
    //                                       Botoes do Ecra "Ver_Checkpoints"                                //
    //                                       Botoes do Ecra "Ver_Checkpoints"                                //
    //                                       Botoes do Ecra "Ver_Checkpoints"                                //
    //                                       Botoes do Ecra "Ver_Checkpoints"                                //
    //                                       Botoes do Ecra "Ver_Checkpoints"                                //
    ///////////////////////////////////////////////////////////////////////////////////////////////////
    else if (this.props.Titulo_Menu === "Lista Checkpoints Sistema") {
      return (
        <div>
          <Link
            style={{ textDecoration: "none" }}
            to={{
              pathname: "/adicionar_checkpoint",
              state: {
                Link_Menu_Anterior: this.props.Link_Menu_Anterior,
                Link_Tabela_Anterior: this.props.Link_Tabela_Anterior,
                Faz_Refresh: false //true,
              }
            }}
          >
            <ListItem button>
              <ListItemIcon
                className={classNames(classes.icon, "fa fa-plus-circle fa-2x")} //fa-3x
              />
              <ListItemText primary={"Adicionar Checkpoint ao Sistema"} />
            </ListItem>
          </Link>

          <Divider />
          <Link
            style={{ textDecoration: "none" }}
            to={{
              pathname: this.props.Link_Menu_Anterior,
              state: {
                Fez_Login: false,
                Fez_Insercao_Evento: false,
                Fez_Edicao_Evento: false,
                Faz_Refresh: true,
                Fez_Copia_Evento: false,
                Fez_Eliminacao_Evento: false
              }
            }}
          >
            <ListItem button>
              <ListItemIcon
                className={classNames(classes.icon, "fa fa-arrow-left fa-2x")} //fa-3x
              />
              <ListItemText primary={"Voltar"} />
            </ListItem>
          </Link>
          <Divider />
        </div>
      );
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////
    //                                       Botoes do Ecra "Dashboard_Prova" e "Dashboard_Todas_Provas"                                //
    //                                       Botoes do Ecra "Dashboard_Prova" e "Dashboard_Todas_Provas"                                //
    //                                       Botoes do Ecra "Dashboard_Prova" e "Dashboard_Todas_Provas"                                //
    //                                       Botoes do Ecra "Dashboard_Prova" e "Dashboard_Todas_Provas"                                //
    //                                       Botoes do Ecra "Dashboard_Prova" e "Dashboard_Todas_Provas"                                //
    //                                       Botoes do Ecra "Dashboard_Prova" e "Dashboard_Todas_Provas"                                //
    ///////////////////////////////////////////////////////////////////////////////////////////////////
    else if (
      this.props.Titulo_Menu === "Dashboard Evento" ||
      this.props.Titulo_Menu === "Dashboard Prova"
    ) {
      return (
        <div>
          <Link
            style={{ textDecoration: "none" }}
            to={{
              pathname: "/competicoes",
              state: {
                ID_Evento: this.props.ID_Evento,
                Fez_Insercao_Competicao: false,
                Fez_Edicao_Competicao: false,
                Faz_Refresh: true,
                Nome_Evento: this.props.Nome_Evento,
                Fez_Eliminacao_Competicao: false
              }
            }}
          >
            <ListItem button>
              <ListItemIcon
                className={classNames(classes.icon, "fa fa-arrow-left fa-2x")} //fa-3x
              />
              <ListItemText primary={"Voltar"} />
            </ListItem>
          </Link>
          <Divider />
        </div>
      );
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////
    //                                       Botoes do Ecra "Lancar_Desistencia_Atleta"                                //
    //                                       Botoes do Ecra "Lancar_Desistencia_Atleta"                                //
    //                                       Botoes do Ecra "Lancar_Desistencia_Atleta"                                //
    //                                       Botoes do Ecra "Lancar_Desistencia_Atleta"                                //
    //                                       Botoes do Ecra "Lancar_Desistencia_Atleta"                                //
    //                                       Botoes do Ecra "Lancar_Desistencia_Atleta"                                //
    ///////////////////////////////////////////////////////////////////////////////////////////////////
    else if (this.props.Titulo_Menu === "Lançamento Desistência Atleta") {
      return (
        <div>
          <Link
            style={{ textDecoration: "none" }}
            to={{
              pathname: this.props.Link_Tabela_Anterior,
              state: {
                Link_Menu_Anterior: this.props.Link_Menu_Anterior,
                ID_Evento: this.props.ID_Evento,
                ID_Prova: this.props.ID_Prova,
                Nome_Evento: this.props.Nome_Evento,
                Nome_Prova: this.props.Nome_Prova,
                Fez_Insercao_Inscricao_Atleta_Sistema: false,
                Fez_Edicao_Dados_Atleta_Sistema: false,
                Fez_Edicao_Inscricao_Atleta_Sistema: false,
                Faz_Refresh: true,
                Fez_Lancamento_Desistencia_Sucesso: false,
                Fez_Eliminacao_Inscricao_Sucesso: false
              }
            }}
          >
            <ListItem button>
              <ListItemIcon
                className={classNames(classes.icon, "fa fa-arrow-left fa-2x")} //fa-3x
              />
              <ListItemText primary={"Voltar"} />
            </ListItem>
          </Link>
          <Divider />
        </div>
      );
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////
    //                                       Botoes do Ecra "Adicionar_Competicao"                                //
    //                                       Botoes do Ecra "Adicionar_Competicao"                                //
    //                                       Botoes do Ecra "Adicionar_Competicao"                                //
    //                                       Botoes do Ecra "Adicionar_Competicao"                                //
    //                                       Botoes do Ecra "Adicionar_Competicao"                                //
    //                                       Botoes do Ecra "Adicionar_Competicao"                                //
    ///////////////////////////////////////////////////////////////////////////////////////////////////
    else if (this.props.Titulo_Menu === "Inserir Nova Prova") {
      //"Inserir Nova Competição") {
      return (
        <div>
          <Link
            style={{ textDecoration: "none" }}
            to={{
              pathname: this.props.Link_Menu_Anterior,
              state: {
                ID_Evento: this.props.ID_Evento,
                Fez_Insercao_Competicao: false,
                Fez_Edicao_Competicao: false,
                Faz_Refresh: true,
                Nome_Evento: this.props.Nome_Evento,
                Fez_Eliminacao_Competicao: false
              }
            }}
          >
            <ListItem button>
              <ListItemIcon
                className={classNames(classes.icon, "fa fa-arrow-left fa-2x")} //fa-3x
              />
              <ListItemText primary={"Voltar"} />
            </ListItem>
          </Link>
          <Divider />
        </div>
      );
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////
    //                                       Botoes do Ecra "Adicionar_Evento"                                //
    //                                       Botoes do Ecra "Adicionar_Evento"                                //
    //                                       Botoes do Ecra "Adicionar_Evento"                                //
    //                                       Botoes do Ecra "Adicionar_Evento"                                //
    //                                       Botoes do Ecra "Adicionar_Evento"                                //
    //                                       Botoes do Ecra "Adicionar_Evento"                                //
    ///////////////////////////////////////////////////////////////////////////////////////////////////
    else if (this.props.Titulo_Menu === "Inserir Novo Evento") {
      return (
        <div>
          <Link
            style={{ textDecoration: "none" }}
            to={{
              pathname: "/eventos", //this.props.Link_Menu_Anterior,
              state: {
                Fez_Login: false,
                Fez_Insercao_Evento: false,
                Fez_Edicao_Evento: false,
                Faz_Refresh: true,
                Fez_Copia_Evento: false,
                Fez_Eliminacao_Evento: false
              }
            }}
          >
            <ListItem button>
              <ListItemIcon
                className={classNames(classes.icon, "fa fa-arrow-left fa-2x")} //fa-3x
              />
              <ListItemText primary={"Voltar"} />
            </ListItem>
          </Link>
          <Divider />
        </div>
      );
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////
    //                                   Botoes do Ecra "Competições"                                //
    //                                   Botoes do Ecra "Competições"                                //
    //                                   Botoes do Ecra "Competições"                                //
    //                                   Botoes do Ecra "Competições"                                //
    //                                   Botoes do Ecra "Competições"                                //
    //                                   Botoes do Ecra "Competições"                                //
    ///////////////////////////////////////////////////////////////////////////////////////////////////
    else if (this.props.Titulo_Menu === "Provas") {
      return (
        <div>
          <Link
            style={{ textDecoration: "none" }}
            to={{
              pathname: "/adicionar_competicao",
              state: {
                Link_Menu_Anterior: this.props.Link_Menu_Anterior,
                ID_Evento: this.props.ID_Evento,
                Nome_Evento: this.props.Nome_Evento
                //Faz_Refresh: false //true
              }
            }}
          >
            <ListItem button>
              <ListItemIcon
                className={classNames(classes.icon, "fa fa-plus-circle fa-2x")} //fa-3x
              />
              <ListItemText
                primary={
                  //"Adicionar Competição"
                  "Adicionar Prova"
                }
              />
            </ListItem>
          </Link>

          <Divider />
          <Link
            style={{ textDecoration: "none" }}
            to={{
              pathname: "/eventos",
              state: {
                Fez_Login: false,
                Fez_Insercao_Evento: false,
                Fez_Edicao_Evento: false,
                Faz_Refresh: true,
                Fez_Copia_Evento: false,
                Fez_Eliminacao_Evento: false
              }
            }}
          >
            <ListItem button>
              <ListItemIcon
                className={classNames(classes.icon, "fa fa-arrow-left fa-2x")} //fa-3x
              />
              <ListItemText primary={"Voltar"} />
            </ListItem>
          </Link>
          <Divider />
        </div>
      );
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////
    //                                       Botoes do Ecra "Copiar_Evento"                                //
    //                                       Botoes do Ecra "Copiar_Evento"                                //
    //                                       Botoes do Ecra "Copiar_Evento"                                //
    //                                       Botoes do Ecra "Copiar_Evento"                                //
    //                                       Botoes do Ecra "Copiar_Evento"                                //
    //                                       Botoes do Ecra "Copiar_Evento"                                //
    ///////////////////////////////////////////////////////////////////////////////////////////////////
    else if (this.props.Titulo_Menu === "Copiar Evento") {
      return (
        <div>
          <Link
            style={{ textDecoration: "none" }}
            to={{
              pathname: "/eventos",
              state: {
                Fez_Login: false,
                Fez_Insercao_Evento: false,
                Fez_Edicao_Evento: false,
                Faz_Refresh: true,
                Fez_Copia_Evento: false,
                Fez_Eliminacao_Evento: false
              }
            }}
          >
            <ListItem button>
              <ListItemIcon
                className={classNames(classes.icon, "fa fa-arrow-left fa-2x")} //fa-3x
              />
              <ListItemText primary={"Voltar"} />
            </ListItem>
          </Link>
          <Divider />
        </div>
      );
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////
    //                                       Botoes do Ecra "Editar_Competicao"                                //
    //                                       Botoes do Ecra "Editar_Competicao"                                //
    //                                       Botoes do Ecra "Editar_Competicao"                                //
    //                                       Botoes do Ecra "Editar_Competicao"                                //
    //                                       Botoes do Ecra "Editar_Competicao"                                //
    //                                       Botoes do Ecra "Editar_Competicao"                                //
    ///////////////////////////////////////////////////////////////////////////////////////////////////
    else if (this.props.Titulo_Menu === "Editar Prova") {
      //"Editar Competição") {
      return (
        <div>
          <ListItem
            button
            onClick={this.handleClickOpenDialogEliminarCompeticao}
          >
            <ListItemIcon
              className={classNames(classes.icon, "fa fa-trash fa-2x")} //fa-2x
            />

            <ListItemText primary={"Eliminar Prova"} />
          </ListItem>

          <Dialog
            open={this.state.open_dialog_eliminar_competicao}
            onClose={this.handleCloseDialogEliminarCompeticao}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Tem a Certeza Que Deseja Eliminar a Prova?"}
            </DialogTitle>
            <DialogActions>
              <Button
                onClick={this.handleCloseDialogEliminarCompeticao}
                color="primary"
              >
                Cancelar
              </Button>
              <Button
                onClick={this.handleEliminarCompeticao}
                color="primary"
                autoFocus
              >
                Sim
              </Button>
            </DialogActions>
          </Dialog>

          <Divider />
          <Link
            style={{ textDecoration: "none" }}
            to={{
              pathname: this.props.Link_Menu_Anterior, //Link_Tabela_Anterior,
              state: {
                ID_Evento: this.props.ID_Evento,
                Fez_Insercao_Competicao: false,
                Fez_Edicao_Competicao: false,
                Faz_Refresh: true,
                Nome_Evento: this.props.Nome_Evento,
                Fez_Eliminacao_Competicao: false
              }
            }}
          >
            <ListItem button>
              <ListItemIcon
                className={classNames(classes.icon, "fa fa-arrow-left fa-2x")} //fa-3x
              />
              <ListItemText primary={"Voltar"} />
            </ListItem>
          </Link>
          <Divider />
        </div>
      );
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////
    //                                       Botoes do Ecra "Editar_Evento"                                //
    //                                       Botoes do Ecra "Editar_Evento"                                //
    //                                       Botoes do Ecra "Editar_Evento"                                //
    //                                       Botoes do Ecra "Editar_Evento"                                //
    //                                       Botoes do Ecra "Editar_Evento"                                //
    //                                       Botoes do Ecra "Editar_Evento"                                //
    ///////////////////////////////////////////////////////////////////////////////////////////////////
    else if (this.props.Titulo_Menu === "Editar Evento") {
      return (
        <div>
          <ListItem button onClick={this.handleClickOpenDialogEliminarEvento}>
            <ListItemIcon
              className={classNames(classes.icon, "fa fa-trash fa-2x")} //fa-2x
            />

            <ListItemText primary={"Eliminar Evento"} />
          </ListItem>

          <Dialog
            open={this.state.open_dialog_eliminar_evento}
            onClose={this.handleCloseDialogEliminarEvento}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Tem a Certeza Que Deseja Eliminar o Evento?"}
            </DialogTitle>
            <DialogActions>
              <Button
                onClick={this.handleCloseDialogEliminarEvento}
                color="primary"
              >
                Cancelar
              </Button>
              <Button
                onClick={this.handleEliminarEvento}
                color="primary"
                autoFocus
              >
                Sim
              </Button>
            </DialogActions>
          </Dialog>

          <Divider />
          <Link
            style={{ textDecoration: "none" }}
            to={{
              //pathname: this.props.Link_Menu_Anterior
              pathname: "/eventos",
              state: {
                Fez_Login: false,
                Fez_Insercao_Evento: false,
                Fez_Edicao_Evento: false,
                Faz_Refresh: true,
                Fez_Copia_Evento: false,
                Fez_Eliminacao_Evento: false
              }
            }}
          >
            <ListItem button>
              <ListItemIcon
                className={classNames(classes.icon, "fa fa-arrow-left fa-2x")} //fa-3x
              />
              <ListItemText primary={"Voltar"} />
            </ListItem>
          </Link>
          <Divider />
        </div>
      );
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////
    //                                       Botoes do Ecra "Eventos"                                //
    //                                       Botoes do Ecra "Eventos"                                //
    //                                       Botoes do Ecra "Eventos"                                //
    //                                       Botoes do Ecra "Eventos"                                //
    //                                       Botoes do Ecra "Eventos"                                //
    //                                       Botoes do Ecra "Eventos"                                //
    ///////////////////////////////////////////////////////////////////////////////////////////////////
    else if (this.props.Titulo_Menu === "Eventos") {
      if (localStorage.getItem("Tipo") === "Admin") {
        return (
          <div>
            <Link
              style={{ textDecoration: "none" }}
              to={{
                pathname: "/adicionar_evento",
                state: {
                  Faz_Refresh: false //true
                }
              }}
            >
              <ListItem button>
                <ListItemIcon
                  className={classNames(classes.icon, "fa fa-file fa-2x")} //fa-2x
                />

                <ListItemText primary={"Adicionar Evento"} />
              </ListItem>
            </Link>

            <Link
              style={{ textDecoration: "none" }}
              to={{
                pathname: "/copiar_evento",
                state: {
                  Faz_Refresh: false //true
                }
              }}
            >
              <ListItem button>
                <ListItemIcon
                  className={classNames(classes.icon, "fa fa-copy fa-2x")} //fa-3x
                />

                <ListItemText primary={"Copiar Evento"} />
              </ListItem>
            </Link>

            <Divider />

            <Link
              style={{ textDecoration: "none" }}
              to={{
                pathname: "/adicionar_utilizador",
                state: {
                  Link_Menu_Anterior: this.props.Link_Menu_Anterior,
                  Faz_Refresh: false //true
                }
              }}
            >
              <ListItem button>
                <ListItemIcon
                  className={classNames(classes.icon, "fa fa-user-plus fa-2x")} //fa-3x
                />

                <ListItemText primary={"Adicionar Utilizador"} />
              </ListItem>
            </Link>

            <Link
              style={{ textDecoration: "none" }}
              to={{
                pathname: "/ver_utilizadores",
                state: {
                  Faz_Refresh: true,
                  Fez_Desactivacao_Utilizador: false
                }
              }}
            >
              <ListItem button>
                <ListItemIcon
                  className={classNames(classes.icon, "fa fa-users fa-2x")} //fa-3x
                />

                <ListItemText primary={"Ver Utilizadores"} />
              </ListItem>
            </Link>
            <Link
              style={{ textDecoration: "none" }}
              to={{
                pathname: "/ver_perfil"
              }}
            >
              <ListItem button>
                <ListItemIcon
                  className={classNames(classes.icon, "fa fa-user fa-2x")} //fa-3x
                />

                <ListItemText primary={"Meu Perfil"} />
              </ListItem>
            </Link>
            <Divider />
          </div>
        );
      } else {
        return (
          <div>
            <Link
              style={{ textDecoration: "none" }}
              to={{
                pathname: "/adicionar_evento",
                state: {
                  Faz_Refresh: false //true
                }
              }}
            >
              <ListItem button>
                <ListItemIcon
                  className={classNames(classes.icon, "fa fa-file fa-2x")} //fa-2x
                />

                <ListItemText primary={"Adicionar Evento"} />
              </ListItem>
            </Link>

            <Link
              style={{ textDecoration: "none" }}
              to={{
                pathname: "/copiar_evento",
                state: {
                  Faz_Refresh: false //true
                }
              }}
            >
              <ListItem button>
                <ListItemIcon
                  className={classNames(classes.icon, "fa fa-copy fa-2x")} //fa-3x
                />

                <ListItemText primary={"Copiar Evento"} />
              </ListItem>
            </Link>
            <Divider />
            <Link
              style={{ textDecoration: "none" }}
              to={{
                pathname: "/ver_perfil"
              }}
            >
              <ListItem button>
                <ListItemIcon
                  className={classNames(classes.icon, "fa fa-user fa-2x")} //fa-3x
                />

                <ListItemText primary={"Meu Perfil"} />
              </ListItem>
            </Link>
            <Divider />
          </div>
        );
      }

      /*
          <Link
            style={{ textDecoration: "none" }}
            to={{
              pathname: "/ver_atletas",
              state: {
                Link_Menu_Anterior: this.props.Link_Menu_Anterior,
                Faz_Refresh: true
              }
            }}
          >
            <ListItem button>
              <ListItemIcon
                className={classNames(classes.icon, "fa fa-list-ul fa-2x")} //fa-3x
              />

              <ListItemText primary={"Ver Atletas Sistema"} />
            </ListItem>
          </Link>

          <Link
            style={{ textDecoration: "none" }}
            to={{
              pathname: "/ver_checkpoints",
              state: {
                Link_Menu_Anterior: this.props.Link_Menu_Anterior,
                Faz_Refresh: true
              }
            }}
          >
            <ListItem button>
              <ListItemIcon
                className={classNames(classes.icon, "fa fa-list-ul fa-2x")} //fa-3x
              />

              <ListItemText primary={"Ver Checkpoints Sistema"} />
            </ListItem>
          </Link>
          <Divider />
          */
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////
    //                                       Botoes do Ecra "Importar_Dados_Excel"                                //
    //                                       Botoes do Ecra "Importar_Dados_Excel"                                //
    //                                       Botoes do Ecra "Importar_Dados_Excel"                                //
    //                                       Botoes do Ecra "Importar_Dados_Excel"                                //
    //                                       Botoes do Ecra "Importar_Dados_Excel"                                //
    //                                       Botoes do Ecra "Importar_Dados_Excel"                                //
    ///////////////////////////////////////////////////////////////////////////////////////////////////
    else if (this.props.Titulo_Menu === "Importar Dados Excel") {
      if (
        this.props.Link_Tabela_Anterior === "NULL" ||
        this.props.Link_Tabela_Anterior === null
      ) {
        return (
          <div>
            <Link
              style={{ textDecoration: "none" }}
              to={{
                pathname: "/ver_erros_inscricoes",
                state: {
                  Link_Tabela_Anterior: this.props.Link_Tabela_Anterior,
                  Link_Menu_Anterior: this.props.Link_Menu_Anterior,
                  ID_Prova: this.props.ID_Prova,
                  ID_Evento: this.props.ID_Evento,
                  Faz_Refresh: true,
                  Nome_Evento: this.props.Nome_Evento,
                  Nome_Prova: this.props.Nome_Prova
                }
              }}
            >
              <ListItem button>
                <ListItemIcon
                  className={classNames(classes.icon, "fa fa-list-ul fa-2x")} //fa-3x
                />
                <ListItemText primary={"Ver Erros Inscrições"} />
              </ListItem>
            </Link>
            <Divider />

            <Link
              style={{ textDecoration: "none" }}
              to={{
                pathname: this.props.Link_Menu_Anterior,
                state: {
                  ID_Evento: this.props.ID_Evento,
                  Fez_Insercao_Competicao: false,
                  Fez_Edicao_Competicao: false,
                  Faz_Refresh: true,
                  Fez_Lancamento_Desistencia_Sucesso: false,
                  Fez_Eliminacao_Inscricao_Sucesso: false,
                  Nome_Evento: this.props.Nome_Evento,
                  Nome_Prova: this.props.Nome_Prova,
                  Fez_Eliminacao_Competicao: false
                }
              }}
            >
              <ListItem button>
                <ListItemIcon
                  className={classNames(classes.icon, "fa fa-arrow-left fa-2x")} //fa-3x
                />
                <ListItemText primary={"Voltar"} />
              </ListItem>
            </Link>
            <Divider />
          </div>
        );
      } else {
        return (
          <div>
            <Link
              style={{ textDecoration: "none" }}
              to={{
                pathname: this.props.Link_Tabela_Anterior,
                state: {
                  Link_Menu_Anterior: this.props.Link_Menu_Anterior,
                  ID_Evento: this.props.ID_Evento,
                  ID_Prova: this.props.ID_Prova,
                  Fez_Insercao_Competicao: false,
                  Fez_Edicao_Competicao: false,
                  Faz_Refresh: true,
                  Fez_Lancamento_Desistencia_Sucesso: false,
                  Fez_Eliminacao_Inscricao_Sucesso: false,
                  Nome_Evento: this.props.Nome_Evento,
                  Nome_Prova: this.props.Nome_Prova,
                  Fez_Eliminacao_Competicao: false
                }
              }}
            >
              <ListItem button>
                <ListItemIcon
                  className={classNames(classes.icon, "fa fa-arrow-left fa-2x")} //fa-3x
                />
                <ListItemText primary={"Voltar"} />
              </ListItem>
            </Link>
            <Divider />
          </div>
        );
      }

      /*
      return (
        <div>
          <Link
            style={{ textDecoration: "none" }}
            to={{
              pathname: this.props.Link_Tabela_Anterior,
              state: {
                Link_Menu_Anterior: this.props.Link_Menu_Anterior,
                ID_Evento: this.props.ID_Evento,
                ID_Prova: this.props.ID_Prova,
              Faz_Refresh: true
              }
            }}
          >
            <ListItem button>
              <ListItemIcon
                className={classNames(classes.icon, "fa fa-arrow-left fa-2x")} //fa-3x
              />
              <ListItemText primary={"Voltar"} />
            </ListItem>
          </Link>
          <Divider />
        </div>
      );
      */
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////
    //                                       Botoes do Ecra "Ver_Erros_Inscricoes_Atletas"                                //
    //                                       Botoes do Ecra "Ver_Erros_Inscricoes_Atletas"                                //
    //                                       Botoes do Ecra "Ver_Erros_Inscricoes_Atletas"                                //
    //                                       Botoes do Ecra "Ver_Erros_Inscricoes_Atletas"                                //
    //                                       Botoes do Ecra "Ver_Erros_Inscricoes_Atletas"                                //
    //                                       Botoes do Ecra "Ver_Erros_Inscricoes_Atletas"                                //
    ///////////////////////////////////////////////////////////////////////////////////////////////////
    else if (
      this.props.Titulo_Menu === "Lista Erros Importação Inscrições Atletas"
    ) {
      return (
        <div>
          <Link
            style={{ textDecoration: "none" }}
            to={{
              pathname: "/importar_dados", //this.props.Link_Tabela_Anterior,
              state: {
                Link_Tabela_Anterior: this.props.Link_Tabela_Anterior,
                Link_Menu_Anterior: this.props.Link_Menu_Anterior,
                ID_Evento: this.props.ID_Evento,
                ID_Prova: this.props.ID_Prova,
                //Faz_Refresh: true,
                Nome_Evento: this.props.Nome_Evento,
                Nome_Prova: this.props.Nome_Prova
              }
            }}
          >
            <ListItem button>
              <ListItemIcon
                className={classNames(classes.icon, "fa fa-arrow-left fa-2x")} //fa-3x
              />
              <ListItemText primary={"Voltar"} />
            </ListItem>
          </Link>
          <Divider />
        </div>
      );
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////
    //                                       Botoes do Ecra "Definicoes_Notificacoes"                                //
    //                                       Botoes do Ecra "Definicoes_Notificacoes"                                //
    //                                       Botoes do Ecra "Definicoes_Notificacoes"                                //
    //                                       Botoes do Ecra "Definicoes_Notificacoes"                                //
    //                                       Botoes do Ecra "Definicoes_Notificacoes"                                //
    //                                       Botoes do Ecra "Definicoes_Notificacoes"                                //
    ///////////////////////////////////////////////////////////////////////////////////////////////////
    else if (this.props.Titulo_Menu === "Alterar Definições Notificações") {
      return (
        <div>
          <Link
            style={{ textDecoration: "none" }}
            to={{
              pathname: "/lista_notificacoes_prova", //this.props.Link_Tabela_Anterior,
              state: {
                Link_Menu_Anterior: this.props.Link_Menu_Anterior,
                ID_Evento: this.props.ID_Evento,
                ID_Prova: this.props.ID_Prova,
                Nome_Evento: this.props.Nome_Evento,
                Nome_Prova: this.props.Nome_Prova
              }
            }}
          >
            <ListItem button>
              <ListItemIcon
                className={classNames(classes.icon, "fa fa-arrow-left fa-2x")} //fa-3x
              />
              <ListItemText primary={"Voltar"} />
            </ListItem>
          </Link>
          <Divider />
        </div>
      );
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////
    //                                       Botoes do Ecra "Ver_Notificacoes_Prova"                                //
    //                                       Botoes do Ecra "Ver_Notificacoes_Prova"                                //
    //                                       Botoes do Ecra "Ver_Notificacoes_Prova"                                //
    //                                       Botoes do Ecra "Ver_Notificacoes_Prova"                                //
    //                                       Botoes do Ecra "Ver_Notificacoes_Prova"                                //
    //                                       Botoes do Ecra "Ver_Notificacoes_Prova"                                //
    ///////////////////////////////////////////////////////////////////////////////////////////////////
    else if (this.props.Titulo_Menu === "Lista de Notificações da Prova") {
      /*
      return (
        <div>
          <Link
            style={{ textDecoration: "none" }}
            to={{
              pathname: "/definicoes_notificacoes", //this.props.Link_Tabela_Anterior,
              state: {
                Link_Menu_Anterior: this.props.Link_Menu_Anterior,
                Link_Tabela_Anterior: this.props.Link_Tabela_Anterior,
                ID_Evento: this.props.ID_Evento,
                ID_Prova: this.props.ID_Prova,
                Nome_Evento: this.props.Nome_Evento,
                Nome_Prova: this.props.Nome_Prova
              }
            }}
          >
            <ListItem button>
              <ListItemIcon
                className={classNames(classes.icon, "fa fa-cog fa-2x")} //fa-3x
              />
              <ListItemText primary={"Definições das Notificações"} />
            </ListItem>
          </Link>
          <Divider />
          <Link
            style={{ textDecoration: "none" }}
            to={{
              pathname: "/competicoes", //this.props.Link_Tabela_Anterior,
              state: {
                //Link_Menu_Anterior: this.props.Link_Menu_Anterior,
                ID_Evento: this.props.ID_Evento,
                Fez_Insercao_Competicao: false,
                Fez_Edicao_Competicao: false,
                Faz_Refresh: true,
                Nome_Evento: this.props.Nome_Evento,
                Fez_Eliminacao_Competicao:false
              }
            }}
          >
            <ListItem button>
              <ListItemIcon
                className={classNames(classes.icon, "fa fa-arrow-left fa-2x")} //fa-3x
              />
              <ListItemText primary={"Voltar"} />
            </ListItem>
          </Link>
          <Divider />
        </div>
      );
      */

      return (
        <div>
          <Link
            style={{ textDecoration: "none" }}
            to={{
              pathname: "/competicoes", //this.props.Link_Tabela_Anterior,
              state: {
                //Link_Menu_Anterior: this.props.Link_Menu_Anterior,
                ID_Evento: this.props.ID_Evento,
                Fez_Insercao_Competicao: false,
                Fez_Edicao_Competicao: false,
                Faz_Refresh: true,
                Nome_Evento: this.props.Nome_Evento,
                Fez_Eliminacao_Competicao: false
              }
            }}
          >
            <ListItem button>
              <ListItemIcon
                className={classNames(classes.icon, "fa fa-arrow-left fa-2x")} //fa-3x
              />
              <ListItemText primary={"Voltar"} />
            </ListItem>
          </Link>
          <Divider />
        </div>
      );
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////
    //                                       Botoes do Ecra "Adicionar_Resultado_Atleta"                                //
    //                                       Botoes do Ecra "Adicionar_Resultado_Atleta"                                //
    //                                       Botoes do Ecra "Adicionar_Resultado_Atleta"                                //
    //                                       Botoes do Ecra "Adicionar_Resultado_Atleta"                                //
    //                                       Botoes do Ecra "Adicionar_Resultado_Atleta"                                //
    //                                       Botoes do Ecra "Adicionar_Resultado_Atleta"                                //
    ///////////////////////////////////////////////////////////////////////////////////////////////////
    else if (this.props.Titulo_Menu === "Inserir Novo Resultado Atleta") {
      if (
        this.props.Link_Tabela_Anterior2 === "/lista_resultados_checkpoints"
      ) {
        return (
          <div>
            <Link
              style={{ textDecoration: "none" }}
              to={{
                pathname: this.props.Link_Tabela_Anterior2,
                state: {
                  Link_Tabela_Anterior: this.props.Link_Tabela_Anterior,
                  Link_Menu_Anterior: this.props.Link_Menu_Anterior,
                  ID_Evento: this.props.ID_Evento,
                  ID_Prova: this.props.ID_Prova,
                  ID_Atleta: this.props.ID_Atleta,
                  Fez_Edicao_Resultado: false,
                  Fez_Insercao_Resultado: false,
                  Faz_Refresh: true
                }
              }}
            >
              <ListItem button>
                <ListItemIcon
                  className={classNames(classes.icon, "fa fa-arrow-left fa-2x")} //fa-3x
                />
                <ListItemText primary={"Voltar"} />
              </ListItem>
            </Link>
            <Divider />
          </div>
        );
      } else {
        //if(this.props.Link_Tabela_Anterior2==="/lista_resultados_atleta")
        return (
          <div>
            <Link
              style={{ textDecoration: "none" }}
              to={{
                pathname: this.props.Link_Tabela_Anterior2,
                state: {
                  Link_Tabela_Anterior: this.props.Link_Tabela_Anterior,
                  Link_Menu_Anterior: this.props.Link_Menu_Anterior,
                  ID_Evento: this.props.ID_Evento,
                  ID_Prova: this.props.ID_Prova,
                  ID_Atleta: this.props.ID_Atleta,
                  Fez_Edicao_Resultado: false,
                  Fez_Insercao_Resultado: false,
                  Faz_Refresh: true,
                  Nome_Evento: this.props.Nome_Evento,
                  Nome_Prova: this.props.Nome_Prova,
                  Nome_Atleta: this.props.Nome_Atleta
                }
              }}
            >
              <ListItem button>
                <ListItemIcon
                  className={classNames(classes.icon, "fa fa-arrow-left fa-2x")} //fa-3x
                />
                <ListItemText primary={"Voltar"} />
              </ListItem>
            </Link>
            <Divider />
          </div>
        );
      }
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////
    //                                       Botoes do Ecra "Editar_Resultado_Atleta"                                //
    //                                       Botoes do Ecra "Editar_Resultado_Atleta"                                //
    //                                       Botoes do Ecra "Editar_Resultado_Atleta"                                //
    //                                       Botoes do Ecra "Editar_Resultado_Atleta"                                //
    //                                       Botoes do Ecra "Editar_Resultado_Atleta"                                //
    //                                       Botoes do Ecra "Editar_Resultado_Atleta"                                //
    ///////////////////////////////////////////////////////////////////////////////////////////////////
    else if (this.props.Titulo_Menu === "Editar Resultado Atleta") {
      return (
        <div>
          <Link
            style={{ textDecoration: "none" }}
            to={{
              pathname: this.props.Link_Tabela_Anterior2,
              state: {
                Link_Menu_Anterior: this.props.Link_Menu_Anterior,
                Link_Tabela_Anterior: this.props.Link_Tabela_Anterior,
                ID_Evento: this.props.ID_Evento,
                ID_Prova: this.props.ID_Prova,
                ID_Atleta: this.props.ID_Atleta,
                ID_Competition_Checkpoint: this.props.ID_Competition_Checkpoint,
                Fez_Edicao_Resultado: false,
                Fez_Insercao_Resultado: false,
                Faz_Refresh: true,
                Nome_Evento: this.props.Nome_Evento,
                Nome_Prova: this.props.Nome_Prova,
                Nome_Atleta: this.props.Nome_Atleta,
                Nome_Checkpoint: this.props.Nome_Checkpoint
              }
            }}
          >
            <ListItem button>
              <ListItemIcon
                className={classNames(classes.icon, "fa fa-arrow-left fa-2x")} //fa-3x
              />
              <ListItemText primary={"Voltar"} />
            </ListItem>
          </Link>
          <Divider />
        </div>
      );
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////
    //                                       Botoes do Ecra "Editar_Resultado_Atleta_Checkpoint"                                //
    //                                       Botoes do Ecra "Editar_Resultado_Atleta_Checkpoint"                                //
    //                                       Botoes do Ecra "Editar_Resultado_Atleta_Checkpoint"                                //
    //                                       Botoes do Ecra "Editar_Resultado_Atleta_Checkpoint"                                //
    //                                       Botoes do Ecra "Editar_Resultado_Atleta_Checkpoint"                                //
    //                                       Botoes do Ecra "Editar_Resultado_Atleta_Checkpoint"                                //
    ///////////////////////////////////////////////////////////////////////////////////////////////////
    else if (this.props.Titulo_Menu === "Editar Resultado Atleta Checkpoint") {
      return (
        <div>
          <Link
            style={{ textDecoration: "none" }}
            to={{
              pathname: this.props.Link_Tabela_Anterior2,
              state: {
                Link_Menu_Anterior: this.props.Link_Menu_Anterior,
                Link_Tabela_Anterior: this.props.Link_Tabela_Anterior,
                ID_Evento: this.props.ID_Evento,
                ID_Prova: this.props.ID_Prova,
                ID_Atleta: this.props.ID_Atleta,
                ID_Competition_Checkpoint: this.props.ID_Competition_Checkpoint,
                Fez_Edicao_Resultado: false,
                Fez_Insercao_Resultado: false,
                Faz_Refresh: true,
                Nome_Evento: this.props.Nome_Evento,
                Nome_Prova: this.props.Nome_Prova,
                Nome_Atleta: this.props.Nome_Atleta,
                Nome_Checkpoint: this.props.Nome_Checkpoint
              }
            }}
          >
            <ListItem button>
              <ListItemIcon
                className={classNames(classes.icon, "fa fa-arrow-left fa-2x")} //fa-3x
              />
              <ListItemText primary={"Voltar"} />
            </ListItem>
          </Link>
          <Divider />
        </div>
      );
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////
    //                                       Botoes do Ecra "Ver_Resultados_Atleta"                                //
    //                                       Botoes do Ecra "Ver_Resultados_Atleta"                                //
    //                                       Botoes do Ecra "Ver_Resultados_Atleta"                                //
    //                                       Botoes do Ecra "Ver_Resultados_Atleta"                                //
    //                                       Botoes do Ecra "Ver_Resultados_Atleta"                                //
    //                                       Botoes do Ecra "Ver_Resultados_Atleta"                                //
    ///////////////////////////////////////////////////////////////////////////////////////////////////
    else if (this.props.Titulo_Menu === "Lista Resultados Atleta") {
      return (
        <div>
          <Link
            style={{ textDecoration: "none" }}
            to={{
              pathname: "/adicionar_resultado_atleta", //Link_Menu_Anterior,
              state: {
                ID_Evento: this.props.ID_Evento,
                ID_Prova: this.props.ID_Prova,
                ID_Atleta: this.props.ID_Atleta,
                Link_Menu_Anterior: this.props.Link_Menu_Anterior,
                Link_Tabela_Anterior: this.props.Link_Tabela_Anterior,
                Link_Tabela_Anterior2: this.props.Link_Tabela_Anterior2,
                Faz_Refresh: false, //true
                Nome_Evento: this.props.Nome_Evento,
                Nome_Prova: this.props.Nome_Prova,
                Nome_Atleta: this.props.Nome_Atleta
              }
            }}
          >
            <ListItem button>
              <ListItemIcon
                className={classNames(classes.icon, "fa fa-plus-circle fa-2x")} //fa-3x
              />
              <ListItemText primary={"Adicionar Novo Resultado"} />
            </ListItem>
          </Link>

          <Divider />
          <Link
            style={{ textDecoration: "none" }}
            to={{
              pathname: this.props.Link_Tabela_Anterior, //Link_Menu_Anterior,
              state: {
                Link_Menu_Anterior: this.props.Link_Menu_Anterior,
                ID_Evento: this.props.ID_Evento,
                ID_Prova: this.props.ID_Prova,
                Fez_Insercao_Inscricao_Atleta_Sistema: false,
                Fez_Edicao_Dados_Atleta_Sistema: false,
                Fez_Edicao_Inscricao_Atleta_Sistema: false,
                Faz_Refresh: true,

                Fez_Lancamento_Desistencia_Sucesso: false,
                Fez_Eliminacao_Inscricao_Sucesso: false,
                Nome_Evento: this.props.Nome_Evento,
                Nome_Prova: this.props.Nome_Prova
              }
            }}
          >
            <ListItem button>
              <ListItemIcon
                className={classNames(classes.icon, "fa fa-arrow-left fa-2x")} //fa-3x
              />
              <ListItemText primary={"Voltar"} />
            </ListItem>
          </Link>
          <Divider />
        </div>
      );
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////
    //                                       Botoes do Ecra "Ver_Resultados_Checkpoints"                                //
    //                                       Botoes do Ecra "Ver_Resultados_Checkpoints"                                //
    //                                       Botoes do Ecra "Ver_Resultados_Checkpoints"                                //
    //                                       Botoes do Ecra "Ver_Resultados_Checkpoints"                                //
    //                                       Botoes do Ecra "Ver_Resultados_Checkpoints"                                //
    //                                       Botoes do Ecra "Ver_Resultados_Checkpoints"                                //
    ///////////////////////////////////////////////////////////////////////////////////////////////////
    else if (this.props.Titulo_Menu === "Lista Resultados Checkpoint") {
      return (
        <div>
          <Link
            style={{ textDecoration: "none" }}
            to={{
              pathname: this.props.Link_Tabela_Anterior, //Link_Menu_Anterior,
              state: {
                ID_Evento: this.props.ID_Evento,
                ID_Prova: this.props.ID_Prova,
                Link_Menu_Anterior: this.props.Link_Menu_Anterior,
                Fez_Insercao_Checkpoint_Prova: false,
                Fez_Edicao_Checkpoint_Prova: false,
                Faz_Refresh: true,
                Nome_Evento: this.props.Nome_Evento,
                Nome_Prova: this.props.Nome_Prova
              }
            }}
          >
            <ListItem button>
              <ListItemIcon
                className={classNames(classes.icon, "fa fa-arrow-left fa-2x")} //fa-3x
              />
              <ListItemText primary={"Voltar"} />
            </ListItem>
          </Link>
          <Divider />
        </div>
      );
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////
    //                                       Botoes do Ecra "Adicionar_Utilizador"                                //
    //                                       Botoes do Ecra "Adicionar_Utilizador"                                //
    //                                       Botoes do Ecra "Adicionar_Utilizador"                                //
    //                                       Botoes do Ecra "Adicionar_Utilizador"                                //
    //                                       Botoes do Ecra "Adicionar_Utilizador"                                //
    //                                       Botoes do Ecra "Adicionar_Utilizador"                                //
    ///////////////////////////////////////////////////////////////////////////////////////////////////
    else if (this.props.Titulo_Menu === "Inserir Novo Utilizador") {
      return (
        <div>
          <Link
            style={{ textDecoration: "none" }}
            to={{
              pathname: this.props.Link_Menu_Anterior,
              state: {
                Fez_Login: false,
                Fez_Insercao_Evento: false,
                Fez_Edicao_Evento: false,
                Faz_Refresh: true,
                Fez_Copia_Evento: false,
                Fez_Eliminacao_Evento: false
              }
            }}
          >
            <ListItem button>
              <ListItemIcon
                className={classNames(classes.icon, "fa fa-arrow-left fa-2x")} //fa-3x
              />
              <ListItemText primary={"Voltar"} />
            </ListItem>
          </Link>
          <Divider />
        </div>
      );
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////
    //                                       Botoes do Ecra "Ver_Perfil"                                //
    //                                       Botoes do Ecra "Ver_Perfil"                                //
    //                                       Botoes do Ecra "Ver_Perfil"                                //
    //                                       Botoes do Ecra "Ver_Perfil"                                //
    //                                       Botoes do Ecra "Ver_Perfil"                                //
    //                                       Botoes do Ecra "Ver_Perfil"                                //
    ///////////////////////////////////////////////////////////////////////////////////////////////////
    else if (this.props.Titulo_Menu === "Meu Perfil") {
      return (
        <div>
          <Link
            style={{ textDecoration: "none" }}
            to={{
              pathname: "/eventos",
              state: {
                Fez_Login: false,
                Fez_Insercao_Evento: false,
                Fez_Edicao_Evento: false,
                Faz_Refresh: false,
                Fez_Copia_Evento: false,
                Fez_Eliminacao_Evento: false
              }
            }}
          >
            <ListItem button>
              <ListItemIcon
                className={classNames(classes.icon, "fa fa-arrow-left fa-2x")} //fa-3x
              />
              <ListItemText primary={"Voltar"} />
            </ListItem>
          </Link>
          <Divider />
        </div>
      );
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////
    //                                       Botoes do Ecra "Ver_Utilizadores"                                //
    //                                       Botoes do Ecra "Ver_Utilizadores"                                //
    //                                       Botoes do Ecra "Ver_Utilizadores"                                //
    //                                       Botoes do Ecra "Ver_Utilizadores"                                //
    //                                       Botoes do Ecra "Ver_Utilizadores"                                //
    //                                       Botoes do Ecra "Ver_Utilizadores"                                //
    ///////////////////////////////////////////////////////////////////////////////////////////////////
    else if (this.props.Titulo_Menu === "Ver Utilizadores Sistema") {
      return (
        <div>
          <Link
            style={{ textDecoration: "none" }}
            to={{
              pathname: "/adicionar_utilizador",
              state: {
                Link_Menu_Anterior: this.props.Link_Menu_Anterior,
                Faz_Refresh: false //true
              }
            }}
          >
            <ListItem button>
              <ListItemIcon
                className={classNames(classes.icon, "fa fa-user-plus fa-2x")} //fa-3x
              />
              <ListItemText primary={"Adicionar Utilizador"} />
            </ListItem>
          </Link>
          <Divider />

          <Link
            style={{ textDecoration: "none" }}
            to={{
              pathname: "/eventos",
              state: {
                Fez_Login: false,
                Fez_Insercao_Evento: false,
                Fez_Edicao_Evento: false,
                Faz_Refresh: true,
                Fez_Copia_Evento: false,
                Fez_Eliminacao_Evento: false
              }
            }}
          >
            <ListItem button>
              <ListItemIcon
                className={classNames(classes.icon, "fa fa-arrow-left fa-2x")} //fa-3x
              />
              <ListItemText primary={"Voltar"} />
            </ListItem>
          </Link>
          <Divider />
        </div>
      );
    }

    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //

    ///////////////////////////////////////////////////////////////////////////////////////////////////
    //                                       Botoes do Ecra "Eventos"                                //
    //                                       Botoes do Ecra "Eventos"                                //
    //                                       Botoes do Ecra "Eventos"                                //
    //                                       Botoes do Ecra "Eventos"                                //
    //                                       Botoes do Ecra "Eventos"                                //
    //                                       Botoes do Ecra "Eventos"                                //
    ///////////////////////////////////////////////////////////////////////////////////////////////////
    /*
    else if (this.props.Titulo_Menu === "Competições2") {
      return (
        <div>
          <List>
            <Link
              style={{ textDecoration: "none" }}
              to={{
                pathname: "/adicionar_competicao",
                state: {
                  //Link_Menu_Anterior: this.props.location.pathname,
                  ID_Evento: this.props.ID_Evento,
                Faz_Refresh: true
                }
              }}
            >
              <ListItem button>
                <ListItemIcon
                  className={classNames(classes.icon, "fa fa-arrow-left fa-2x")} //fa-3x
                />
                <ListItemText primary={"Voltar"} />
              </ListItem>
            </Link>
          </List>
          <Divider />
        </div>
      );
    }
  */
  };
  render() {
    const { classes /*, theme*/ } = this.props;
    const { open /*Logout_Realizado*/ } = this.state;

    if (this.state.Logout_Realizado === true) {
      //if (Logout_Realizado === true) {
      //window.location.reload();
      /*
      this.props.history.push({
        pathanme: "/"
      });*/

      this.props.history.push({
        pathname: "/"
      });
    }

    if (this.state.Eliminar_Inscricao_Realizado !== false) {
      this.props.history.push({
        pathname: this.props.Link_Tabela_Anterior,
        state: {
          Link_Menu_Anterior: this.props.Link_Menu_Anterior,
          ID_Evento: this.props.ID_Evento,
          ID_Prova: this.props.ID_Prova,
          Fez_Insercao_Inscricao_Atleta_Sistema: false,
          Fez_Edicao_Dados_Atleta_Sistema: false,
          Fez_Edicao_Inscricao_Atleta_Sistema: false,
          Faz_Refresh: true,
          Fez_Lancamento_Desistencia_Sucesso: false,
          Fez_Eliminacao_Inscricao_Sucesso: this.state
            .Eliminar_Inscricao_Realizado, //true,
          Nome_Evento: this.props.Nome_Evento,
          Nome_Prova: this.props.Nome_Prova
        }
      });
    }

    if (this.state.Eliminar_Evento_Realizado !== false) {
      //!==undefined){//}=== true) {
      this.props.history.push({
        pathname: "/eventos",
        state: {
          Fez_Login: false,
          Fez_Insercao_Evento: false,
          Fez_Edicao_Evento: false,
          Faz_Refresh: true,
          Fez_Copia_Evento: false,
          //Fez_Eliminacao_Evento: false
          Fez_Eliminacao_Evento: this.state.Eliminar_Evento_Realizado
        }
      });
    }

    if (this.state.Eliminar_Competicao_Realizado !== false) {
      //!==undefined){//}=== true) {
      this.props.history.push({
        pathname: "/competicoes",
        state: {
          ID_Evento: this.props.ID_Evento,
          Fez_Insercao_Competicao: false,
          Fez_Edicao_Competicao: false,
          Faz_Refresh: true,
          Nome_Evento: this.props.Nome_Evento,
          Fez_Eliminacao_Competicao: this.state.Eliminar_Competicao_Realizado
        }
      });
    }

    if (this.state.Lancamento_Desistencia_Realizada === true) {
      this.props.history.push({
        pathname: this.props.Link_Tabela_Anterior,
        state: {
          Link_Menu_Anterior: this.props.Link_Menu_Anterior,
          ID_Evento: this.props.ID_Evento,
          ID_Prova: this.props.ID_Prova,
          Fez_Insercao_Inscricao_Atleta_Sistema: false,
          Fez_Edicao_Dados_Atleta_Sistema: false,
          Fez_Edicao_Inscricao_Atleta_Sistema: false,
          Faz_Refresh: true,
          Fez_Lancamento_Desistencia_Sucesso: true,
          Fez_Eliminacao_Inscricao_Sucesso: false
        }
      });
    }

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          //position="static"
          className={classNames(classes.appBar, {
            [classes.appBarShift]: open
          })}
        >
          <Toolbar disableGutters={!open}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerOpen}
              className={classNames(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" noWrap>
              {//this.props.Titulo_Menu
              this.Mostra_Titulo_Drawer()}
            </Typography>
            {/*<div className="botoes_Direita_Toolbar">
              <button>asdasd</button>
            </div>*/}
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper
          }}
        >
          <div className={classes.drawerHeader}>
            {/*<div className="centra_drawerHeader">Main Menu</div>*/}
            <div className="centra_drawerHeader2">Menu</div>
            {/*
            <IconButton onClick={this.handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
            */}
          </div>
          <Divider />
          {this.Mostra_Botoes_Basicos_Drawer(classes)}
          {this.Mostra_Botoes_Extra_Drawer(classes)}
          {this.Mostra_Botao_Logout_Drawer(classes)}
        </Drawer>
      </div>
    );
  }
}

PersistentDrawerLeft.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(PersistentDrawerLeft);
