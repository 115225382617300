import React, { Component } from "react";
import axios from "axios";
import Moment from "moment";
import Drawer from "../Drawer/Drawer.js";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

//Funções Auxiliares:
import {
  mostra_mensagem_erro,
  //ve_resultados,
  Trata_Validacoes,
  isEmptyObject
} from "../Funcoes_Auxiliares/Funcoes_Auxiliares.js";

let REACT_APP_API_URL;

if (process.env.REACT_APP_ESTADO === "Deploy") {
  REACT_APP_API_URL = process.env.REACT_APP_API_URL_PROD;
} //Development
else {
  REACT_APP_API_URL = process.env.REACT_APP_API_URL_DEV;
}

class Formulario_Edicao_Checkpoint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dados: undefined, //[],
      Competicoes_Lidas: undefined, //null,, //[],
      Numeros_Checkpoints_Lidos_Prova: undefined,
      Nome_Evento: null,
      Nome_Prova: null,
      Nome_Checkpoint: null,

      //username: null,
      //id_inscricao: null,
      checkpoint_order_original: null,

      checkpoint_order: null, //"", //null,
      //local: null,
      abertura_posto: null,
      fecho_posto: null,
      //competicao: null,
      //competition_checkpoint_id: null,

      Link_Tabela_Anterior: null,
      Link_Menu_Anterior: null,
      //ID_Checkpoint: null,
      ID_Competition_Checkpoint: null,
      ID_Evento: null,
      ID_Prova: null,

      coordenadas: null,
      altitude: null,
      id_checkpoint: null,

      tipo_controlo: null,
      previsao_rapido: null,
      distancia_mapa: null,
      distancia: null,
      distancia_acumulada: null,
      previsao_lento: null,
      desnivel_positivo: null,
      desnivel_positivo_acumulado: null,
      desnivel_negativo: null,
      desnivel_negativo_acumulado: null,
      abastecimento: null,

      //Variavel Controlo de Erro na Leitura dos Props
      Mostra_Erro: false,

      //Variavel Para Controlar o Redirect
      Inserido_Sucesso: false,

      //Lista Para os Erros de Validação:
      errors: {}
    };

    if (props.location.state === undefined) {
      this.state.Mostra_Erro = true;
    } else {
      if (props.location.state.Link_Tabela_Anterior === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Link_Tabela_Anterior =
          props.location.state.Link_Tabela_Anterior;
      }
      if (props.location.state.Link_Menu_Anterior === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Link_Menu_Anterior = props.location.state.Link_Menu_Anterior;
      }
      if (props.location.state.ID_Competition_Checkpoint === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.ID_Competition_Checkpoint =
          props.location.state.ID_Competition_Checkpoint;
      }
      if (props.location.state.ID_Evento === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.ID_Evento = props.location.state.ID_Evento;
      }
      if (props.location.state.ID_Prova === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.ID_Prova = props.location.state.ID_Prova;
      }
      if (props.location.state.Nome_Evento === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Nome_Evento = props.location.state.Nome_Evento;
      }
      if (props.location.state.Nome_Prova === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Nome_Prova = props.location.state.Nome_Prova;
      }
      if (props.location.state.Nome_Checkpoint === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Nome_Checkpoint = props.location.state.Nome_Checkpoint;
      }
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.handleApaga_Checkpoint = this.handleApaga_Checkpoint.bind(this);
  }

  handleApaga_Checkpoint(event) {
    event.preventDefault();
    this.Apaga_Checkpoint();

    this.props.history.push({
      pathname: this.state.Link_Tabela_Anterior,
      state: {
        Link_Menu_Anterior: this.state.Link_Menu_Anterior,
        ID_Evento: this.state.ID_Evento,
        ID_Prova: this.state.ID_Prova,
        Faz_Refresh: true,
        Nome_Evento: this.state.Nome_Evento,
        Nome_Prova: this.state.Nome_Prova
      }
    });
  }

  Apaga_Checkpoint() {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .delete(
        REACT_APP_API_URL +
          "/delete_competition_checkpoint/" +
          this.state.ID_Competition_Checkpoint,
        {
          headers: { authorization: "Bearer " + Token_Lido }
        }
      )
      .then(res => {
        console.log(res);
      });
  }

  Vai_Buscar_Dados_Inscricao_Atleta() {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .get(
        REACT_APP_API_URL +
          "/dados_checkpoint/" +
          this.state.ID_Competition_Checkpoint,
        {
          headers: { authorization: "Bearer " + Token_Lido }
        }
      ) //+ this.state.ID_Checkpoint)
      .then(res => {
        console.log(res);
        this.setState({ dados: res.data });
        const Dados_Lidos = res.data;
        this.setState({
          checkpoint_order_original: Dados_Lidos[0].checkpoint_order,
          checkpoint_order: Dados_Lidos[0].checkpoint_order,
          //local: Dados_Lidos[0].local,
          abertura_posto: Dados_Lidos[0].abertura,
          fecho_posto: Dados_Lidos[0].fecho,
          //competicao: Dados_Lidos[0].competition_competition_id
          //competicao: Dados_Lidos[0].long_name

          coordenadas: Dados_Lidos[0].coordinate,
          altitude: Dados_Lidos[0].altitude,
          id_checkpoint: Dados_Lidos[0].checkpoint_checkpoint_id,

          tipo_controlo: Dados_Lidos[0].tipo_controlo,
          previsao_rapido: Dados_Lidos[0].previsao_rapido,
          distancia_mapa: Dados_Lidos[0].distancia_mapa,
          distancia: Dados_Lidos[0].distancia,
          distancia_acumulada: Dados_Lidos[0].distancia_acumulada,
          previsao_lento: Dados_Lidos[0].previsao_lento,
          desnivel_positivo: Dados_Lidos[0].desnivel_positivo,
          desnivel_positivo_acumulado:
            Dados_Lidos[0].desnivel_positivo_acumulado,
          desnivel_negativo: Dados_Lidos[0].desnivel_negativo,
          desnivel_negativo_acumulado:
            Dados_Lidos[0].desnivel_negativo_acumulado,
          abastecimento: Dados_Lidos[0].abastecimento

          //competition_checkpoint_id: Dados_Lidos[0].competition_checkpoint_id
        });
      });
  }

  Vai_Buscar_Dados_Competicoes() {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .get(REACT_APP_API_URL + "/competicoes/" + this.state.ID_Evento, {
        headers: { authorization: "Bearer " + Token_Lido }
      })
      .then(res => {
        console.log(res);
        this.setState({ Competicoes_Lidas: res.data });
      });
  }

  Vai_Buscar_Checkpoints_Prova() {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .get(
        REACT_APP_API_URL + "/lista_checkpoints_prova/" + this.state.ID_Prova,
        {
          headers: { authorization: "Bearer " + Token_Lido }
        }
      )
      .then(res => {
        console.log(res);
        this.setState({ Numeros_Checkpoints_Lidos_Prova: res.data });
      });
  }

  Verifica_Existencia_Checkpoint_Order(Numero_Checkpoint_Order) {
    var Array_Numeros_Checkpoints = [];

    var Numero_Checkpoint_Order2 = Numero_Checkpoint_Order;
    var Numero_Checkpoint_Order_Aux = Numero_Checkpoint_Order;

    Numero_Checkpoint_Order2 = Numero_Checkpoint_Order_Aux * 1; //*1 Passa de String Para Int

    //Talvez Tirar Daqui e Fazer Isto Depois de Ler da BD
    this.state.Numeros_Checkpoints_Lidos_Prova.forEach(function(linha) {
      Array_Numeros_Checkpoints.push(linha.checkpoint_order * 1);
    });

    if (Array_Numeros_Checkpoints.indexOf(Numero_Checkpoint_Order2) > -1) {
      //!==-1)
      return true;
    } else {
      return false;
    }
  }

  /*
Procedimento Com Axios */
  componentDidMount() {
    if (localStorage.getItem("Token") !== null) {
      this.Vai_Buscar_Dados_Competicoes();
      this.Vai_Buscar_Checkpoints_Prova();
      this.Vai_Buscar_Dados_Inscricao_Atleta();
    }
  } //Fecha Procedimento Que Acontece Depois do Output do Componente Foi Rendered

  /*
  componentDidUpdate() {
    this.Vai_Buscar_Dados_Inscricao_Atleta();
  } //Fecha Procedimento Que Acontece Depois do Output do Componente Foi Rendered
  */

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleValidation() {
    let errors = {};
    let formIsValid = false; //true;

    //Para Cada Campo Invocar o Trata_Validacoes
    errors = Trata_Validacoes(
      this.state.checkpoint_order,
      "competition_checkpoint/checkpoint_order",
      errors
    );

    if (
      this.Verifica_Existencia_Checkpoint_Order(this.state.checkpoint_order)
    ) {
      if (
        this.state.checkpoint_order * 1 !==
        this.state.checkpoint_order_original * 1
      ) {
        errors["competition_checkpoint/checkpoint_order"] =
          "*Já Existe um Checkpoint Com Esse Número";
      }
    }

    errors = Trata_Validacoes(
      this.state.abertura_posto,
      "competition_checkpoint/abertura",
      errors
    );
    errors = Trata_Validacoes(
      this.state.previsao_rapido,
      "competition_checkpoint/previsao_rapido",
      errors
    );
    errors = Trata_Validacoes(
      this.state.previsao_lento,
      "competition_checkpoint/previsao_lento",
      errors
    );
    errors = Trata_Validacoes(
      this.state.fecho_posto,
      "competition_checkpoint/fecho",
      errors
    );
    errors = Trata_Validacoes(
      this.state.tipo_controlo,
      "competition_checkpoint/tipo_controlo",
      errors
    );
    errors = Trata_Validacoes(
      this.state.distancia_mapa,
      "competition_checkpoint/distancia_mapa",
      errors
    );
    errors = Trata_Validacoes(
      this.state.distancia,
      "competition_checkpoint/distancia",
      errors
    );
    errors = Trata_Validacoes(
      this.state.distancia_acumulada,
      "competition_checkpoint/distancia_acumulada",
      errors
    );
    errors = Trata_Validacoes(
      this.state.desnivel_positivo,
      "competition_checkpoint/desnivel_positivo",
      errors
    );
    errors = Trata_Validacoes(
      this.state.desnivel_positivo_acumulado,
      "competition_checkpoint/desnivel_positivo_acumulado",
      errors
    );
    errors = Trata_Validacoes(
      this.state.desnivel_negativo,
      "competition_checkpoint/desnivel_negativo",
      errors
    );
    errors = Trata_Validacoes(
      this.state.desnivel_negativo_acumulado,
      "competition_checkpoint/desnivel_negativo_acumulado",
      errors
    );
    errors = Trata_Validacoes(
      this.state.abastecimento,
      "competition_checkpoint/abastecimento",
      errors
    );

    //if (errors.length > 0) {
    if (isEmptyObject(errors)) {
      formIsValid = true; //false;
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.handleValidation()) {
      const Token_Lido = localStorage.getItem("Token");
      axios
        .patch(
          REACT_APP_API_URL +
            "/update_checkpoint/" +
            this.state.ID_Competition_Checkpoint, //+ this.state.ID_Checkpoint,
          {
            checkpoint_order: this.state.checkpoint_order,
            abertura_posto: this.state.abertura_posto,
            fecho_posto: this.state.fecho_posto,
            competition_checkpoint_id: this.state.ID_Competition_Checkpoint,

            tipo_controlo: this.state.tipo_controlo,
            previsao_rapido: this.state.previsao_rapido,
            distancia_mapa: this.state.distancia_mapa,
            distancia: this.state.distancia,
            distancia_acumulada: this.state.distancia_acumulada,
            previsao_lento: this.state.previsao_lento,
            desnivel_positivo: this.state.desnivel_positivo,
            desnivel_positivo_acumulado: this.state.desnivel_positivo_acumulado,
            desnivel_negativo: this.state.desnivel_negativo,
            desnivel_negativo_acumulado: this.state.desnivel_negativo_acumulado,
            abastecimento: this.state.abastecimento

            /*
          //Estes Pertencem à Tabela "Checkpoint" e Nao à Tabela "Competition_Checkpoint".
          //Ao Alterar Estes, Vao Ser Alterados Todas as Competicoes Que Usam Este Checkpoint
          coordenadas: this.state.coordenadas,
          altitude: this.state.altitude,
          id_checkpoint: this.state.id_checkpoint,
          */
          },
          {
            headers: { authorization: "Bearer " + Token_Lido }
          }
        )
        .then(res => {
          //console.log(res);
          this.setState({ Inserido_Sucesso: res.data.success });
        });
    }
  }

  render() {
    if (localStorage.getItem("Token") === null) {
      this.props.history.push({
        pathname: "/"
      });
    }

    if (this.state.Inserido_Sucesso === true) {
      this.props.history.push({
        pathname: this.state.Link_Tabela_Anterior,
        state: {
          //ID_Checkpoint: this.state.ID_Checkpoint,
          ID_Competition_Checkpoint: this.state.ID_Competition_Checkpoint,
          Link_Menu_Anterior: this.state.Link_Menu_Anterior,
          Link_Tabela_Anterior: this.state.Link_Tabela_Anterior,
          ID_Evento: this.state.ID_Evento,
          ID_Prova: this.state.ID_Prova,
          Fez_Insercao_Checkpoint_Prova: false,
          Fez_Edicao_Checkpoint_Prova: true,
          Faz_Refresh: true,
          Nome_Evento: this.state.Nome_Evento,
          Nome_Prova: this.state.Nome_Prova
        }
      });
    }

    //Verifica Se Existe Erro. Se Houver Mostra Mensagem de Erro
    if (this.state.Mostra_Erro === true) {
      return (
        <div>
          <Drawer
            Titulo_Menu="" //"Erro"
            history={this.props.history}
          />
          {mostra_mensagem_erro()}
        </div>
      );
    } else {
      if (
        this.state.dados === undefined ||
        this.state.Competicoes_Lidas === undefined ||
        this.state.Numeros_Checkpoints_Lidos_Prova === undefined
      ) {
        return (
          <div className="centra_loading">
            <h1>Loading...</h1>
          </div>
        );
      } else {
        const border_erro = "1px solid #ff0000";

        return (
          <div>
            <Drawer
              Titulo_Menu="Editar Checkpoint Prova"
              Link_Menu_Anterior={this.state.Link_Menu_Anterior}
              Link_Tabela_Anterior={this.state.Link_Tabela_Anterior}
              ID_Evento={this.state.ID_Evento}
              ID_Prova={this.state.ID_Prova}
              Nome_Evento={this.state.Nome_Evento}
              Nome_Prova={this.state.Nome_Prova}
              Nome_Checkpoint={this.state.Nome_Checkpoint}
              history={this.props.history}
            />
            {this.state.dados.map(linha => {
              return (
                <div>
                  <div className="novas_margens_volta_novos_forms">
                    <div className="centra_novos_forms">
                      <form className="login-form" onSubmit={this.handleSubmit}>
                        <div className="centra_titulos_tabs">
                          <Tabs>
                            <TabList>
                              <Tab
                                style={{
                                  border: this.state.errors[
                                    "competition_checkpoint/checkpoint_order"
                                  ]
                                    ? border_erro
                                    : ""
                                }}
                              >
                                Dados Checkpoint
                              </Tab>
                              <Tab
                                style={{
                                  border:
                                    this.state.errors[
                                      "competition_checkpoint/abertura"
                                    ] ||
                                    this.state.errors[
                                      "competition_checkpoint/previsao_rapido"
                                    ] ||
                                    this.state.errors[
                                      "competition_checkpoint/previsao_lento"
                                    ] ||
                                    this.state.errors[
                                      "competition_checkpoint/fecho"
                                    ]
                                      ? border_erro
                                      : ""
                                }}
                              >
                                Datas Previsões
                              </Tab>
                              <Tab
                                style={{
                                  border:
                                    this.state.errors[
                                      "competition_checkpoint/desnivel_positivo"
                                    ] ||
                                    this.state.errors[
                                      "competition_checkpoint/desnivel_positivo_acumulado"
                                    ] ||
                                    this.state.errors[
                                      "competition_checkpoint/desnivel_negativo"
                                    ] ||
                                    this.state.errors[
                                      "competition_checkpoint/desnivel_negativo_acumulado"
                                    ]
                                      ? border_erro
                                      : ""
                                }}
                              >
                                Desniveis
                              </Tab>
                              <Tab
                                style={{
                                  border:
                                    this.state.errors[
                                      "competition_checkpoint/distancia"
                                    ] ||
                                    this.state.errors[
                                      "competition_checkpoint/distancia_acumulada"
                                    ] ||
                                    this.state.errors[
                                      "competition_checkpoint/distancia_mapa"
                                    ]
                                      ? border_erro
                                      : ""
                                }}
                              >
                                Distâncias
                              </Tab>
                              <Tab
                                style={{
                                  border:
                                    this.state.errors[
                                      "competition_checkpoint/tipo_controlo"
                                    ] ||
                                    this.state.errors[
                                      "competition_checkpoint/abastecimento"
                                    ]
                                      ? border_erro
                                      : ""
                                }}
                              >
                                Tipo Checkpoint
                              </Tab>
                              <button
                                type="submit"
                                style={{ marginLeft: "10px" }}
                              >
                                Alterar Checkpoint
                              </button>
                            </TabList>
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}

                            <TabPanel>
                              <div className="form">
                                <div>
                                  {/*}
                                  <label>Nome Checkpoint</label>
                                  <input
                                    style={{
                                      backgroundColor: "#b3b3cc",
                                      textAlign: "center"
                                    }}
                                    type="text"
                                    name="local"
                                    placeholder="Nome"
                                    defaultValue={linha.local}
                                    readOnly
                                  />
                                  <div className="espaco_span" />
                                  */}
                                  <label>
                                    Número Checkpoint
                                    <span
                                      className="bolder"
                                      style={{ color: "#e60000" }}
                                    >
                                      {" (*)"}
                                    </span>
                                  </label>
                                  <input
                                    style={{
                                      border: this.state.errors[
                                        "competition_checkpoint/checkpoint_order"
                                      ]
                                        ? border_erro
                                        : "",
                                      textAlign: "center"
                                    }}
                                    type="number"
                                    name="checkpoint_order"
                                    placeholder="Número do Checkpoint"
                                    onChange={this.handleChange}
                                    defaultValue={this.state.checkpoint_order}
                                    min="0"
                                    max="99"
                                  />
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {
                                      this.state.errors[
                                        "competition_checkpoint/checkpoint_order"
                                      ]
                                    }
                                  </span>
                                  <div className="espaco_span" />
                                  <label>Coordenadas</label>
                                  <input
                                    style={{
                                      backgroundColor: "#b3b3cc",
                                      textAlign: "center"
                                    }}
                                    type="text"
                                    name="coordenadas"
                                    placeholder="Coordenadas"
                                    defaultValue={linha.coordinate}
                                    readOnly
                                  />
                                  <div className="espaco_span" />
                                  <label>Altitude</label>
                                  <input
                                    style={{
                                      backgroundColor: "#b3b3cc",
                                      textAlign: "center"
                                    }}
                                    type="text"
                                    name="altitude"
                                    placeholder="Altitude"
                                    defaultValue={linha.altitude}
                                    readOnly
                                  />
                                  <div className="espaco_span" />
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {" (*)"}
                                  </span>{" "}
                                  <span className="bolder">
                                    {" "}
                                    Campos Obrigatórios
                                  </span>
                                </div>
                              </div>
                              {/*}
                              <div className="form">
                                <button type="submit">
                                  Alterar Checkpoint
                                </button>
                              </div>
                              */}
                            </TabPanel>
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}

                            <TabPanel>
                              <div className="form">
                                <div>
                                  <label>
                                    Data de Abertura
                                    <span
                                      className="bolder"
                                      style={{ color: "#e60000" }}
                                    >
                                      {" (*)"}
                                    </span>
                                  </label>
                                  <input
                                    style={{
                                      border: this.state.errors[
                                        "competition_checkpoint/abertura"
                                      ]
                                        ? border_erro
                                        : "",
                                      textAlign: "center"
                                    }}
                                    type="datetime-local"
                                    step="1"
                                    name="abertura_posto"
                                    placeholder="Abertura Posto"
                                    onChange={this.handleChange}
                                    defaultValue={
                                      String(
                                        Moment(
                                          this.state.abertura_posto
                                        ).format("YYYY-MM-DD")
                                      ) +
                                      "T" +
                                      String(
                                        Moment(
                                          this.state.abertura_posto
                                        ).format("HH:mm:ss")
                                      )
                                    }
                                  />
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {
                                      this.state.errors[
                                        "competition_checkpoint/abertura"
                                      ]
                                    }
                                  </span>
                                  <div className="espaco_span" />
                                  <label>
                                    Previsão 1º Atleta
                                    <span
                                      className="bolder"
                                      style={{ color: "#e60000" }}
                                    >
                                      {" (*)"}
                                    </span>
                                  </label>
                                  <input
                                    style={{
                                      border: this.state.errors[
                                        "competition_checkpoint/previsao_rapido"
                                      ]
                                        ? border_erro
                                        : "",
                                      textAlign: "center"
                                    }}
                                    type="datetime-local"
                                    step="1"
                                    name="previsao_rapido"
                                    placeholder="Previsão 1º Atleta"
                                    onChange={this.handleChange}
                                    defaultValue={
                                      String(
                                        Moment(
                                          this.state.previsao_rapido
                                        ).format("YYYY-MM-DD")
                                      ) +
                                      "T" +
                                      String(
                                        Moment(
                                          this.state.previsao_rapido
                                        ).format("HH:mm:ss")
                                      )
                                    }
                                  />
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {
                                      this.state.errors[
                                        "competition_checkpoint/previsao_rapido"
                                      ]
                                    }
                                  </span>
                                  <div className="espaco_span" />
                                  <label>
                                    Previsão Último Atleta
                                    <span
                                      className="bolder"
                                      style={{ color: "#e60000" }}
                                    >
                                      {" (*)"}
                                    </span>
                                  </label>
                                  <input
                                    style={{
                                      border: this.state.errors[
                                        "competition_checkpoint/previsao_lento"
                                      ]
                                        ? border_erro
                                        : "",
                                      textAlign: "center"
                                    }}
                                    type="datetime-local"
                                    step="1"
                                    name="previsao_lento"
                                    placeholder="Previsão Último Atleta"
                                    onChange={this.handleChange}
                                    defaultValue={
                                      String(
                                        Moment(
                                          this.state.previsao_lento
                                        ).format("YYYY-MM-DD")
                                      ) +
                                      "T" +
                                      String(
                                        Moment(
                                          this.state.previsao_lento
                                        ).format("HH:mm:ss")
                                      )
                                    }
                                  />
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {
                                      this.state.errors[
                                        "competition_checkpoint/previsao_lento"
                                      ]
                                    }
                                  </span>
                                  <div className="espaco_span" />
                                  <label>
                                    Data de Fecho
                                    <span
                                      className="bolder"
                                      style={{ color: "#e60000" }}
                                    >
                                      {" (*)"}
                                    </span>
                                  </label>
                                  <input
                                    style={{
                                      border: this.state.errors[
                                        "competition_checkpoint/fecho"
                                      ]
                                        ? border_erro
                                        : "",
                                      textAlign: "center"
                                    }}
                                    type="datetime-local"
                                    step="1"
                                    name="fecho_posto"
                                    placeholder="Fecho Posto"
                                    onChange={this.handleChange}
                                    defaultValue={
                                      String(
                                        Moment(this.state.fecho_posto).format(
                                          "YYYY-MM-DD"
                                        )
                                      ) +
                                      "T" +
                                      String(
                                        Moment(this.state.fecho_posto).format(
                                          "HH:mm:ss"
                                        )
                                      )
                                    }
                                  />
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {
                                      this.state.errors[
                                        "competition_checkpoint/fecho"
                                      ]
                                    }
                                  </span>
                                  <div className="espaco_span" />
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {" (*)"}
                                  </span>{" "}
                                  <span className="bolder">
                                    {" "}
                                    Campos Obrigatórios
                                  </span>
                                </div>
                              </div>
                              {/*}
                              <div className="form">
                                <button type="submit">
                                  Alterar Checkpoint
                                </button>
                              </div>
                              */}
                            </TabPanel>
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}

                            <TabPanel>
                              <div className="form">
                                <div>
                                  <label>
                                    Desnivel Positivo
                                    <span
                                      className="bolder"
                                      style={{ color: "#e60000" }}
                                    >
                                      {" (*)"}
                                    </span>
                                  </label>
                                  <input
                                    style={{
                                      border: this.state.errors[
                                        "competition_checkpoint/desnivel_positivo"
                                      ]
                                        ? border_erro
                                        : "",
                                      textAlign: "center"
                                    }}
                                    type="text"
                                    name="desnivel_positivo"
                                    placeholder="Desnivel Positivo"
                                    onChange={this.handleChange}
                                    defaultValue={this.state.desnivel_positivo}
                                  />
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {
                                      this.state.errors[
                                        "competition_checkpoint/desnivel_positivo"
                                      ]
                                    }
                                  </span>
                                  <div className="espaco_span" />
                                  <label>
                                    Desnivel Positivo Acumulado
                                    <span
                                      className="bolder"
                                      style={{ color: "#e60000" }}
                                    >
                                      {" (*)"}
                                    </span>
                                  </label>
                                  <input
                                    style={{
                                      border: this.state.errors[
                                        "competition_checkpoint/desnivel_positivo_acumulado"
                                      ]
                                        ? border_erro
                                        : "",
                                      textAlign: "center"
                                    }}
                                    type="text"
                                    name="desnivel_positivo_acumulado"
                                    placeholder="Desnivel Positivo Acumulado"
                                    onChange={this.handleChange}
                                    defaultValue={
                                      this.state.desnivel_positivo_acumulado
                                    }
                                  />
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {
                                      this.state.errors[
                                        "competition_checkpoint/desnivel_positivo_acumulado"
                                      ]
                                    }
                                  </span>
                                  <div className="espaco_span" />
                                  <label>
                                    Desnivel Negativo
                                    <span
                                      className="bolder"
                                      style={{ color: "#e60000" }}
                                    >
                                      {" (*)"}
                                    </span>
                                  </label>
                                  <input
                                    style={{
                                      border: this.state.errors[
                                        "competition_checkpoint/desnivel_negativo"
                                      ]
                                        ? border_erro
                                        : "",
                                      textAlign: "center"
                                    }}
                                    type="text"
                                    name="desnivel_negativo"
                                    placeholder="Desnivel Negativo"
                                    onChange={this.handleChange}
                                    defaultValue={this.state.desnivel_negativo}
                                  />
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {
                                      this.state.errors[
                                        "competition_checkpoint/desnivel_negativo"
                                      ]
                                    }
                                  </span>
                                  <div className="espaco_span" />
                                  <label>
                                    Desnivel Negativo Acumulado
                                    <span
                                      className="bolder"
                                      style={{ color: "#e60000" }}
                                    >
                                      {" (*)"}
                                    </span>
                                  </label>
                                  <input
                                    style={{
                                      border: this.state.errors[
                                        "competition_checkpoint/desnivel_negativo_acumulado"
                                      ]
                                        ? border_erro
                                        : "",
                                      textAlign: "center"
                                    }}
                                    type="text"
                                    name="desnivel_negativo_acumulado"
                                    placeholder="Desnivel Negativo Acumulado"
                                    onChange={this.handleChange}
                                    defaultValue={
                                      this.state.desnivel_negativo_acumulado
                                    }
                                  />
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {
                                      this.state.errors[
                                        "competition_checkpoint/desnivel_negativo_acumulado"
                                      ]
                                    }
                                  </span>
                                  <div className="espaco_span" />
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {" (*)"}
                                  </span>{" "}
                                  <span className="bolder">
                                    {" "}
                                    Campos Obrigatórios
                                  </span>
                                </div>
                              </div>
                              {/*}
                              <div className="form">
                                <button type="submit">
                                  Alterar Checkpoint
                                </button>
                              </div>
                              */}
                            </TabPanel>
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}

                            <TabPanel>
                              <div className="form">
                                <div>
                                  <label>
                                    Distância
                                    <span
                                      className="bolder"
                                      style={{ color: "#e60000" }}
                                    >
                                      {" (*)"}
                                    </span>
                                  </label>
                                  <input
                                    style={{
                                      border: this.state.errors[
                                        "competition_checkpoint/distancia"
                                      ]
                                        ? border_erro
                                        : "",
                                      textAlign: "center"
                                    }}
                                    type="text"
                                    name="distancia"
                                    placeholder="Distância"
                                    onChange={this.handleChange}
                                    defaultValue={this.state.distancia}
                                  />
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {
                                      this.state.errors[
                                        "competition_checkpoint/distancia"
                                      ]
                                    }
                                  </span>
                                  <div className="espaco_span" />
                                  <label>
                                    Distância Acumulada
                                    <span
                                      className="bolder"
                                      style={{ color: "#e60000" }}
                                    >
                                      {" (*)"}
                                    </span>
                                  </label>
                                  <input
                                    style={{
                                      border: this.state.errors[
                                        "competition_checkpoint/distancia_acumulada"
                                      ]
                                        ? border_erro
                                        : "",
                                      textAlign: "center"
                                    }}
                                    type="text"
                                    name="distancia_acumulada"
                                    placeholder="Distância Acumulada"
                                    onChange={this.handleChange}
                                    defaultValue={
                                      this.state.distancia_acumulada
                                    }
                                  />
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {
                                      this.state.errors[
                                        "competition_checkpoint/distancia_acumulada"
                                      ]
                                    }
                                  </span>
                                  <div className="espaco_span" />
                                  <label>
                                    Distância do Mapa
                                    <span
                                      className="bolder"
                                      style={{ color: "#e60000" }}
                                    >
                                      {" (*)"}
                                    </span>
                                  </label>
                                  <input
                                    style={{
                                      border: this.state.errors[
                                        "competition_checkpoint/distancia_mapa"
                                      ]
                                        ? border_erro
                                        : "",
                                      textAlign: "center"
                                    }}
                                    type="text"
                                    name="distancia_mapa"
                                    placeholder="Distância do Mapa"
                                    onChange={this.handleChange}
                                    defaultValue={this.state.distancia_mapa}
                                  />
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {
                                      this.state.errors[
                                        "competition_checkpoint/distancia_mapa"
                                      ]
                                    }
                                  </span>
                                  <div className="espaco_span" />
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {" (*)"}
                                  </span>{" "}
                                  <span className="bolder">
                                    {" "}
                                    Campos Obrigatórios
                                  </span>
                                </div>
                              </div>
                              {/*}
                              <div className="form">
                                <button type="submit">
                                  Alterar Checkpoint
                                </button>
                              </div>
                              */}
                            </TabPanel>
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}

                            <TabPanel>
                              <div className="form">
                                <div>
                                  <label>
                                    Tipo de Controlo
                                    <span
                                      className="bolder"
                                      style={{ color: "#e60000" }}
                                    >
                                      {" (*)"}
                                    </span>
                                  </label>
                                  <input
                                    style={{
                                      border: this.state.errors[
                                        "competition_checkpoint/tipo_controlo"
                                      ]
                                        ? border_erro
                                        : "",
                                      textAlign: "center"
                                    }}
                                    type="text"
                                    name="tipo_controlo"
                                    placeholder="Tipo de Controlo"
                                    onChange={this.handleChange}
                                    defaultValue={this.state.tipo_controlo}
                                  />
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {
                                      this.state.errors[
                                        "competition_checkpoint/tipo_controlo"
                                      ]
                                    }
                                  </span>
                                  <div className="espaco_span" />
                                  <label>
                                    Abastecimento
                                    <span
                                      className="bolder"
                                      style={{ color: "#e60000" }}
                                    >
                                      {" (*)"}
                                    </span>
                                  </label>
                                  <input
                                    style={{
                                      border: this.state.errors[
                                        "competition_checkpoint/abastecimento"
                                      ]
                                        ? border_erro
                                        : "",
                                      textAlign: "center"
                                    }}
                                    type="text"
                                    name="abastecimento"
                                    placeholder="Abastecimento"
                                    onChange={this.handleChange}
                                    defaultValue={this.state.abastecimento}
                                  />
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {
                                      this.state.errors[
                                        "competition_checkpoint/abastecimento"
                                      ]
                                    }
                                  </span>
                                </div>
                                <div className="espaco_span">
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {" (*)"}
                                  </span>{" "}
                                  <span className="bolder">
                                    {" "}
                                    Campos Obrigatórios
                                  </span>
                                </div>
                              </div>
                              {/*}
                              <div className="form">
                                <button type="submit">
                                  Alterar Checkpoint
                                </button>
                              </div>
                              */}
                            </TabPanel>
                          </Tabs>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        );
      }
    }
  }
}

export default Formulario_Edicao_Checkpoint;
