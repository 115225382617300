import React from "react";
import {
  Switch,
  Route //Router
} from "react-router-dom";

//Atletas
import Adicionar_Atleta from "./Atletas/Adicionar_Atleta.js";
import Editar_Atleta from "./Atletas/Editar_Atleta.js";
import Ver_Atletas from "./Atletas/Ver_Atletas.js";

//Atletas Inscritos:
import Adicionar_Inscricao_Atleta from "./Atletas_Inscritos/Adicionar_Inscricao_Atleta.js";
import Editar_Inscricao_Atleta from "./Atletas_Inscritos/Editar_Inscricao_Atleta.js";
import Ver_Atletas_Inscritos_Prova from "./Atletas_Inscritos/Ver_Atletas_Inscritos_Prova.js";
import Ver_Atletas_Inscritos_Todas_Provas from "./Atletas_Inscritos/Ver_Atletas_Inscritos_Todas_Provas.js";

//Checkpoints:
import Adicionar_Checkpoint from "./Checkpoints/Adicionar_Checkpoint.js";
import Editar_Checkpoint from "./Checkpoints/Editar_Checkpoint.js";
import Adicionar_Checkpoint_Prova from "./Checkpoints/Adicionar_Checkpoint_Prova.js";
import Editar_Checkpoint_Prova from "./Checkpoints/Editar_Checkpoint_Prova.js";
import Ver_Checkpoints from "./Checkpoints/Ver_Checkpoints";
import Ver_Checkpoints_Prova from "./Checkpoints/Ver_Checkpoints_Prova";
import Ver_Checkpoints_Todas_Provas from "./Checkpoints/Ver_Checkpoints_Todas_Provas";

//Dashboard Provas:
//import Dashboard_Prova from "./Dashboard_Provas/Dashboard_Prova.js";
//import Dashboard_Todas_Provas from "./Dashboard_Provas/Dashboard_Todas_Provas.js";

//Desistencias:
import Lancar_Desistencia_Atleta from "./Desistencias/Lancar_Desistencia_Atleta.js";

//Eventos e Competicoes:
import Adicionar_Competicao from "./Eventos_Competicoes/Adicionar_Competicao.js";
import Adicionar_Evento from "./Eventos_Competicoes/Adicionar_Evento.js";
import Competicoes from "./Eventos_Competicoes/Competicoes.js";
import Eventos from "./Eventos_Competicoes/Eventos.js";
import Editar_Competicao from "./Eventos_Competicoes/Editar_Competicao.js";
import Editar_Evento from "./Eventos_Competicoes/Editar_Evento.js";

//Login:
import Login from "./Login/Login.js";

//Notificacoes:
import Definicoes_Notificacoes from "./Notificacoes/Definicoes_Notificacoes.js";
import Ver_Notificacoes_Prova from "./Notificacoes/Ver_Notificacoes_Prova.js";
//import Ver_Notificacoes_Provas from "./Notificacoes/Ver_Notificacoes_Provas.js";

//Resultados:
import Adicionar_Resultado_Atleta from "./Resultados/Adicionar_Resultado_Atleta";
import Editar_Resultados_Atleta from "./Resultados/Editar_Resultado_Atleta";
import Editar_Resultados_Atleta_Checkpoint from "./Resultados/Editar_Resultado_Atleta_Checkpoint";
import Ver_Resultados_Atleta from "./Resultados/Ver_Resultados_Atleta.js";
import Ver_Resultados_Checkpoints from "./Resultados/Ver_Resultados_Checkpoints.js";

//Utilizadores:
import Adicionar_Utilizador from "./Utilizadores/Adicionar_Utilizador.js";
import Ver_Perfil_Utilizador from "./Utilizadores/Ver_Perfil.js";
import Ver_Utilizadores from "./Utilizadores/Ver_Utilizadores.js";

//Importar Dados Excel:
import Importar_Dados_Excel from "./Importar_Dados_Excel/Importar_Dados_Excel.js";
import Ver_Erros_Inscricoes_Atletas from "./Importar_Dados_Excel/Ver_Erros_Inscricoes_Atletas.js";

//Copiar Evento e Etc:
import Copiar_Evento from "./Eventos_Competicoes/Copiar_Evento.js";

/*

import Tabela_Lista_Notificacoes from "./Tabelas/Tabela_Lista_Notificacoes.js";
import Tabela_Numero_Atletas_Checkpoint from "./Dashboard_Provas/Tabela_Numero_Atletas_Checkpoint.js";
*/

const Main = props => (
  //<main>
  //<Router>
  <Switch>
    <Route exact path="/" component={Login} />
    <Route exact path="/competicoes" component={Competicoes} />
    <Route exact path="/eventos" component={Eventos} />
    <Route
      exact
      path="/lista_atletas_inscritos_prova"
      component={Ver_Atletas_Inscritos_Prova}
    />
    <Route
      exact
      path="/lista_atletas_inscritos_provas"
      component={Ver_Atletas_Inscritos_Todas_Provas}
    />
    <Route
      exact
      path="/adicionar_inscricao_atleta"
      component={Adicionar_Inscricao_Atleta}
    />
    <Route
      exact
      path="/editar_inscricao_atleta" //Antigo ver_inscricao_atleta
      component={Editar_Inscricao_Atleta}
    />
    <Route
      exact
      path="/lista_checkpoints_prova"
      component={Ver_Checkpoints_Prova}
    />
    <Route
      exact
      path="/lista_checkpoints_provas"
      component={Ver_Checkpoints_Todas_Provas}
    />
    <Route
      exact
      path="/editar_checkpoint_prova"
      component={Editar_Checkpoint_Prova}
    />
    <Route
      exact
      path="/lista_notificacoes_prova"
      component={Ver_Notificacoes_Prova}
    />
    <Route
      exact
      path="/lista_resultados_atleta"
      component={Ver_Resultados_Atleta}
    />
    <Route
      exact
      path="/lista_resultados_checkpoints"
      component={Ver_Resultados_Checkpoints}
    />
    <Route
      exact
      path="/editar_resultados_atleta"
      component={Editar_Resultados_Atleta}
    />
    <Route
      exact
      path="/editar_resultados_atleta_checkpoint"
      component={Editar_Resultados_Atleta_Checkpoint}
    />
    <Route exact path="/editar_competicao" component={Editar_Competicao} />
    <Route
      exact
      path="/adicionar_resultado_atleta"
      component={Adicionar_Resultado_Atleta}
    />
    <Route
      exact
      path="/adicionar_competicao"
      component={Adicionar_Competicao}
    />
    <Route exact path="/adicionar_evento" component={Adicionar_Evento} />
    <Route exact path="/editar_evento" component={Editar_Evento} />
    <Route
      exact
      path="/adicionar_checkpoint_prova"
      component={Adicionar_Checkpoint_Prova}
    />
    <Route
      exact
      path="/adicionar_checkpoint"
      component={Adicionar_Checkpoint}
    />
    <Route exact path="/adicionar_atleta" component={Adicionar_Atleta} />

    <Route exact path="/editar_checkpoint" component={Editar_Checkpoint} />

    <Route exact path="/editar_atleta" component={Editar_Atleta} />

    <Route exact path="/ver_atletas" component={Ver_Atletas} />

    <Route exact path="/ver_checkpoints" component={Ver_Checkpoints} />

    <Route exact path="/importar_dados" component={Importar_Dados_Excel} />

    <Route exact path="/copiar_evento" component={Copiar_Evento} />

    <Route
      exact
      path="/adicionar_utilizador"
      component={Adicionar_Utilizador}
    />

    <Route exact path="/ver_utilizadores" component={Ver_Utilizadores} />

    <Route
      exact
      path="/ver_erros_inscricoes"
      component={Ver_Erros_Inscricoes_Atletas}
    />

    <Route
      exact
      path="/definicoes_notificacoes"
      component={Definicoes_Notificacoes}
    />

    <Route
      exact
      path="/lancar_desistencia_atleta"
      component={Lancar_Desistencia_Atleta}
    />

    <Route exact path="/ver_perfil" component={Ver_Perfil_Utilizador} />
  </Switch>
  //</Router>
  //</main>
);

export default Main;
