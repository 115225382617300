import React, { Component } from "react";
//import { Link } from "react-router-dom";
import axios from "axios";
import Moment from "moment";
import Drawer from "../Drawer/Drawer.js";
import Select from "react-select";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

//Funções Auxiliares:
import {
  mostra_mensagem_erro,
  //ve_resultados,
  Trata_Validacoes,
  isEmptyObject
} from "../Funcoes_Auxiliares/Funcoes_Auxiliares.js";

let REACT_APP_API_URL;

if (process.env.REACT_APP_ESTADO === "Deploy") {
  REACT_APP_API_URL = process.env.REACT_APP_API_URL_PROD;
} //Development
else {
  REACT_APP_API_URL = process.env.REACT_APP_API_URL_DEV;
}

const opcoes_select_gender = [
  { value: "M", label: "Masculino" },
  { value: "F", label: "Feminino" }
];

const Opcoes_Regional = [
  { value: 1, label: "Sim" },
  { value: 0, label: "Não" }
];

class Formulario_Adicionar_Inscricao_Atleta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Link_Tabela_Anterior: null,
      Link_Menu_Anterior: null,
      ID_Evento: null,
      ID_Prova: null,
      Nome_Evento: null,
      Nome_Prova: null,
      //Nome_Atleta: null,

      Escaloes_Lidos: undefined, //[],
      Estados_Inscricao_Lidos: undefined, //[],
      Dados_Prova_Lidos: undefined, //[],
      Atletas_Lidos: undefined, //[],
      Nacionaliades: undefined,

      Nome_Prova1: null,
      Nome_Evento1: null,

      nome: null,
      email: null,
      birthday: null,
      gender: null,
      regional: null,
      language: null,
      identification_type: null,
      identification_id: null,
      phone_number1: null,
      phone_number2: null,
      phone_number3: null,
      postal_code: null,
      address: null,
      city: null,
      previous_participated_events: null,
      nif: null,
      atrp_number: null,
      raking_name: null,
      extra_info: null,
      previous_participated_event: null,
      previous_participated_event_link: null,
      extra_info2: null,
      raking_name_last: null,
      fcmp_number: null,
      name_phone_number3: null,
      password: null,
      isf_number: null,
      linha_nacionalidade: null,

      id_atleta: null,
      rf_id: null,
      rf_id2: null,
      rf_id3: null,
      rf_id4: null,
      dorsal: null,
      estado_atleta: null,
      escalao: null,
      clube: null,
      codigo_estado_inscricao: null,
      patrocinador: null,
      //sigla_clube: null,

      //Variavel Controlo de Erro na Leitura dos Props
      Mostra_Erro: false,

      //Variaveis Para as Opcoes de Select:
      Opcao_Escolhida_Select_Escalao: null,
      Opcao_Escolhida_Select_Estado_Atleta: null,
      Opcao_Escolhida_Select_Codigo_Estado_Inscricao: null,
      Opcao_Escolhida_Select_Sexo: null,
      Opcao_Escolhida_Select_Nacionalidade: null,

      //Variavel Para Controlar o Redirect
      Inserido_Sucesso: false, //false

      //Lista Para os Erros de Validação:
      errors: {}
    };

    if (props.location.state === undefined) {
      this.state.Mostra_Erro = true;
    } else {
      if (props.location.state.Link_Tabela_Anterior === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Link_Tabela_Anterior =
          props.location.state.Link_Tabela_Anterior;
      }
      if (props.location.state.Link_Menu_Anterior === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Link_Menu_Anterior = props.location.state.Link_Menu_Anterior;
      }
      if (props.location.state.ID_Evento === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.ID_Evento = props.location.state.ID_Evento;
      }
      if (props.location.state.ID_Prova === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.ID_Prova = props.location.state.ID_Prova;
      }
      if (props.location.state.Nome_Evento === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Nome_Evento = props.location.state.Nome_Evento;
      }
      if (props.location.state.Nome_Prova === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Nome_Prova = props.location.state.Nome_Prova;
      }
      /*
      if (props.location.state.Nome_Atleta === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Nome_Atleta = props.location.state.Nome_Atleta;
      }
      */
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  Vai_Buscar_Dados_Prova() {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .get(
        REACT_APP_API_URL +
          "/devolve_nome_prova_e_evento/" +
          this.state.ID_Prova,
        {
          headers: { authorization: "Bearer " + Token_Lido }
        }
      )
      .then(res => {
        console.log(res);
        this.setState({
          Dados_Prova_Lidos: res.data,
          Nome_Evento1: res.data[0].name,
          Nome_Prova1: res.data[0].long_name //Dados_Prova_Lidos: res.data
        });
      });
  }

  Vai_Buscar_Dados_Escaloes() {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .get(REACT_APP_API_URL + "/devolve_escaloes", {
        headers: { authorization: "Bearer " + Token_Lido }
      })
      .then(res => {
        console.log(res);
        this.setState({ Escaloes_Lidos: res.data });
      });
  }

  Vai_Buscar_Estados_Inscricao() {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .get(REACT_APP_API_URL + "/devolve_estados_inscricao", {
        headers: { authorization: "Bearer " + Token_Lido }
      })
      .then(res => {
        console.log(res);
        this.setState({ Estados_Inscricao_Lidos: res.data });
      });
  }

  Vai_Buscar_Atletas() {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .get(REACT_APP_API_URL + "/devolve_atletas_sistema", {
        headers: { authorization: "Bearer " + Token_Lido }
      })
      .then(res => {
        console.log(res);
        this.setState({ Atletas_Lidos: res.data });
      });
  }

  Vai_Buscar_Paises() {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .get(REACT_APP_API_URL + "/devolve_paises_sistema", {
        headers: { authorization: "Bearer " + Token_Lido }
      })
      .then(res => {
        console.log(res);
        this.setState({ Nacionaliades: res.data });
      });
  }

  componentDidMount() {
    if (localStorage.getItem("Token") !== null) {
      //this.Vai_Buscar_Atletas();
      this.Vai_Buscar_Dados_Prova();
      this.Vai_Buscar_Dados_Escaloes();
      this.Vai_Buscar_Estados_Inscricao();
      this.Vai_Buscar_Paises();
    }
  } //Fecha Procedimento Que Acontece Depois do Output do Componente Foi Rendered

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleChangeSelectRegional = regional => {
    this.setState({ regional });
  };

  handleChangeSelectSexo = Opcao_Escolhida_Select_Sexo => {
    this.setState({ Opcao_Escolhida_Select_Sexo });
  };

  handleChangeSelectNacionalidade = Opcao_Escolhida_Select_Nacionalidade => {
    this.setState({ Opcao_Escolhida_Select_Nacionalidade });
  };

  /*
  handleChangeSelectRegional = regional => {
    this.setState({ regional });
  };
  */

  handleChangeSelectEscalao = escalao => {
    this.setState({ escalao });
  };

  handleChangeSelectEstadoAtleta = estado_atleta => {
    this.setState({ estado_atleta });
  };

  handleChangeSelectCodigoEstadoInscricao = codigo_estado_inscricao => {
    this.setState({ codigo_estado_inscricao });
  };

  handleValidation() {
    let errors = {};
    let formIsValid = false; //true;

    //Validacoes da Tabela "Athlete"

    //Para Cada Campo Invocar o Trata_Validacoes
    errors = Trata_Validacoes(this.state.nome, "athlete/nome", errors);
    errors = Trata_Validacoes(this.state.email, "athlete/email", errors);
    errors = Trata_Validacoes(this.state.birthday, "athlete/birthday", errors);
    //errors = Trata_Validacoes(this.state.gender, "athlete/gender", errors);
    errors = Trata_Validacoes(
      this.state.Opcao_Escolhida_Select_Sexo,
      "athlete/gender",
      errors
    );
    errors = Trata_Validacoes(
      this.state.raking_name,
      "athlete/raking_name",
      errors
    );
    errors = Trata_Validacoes(
      this.state.raking_name_last,
      "athlete/raking_name_last",
      errors
    );
    /*
    errors = Trata_Validacoes(this.state.regional, "athlete/regional", errors);
    errors = Trata_Validacoes(this.state.language, "athlete/language", errors);
    errors = Trata_Validacoes(
      this.state.identification_type,
      "athlete/identification_type",
      errors
    );
    errors = Trata_Validacoes(
      this.state.identification_id,
      "athlete/identification_id",
      errors
    );
    errors = Trata_Validacoes(
      this.state.phone_number1,
      "athlete/phone_number1",
      errors
    );
    errors = Trata_Validacoes(
      this.state.phone_number2,
      "athlete/phone_number2",
      errors
    );
    */
    errors = Trata_Validacoes(
      this.state.phone_number3,
      "athlete/phone_number3",
      errors
    );
    /*
    errors = Trata_Validacoes(
      this.state.postal_code,
      "athlete/postal_code",
      errors
    );
    errors = Trata_Validacoes(this.state.address, "athlete/address", errors);
    */
    errors = Trata_Validacoes(this.state.city, "athlete/city", errors);
    /*
    errors = Trata_Validacoes(
      this.state.previous_participated_events,
      "athlete/previous_participated_events",
      errors
    );
    errors = Trata_Validacoes(this.state.nif, "athlete/nif", errors);
    errors = Trata_Validacoes(
      this.state.atrp_number,
      "athlete/atrp_number",
      errors
    );
    errors = Trata_Validacoes(
      this.state.extra_info,
      "athlete/extra_info",
      errors
    );
    errors = Trata_Validacoes(
      this.state.previous_participated_event,
      "athlete/previous_participated_event",
      errors
    );
    errors = Trata_Validacoes(
      this.state.previous_participated_event_link,
      "athlete/previous_participated_event_link",
      errors
    );
    errors = Trata_Validacoes(
      this.state.extra_info2,
      "athlete/extra_info2",
      errors
    );
    errors = Trata_Validacoes(
      this.state.fcmp_number,
      "athlete/fcmp_number",
      errors
    );
    */
    errors = Trata_Validacoes(
      this.state.name_phone_number3,
      "athlete/name_phone_number3",
      errors
    );
    /*
    errors = Trata_Validacoes(this.state.password, "athlete/password", errors);
    errors = Trata_Validacoes(this.state.isf_number,"athlete/isf_number",errors);
    */

    errors = Trata_Validacoes(
      //this.state.linha_nacionalidade,
      this.state.Opcao_Escolhida_Select_Nacionalidade,
      "athlete/linha_nacionalidade",
      errors
    );

    // Validacoes da Tabela "Inscription"

    errors = Trata_Validacoes(
      this.state.dorsal,
      "inscription/dorsal_number",
      errors
    );
    errors = Trata_Validacoes(
      this.state.escalao,
      "inscription/category",
      errors
    );
    errors = Trata_Validacoes(this.state.clube, "inscription/club", errors);
    errors = Trata_Validacoes(
      this.state.estado_atleta,
      "inscription/athlete_status",
      errors
    );
    errors = Trata_Validacoes(
      this.state.rf_id,
      "inscription/rf_id_number",
      errors
    );
    errors = Trata_Validacoes(
      this.state.rf_id2,
      "inscription/rf_id_number2",
      errors
    );
    errors = Trata_Validacoes(
      this.state.rf_id3,
      "inscription/rf_id_number3",
      errors
    );
    errors = Trata_Validacoes(
      this.state.rf_id4,
      "inscription/rf_id_number4",
      errors
    );
    //errors = Trata_Validacoes(this.state.patrocinador,"inscription/sponsor",errors);
    //errors = Trata_Validacoes(this.state.sigla_clube,"inscription/club_sigla",errors);
    errors = Trata_Validacoes(
      this.state.codigo_estado_inscricao,
      "inscription/inscription_status_code",
      errors
    );

    //if (errors.length > 0) {
    if (isEmptyObject(errors)) {
      formIsValid = true; //false;
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.handleValidation()) {
      const Token_Lido = localStorage.getItem("Token");
      axios
        .post(
          REACT_APP_API_URL + "/inserir_inscricao2/",
          {
            //Parte da Informação do Atleta

            nome: this.state.nome,
            email: this.state.email,
            birthday: Moment(this.state.birthday, "YYYY-MM-DD"), //this.state.birthday,
            gender: this.state.Opcao_Escolhida_Select_Sexo.value, //this.state.gender.value, //Vai Receber um Objecto [{Value,Label}]. Pomos .value Porque So Queremos o Value
            nacionality: this.state.Opcao_Escolhida_Select_Nacionalidade
              .Valor_name_pt, //this.state.linha_nacionalidade.Valor_name_pt, //this.state.nacionality,
            //linha_nacionalidade:this.state.linha_nacionalidade,
            regional: this.state.regional ? this.state.regional.value : "0",
            language: this.state.language,
            identification_type: this.state.identification_type,
            identification_id: this.state.identification_id,
            phone_number1: this.state.phone_number1,
            phone_number2: this.state.phone_number2,
            phone_number3: this.state.phone_number3,
            postal_code: this.state.postal_code,
            address: this.state.address,
            city: this.state.city,
            country: this.state.Opcao_Escolhida_Select_Nacionalidade.Valor_ioc, // this.state.linha_nacionalidade.Valor_ioc, //this.state.country,
            previous_participated_events: this.state
              .previous_participated_events,
            nif: this.state.nif,
            atrp_number: this.state.atrp_number,
            raking_name: this.state.raking_name,
            extra_info: this.state.extra_info,
            previous_participated_event: this.state.previous_participated_event,
            previous_participated_event_link: this.state
              .previous_participated_event_link,
            extra_info2: this.state.extra_info2,
            raking_name_last: this.state.raking_name_last,
            fcmp_number: this.state.fcmp_number,
            name_phone_number3: this.state.name_phone_number3,
            password: this.state.password,
            isf_number: this.state.isf_number,

            //Parte da Informação da Inscricao
            id_atleta: this.state.id_atleta,
            id_competicao: this.state.ID_Prova,
            rf_id: this.state.rf_id,
            rf_id2: this.state.rf_id2,
            rf_id3: this.state.rf_id3,
            rf_id4: this.state.rf_id4,
            dorsal: this.state.dorsal,
            estado_atleta: this.state.estado_atleta.value,
            escalao: this.state.escalao.value,
            clube: this.state.clube,
            codigo_estado_inscricao: this.state.codigo_estado_inscricao.value,
            patrocinador: this.state.patrocinador
            //sigla_clube: this.state.sigla_clube
            //estado_atleta: this.state.Opcao_Escolhida_Select_Estado_Atleta.value, //this.state.estado_atleta,
            //escalao: this.state.Opcao_Escolhida_Select_Escalao.value, //this.state.escalao,
            //codigo_estado_inscricao: this.state.Opcao_Escolhida_Select_Codigo_Estado_Inscricao.value, //this.state.codigo_estado_inscricao,
          },
          {
            headers: { authorization: "Bearer " + Token_Lido }
          }
        )
        .then(res => {
          //console.log(res);
          this.setState({ Inserido_Sucesso: res.data.success });
        });
    }
  }

  render() {
    if (localStorage.getItem("Token") === null) {
      this.props.history.push({
        pathname: "/"
      });
    }

    if (this.state.Inserido_Sucesso === true) {
      this.props.history.push({
        pathname: this.state.Link_Tabela_Anterior,
        state: {
          Link_Menu_Anterior: this.state.Link_Menu_Anterior,
          ID_Evento: this.state.ID_Evento,
          ID_Prova: this.state.ID_Prova,
          Fez_Insercao_Inscricao_Atleta_Sistema: true,
          Fez_Edicao_Dados_Atleta_Sistema: false,
          Fez_Edicao_Inscricao_Atleta_Sistema: false,
          Fez_Lancamento_Desistencia_Sucesso: false,
          Fez_Eliminacao_Inscricao_Sucesso: false,
          Faz_Refresh: true,
          Nome_Evento: this.state.Nome_Evento,
          Nome_Prova: this.state.Nome_Prova
        }
      });
    }

    //Verifica Se Existe Erro. Se Houver Mostra Mensagem de Erro
    if (this.state.Mostra_Erro === true) {
      return (
        <div>
          <Drawer
            Titulo_Menu="" //"Erro"
            history={this.props.history}
          />
          {mostra_mensagem_erro()}
        </div>
      );
    } else {
      if (
        this.state.Escaloes_Lidos === undefined ||
        this.state.Estados_Inscricao_Lidos === undefined ||
        this.state.Dados_Prova_Lidos === undefined || // ||this.state.Atletas_Lidos === undefined
        this.state.Nacionaliades === undefined
      ) {
        return (
          <div className="centra_loading">
            <h1>Loading...</h1>
          </div>
        );
      } else {
        var Opcoes_Codigo_Estado_Inscricao = [];
        //this.state.Estados_Inscricao_Lidos.map(linha2 => {
        this.state.Estados_Inscricao_Lidos.forEach(function(linha2) {
          var Linha = {
            value: linha2.code,
            label: linha2.description + " (" + linha2.code + ")"
          };
          Opcoes_Codigo_Estado_Inscricao.push(Linha);
        });

        var Opcoes_Escalao = [];
        //this.state.Escaloes_Lidos.map(linha3 => {
        this.state.Escaloes_Lidos.forEach(function(linha3) {
          var Linha2 = {
            value: linha3.categorie_id,
            label: linha3.categorie_id
          };
          Opcoes_Escalao.push(Linha2);
        });

        var Opcoes_Nacionalidade = [];
        //this.state.Nacionaliades.map(linha4 => {
        this.state.Nacionaliades.forEach(function(linha4) {
          var Linha3 = {
            Valor_ioc: linha4.ioc,
            Valor_name_pt: linha4.name_pt,
            label: linha4.name_pt
          };
          Opcoes_Nacionalidade.push(Linha3);
        });

        const {
          //Opcao_Escolhida_Select_Escalao,
          //Opcao_Escolhida_Select_Estado_Atleta,
          //Opcao_Escolhida_Select_Codigo_Estado_Inscricao,
          Opcao_Escolhida_Select_Sexo,
          Opcao_Escolhida_Select_Nacionalidade,

          estado_atleta,
          escalao,
          codigo_estado_inscricao
          //regional
        } = this.state;

        const border_erro = "1px solid #ff0000";

        return (
          <div>
            <Drawer
              Titulo_Menu="Inserir Inscrição Atleta"
              Link_Tabela_Anterior={this.state.Link_Tabela_Anterior}
              Link_Menu_Anterior={this.state.Link_Menu_Anterior}
              ID_Evento={this.state.ID_Evento}
              ID_Prova={this.state.ID_Prova}
              Nome_Evento={this.state.Nome_Evento}
              Nome_Prova={this.state.Nome_Prova}
              Nome_Atleta={this.state.Nome_Atleta}
              history={this.props.history}
            />
            <div className="novas_margens_volta_novos_forms">
              <div className="centra_novos_forms">
                <form className="login-form" onSubmit={this.handleSubmit}>
                  <div className="centra_titulos_tabs">
                    <Tabs>
                      <TabList>
                        <Tab
                          style={{
                            border:
                              this.state.errors["athlete/nome"] ||
                              this.state.errors["inscription/dorsal_number"] ||
                              this.state.errors[
                                "inscription/inscription_status_code"
                              ] ||
                              this.state.errors["inscription/category"] ||
                              this.state.errors["inscription/club"] ||
                              this.state.errors["inscription/athlete_status"]
                                ? border_erro
                                : ""
                          }}
                        >
                          Informação do Atleta
                        </Tab>
                        <Tab
                          style={{
                            border:
                              this.state.errors["inscription/rf_id_number"] ||
                              this.state.errors["inscription/rf_id_number2"] ||
                              this.state.errors["inscription/rf_id_number3"] ||
                              this.state.errors["inscription/rf_id_number4"]
                                ? border_erro
                                : ""
                          }}
                        >
                          Identificadores
                        </Tab>

                        <Tab
                          style={{
                            border:
                              this.state.errors["athlete/email"] ||
                              this.state.errors["athlete/birthday"] ||
                              this.state.errors["athlete/gender"] ||
                              this.state.errors[
                                "athlete/linha_nacionalidade"
                              ] ||
                              this.state.errors["athlete/raking_name"] ||
                              this.state.errors["athlete/raking_name_last"] ||
                              this.state.errors["athlete/phone_number3"] ||
                              this.state.errors["athlete/city"] ||
                              this.state.errors["athlete/name_phone_number3"]
                                ? border_erro
                                : ""
                          }}
                        >
                          Mais Informação do Atleta
                        </Tab>
                        <button type="submit" style={{ marginLeft: "10px" }}>
                          Adicionar Inscrição
                        </button>
                      </TabList>
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      <TabPanel>
                        <div className="form">
                          <div>
                            <label>
                              Nome Atleta
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              >
                                {" (*)"}
                              </span>
                            </label>
                            <input
                              style={{
                                border: this.state.errors["athlete/nome"]
                                  ? border_erro
                                  : "",
                                textAlign: "center"
                              }}
                              type="text"
                              name="nome"
                              placeholder="Nome Atleta"
                              onChange={this.handleChange}
                              defaultValue={this.state.nome}
                            />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {this.state.errors["athlete/nome"]}
                            </span>
                            <div className="espaco_span" />

                            {
                              //Dados Para a Tabela Inscription:
                              //Nota: ID_Atleta Vai Ser Obtido Inserindo os Dados do Atleta 1º e Depois Obter o ID da Ultima Insercao
                            }

                            <label>
                              Dorsal
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              >
                                {" (*)"}
                              </span>
                            </label>
                            <input
                              style={{
                                border: this.state.errors[
                                  "inscription/dorsal_number"
                                ]
                                  ? border_erro
                                  : "",
                                textAlign: "center"
                              }}
                              type="number"
                              name="dorsal"
                              placeholder="Dorsal"
                              onChange={this.handleChange}
                              defaultValue={this.state.dorsal}
                            />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {this.state.errors["inscription/dorsal_number"]}
                            </span>
                            <div className="espaco_span" />

                            <label>
                              Estado da Inscrição
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              >
                                {" (*)"}
                              </span>
                            </label>
                            <div
                              style={{
                                border: this.state.errors[
                                  "inscription/inscription_status_code"
                                ]
                                  ? border_erro
                                  : ""
                              }}
                            >
                              <Select
                                value={
                                  codigo_estado_inscricao //Opcao_Escolhida_Select_Codigo_Estado_Inscricao
                                }
                                name="codigo_estado_inscricao"
                                placeholder="Estado da Inscrição"
                                onChange={
                                  this.handleChangeSelectCodigoEstadoInscricao
                                }
                                options={Opcoes_Codigo_Estado_Inscricao}
                              />
                            </div>
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {
                                this.state.errors[
                                  "inscription/inscription_status_code"
                                ]
                              }
                            </span>
                            <div className="espaco_span" />

                            <label>
                              Escalão:
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              >
                                {" (*)"}
                              </span>
                            </label>
                            <div
                              style={{
                                border: this.state.errors[
                                  "inscription/category"
                                ]
                                  ? border_erro
                                  : ""
                              }}
                            >
                              <Select
                                value={
                                  escalao //Opcao_Escolhida_Select_Escalao
                                }
                                name="escalao"
                                placeholder="Escalão"
                                onChange={this.handleChangeSelectEscalao}
                                options={Opcoes_Escalao}
                              />
                            </div>
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {this.state.errors["inscription/category"]}
                            </span>
                            <div className="espaco_span" />
                            <label>
                              Clube Atleta
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              >
                                {" (*)"}
                              </span>
                            </label>
                            <input
                              style={{
                                border: this.state.errors["inscription/club"]
                                  ? border_erro
                                  : "",
                                textAlign: "center"
                              }}
                              type="text"
                              name="clube"
                              placeholder="Clube"
                              onChange={this.handleChange}
                              defaultValue={this.state.clube}
                              //required
                            />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {this.state.errors["inscription/club"]}
                            </span>
                            <div className="espaco_span" />
                            {/*}
                            <label>
                              Sigla Clube
                            </label>
                            <input
                              style={{
                                border: this.state.errors[
                                  "inscription/club_sigla"
                                ]
                                  ? border_erro
                                  : "",
                                textAlign: "center"
                              }}
                              type="text"
                              name="sigla_clube"
                              placeholder="Sigla do Clube"
                              onChange={this.handleChange}
                              defaultValue={this.state.sigla_clube}
                            />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {this.state.errors["inscription/club_sigla"]}
                            </span>
                            <div className="espaco_span" />
                            */}

                            <label>Patrocinador</label>
                            <input
                              style={{
                                border: this.state.errors["inscription/sponsor"]
                                  ? border_erro
                                  : "",
                                textAlign: "center"
                              }}
                              type="text"
                              name="patrocinador"
                              placeholder="Patrocinador"
                              onChange={this.handleChange}
                              defaultValue={this.state.patrocinador}
                            />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {this.state.errors["inscription/sponsor"]}
                            </span>
                            <div className="espaco_span" />
                            <label>
                              Estado na Competição:
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              >
                                {" (*)"}
                              </span>
                            </label>
                            <div
                              style={{
                                border: this.state.errors[
                                  "inscription/athlete_status"
                                ]
                                  ? border_erro
                                  : ""
                              }}
                            >
                              <Select
                                value={
                                  estado_atleta //Opcao_Escolhida_Select_Estado_Atleta
                                }
                                name="estado_atleta"
                                placeholder="Estado na Competição"
                                onChange={this.handleChangeSelectEstadoAtleta}
                                options={Opcoes_Codigo_Estado_Inscricao}
                              />
                            </div>
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {this.state.errors["inscription/athlete_status"]}
                            </span>
                            <div className="espaco_span" />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {" (*)"}
                            </span>
                            <span className="bolder"> Campos Obrigatórios</span>
                          </div>
                        </div>
                        {/*}
                        <div className="form">
                          <button type="submit">Adicionar Inscrição</button>
                        </div>
                        */}
                      </TabPanel>
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}

                      <TabPanel>
                        <div className="form">
                          <div>
                            <label>
                              RF ID
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              >
                                {" (*)"}
                              </span>
                            </label>
                            <input
                              style={{
                                border: this.state.errors[
                                  "inscription/rf_id_number"
                                ]
                                  ? border_erro
                                  : "",
                                textAlign: "center"
                              }}
                              type="text"
                              name="rf_id"
                              placeholder="RF ID"
                              onChange={this.handleChange}
                              defaultValue={this.state.rf_id}
                            />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {this.state.errors["inscription/rf_id_number"]}
                            </span>
                            <div className="espaco_span" />

                            <label>
                              RF ID 2
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              >
                                {" (*)"}
                              </span>
                            </label>
                            <input
                              style={{
                                border: this.state.errors[
                                  "inscription/rf_id_number2"
                                ]
                                  ? border_erro
                                  : "",
                                textAlign: "center"
                              }}
                              type="text"
                              name="rf_id2"
                              placeholder="RF ID 2"
                              onChange={this.handleChange}
                              defaultValue={this.state.rf_id2}
                            />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {this.state.errors["inscription/rf_id_number2"]}
                            </span>
                            <div className="espaco_span" />

                            <label>
                              RF ID 3
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              >
                                {" (*)"}
                              </span>
                            </label>
                            <input
                              style={{
                                border: this.state.errors[
                                  "inscription/rf_id_number3"
                                ]
                                  ? border_erro
                                  : "",
                                textAlign: "center"
                              }}
                              type="text"
                              name="rf_id3"
                              placeholder="RF ID 3"
                              onChange={this.handleChange}
                              defaultValue={this.state.rf_id3}
                            />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {this.state.errors["inscription/rf_id_number3"]}
                            </span>
                            <div className="espaco_span" />

                            <label>
                              RF ID 4
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              >
                                {" (*)"}
                              </span>
                            </label>
                            <input
                              style={{
                                border: this.state.errors[
                                  "inscription/rf_id_number4"
                                ]
                                  ? border_erro
                                  : "",
                                textAlign: "center"
                              }}
                              type="text"
                              name="rf_id4"
                              placeholder="RF ID 4"
                              onChange={this.handleChange}
                              defaultValue={this.state.rf_id4}
                            />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {this.state.errors["inscription/rf_id_number4"]}
                            </span>
                            <div className="espaco_span" />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {" (*)"}
                            </span>
                            <span className="bolder"> Campos Obrigatórios</span>
                          </div>
                        </div>
                        {/*}
                        <div className="form">
                          <button type="submit">Adicionar Inscrição</button>
                        </div>
                        */}
                      </TabPanel>
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}

                      <TabPanel>
                        <div className="form">
                          <div>
                            <label>
                              Email
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              >
                                {" (*)"}
                              </span>
                            </label>
                            <input
                              style={{
                                border: this.state.errors["athlete/email"]
                                  ? border_erro
                                  : "",
                                textAlign: "center"
                              }}
                              type="text"
                              name="email"
                              placeholder="Email"
                              onChange={this.handleChange}
                              defaultValue={this.state.email}
                            />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {this.state.errors["athlete/email"]}
                            </span>
                            <div className="espaco_span" />

                            <label>
                              Data Nascimento
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              >
                                {" (*)"}
                              </span>
                            </label>
                            <input
                              style={{
                                border: this.state.errors["athlete/birthday"]
                                  ? border_erro
                                  : "",
                                textAlign: "center"
                              }}
                              type="date"
                              name="birthday"
                              onChange={this.handleChange}
                              defaultValue={this.state.birthday}
                            />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {this.state.errors["athlete/birthday"]}
                            </span>
                            <div className="espaco_span" />

                            <label>
                              Sexo
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              >
                                {" (*)"}
                              </span>
                            </label>
                            <div
                              style={{
                                border: this.state.errors["athlete/gender"]
                                  ? border_erro
                                  : ""
                              }}
                            >
                              <Select
                                value={Opcao_Escolhida_Select_Sexo}
                                name="gender"
                                placeholder="Sexo"
                                onChange={this.handleChangeSelectSexo}
                                options={opcoes_select_gender}
                              />
                            </div>
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {this.state.errors["athlete/gender"]}
                            </span>
                            <div className="espaco_span" />

                            <label>
                              Nacionalidade:
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              >
                                {" (*)"}
                              </span>
                            </label>
                            <div
                              style={{
                                border: this.state.errors[
                                  "athlete/linha_nacionalidade"
                                ]
                                  ? border_erro
                                  : ""
                              }}
                            >
                              <Select
                                value={Opcao_Escolhida_Select_Nacionalidade}
                                name="linha_nacionalidade"
                                placeholder="Nacionalidade"
                                onChange={this.handleChangeSelectNacionalidade}
                                options={Opcoes_Nacionalidade}
                              />
                            </div>
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {this.state.errors["athlete/linha_nacionalidade"]}
                            </span>
                            <div className="espaco_span" />

                            <label>
                              Nome Ranking
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              >
                                {" (*)"}
                              </span>
                            </label>
                            <input
                              style={{
                                border: this.state.errors["athlete/raking_name"]
                                  ? border_erro
                                  : "",
                                textAlign: "center"
                              }}
                              type="text"
                              name="raking_name"
                              placeholder="Nome Ranking"
                              onChange={this.handleChange}
                              defaultValue={this.state.raking_name}
                            />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {this.state.errors["athlete/raking_name"]}
                            </span>
                            <div className="espaco_span" />

                            <label>
                              Último Nome Ranking
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              >
                                {" (*)"}
                              </span>
                            </label>
                            <input
                              style={{
                                border: this.state.errors[
                                  "athlete/raking_name_last"
                                ]
                                  ? border_erro
                                  : "",
                                textAlign: "center"
                              }}
                              type="text"
                              name="raking_name_last"
                              placeholder="Último Nome Ranking"
                              onChange={this.handleChange}
                              defaultValue={this.state.raking_name_last}
                            />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {this.state.errors["athlete/raking_name_last"]}
                            </span>
                            <div className="espaco_span" />

                            <label>
                              Tipo Identificação
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              />
                            </label>
                            <input
                              style={{
                                border: this.state.errors[
                                  "athlete/identification_type"
                                ]
                                  ? border_erro
                                  : "",
                                textAlign: "center"
                              }}
                              type="text"
                              name="identification_type"
                              placeholder="Tipo Identificação"
                              onChange={this.handleChange}
                              defaultValue={this.state.identification_type}
                            />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {this.state.errors["athlete/identification_type"]}
                            </span>
                            <div className="espaco_span" />

                            <label>
                              ID Identificação
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              />
                            </label>
                            <input
                              style={{
                                border: this.state.errors[
                                  "athlete/identification_id"
                                ]
                                  ? border_erro
                                  : "",
                                textAlign: "center"
                              }}
                              type="text"
                              name="identification_id"
                              placeholder="ID Identificação"
                              onChange={this.handleChange}
                              defaultValue={this.state.identification_id}
                            />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {this.state.errors["athlete/identification_id"]}
                            </span>
                            <div className="espaco_span" />

                            <label>
                              Telefone 1
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              />
                            </label>
                            <input
                              style={{
                                border: this.state.errors[
                                  "athlete/phone_number1"
                                ]
                                  ? border_erro
                                  : "",
                                textAlign: "center"
                              }}
                              type="text"
                              name="phone_number1"
                              placeholder="Telefone 1"
                              onChange={this.handleChange}
                              defaultValue={this.state.phone_number1}
                            />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {this.state.errors["athlete/phone_number1"]}
                            </span>
                            <div className="espaco_span" />

                            <label>
                              Telefone 2
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              />
                            </label>
                            <input
                              style={{
                                border: this.state.errors[
                                  "athlete/phone_number2"
                                ]
                                  ? border_erro
                                  : "",
                                textAlign: "center"
                              }}
                              type="text"
                              name="phone_number2"
                              placeholder="Telefone 2"
                              onChange={this.handleChange}
                              defaultValue={this.state.phone_number2}
                            />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {this.state.errors["athlete/phone_number2"]}
                            </span>
                            <div className="espaco_span" />

                            <label>
                              Telefone 3
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              >
                                {" (*)"}
                              </span>
                            </label>
                            <input
                              style={{
                                border: this.state.errors[
                                  "athlete/phone_number3"
                                ]
                                  ? border_erro
                                  : "",
                                textAlign: "center"
                              }}
                              type="text"
                              name="phone_number3"
                              placeholder="Telefone 3"
                              onChange={this.handleChange}
                              defaultValue={this.state.phone_number3}
                            />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {this.state.errors["athlete/phone_number3"]}
                            </span>
                            <div className="espaco_span" />

                            <label>
                              Código Postal
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              />
                            </label>
                            <input
                              style={{
                                border: this.state.errors["athlete/postal_code"]
                                  ? border_erro
                                  : "",
                                textAlign: "center"
                              }}
                              type="text"
                              name="postal_code"
                              placeholder="Código Postal"
                              onChange={this.handleChange}
                              defaultValue={this.state.postal_code}
                            />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {this.state.errors["athlete/postal_code"]}
                            </span>
                            <div className="espaco_span" />

                            <label>
                              Morada
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              />
                            </label>
                            <input
                              style={{
                                border: this.state.errors["athlete/address"]
                                  ? border_erro
                                  : "",
                                textAlign: "center"
                              }}
                              type="text"
                              name="address"
                              placeholder="Morada"
                              onChange={this.handleChange}
                              defaultValue={this.state.address}
                            />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {this.state.errors["athlete/address"]}
                            </span>
                            <div className="espaco_span" />

                            <label>
                              Cidade
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              >
                                {" (*)"}
                              </span>
                            </label>
                            <input
                              style={{
                                border: this.state.errors["athlete/city"]
                                  ? border_erro
                                  : "",
                                textAlign: "center"
                              }}
                              type="text"
                              name="city"
                              placeholder="Cidade"
                              onChange={this.handleChange}
                              defaultValue={this.state.city}
                            />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {this.state.errors["athlete/city"]}
                            </span>
                            <div className="espaco_span" />

                            <label>
                              NIF
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              />
                            </label>
                            <input
                              style={{
                                border: this.state.errors["athlete/nif"]
                                  ? border_erro
                                  : "",
                                textAlign: "center"
                              }}
                              type="text"
                              name="nif"
                              placeholder="NIF"
                              onChange={this.handleChange}
                              defaultValue={this.state.nif}
                            />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {this.state.errors["athlete/nif"]}
                            </span>
                            <div className="espaco_span" />

                            <label>
                              Número ATRP
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              />
                            </label>
                            <input
                              style={{
                                border: this.state.errors["athlete/atrp_number"]
                                  ? border_erro
                                  : "",
                                textAlign: "center"
                              }}
                              type="text"
                              name="atrp_number"
                              placeholder="Número ATRP"
                              onChange={this.handleChange}
                              defaultValue={this.state.atrp_number}
                            />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {this.state.errors["athlete/atrp_number"]}
                            </span>
                            <div className="espaco_span" />

                            <label>
                              Número FCMP
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              />
                            </label>
                            <input
                              style={{
                                border: this.state.errors["athlete/fcmp_number"]
                                  ? border_erro
                                  : "",
                                textAlign: "center"
                              }}
                              type="text"
                              name="fcmp_number"
                              placeholder="Número FCMP"
                              onChange={this.handleChange}
                              defaultValue={this.state.fcmp_number}
                            />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {this.state.errors["athlete/fcmp_number"]}
                            </span>
                            <div className="espaco_span" />

                            <label>
                              Nome Telefone 3
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              >
                                {" (*)"}
                              </span>
                            </label>
                            <input
                              style={{
                                border: this.state.errors[
                                  "athlete/name_phone_number3"
                                ]
                                  ? border_erro
                                  : "",
                                textAlign: "center"
                              }}
                              type="text"
                              name="name_phone_number3"
                              placeholder="Nome Telefone 3"
                              onChange={this.handleChange}
                              defaultValue={this.state.name_phone_number3}
                            />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {this.state.errors["athlete/name_phone_number3"]}
                            </span>
                            <div className="espaco_span" />

                            <label>
                              Password
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              />
                            </label>
                            <input
                              style={{
                                border: this.state.errors["athlete/password"]
                                  ? border_erro
                                  : "",
                                textAlign: "center"
                              }}
                              type="text"
                              name="password"
                              placeholder="Password"
                              onChange={this.handleChange}
                              defaultValue={this.state.password}
                            />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {this.state.errors["athlete/password"]}
                            </span>
                            <div className="espaco_span" />

                            <label>
                              Número ISF
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              />
                            </label>
                            <input
                              style={{
                                border: this.state.errors["athlete/isf_number"]
                                  ? border_erro
                                  : "",
                                textAlign: "center"
                              }}
                              type="text"
                              name="isf_number"
                              placeholder="Número ISF"
                              onChange={this.handleChange}
                              defaultValue={this.state.isf_number}
                            />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {this.state.errors["athlete/isf_number"]}
                            </span>
                            <div className="espaco_span" />

                            <label>
                              Regional:
                              {/*span style={{ color: "#e60000" }}>{" (*)"}</span>*/}
                            </label>
                            <div
                              style={{
                                border: this.state.errors["athlete/regional"]
                                  ? border_erro
                                  : ""
                              }}
                            >
                              <Select
                                value={this.state.regional}
                                name="regional"
                                placeholder="Regional"
                                onChange={this.handleChangeSelectRegional}
                                options={Opcoes_Regional}
                              />
                            </div>
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {this.state.errors["athlete/regional"]}
                            </span>
                            <div className="espaco_span" />

                            <label>
                              Linguagem
                              {/*<span className="bolder" style={{ color: "#e60000" }}>{" (*)"}</span>*/}
                            </label>
                            <input
                              style={{
                                border: this.state.errors["athlete/language"]
                                  ? border_erro
                                  : "",
                                textAlign: "center"
                              }}
                              type="text"
                              name="language"
                              placeholder="Linguagem"
                              onChange={this.handleChange}
                              defaultValue={this.state.language}
                            />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {this.state.errors["athlete/language"]}
                            </span>
                            <div className="espaco_span" />

                            <label>
                              Evento Participado Anteriormente
                              {/*<span className="bolder" style={{ color: "#e60000" }}>{" (*)"}</span>*/}
                            </label>
                            <input
                              style={{
                                border: this.state.errors[
                                  "athlete/previous_participated_event"
                                ]
                                  ? border_erro
                                  : "",
                                textAlign: "center"
                              }}
                              type="text"
                              name="previous_participated_event"
                              placeholder="Evento Participado Anteriormente"
                              onChange={this.handleChange}
                              defaultValue={
                                this.state.previous_participated_event
                              }
                            />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {
                                this.state.errors[
                                  "athlete/previous_participated_event"
                                ]
                              }
                            </span>
                            <div className="espaco_span" />

                            <label>
                              Link Evento Participado Anteriormente
                              {/*<span className="bolder" style={{ color: "#e60000" }}>{" (*)"}</span>*/}
                            </label>
                            <input
                              style={{
                                border: this.state.errors[
                                  "athlete/previous_participated_event_link"
                                ]
                                  ? border_erro
                                  : "",
                                textAlign: "center"
                              }}
                              type="text"
                              name="previous_participated_event_link"
                              placeholder="Link Evento Participado Anteriormente"
                              onChange={this.handleChange}
                              defaultValue={
                                this.state.previous_participated_event_link
                              }
                            />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {
                                this.state.errors[
                                  "athlete/previous_participated_event_link"
                                ]
                              }
                            </span>
                            <div className="espaco_span" />

                            <label>
                              Eventos Participados Anteriormente
                              {/*<span className="bolder" style={{ color: "#e60000" }}>{" (*)"}</span>*/}
                            </label>
                            <input
                              style={{
                                border: this.state.errors[
                                  "athlete/previous_participated_events"
                                ]
                                  ? border_erro
                                  : "",
                                textAlign: "center"
                              }}
                              type="text"
                              name="previous_participated_events"
                              placeholder="Eventos Participados Anteriormente"
                              onChange={this.handleChange}
                              defaultValue={
                                this.state.previous_participated_events
                              }
                            />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {
                                this.state.errors[
                                  "athlete/previous_participated_events"
                                ]
                              }
                            </span>
                            <div className="espaco_span" />

                            <label>
                              Informação Extra
                              {/*<span className="bolder" style={{ color: "#e60000" }}>{" (*)"}</span>*/}
                            </label>
                            <input
                              style={{
                                border: this.state.errors["athlete/extra_info"]
                                  ? border_erro
                                  : "",
                                textAlign: "center"
                              }}
                              type="text"
                              name="extra_info"
                              placeholder="Informação Extra"
                              onChange={this.handleChange}
                              defaultValue={this.state.extra_info}
                            />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {this.state.errors["athlete/extra_info"]}
                            </span>
                            <div className="espaco_span" />

                            <label>
                              Informação Extra 2
                              {/*<span className="bolder" style={{ color: "#e60000" }}>{" (*)"}</span>*/}
                            </label>
                            <input
                              style={{
                                border: this.state.errors["athlete/extra_info2"]
                                  ? border_erro
                                  : "",
                                textAlign: "center"
                              }}
                              type="text"
                              name="extra_info2"
                              placeholder="Informação Extra 2"
                              onChange={this.handleChange}
                              defaultValue={this.state.extra_info2}
                            />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {this.state.errors["athlete/extra_info2"]}
                            </span>
                          </div>
                          <div className="espaco_span">
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {" (*)"}
                            </span>
                            <span className="bolder"> Campos Obrigatórios</span>
                          </div>
                        </div>
                        {/*}
                        <div className="form">
                          <button type="submit">Adicionar Inscrição</button>
                        </div>
                        */}
                      </TabPanel>
                    </Tabs>
                  </div>
                </form>
              </div>
            </div>
          </div>
        );
      }
    }
  }
}

export default Formulario_Adicionar_Inscricao_Atleta;
