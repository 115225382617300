import React from "react";
import { Link } from "react-router-dom";

//Caso Exista Algum Problema nos Props, Mostra Esta Mensagem
export const mostra_mensagem_erro = () => {
  return (
    <div>
      <div className="centra_loading">
        <h1>Ups Something Went Wrong</h1>
      </div>
      <button>
        <Link
          to={{
            pathname: "/"
          }}
        >
          <i className="fa fa-arrow-left fa-lg espaco_icons" />
          Voltar
        </Link>
      </button>
    </div>
  );
};

//Funcao Que Verifica Se Existem Dados a Mostrar ou Não
export const ve_resultados = () => {
  return (
    <div className="novo_centra_loading">
      <tabela>
        <thead>
          <tr>
            {" "}
            <th className="novo_centra_tabelas">Não Existem Dados a Mostrar</th>
          </tr>
        </thead>
      </tabela>
    </div>
  );
};

/*
//Funcao Que Verifica Se Existem Dados a Mostrar ou Não
  export const ve_resultados = Dados => {
  //Se Nao Existirem Resultados, Mostra Apenas 1 Header Com a Mensagem "Nao Existem Dados a Mostrar"
  if (Dados.length <= 0) {
  {
  return (
    <div className="centra_tabelas">
      <tabela>
        <thead>
          <tr>
            {" "}
            <th>Não Existem Dados a Mostrar</th>
          </tr>
        </thead>
      </tabela>
    </div>
  );
  }
  }
};
*/

export const isEmptyObject = obj => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

// Algoritmo Para as Validações:
export const Trata_Validacoes = (Valor, Campo_Tabela, errors) => {
  //Tabela Athlete:
  //Tabela Athlete:
  //Tabela Athlete:
  //Tabela Athlete:
  //Tabela Athlete:
  //Tabela Athlete:
  //Tabela Athlete:
  //Tabela Athlete:
  //Tabela Athlete:
  //Tabela Athlete:
  //Tabela Athlete:
  //Tabela Athlete:
  //Tabela Athlete:
  //Tabela Athlete:
  //Tabela Athlete:

  if (Campo_Tabela === "athlete/nome") {
    if (!Valor) {
      errors["athlete/nome"] = "*Insira o Nome Completo do Atleta";
    }
  }
  if (Campo_Tabela === "athlete/email") {
    if (!Valor) {
      errors["athlete/email"] = "*Insira um Email Válido";
    }
  }
  if (Campo_Tabela === "athlete/birthday") {
    if (!Valor) {
      errors["athlete/birthday"] = "*Insira a Data de Nascimento do Atleta";
    }
  }
  if (Campo_Tabela === "athlete/gender") {
    if (!Valor) {
      errors["athlete/gender"] = "*Escolha o Sexo do Atleta";
    }
  }
  /*
  if (Campo_Tabela === "athlete/nacionality") {
    if (!Valor) {
      errors["athlete/nacionality"] = "*Insira um Nome";
    }
  }
  */
  /*
  if (Campo_Tabela === "athlete/regional") {
    if (!Valor) {
      errors["athlete/regional"] = "*Escolha uma Opção Válida";
    }
  }
  if (Campo_Tabela === "athlete/language") {
    if (!Valor) {
      errors["athlete/language"] = "*Insira um Nome";
    }
  }
  */
  if (Campo_Tabela === "athlete/identification_type") {
    if (!Valor) {
      errors["athlete/identification_type"] =
        "*Insira um Tipo de Identificação";
    }
  }
  if (Campo_Tabela === "athlete/identification_id") {
    if (!Valor) {
      errors["athlete/identification_id"] = "*Insira o Número do ID";
    }
  }
  if (Campo_Tabela === "athlete/phone_number1") {
    if (!Valor) {
      errors["athlete/phone_number1"] = "*Insira um Número de Telefone";
    }
  }
  if (Campo_Tabela === "athlete/phone_number2") {
    if (!Valor) {
      errors["athlete/phone_number2"] = "*Insira um Número de Telefone";
    }
  }
  if (Campo_Tabela === "athlete/phone_number3") {
    if (!Valor) {
      errors["athlete/phone_number3"] =
        "*Insira o Número de Telefone do Contacto de Emergência";
    }
  }
  if (Campo_Tabela === "athlete/postal_code") {
    if (!Valor) {
      errors["athlete/postal_code"] = "*Insira o Código Postal do Atleta";
    }
  }
  if (Campo_Tabela === "athlete/address") {
    if (!Valor) {
      errors["athlete/address"] = "*Insira a Morada do Atleta";
    }
  }
  if (Campo_Tabela === "athlete/city") {
    if (!Valor) {
      errors["athlete/city"] = "*Insira a Cidade";
    }
  }
  /*
  if (Campo_Tabela === "athlete/country") {
    if (!Valor) {
      errors["athlete/country"] = "*Insira um Nome";
    }
  }
  */
  /*
  if (Campo_Tabela === "athlete/previous_participated_events") {
    if (!Valor) {
      errors["athlete/previous_participated_events"] = "*Insira um Nome";
    }
  }
  */
  if (Campo_Tabela === "athlete/nif") {
    if (!Valor) {
      errors["athlete/nif"] = "*Insira o Nif do Atleta";
    }
  }
  if (Campo_Tabela === "athlete/atrp_number") {
    if (!Valor) {
      errors["athlete/atrp_number"] = "*Insira o Número ATRP do Atleta";
    }
  }
  if (Campo_Tabela === "athlete/raking_name") {
    if (!Valor) {
      errors["athlete/raking_name"] =
        "*Insira o Primeiro Nome a Aparecer nos Rankings";
    }
  }
  /*
  if (Campo_Tabela === "athlete/extra_info") {
    if (!Valor) {
      errors["athlete/extra_info"] = "*Insira um Nome";
    }
  }
  if (Campo_Tabela === "athlete/previous_participated_event") {
    if (!Valor) {
      errors["athlete/previous_participated_event"] = "*Insira um Nome";
    }
  }
  if (Campo_Tabela === "athlete/previous_participated_event_link") {
    if (!Valor) {
      errors["athlete/previous_participated_event_link"] = "*Insira um Nome";
    }
  }
  if (Campo_Tabela === "athlete/extra_info2") {
    if (!Valor) {
      errors["athlete/extra_info2"] = "*Insira um Nome";
    }
  }
  */
  if (Campo_Tabela === "athlete/raking_name_last") {
    if (!Valor) {
      errors["athlete/raking_name_last"] =
        "*Insira o Último Nome a Aparecer nos Rankings";
    }
  }
  if (Campo_Tabela === "athlete/fcmp_number") {
    if (!Valor) {
      errors["athlete/fcmp_number"] = "*Insira o Número FCMP do Atleta";
    }
  }
  if (Campo_Tabela === "athlete/name_phone_number3") {
    if (!Valor) {
      errors["athlete/name_phone_number3"] =
        "*Insira o Nome do Contacto de Emergência";
    }
  }
  /*
  if (Campo_Tabela === "athlete/password") {
    if (!Valor) {
      errors["athlete/password"] = "*Insira uma Password";
    }
  }
  */
  if (Campo_Tabela === "athlete/isf_number") {
    if (!Valor) {
      errors["athlete/isf_number"] = "*Insira o Número ISF do Atleta";
    }
  }
  //Tabela Athlete Caso Particular:
  if (Campo_Tabela === "athlete/linha_nacionalidade") {
    if (!Valor) {
      errors["athlete/linha_nacionalidade"] =
        "*Escolha o País de Nascença do Atleta";
    }
  }

  //Tabela Categories:
  //Tabela Categories:
  //Tabela Categories:
  //Tabela Categories:
  //Tabela Categories:
  //Tabela Categories:
  //Tabela Categories:
  //Tabela Categories:
  //Tabela Categories:
  //Tabela Categories:
  //Tabela Categories:

  if (Campo_Tabela === "categories/categorie_id") {
    if (!Valor) {
      errors["categories/categorie_id"] = "*Insira o Nome da Categoria";
    }
  }
  if (Campo_Tabela === "categories/categorie_order") {
    if (!Valor) {
      errors["categories/categorie_order"] = "*Insira a Ordem da Categoria";
    }
  }
  if (Campo_Tabela === "categories/age_limit") {
    if (!Valor) {
      errors["categories/age_limit"] =
        "*Insira o Limite Máximo de Idade da Categoria";
    }
  }

  //Tabela Checkpoint:
  //Tabela Checkpoint:
  //Tabela Checkpoint:
  //Tabela Checkpoint:
  //Tabela Checkpoint:
  //Tabela Checkpoint:
  //Tabela Checkpoint:
  //Tabela Checkpoint:
  //Tabela Checkpoint:
  //Tabela Checkpoint:
  //Tabela Checkpoint:
  //Tabela Checkpoint:
  //Tabela Checkpoint:
  //Tabela Checkpoint:
  //Tabela Checkpoint:
  //Tabela Checkpoint:
  //Tabela Checkpoint:
  //Tabela Checkpoint:

  if (
    Campo_Tabela === "checkpoint/local" ||
    Campo_Tabela === "checkpoint/local2"
  ) {
    if (!Valor) {
      errors["checkpoint/local"] = "*Insira o Nome do Checkpoint";
    }
  }
  if (
    Campo_Tabela === "checkpoint/coordinate" ||
    Campo_Tabela === "checkpoint/coordinate2"
  ) {
    if (!Valor) {
      errors["checkpoint/coordinate"] = "*Insira as Coordenadas do Checkpoint";
    }
  }
  if (
    Campo_Tabela === "checkpoint/altitude" ||
    Campo_Tabela === "checkpoint/altitude2"
  ) {
    if (!Valor) {
      errors["checkpoint/altitude"] = "*Insira a Altitude do Checkpoint";
    }
  }

  //Tabela Clubs:
  //Tabela Clubs:
  //Tabela Clubs:
  //Tabela Clubs:
  //Tabela Clubs:
  //Tabela Clubs:
  //Tabela Clubs:
  //Tabela Clubs:
  //Tabela Clubs:
  //Tabela Clubs:
  //Tabela Clubs:
  //Tabela Clubs:

  if (Campo_Tabela === "clubs/name") {
    if (!Valor) {
      errors["clubs/name"] = "*Insira o Nome do Clube";
    }
  }
  if (Campo_Tabela === "clubs/sigla") {
    if (!Valor) {
      errors["clubs/sigla"] = "*Insira a Sigla do Clube";
    }
  }

  //Tabela Competition:
  //Tabela Competition:
  //Tabela Competition:
  //Tabela Competition:
  //Tabela Competition:
  //Tabela Competition:
  //Tabela Competition:
  //Tabela Competition:

  if (Campo_Tabela === "competition/name") {
    if (!Valor) {
      errors["competition/name"] = "*Insira a Abreviatura da Competição";
    }
  }
  if (Campo_Tabela === "competition/init_date") {
    if (!Valor) {
      errors["competition/init_date"] =
        "*Insira a Data de Início da Competição";
    }
  }
  if (Campo_Tabela === "competition/end_date") {
    if (!Valor) {
      errors["competition/end_date"] = "*Insira a Data de Fim da Competição";
    }
  }
  if (Campo_Tabela === "competition/total_distance") {
    if (!Valor) {
      errors["competition/total_distance"] =
        "*Insira a Distância Total da Competição";
    }
  }
  if (Campo_Tabela === "competition/active") {
    if (!Valor) {
      errors["competition/active"] = "*Escolha o Estado da Competição";
    }
  }
  if (Campo_Tabela === "competition/color_code") {
    if (!Valor) {
      errors["competition/color_code"] = "*Insira o Código de Cor";
    }
  }
  if (Campo_Tabela === "competition/kml_file_name") {
    if (!Valor) {
      errors["competition/kml_file_name"] = "*Insira o Nome do Ficheiro .kml";
    }
  }
  /*
  if (Campo_Tabela === "competition/trail_event_trail_event_id") {
    if (!Valor) {
      errors["competition/trail_event_trail_event_id"] = "*Insira um Nome";
    }
  }
  */
  if (Campo_Tabela === "competition/long_name") {
    if (!Valor) {
      errors["competition/long_name"] = "*Insira o Nome Completo da Competição";
    }
  }
  if (Campo_Tabela === "competition/max_inscriptions") {
    if (!Valor) {
      errors["competition/max_inscriptions"] =
        "*Insira o Número Máximo de Inscrições";
    }
  }
  if (Campo_Tabela === "competition/certification") {
    if (!Valor) {
      errors["competition/certification"] =
        "*Escolha se Deve Ser Emitido Certificado";
    }
  }
  if (Campo_Tabela === "competition/cert_tpl") {
    if (!Valor) {
      errors["competition/cert_tpl"] =
        "*Insira o Número do Template do Certificado";
    }
  }
  if (Campo_Tabela === "competition/cert_code") {
    if (!Valor) {
      errors["competition/cert_code"] =
        "*Insira o Código de Segurança do Certificado";
    }
  }
  if (Campo_Tabela === "competition/alterar_atletas") {
    if (!Valor) {
      errors["competition/alterar_atletas"] =
        "*Escolha se Deve Ser Possível Alterar Atletas";
    }
  }

  //Tabela Competition_Checkpoint:
  //Tabela Competition_Checkpoint:
  //Tabela Competition_Checkpoint:
  //Tabela Competition_Checkpoint:
  //Tabela Competition_Checkpoint:
  //Tabela Competition_Checkpoint:
  //Tabela Competition_Checkpoint:
  //Tabela Competition_Checkpoint:
  //Tabela Competition_Checkpoint:
  //Tabela Competition_Checkpoint:
  //Tabela Competition_Checkpoint:
  //Tabela Competition_Checkpoint:
  //Tabela Competition_Checkpoint:

  if (Campo_Tabela === "competition_checkpoint/checkpoint_order") {
    if (!Valor) {
      errors["competition_checkpoint/checkpoint_order"] =
        "*Insira o Número do Checkpoint";
    }
  }
  if (Campo_Tabela === "competition_checkpoint/tipo_controlo") {
    if (!Valor) {
      errors["competition_checkpoint/tipo_controlo"] =
        "*Insira o Tipo de Controlo do Checkpoint";
    }
  }
  if (Campo_Tabela === "competition_checkpoint/previsao_rapido") {
    if (!Valor) {
      errors["competition_checkpoint/previsao_rapido"] =
        "*Insira a Data de Previsão do 1º Atleta";
    }
  }
  if (Campo_Tabela === "competition_checkpoint/distancia_mapa") {
    if (!Valor) {
      errors["competition_checkpoint/distancia_mapa"] =
        "*Insira a Distância do Mapa";
    }
  }
  if (Campo_Tabela === "competition_checkpoint/distancia") {
    if (!Valor) {
      errors["competition_checkpoint/distancia"] = "*Insira a Distância";
    }
  }
  if (Campo_Tabela === "competition_checkpoint/distancia_acumulada") {
    if (!Valor) {
      errors["competition_checkpoint/distancia_acumulada"] =
        "*Insira a Distância Acumulada";
    }
  }
  if (Campo_Tabela === "competition_checkpoint/abertura") {
    if (!Valor) {
      errors["competition_checkpoint/abertura"] =
        "*Insira a Data de Abertura do Checkpoint";
    }
  }
  if (Campo_Tabela === "competition_checkpoint/desnivel_positivo") {
    if (!Valor) {
      errors["competition_checkpoint/desnivel_positivo"] =
        "*Insira o Desnível Positivo";
    }
  }
  if (Campo_Tabela === "competition_checkpoint/desnivel_positivo_acumulado") {
    if (!Valor) {
      errors["competition_checkpoint/desnivel_positivo_acumulado"] =
        "*Insira o Desnível Positivo Acumulado";
    }
  }
  if (Campo_Tabela === "competition_checkpoint/desnivel_negativo") {
    if (!Valor) {
      errors["competition_checkpoint/desnivel_negativo"] =
        "*Insira o Desnível Negativo";
    }
  }
  if (Campo_Tabela === "competition_checkpoint/desnivel_negativo_acumulado") {
    if (!Valor) {
      errors["competition_checkpoint/desnivel_negativo_acumulado"] =
        "*Insira o Desnível Negativo Acumulado";
    }
  }
  if (Campo_Tabela === "competition_checkpoint/previsao_lento") {
    if (!Valor) {
      errors["competition_checkpoint/previsao_lento"] =
        "*Insira a Data de Previsão do Último Atleta";
    }
  }
  if (Campo_Tabela === "competition_checkpoint/fecho") {
    if (!Valor) {
      errors["competition_checkpoint/fecho"] =
        "*Insira a Data de Fecho do Checkpoint";
    }
  }
  if (Campo_Tabela === "competition_checkpoint/abastecimento") {
    if (!Valor) {
      errors["competition_checkpoint/abastecimento"] =
        "*Insira o Tipo de Abastecimento do Checkpoint";
    }
  }
  if (Campo_Tabela === "competition_checkpoint/checkpoint_checkpoint_id") {
    if (!Valor) {
      errors["competition_checkpoint/checkpoint_checkpoint_id"] =
        "*Escolha um Checkpoint";
    }
  }
  /*
  if (Campo_Tabela === "competition_checkpoint/competition_competition_id") {
    if (!Valor) {
      errors["competition_checkpoint/competition_competition_id"] =
        "*Insira um Nome";
    }
  }
  */

  if (Campo_Tabela === "competition_checkpoint/checkpoint_order2") {
    if (!Valor) {
      errors["competition_checkpoint/checkpoint_order2"] =
        "*Insira o Número do Checkpoint";
    }
  }
  if (Campo_Tabela === "competition_checkpoint/tipo_controlo2") {
    if (!Valor) {
      errors["competition_checkpoint/tipo_controlo2"] =
        "*Insira o Tipo de Controlo do Checkpoint";
    }
  }
  if (Campo_Tabela === "competition_checkpoint/previsao_rapido2") {
    if (!Valor) {
      errors["competition_checkpoint/previsao_rapido2"] =
        "*Insira a Data de Previsão do 1º Atleta";
    }
  }
  if (Campo_Tabela === "competition_checkpoint/distancia_mapa2") {
    if (!Valor) {
      errors["competition_checkpoint/distancia_mapa2"] =
        "*Insira a Distância do Mapa";
    }
  }
  if (Campo_Tabela === "competition_checkpoint/distancia2") {
    if (!Valor) {
      errors["competition_checkpoint/distancia2"] = "*Insira a Distância";
    }
  }
  if (Campo_Tabela === "competition_checkpoint/distancia_acumulada2") {
    if (!Valor) {
      errors["competition_checkpoint/distancia_acumulada2"] =
        "*Insira a Distância Acumulada";
    }
  }
  if (Campo_Tabela === "competition_checkpoint/abertura2") {
    if (!Valor) {
      errors["competition_checkpoint/abertura2"] =
        "*Insira a Data de Abertura do Checkpoint";
    }
  }
  if (Campo_Tabela === "competition_checkpoint/desnivel_positivo2") {
    if (!Valor) {
      errors["competition_checkpoint/desnivel_positivo2"] =
        "*Insira o Desnível Positivo";
    }
  }
  if (Campo_Tabela === "competition_checkpoint/desnivel_positivo_acumulado2") {
    if (!Valor) {
      errors["competition_checkpoint/desnivel_positivo_acumulado2"] =
        "*Insira o Desnível Positivo Acumulado";
    }
  }
  if (Campo_Tabela === "competition_checkpoint/desnivel_negativo2") {
    if (!Valor) {
      errors["competition_checkpoint/desnivel_negativo2"] =
        "*Insira o Desnível Negativo";
    }
  }
  if (Campo_Tabela === "competition_checkpoint/desnivel_negativo_acumulado2") {
    if (!Valor) {
      errors["competition_checkpoint/desnivel_negativo_acumulado2"] =
        "*Insira o Desnível Negativo Acumulado";
    }
  }
  if (Campo_Tabela === "competition_checkpoint/previsao_lento2") {
    if (!Valor) {
      errors["competition_checkpoint/previsao_lento2"] =
        "*Insira a Data de Previsão do Último Atleta";
    }
  }
  if (Campo_Tabela === "competition_checkpoint/fecho2") {
    if (!Valor) {
      errors["competition_checkpoint/fecho2"] =
        "*Insira a Data de Fecho do Checkpoint";
    }
  }
  if (Campo_Tabela === "competition_checkpoint/abastecimento2") {
    if (!Valor) {
      errors["competition_checkpoint/abastecimento2"] =
        "*Insira o Tipo de Abastecimento do Checkpoint";
    }
  }

  //Tabela Inscription:
  //Tabela Inscription:
  //Tabela Inscription:
  //Tabela Inscription:
  //Tabela Inscription:
  //Tabela Inscription:
  //Tabela Inscription:
  //Tabela Inscription:
  //Tabela Inscription:
  //Tabela Inscription:
  //Tabela Inscription:
  //Tabela Inscription:
  //Tabela Inscription:
  //Tabela Inscription:
  //Tabela Inscription:
  //Tabela Inscription:
  //Tabela Inscription:
  //Tabela Inscription:
  //Tabela Inscription:
  //Tabela Inscription:
  //Tabela Inscription:
  //Tabela Inscription:
  //Tabela Inscription:
  //Tabela Inscription:
  //Tabela Inscription:
  //Tabela Inscription:

  if (Campo_Tabela === "inscription/rf_id_number") {
    if (!Valor) {
      errors["inscription/rf_id_number"] = "*Insira o RFID 1 do Atleta";
    }
  }
  if (Campo_Tabela === "inscription/rf_id_number2") {
    if (!Valor) {
      errors["inscription/rf_id_number2"] = "*Insira o RFID 2 do Atleta";
    }
  }
  if (Campo_Tabela === "inscription/rf_id_number3") {
    if (!Valor) {
      errors["inscription/rf_id_number3"] = "*Insira o RFID 3 do Atleta";
    }
  }
  if (Campo_Tabela === "inscription/rf_id_number4") {
    if (!Valor) {
      errors["inscription/rf_id_number4"] = "*Insira o RFID 4 do Atleta";
    }
  }
  if (Campo_Tabela === "inscription/dorsal_number") {
    if (!Valor) {
      errors["inscription/dorsal_number"] =
        "*Insira o Número do Dorsal do Atleta";
    }
  }
  if (Campo_Tabela === "inscription/athlete_status") {
    if (!Valor) {
      errors["inscription/athlete_status"] = "*Escolha o Estado do Atleta";
    }
  }
  /*
  if (Campo_Tabela === "inscription/athlete_observation") {
    if (!Valor) {
      errors["inscription/athlete_observation"] = "*Insira um Nome";
    }
  }
*/
  if (Campo_Tabela === "inscription/category") {
    if (!Valor) {
      errors["inscription/category"] = "*Escolha o Escalão do Atleta";
    }
  }
  if (Campo_Tabela === "inscription/club") {
    if (!Valor) {
      errors["inscription/club"] = "*Insira o Clube do Atleta";
    }
  }
  /*
  if (Campo_Tabela === "inscription/responsability_term") {
    if (!Valor) {
      errors["inscription/responsability_term"] = "*Insira um Nome";
    }
  }
  if (Campo_Tabela === "inscription/medical_record") {
    if (!Valor) {
      errors["inscription/medical_record"] = "*Insira um Nome";
    }
  }
  if (Campo_Tabela === "inscription/transport_request") {
    if (!Valor) {
      errors["inscription/transport_request"] = "*Insira um Nome";
    }
  }
  if (Campo_Tabela === "inscription/transport_request_buddy") {
    if (!Valor) {
      errors["inscription/transport_request_buddy"] = "*Insira um Nome";
    }
  }
  if (Campo_Tabela === "inscription/hotel_name") {
    if (!Valor) {
      errors["inscription/hotel_name"] = "*Insira um Nome";
    }
  }
  if (Campo_Tabela === "inscription/hotel_room") {
    if (!Valor) {
      errors["inscription/hotel_room"] = "*Insira um Nome";
    }
  }
  if (Campo_Tabela === "inscription/observations") {
    if (!Valor) {
      errors["inscription/observations"] = "*Insira um Nome";
    }
  }
  if (Campo_Tabela === "inscription/extra_info") {
    if (!Valor) {
      errors["inscription/extra_info"] = "*Insira um Nome";
    }
  }
*/
  if (Campo_Tabela === "inscription/inscription_status_code") {
    if (!Valor) {
      errors["inscription/inscription_status_code"] =
        "*Escolha o Estado da Inscrição do Atleta";
    }
  }
  /*
  if (Campo_Tabela === "inscription/payment") {
    if (!Valor) {
      errors["inscription/payment"] = "*Insira um Nome";
    }
  }
  if (Campo_Tabela === "inscription/payment_type") {
    if (!Valor) {
      errors["inscription/payment_type"] = "*Insira um Nome";
    }
  }
  if (Campo_Tabela === "inscription/payment_status") {
    if (!Valor) {
      errors["inscription/payment_status"] = "*Insira um Nome";
    }
  }
  if (Campo_Tabela === "inscription/payment_date") {
    if (!Valor) {
      errors["inscription/payment_date"] = "*Insira um Nome";
    }
  }
  if (Campo_Tabela === "inscription/tshirt_size") {
    if (!Valor) {
      errors["inscription/tshirt_size"] = "*Insira um Nome";
    }
  }
  if (Campo_Tabela === "inscription/pp_atleta") {
    if (!Valor) {
      errors["inscription/pp_atleta"] = "*Insira um Nome";
    }
  }
  if (Campo_Tabela === "inscription/pp_criancas") {
    if (!Valor) {
      errors["inscription/pp_criancas"] = "*Insira um Nome";
    }
  }
  if (Campo_Tabela === "inscription/pp_adultos") {
    if (!Valor) {
      errors["inscription/pp_adultos"] = "*Insira um Nome";
    }
  }
  if (Campo_Tabela === "inscription/timestamp") {
    if (!Valor) {
      errors["inscription/timestamp"] = "*Insira um Nome";
    }
  }
  if (Campo_Tabela === "inscription/price") {
    if (!Valor) {
      errors["inscription/price"] = "*Insira um Nome";
    }
  }
*/
  if (Campo_Tabela === "inscription/sponsor") {
    if (!Valor) {
      errors["inscription/sponsor"] =
        "*Insira o Nome do Patrocinador do Atleta";
    }
  }
  if (Campo_Tabela === "inscription/club_sigla") {
    if (!Valor) {
      errors["inscription/club_sigla"] = "*Insira a Sigla do Clube do Atleta";
    }
  }
  /*
  if (Campo_Tabela === "inscription/update_timestamp") {
    if (!Valor) {
      errors["inscription/update_timestamp"] = "*Insira um Nome";
    }
  }
  if (Campo_Tabela === "inscription/payment_reference") {
    if (!Valor) {
      errors["inscription/payment_reference"] = "*Insira um Nome";
    }
  }
  if (Campo_Tabela === "inscription/data_for_other_events") {
    if (!Valor) {
      errors["inscription/data_for_other_events"] = "*Insira um Nome";
    }
  }
  if (Campo_Tabela === "inscription/extra_info2") {
    if (!Valor) {
      errors["inscription/extra_info2"] = "*Insira um Nome";
    }
  }
  if (Campo_Tabela === "inscription/insc_language_form") {
    if (!Valor) {
      errors["inscription/insc_language_form"] = "*Insira um Nome";
    }
  }
  if (Campo_Tabela === "inscription/invited") {
    if (!Valor) {
      errors["inscription/invited"] = "*Insira um Nome";
    }
  }
  if (Campo_Tabela === "inscription/time_penalty_total") {
    if (!Valor) {
      errors["inscription/time_penalty_total"] = "*Insira um Nome";
    }
  }
  if (Campo_Tabela === "inscription/fatura") {
    if (!Valor) {
      errors["inscription/fatura"] = "*Insira um Nome";
    }
  }
  if (Campo_Tabela === "inscription/fatura-nif") {
    if (!Valor) {
      errors["inscription/fatura-nif"] = "*Insira um Nome";
    }
  }
  if (Campo_Tabela === "inscription/fatura-name") {
    if (!Valor) {
      errors["inscription/fatura-name"] = "*Insira um Nome";
    }
  }
  if (Campo_Tabela === "inscription/fatura-address") {
    if (!Valor) {
      errors["inscription/fatura-address"] = "*Insira um Nome";
    }
  }
  if (Campo_Tabela === "inscription/awards_dinner_adultos") {
    if (!Valor) {
      errors["inscription/awards_dinner_adultos"] = "*Insira um Nome";
    }
  }
  if (Campo_Tabela === "inscription/awards_dinner_criancas") {
    if (!Valor) {
      errors["inscription/awards_dinner_criancas"] = "*Insira um Nome";
    }
  }
  if (Campo_Tabela === "inscription/massagens") {
    if (!Valor) {
      errors["inscription/massagens"] = "*Insira um Nome";
    }
  }
  if (Campo_Tabela === "inscription/donation") {
    if (!Valor) {
      errors["inscription/donation"] = "*Insira um Nome";
    }
  }
  if (Campo_Tabela === "inscription/travel_package") {
    if (!Valor) {
      errors["inscription/travel_package"] = "*Insira um Nome";
    }
  }
  if (Campo_Tabela === "inscription/insurance") {
    if (!Valor) {
      errors["inscription/insurance"] = "*Insira um Nome";
    }
  }
  if (Campo_Tabela === "inscription/number_atl_criancas") {
    if (!Valor) {
      errors["inscription/number_atl_criancas"] = "*Insira um Nome";
    }
  }
  if (Campo_Tabela === "inscription/nomes_atl_criancas") {
    if (!Valor) {
      errors["inscription/nomes_atl_criancas"] = "*Insira um Nome";
    }
  }
  if (Campo_Tabela === "inscription/newsletter") {
    if (!Valor) {
      errors["inscription/newsletter"] = "*Insira um Nome";
    }
  }
  if (Campo_Tabela === "inscription/third_part_data") {
    if (!Valor) {
      errors["inscription/third_part_data"] = "*Insira um Nome";
    }
  }
  if (Campo_Tabela === "inscription/observations_org") {
    if (!Valor) {
      errors["inscription/observations_org"] = "*Insira um Nome";
    }
  }
  if (Campo_Tabela === "inscription/firsttime") {
    if (!Valor) {
      errors["inscription/firsttime"] = "*Insira um Nome";
    }
  }
  if (Campo_Tabela === "inscription/hotel_stay") {
    if (!Valor) {
      errors["inscription/hotel_stay"] = "*Insira um Nome";
    }
  }
  if (Campo_Tabela === "inscription/companions") {
    if (!Valor) {
      errors["inscription/companions"] = "*Insira um Nome";
    }
  }
  */

  //Tabela Inscription_Status:
  //Tabela Inscription_Status:
  //Tabela Inscription_Status:
  //Tabela Inscription_Status:
  //Tabela Inscription_Status:
  //Tabela Inscription_Status:
  //Tabela Inscription_Status:

  if (Campo_Tabela === "inscription_status/code") {
    if (!Valor) {
      errors["inscription_status/code"] =
        "*Insira o Código do Estado da Inscrição";
    }
  }
  if (Campo_Tabela === "inscription_status/description") {
    if (!Valor) {
      errors["inscription_status/description"] =
        "*Insira a Descrição do Estado da Inscrição";
    }
  }

  //Tabela Time_Checkpoint:
  //Tabela Time_Checkpoint:
  //Tabela Time_Checkpoint:
  //Tabela Time_Checkpoint:
  //Tabela Time_Checkpoint:
  //Tabela Time_Checkpoint:
  //Tabela Time_Checkpoint:

  if (
    Campo_Tabela ===
    "time_checkpoint/competition_checkpoint_checkpoint_checkpoint_id"
  ) {
    if (!Valor) {
      errors[
        "time_checkpoint/competition_checkpoint_checkpoint_checkpoint_id"
      ] = "*Escolha um Checkpoint";
    }
  }
  if (Campo_Tabela === "time_checkpoint/time_stamp") {
    if (!Valor) {
      errors["time_checkpoint/time_stamp"] = "*Insira o Time Stamp";
    }
  }

  if (Campo_Tabela === "time_checkpoint/acumulated_time") {
    if (!Valor) {
      errors["time_checkpoint/acumulated_time"] = "*Insira o Tempo Acumulado";
    }
  }

  if (Campo_Tabela === "time_checkpoint/checkpoint_point_time") {
    if (!Valor) {
      errors["time_checkpoint/checkpoint_point_time"] =
        "*Insira a Data de Chegada ao Checkpoint";
    }
  }
  /*
  if (Campo_Tabela === "time_checkpoint/checkpoint_time_shifted") {
    if (!Valor) {
      errors["time_checkpoint/checkpoint_time_shifted"] = "*Insira um Nome";
    }
  }
  if (Campo_Tabela === "time_checkpoint/acumulated_time_shifted") {
    if (!Valor) {
      errors["time_checkpoint/acumulated_time_shifted"] = "*Insira um Nome";
    }
  }
  
  if (Campo_Tabela === "time_checkpoint/update_type") {
    if (!Valor) {
      errors["time_checkpoint/update_type"] = "*Insira um Nome";
    }
  }
  if (Campo_Tabela === "time_checkpoint/update_user") {
    if (!Valor) {
      errors["time_checkpoint/update_user"] = "*Insira um Nome";
    }
  }
  
  if (Campo_Tabela === "time_checkpoint/result_status") {
    if (!Valor) {
      errors["time_checkpoint/result_status"] = "*Insira um Nome";
    }
  }
  
  if (Campo_Tabela === "time_checkpoint/inscription_athlete_athlete_id") {
    if (!Valor) {
      errors["time_checkpoint/inscription_athlete_athlete_id"] =
        "*Insira um Nome";
    }
  }
  */
  if (Campo_Tabela === "time_checkpoint/time_penalty") {
    if (!Valor) {
      errors["time_checkpoint/time_penalty"] = "*Insira a Penalidade";
    }
  }
  if (Campo_Tabela === "time_checkpoint/time_status") {
    if (!Valor) {
      errors["time_checkpoint/time_status"] = "*Insira o Estado do Tempo";
    }
  }

  //Tabela Trail Event:
  //Tabela Trail Event:
  //Tabela Trail Event:
  //Tabela Trail Event:
  //Tabela Trail Event:
  //Tabela Trail Event:
  //Tabela Trail Event:
  //Tabela Trail Event:
  //Tabela Trail Event:
  //Tabela Trail Event:
  //Tabela Trail Event:
  //Tabela Trail Event:
  //Tabela Trail Event:
  //Tabela Trail Event:

  if (Campo_Tabela === "trail_event/name") {
    if (!Valor) {
      errors["trail_event/name"] = "*Insira o Nome do Evento";
    }
  }
  if (Campo_Tabela === "trail_event/init_date") {
    if (!Valor) {
      errors["trail_event/init_date"] = "*Insira a Data de Início do Evento";
    }
  }
  if (Campo_Tabela === "trail_event/end_date") {
    if (!Valor) {
      errors["trail_event/end_date"] = "*Insira a Data de Fim do Evento";
    }
  }
  if (Campo_Tabela === "trail_event/description") {
    if (!Valor) {
      errors["trail_event/description"] = "*Insira a Descrição do Evento";
    }
  }
  if (Campo_Tabela === "trail_event/status") {
    if (!Valor) {
      errors["trail_event/status"] = "*Escolha o Estado do Evento";
    }
  }
  if (Campo_Tabela === "trail_event/short_name") {
    if (!Valor) {
      errors["trail_event/short_name"] = "*Insira a Abreviatura do Evento";
    }
  }
  /*
  if (Campo_Tabela === "trail_event/organization_id") {
    if (!Valor) {
      errors["trail_event/organization_id"] = "*Insira um Nome";
    }
  }
  */
  if (Campo_Tabela === "trail_event/image_url") {
    if (!Valor) {
      errors["trail_event/image_url"] = "*Insira o URL da Imagem do Evento";
    }
  }
  if (Campo_Tabela === "trail_event/event_url") {
    if (!Valor) {
      errors["trail_event/event_url"] = "*Insira o URL do Evento";
    }
  }
  if (Campo_Tabela === "trail_event/bgcolor") {
    if (!Valor) {
      errors["trail_event/bgcolor"] = "*Insira a Cor do Evento";
    }
  }
  //Tabela Trail_Event Caso Particular:
  if (Campo_Tabela === "trail_event/linha_evento") {
    if (!Valor) {
      errors["trail_event/linha_evento"] = "*Escolha um Evento";
    }
  }

  //Tabela Utilizadores_Registados:
  //Tabela Utilizadores_Registados:
  //Tabela Utilizadores_Registados:
  //Tabela Utilizadores_Registados:
  //Tabela Utilizadores_Registados:
  //Tabela Utilizadores_Registados:
  //Tabela Utilizadores_Registados:
  //Tabela Utilizadores_Registados:
  //Tabela Utilizadores_Registados:
  //Tabela Utilizadores_Registados:

  if (Campo_Tabela === "utilizadores_registados/nome") {
    if (!Valor) {
      errors["utilizadores_registados/nome"] =
        "*Insira o Nome Completo do Utilizador";
    }
  }
  if (Campo_Tabela === "utilizadores_registados/email") {
    if (!Valor) {
      errors["utilizadores_registados/email"] = "*Insira o Email do Utilizador";
    }
  }
  if (Campo_Tabela === "utilizadores_registados/login") {
    if (!Valor) {
      errors["utilizadores_registados/login"] = "*Insira o Nome de Login";
    }
  }
  if (Campo_Tabela === "utilizadores_registados/password") {
    if (!Valor) {
      errors["utilizadores_registados/password"] = "*Insira a Password";
    }
  }
  if (Campo_Tabela === "utilizadores_registados/tipo_utilizador") {
    if (!Valor) {
      errors["utilizadores_registados/tipo_utilizador"] =
        "*Escolha o Tipo de Utilizador";
    }
  }
  if (Campo_Tabela === "utilizadores_registados/id_organizacao") {
    if (!Valor) {
      errors["utilizadores_registados/id_organizacao"] =
        "*Escolha a Organização do Utilizador";
    }
  }

  /*
  if (Campo_Tabela === "utilizadores_registados/eliminado") {
    if (!Valor) {
      errors["utilizadores_registados/eliminado"] = "*Insira um Nome";
    }
  }
  */
  /*
  if (Campo_Tabela === "utilizadores_registados/data_inscricao") {
    if (!Valor) {
      errors["utilizadores_registados/data_inscricao"] = "*Insira um Nome";
    }
  }
  if (Campo_Tabela === "utilizadores_registados/inscrito_por_utilizador_id") {
    if (!Valor) {
      errors["utilizadores_registados/inscrito_por_utilizador_id"] = "*Insira um Nome";
    }
  }
  */

  return errors;
};
