import React, { Component } from "react";
//import { Link } from "react-router-dom";
import axios from "axios";
//import ReactPaginate from "react-paginate";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

//Imports Para as Datas:
import Moment from "moment";

//Imports Para Drawer e Snackbar:
import Drawer from "../Drawer/Drawer.js";

//Funções Auxiliares:
import {
  mostra_mensagem_erro,
  ve_resultados
} from "../Funcoes_Auxiliares/Funcoes_Auxiliares.js";

let REACT_APP_API_URL;

if (process.env.REACT_APP_ESTADO === "Deploy") {
  REACT_APP_API_URL = process.env.REACT_APP_API_URL_PROD;
} //Development
else {
  REACT_APP_API_URL = process.env.REACT_APP_API_URL_DEV;
}

class Tabela_Lista_Notificacoes_Prova extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Link_Menu_Anterior: null,
      ID_Prova: null,
      ID_Evento: null,
      //items: undefined, //[],
      //Headers_Colunas: [],
      Nome_Evento: null,
      Nome_Prova: null,

      primeiro_tempo_todos_checkpoints_prova: undefined,
      total_atletas_chegaram_checkpoints: undefined,
      //data_inicio_prova: undefined,
      //data_fim_prova: undefined,
      total_resultados_atletas: undefined,
      total_checkpoints_prova: undefined,
      permissao_alterar_atletas: undefined,

      //Variavel Controlo de Erro na Leitura dos Props
      Mostra_Erro: false,

      //Variaveis do LocalStorage:
      //Mostrar_Notificacoes_Ordem_Checkpoints: 1, //null, //localStorage.getItem("Mostrar_Notificacoes_Ordem_Checkpoints"),

      /*
      Mostrar_Notificacoes_Ordem_Checkpoints: localStorage.getItem("Mostrar_Notificacoes_Ordem_Checkpoints"),
      //Mostrar_Notificacoes_Atletas_Adiantados: 1, //null, //localStorage.getItem("Mostrar_Notificacoes_Atletas_Adiantados"),
      Mostrar_Notificacoes_Atletas_Adiantados: localStorage.getItem("Mostrar_Notificacoes_Atletas_Adiantados"),
      //Mostrar_Notificacoes_Atletas_Atrasados: 1 //null //localStorage.getItem("Mostrar_Notificacoes_Atletas_Atrasados")
      Mostrar_Notificacoes_Atletas_Atrasados: localStorage.getItem("Mostrar_Notificacoes_Atletas_Atrasados"),
      Mostrar_Notificacoes_Tempos_Depois_Desistencia_Atletas: localStorage.getItem("Mostrar_Notificacoes_Tempos_Depois_Desistencia_Atletas"),
      Mostrar_Notificacoes_Podio_Atletas_Regionais: localStorage.getItem("Mostrar_Notificacoes_Podio_Atletas_Regionais"),
      Mostrar_Notificacoes_Podio_Atletas_Portugal: localStorage.getItem("Mostrar_Notificacoes_Podio_Atletas_Portugal"),
      Mostrar_Notificacoes_Podio_Atletas_Mundo: localStorage.getItem("Mostrar_Notificacoes_Podio_Atletas_Mundo"),
      */

      podios_regionais: undefined,
      podios_nacionais: undefined,
      podios_mundo: undefined,

      //Variaveis Para os Erros das Inscricoes:
      todas_inscricoes_prova: undefined,
      todos_escaloes_sistema: undefined,
      todos_paises_sistema: undefined,
      todos_estados_atletas_sistema: undefined
    };

    //this.state.Mostrar_Notificacoes_Ordem_Checkpoints = localStorage.getItem("Mostrar_Notificacoes_Ordem_Checkpoints");
    //this.state.Mostrar_Notificacoes_Atletas_Adiantados = localStorage.getItem("Mostrar_Notificacoes_Atletas_Adiantados");
    //this.state.Mostrar_Notificacoes_Atletas_Atrasados = localStorage.getItem("Mostrar_Notificacoes_Atletas_Atrasados");

    if (props.location.state === undefined) {
      this.state.Mostra_Erro = true;
    } else {
      if (props.location.state.Link_Menu_Anterior === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Link_Menu_Anterior = props.location.state.Link_Menu_Anterior;
      }
      if (props.location.state.ID_Evento === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.ID_Evento = props.location.state.ID_Evento;
      }
      if (props.location.state.ID_Prova === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.ID_Prova = props.location.state.ID_Prova;
      }
      if (props.location.state.Nome_Evento === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Nome_Evento = props.location.state.Nome_Evento;
      }
      if (props.location.state.Nome_Prova === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Nome_Prova = props.location.state.Nome_Prova;
      }
    }
  } //Fecha Construtor da Class

  Vai_Buscar_Primeiro_Tempo_Todos_Checkpoints_Competicao() {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .get(
        REACT_APP_API_URL +
          "/devolve_primeiro_tempo_todos_checkpoints_competicao/" +
          this.state.ID_Prova,
        {
          headers: { authorization: "Bearer " + Token_Lido }
        }
      )
      .then(res => {
        console.log(res);
        this.setState({ primeiro_tempo_todos_checkpoints_prova: res.data });
      });
  }

  Vai_Buscar_Total_Atletas_Chegaram_Checkpoints_Competicao() {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .get(
        REACT_APP_API_URL +
          "/devolve_total_atletas_chegaram_checkpoints_competicao/" +
          this.state.ID_Prova,
        {
          headers: { authorization: "Bearer " + Token_Lido }
        }
      )
      .then(res => {
        console.log(res);
        this.setState({ total_atletas_chegaram_checkpoints: res.data });
      });
  }

  /*
  Vai_Buscar_Data_Fim_Evento() {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .get(
        REACT_APP_API_URL + "/devolve_data_fim_evento/" + this.state.ID_Prova,
        {
          headers: { authorization: "Bearer " + Token_Lido }
        }
      )
      .then(res => {
        console.log(res);
        this.setState({ data_inicio_prova: res.data[0] });
      });
  }
  */

  /*
  Vai_Buscar_Data_Fim_Competicao() {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .get(
        REACT_APP_API_URL +
          "/devolve_data_fim_competicao/" +
          this.state.ID_Prova,
        {
          headers: { authorization: "Bearer " + Token_Lido }
        }
      )
      .then(res => {
        console.log(res);
        this.setState({ data_fim_prova: res.data[0] });
      });
  }
  */

  Vai_Buscar_Todos_Resultados_Atletas_Competicao() {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .get(
        REACT_APP_API_URL +
          "/devolve_todos_resultados_atletas_competicao/" +
          this.state.ID_Prova,
        {
          headers: { authorization: "Bearer " + Token_Lido }
        }
      )
      .then(res => {
        console.log(res);
        this.setState({ total_resultados_atletas: res.data });
      });
  }

  Vai_Buscar_Numero_Checkpoints_Competicao() {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .get(
        REACT_APP_API_URL +
          "/devolve_numero_checkpoints_prova/" +
          this.state.ID_Prova,
        {
          headers: { authorization: "Bearer " + Token_Lido }
        }
      )
      .then(res => {
        console.log(res);
        this.setState({ total_checkpoints_prova: res.data[0].Total });
      });
  }

  Vai_Buscar_Podios_Regionais() {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .get(
        REACT_APP_API_URL + "/devolve_podios_regionais/" + this.state.ID_Prova,
        {
          headers: { authorization: "Bearer " + Token_Lido }
        }
      )
      .then(res => {
        console.log(res);
        this.setState({ podios_regionais: res.data });
      });
  }

  Vai_Buscar_Podios_Nacionais() {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .get(
        REACT_APP_API_URL + "/devolve_podios_nacionais/" + this.state.ID_Prova,
        {
          headers: { authorization: "Bearer " + Token_Lido }
        }
      )
      .then(res => {
        console.log(res);
        this.setState({ podios_nacionais: res.data });
      });
  }

  Vai_Buscar_Podios_Mundo() {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .get(REACT_APP_API_URL + "/devolve_podios_mundo/" + this.state.ID_Prova, {
        headers: { authorization: "Bearer " + Token_Lido }
      })
      .then(res => {
        console.log(res);
        this.setState({ podios_mundo: res.data });
      });
  }

  Vai_Buscar_Inscricoes_Atleta_Competicao() {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .get(
        REACT_APP_API_URL +
          "/devolve_atletas_inscritos_competicao/" +
          this.state.ID_Prova,
        {
          headers: { authorization: "Bearer " + Token_Lido }
        }
      )
      .then(res => {
        console.log(res);
        this.setState({ todas_inscricoes_prova: res.data });
      });
  }

  Vai_Buscar_Escaloes_Sistema() {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .get(REACT_APP_API_URL + "/devolve_escaloes", {
        headers: { authorization: "Bearer " + Token_Lido }
      })
      .then(res => {
        console.log(res);
        this.setState({ todos_escaloes_sistema: res.data });
      });
  }

  Vai_Buscar_Paises_Sistema() {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .get(REACT_APP_API_URL + "/devolve_paises_sistema", {
        headers: { authorization: "Bearer " + Token_Lido }
      })
      .then(res => {
        console.log(res);
        this.setState({ todos_paises_sistema: res.data });
      });
  }

  Vai_Buscar_Estados_Atletas_Sistema() {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .get(REACT_APP_API_URL + "/devolve_estados_inscricao", {
        headers: { authorization: "Bearer " + Token_Lido }
      })
      .then(res => {
        console.log(res);
        this.setState({ todos_estados_atletas_sistema: res.data });
      });
  }

  Vai_Buscar_Permissao_Atlerar_Atletas() {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .get(
        REACT_APP_API_URL +
          "/devolve_permissao_alteracao_atletas/" +
          this.state.ID_Prova,
        {
          headers: { authorization: "Bearer " + Token_Lido }
        }
      )
      .then(res => {
        console.log(res);
        this.setState({
          permissao_alterar_atletas: res.data[0].alterar_atletas
        });
      });
  }

  componentDidMount() {
    if (localStorage.getItem("Token") !== null) {
      this.Vai_Buscar_Permissao_Atlerar_Atletas();

      this.Vai_Buscar_Primeiro_Tempo_Todos_Checkpoints_Competicao();
      this.Vai_Buscar_Total_Atletas_Chegaram_Checkpoints_Competicao();
      //this.Vai_Buscar_Data_Fim_Evento();
      //this.Vai_Buscar_Data_Fim_Competicao();
      this.Vai_Buscar_Todos_Resultados_Atletas_Competicao();
      this.Vai_Buscar_Numero_Checkpoints_Competicao();
      this.Vai_Buscar_Podios_Regionais();
      this.Vai_Buscar_Podios_Nacionais();
      this.Vai_Buscar_Podios_Mundo();

      this.Vai_Buscar_Inscricoes_Atleta_Competicao();
      this.Vai_Buscar_Escaloes_Sistema();
      this.Vai_Buscar_Paises_Sistema();
      this.Vai_Buscar_Estados_Atletas_Sistema();
    }
  } //Fecha Procedimento Que Acontece Depois do Output do Componente Foi Rendered

  Mostra_Linha_Branco(Linha_Branco) {
    if (Linha_Branco === true) {
      return (
        <tr>
          <td colspan="11" />
        </tr>
      );
    }
  }

  render() {
    if (localStorage.getItem("Token") === null) {
      this.props.history.push({
        pathname: "/"
      });
    }

    //Verifica Se Existe Erro. Se Houver Mostra Mensagem de Erro
    if (this.state.Mostra_Erro === true) {
      return (
        <div>
          <Drawer
            Titulo_Menu="" //"Erro"
            history={this.props.history}
          />
          {mostra_mensagem_erro()}
        </div>
      );
    } else {
      if (
        this.state.primeiro_tempo_todos_checkpoints_prova === undefined ||
        this.state.total_atletas_chegaram_checkpoints === undefined ||
        //this.state.data_inicio_prova === undefined ||
        //this.state.data_fim_prova === undefined ||
        this.state.total_resultados_atletas === undefined ||
        this.state.total_checkpoints_prova === undefined ||
        this.state.podios_regionais === undefined ||
        this.state.podios_nacionais === undefined ||
        this.state.podios_mundo === undefined ||
        this.state.todas_inscricoes_prova === undefined ||
        this.state.todos_escaloes_sistema === undefined ||
        this.state.todos_paises_sistema === undefined ||
        this.state.todos_estados_atletas_sistema === undefined ||
        this.state.permissao_alterar_atletas === undefined
      ) {
        return (
          <div className="centra_loading">
            <h1>Loading...</h1>
          </div>
        );
      } else {
        //Erros nas Inscrições:
        let Array_Notificacoes_Erros_Inscricoes = [];

        //Campos Tabela Inscription:
        //Poe Todos os Escaloes Num Array (Campo "Category")
        let Array_Escaloes_Sistema = [];
        //Poe Todos os Estados do Atleta Num Array (Campo "athlete_status" e "inscription_status_code")
        let Array_Estados_Atletas_Sistema = [];

        //Campos Tabela Athlete:
        //Poe Todos os Sexos Num Array (Campo "Gender")
        let Array_Sexos_Atletas = ["M", "F"];
        //Poe Todos os Paises Num Array (Campo "Nacionality" e "Country")
        let Array_Paises_Sistema = [];

        this.state.todos_escaloes_sistema.forEach(function(linha3) {
          Array_Escaloes_Sistema.push(linha3.categorie_id);
        });

        this.state.todos_estados_atletas_sistema.forEach(function(linha3) {
          Array_Estados_Atletas_Sistema.push(linha3.code);
        });

        this.state.todos_paises_sistema.forEach(function(linha3) {
          Array_Paises_Sistema.push(linha3.name_en);
        });

        let permissao_altera_atletas = this.state.permissao_alterar_atletas;

        //Passar os Dados Para os Arrays:
        this.state.todas_inscricoes_prova.forEach(function(linha4) {
          if (Array_Escaloes_Sistema.indexOf(linha4.category) === -1) {
            Array_Notificacoes_Erros_Inscricoes.push([
              linha4.name, //athlete_name,
              "Escalão",
              linha4.category,
              "Dados da Inscrição"
            ]);
          }

          if (
            //Array_Estados_Atletas_Sistema.indexOf(linha4.insc_status) === -1
            Array_Estados_Atletas_Sistema.indexOf(
              linha4.inscription_status_code
            ) === -1
          ) {
            Array_Notificacoes_Erros_Inscricoes.push([
              linha4.name, //athlete_name,
              "Estado da Inscrição",
              linha4.inscription_status_code, //insc_status,
              "Dados da Inscrição"
            ]);
          }
          if (
            Array_Estados_Atletas_Sistema.indexOf(linha4.athlete_status) === -1
          ) {
            Array_Notificacoes_Erros_Inscricoes.push([
              linha4.name, //athlete_name,
              "Estado na Competição",
              linha4.athlete_status,
              "Dados da Inscrição"
            ]);
          }
          if (permissao_altera_atletas === 1) {
            //if (this.state.permissao_alterar_atletas === 1) {
            if (Array_Sexos_Atletas.indexOf(linha4.gender) === -1) {
              Array_Notificacoes_Erros_Inscricoes.push([
                linha4.name, //athlete_name,
                "Sexo",
                linha4.gender,
                "Dados do Atleta"
              ]);
            }
            if (Array_Paises_Sistema.indexOf(linha4.nacionality) === -1) {
              Array_Notificacoes_Erros_Inscricoes.push([
                linha4.name, //athlete_name,
                "Nacionalidade",
                linha4.nacionality,
                "Dados do Atleta"
              ]);
            }
            if (Array_Paises_Sistema.indexOf(linha4.country) === -1) {
              Array_Notificacoes_Erros_Inscricoes.push([
                linha4.name, //athlete_name,
                "País",
                linha4.country,
                "Dados do Atleta"
              ]);
            }
          }
        });

        ////////////////////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////////////////////////////////////
        if (this.state.total_resultados_atletas.length > 0) {
          //Variaveis Para as Notificacoes da Ordem dos Checkpoints Errada:
          let Numero_Checkpoints_Competicao = this.state
            .total_checkpoints_prova;
          //const Todos_Resultados_Atletas = this.state.total_resultados_atletas.length;
          let ID_Inscricao_Atleta_Analisar = -1; //Começa a -1 Porque Nenhuma Inscrição de Atleta Tem Esse ID
          let Peitoral_Atleta_Analisar = -1;
          let Nome_Atleta_Analisar = -1;
          let Array_Notificacoes_Resultados_Atletas = [];
          let Escreve_Erro = true;

          let aux = 1;
          let Numero_Checkpoint_Actual = 0;
          //Debug: Competition_id=188 -> Maxi Race, Long. 187 Tem 8 Checkpoints Mas Apenas Resultados Para 7 CP's

          //Variaveis Para as Notificacoes de Tempos Apos Desistencia Atleta:
          let Array_Notificacoes_Atletas_Com_Resultados_Depois_Desistencia = [];
          let Time_Status_Anterior = "EP";
          let Encontrou_Desistencia = false;

          //Arrays Para os Podios:
          let Array_Podios_Regionais = [];
          let Array_Podios_Nacionais = [];
          let Array_Podios_Mundiais = [];

          let Array_Checkpoints_Atleta_Passou = [];
          let Encontrou_Erro_Falta_Tempos = 0;

          this.state.total_resultados_atletas.forEach(function(linha) {
            if (
              ID_Inscricao_Atleta_Analisar !== linha.athlete_id //linha.athlete_id //inscription_athlete_athlete_id
            ) {
              if (ID_Inscricao_Atleta_Analisar !== -1) {
                Array_Checkpoints_Atleta_Passou.sort(function(a, b) {
                  return a - b;
                });
                //Verifica se o Array Esta Seguido. Se Nao Estiver, Devolve Erro, Falta Tempos
                //index = 0;
                //foreach(Array_Checkpoints_Atleta_Passou){
                for (
                  let index = 0;
                  index < Array_Checkpoints_Atleta_Passou.length;
                  index++
                ) {
                  if (index !== Array_Checkpoints_Atleta_Passou[index]) {
                    /*
                    if (
                      index === Numero_Checkpoints_Competicao &&
                      Array_Checkpoints_Atleta_Passou[index] === 99
                    ) {
                    } else {
                      Encontrou_Erro_Falta_Tempos = 1;
                    }
                    */

                    if (index === Numero_Checkpoints_Competicao) {
                      if (Array_Checkpoints_Atleta_Passou[index] !== 99) {
                        Encontrou_Erro_Falta_Tempos = 1;
                      }
                    }
                    index++;
                  }
                }

                if (Encontrou_Erro_Falta_Tempos === 1) {
                  Array_Notificacoes_Resultados_Atletas.push([
                    //"Resultados Incorrectos", //"Resultados do Atleta Incorrectos",
                    "Falta Tempos", //"Ordem dos Checkpoints Errada",
                    Peitoral_Atleta_Analisar, //dorsal_number,
                    Nome_Atleta_Analisar
                  ]);
                }
              }

              Encontrou_Erro_Falta_Tempos = 0;

              //Parte das Notificacoes da Ordem dos Checkpoints Errada:
              ID_Inscricao_Atleta_Analisar = linha.athlete_id; //linha.athlete_id; //inscription_athlete_athlete_id;
              Peitoral_Atleta_Analisar = linha.peitoral;
              Nome_Atleta_Analisar = linha.name;
              Escreve_Erro = true;
              aux = 1;
              Numero_Checkpoint_Actual = 0;
              //Numero_Resultados_Atleta = 0;

              //Parte das Notificacoes de Tempos Apos Desistencia Atletas:
              Encontrou_Desistencia = false;
              Time_Status_Anterior = "EP";

              //Parte das Notificacoes Sobre Resultados nos Checkpoints:
              //Encontrou_Ordem_Checkpoints_Errados = 0;
              //Encontrou_Falta_Tempos = 0;
              Array_Checkpoints_Atleta_Passou = [];
            }
            if (aux === Numero_Checkpoints_Competicao) {
              //<=> 99
              Numero_Checkpoint_Actual = 99;
            }
            //Array_Checkpoints_Atleta_Passou.push(Number(linha.checkpoint_order));
            Array_Checkpoints_Atleta_Passou.push(linha.checkpoint_order * 1);

            if (Number(linha.checkpoint_order) !== Numero_Checkpoint_Actual) {
              //if (Escreve_Erro && Mostra_Ordem_Checkpoints === "true") {
              if (Escreve_Erro) {
                Array_Notificacoes_Resultados_Atletas.push([
                  //"Resultados Incorrectos", //"Resultados do Atleta Incorrectos",
                  "Ordem Checkpoints Errados", //"Ordem dos Checkpoints Errada",
                  linha.peitoral, //dorsal_number,
                  linha.name
                ]);
                Escreve_Erro = false;
              }
            }

            //Se Encontrou 1 Vez Que Seja um Valor Que Nao Seja "EP", Devolve Erro Para Quaisquer Valores Seguintes
            //Ex: Encontra Valor Diferente de "EP" no 2º Tempo. Logo Nao Deve Existir Mais Tempos. Se Existir, Devolve Erro
            if (Time_Status_Anterior !== "EP") {
              Encontrou_Desistencia = true;
            }

            //if ((linha.time_status === "DNS" || linha.time_status === "DNF") &&Time_Status_Anterior === "EP") {
            //if (Encontrou_Desistencia &&Mostra_Tempos_Depois_Desistencia_Atletas === "true") {
            if (Encontrou_Desistencia) {
              Array_Notificacoes_Atletas_Com_Resultados_Depois_Desistencia.push(
                [
                  //"Tempos Após Desistência Atleta",
                  linha.peitoral, //dorsal_number,
                  linha.name,
                  linha.local,
                  linha.time_status
                ]
              );
            }

            Time_Status_Anterior = linha.time_status;
            Numero_Checkpoint_Actual = Numero_Checkpoint_Actual + 1;
            aux = aux + 1;
          });
          //}

          ////////////////////////////////////////////////////////////////////////////////////////////////////
          ////////////////////////////////////////////////////////////////////////////////////////////////////
          ////////////////////////////////////////////////////////////////////////////////////////////////////
          ////////////////////////////////////////////////////////////////////////////////////////////////////
          ////////////////////////////////////////////////////////////////////////////////////////////////////
          ////////////////////////////////////////////////////////////////////////////////////////////////////
          ////////////////////////////////////////////////////////////////////////////////////////////////////
          ////////////////////////////////////////////////////////////////////////////////////////////////////
          ////////////////////////////////////////////////////////////////////////////////////////////////////
          ////////////////////////////////////////////////////////////////////////////////////////////////////
          ////////////////////////////////////////////////////////////////////////////////////////////////////
          ////////////////////////////////////////////////////////////////////////////////////////////////////
          ////////////////////////////////////////////////////////////////////////////////////////////////////
          ////////////////////////////////////////////////////////////////////////////////////////////////////
          ////////////////////////////////////////////////////////////////////////////////////////////////////
          ////////////////////////////////////////////////////////////////////////////////////////////////////
          ////////////////////////////////////////////////////////////////////////////////////////////////////
          ////////////////////////////////////////////////////////////////////////////////////////////////////
          ////////////////////////////////////////////////////////////////////////////////////////////////////
          ////////////////////////////////////////////////////////////////////////////////////////////////////
          ////////////////////////////////////////////////////////////////////////////////////////////////////
          ////////////////////////////////////////////////////////////////////////////////////////////////////
          ////////////////////////////////////////////////////////////////////////////////////////////////////
          ////////////////////////////////////////////////////////////////////////////////////////////////////
          ////////////////////////////////////////////////////////////////////////////////////////////////////
          ////////////////////////////////////////////////////////////////////////////////////////////////////
          ////////////////////////////////////////////////////////////////////////////////////////////////////
          ////////////////////////////////////////////////////////////////////////////////////////////////////
          ////////////////////////////////////////////////////////////////////////////////////////////////////
          ////////////////////////////////////////////////////////////////////////////////////////////////////
          ////////////////////////////////////////////////////////////////////////////////////////////////////
          ////////////////////////////////////////////////////////////////////////////////////////////////////
          ////////////////////////////////////////////////////////////////////////////////////////////////////
          ////////////////////////////////////////////////////////////////////////////////////////////////////
          ////////////////////////////////////////////////////////////////////////////////////////////////////
          ////////////////////////////////////////////////////////////////////////////////////////////////////
          ////////////////////////////////////////////////////////////////////////////////////////////////////
          ////////////////////////////////////////////////////////////////////////////////////////////////////

          var Lugar_Podio = 0;

          //Parte dos Podios:
          this.state.podios_regionais.forEach(function(linha3) {
            //if (Mostra_Podios_Atletas_Regionais === "true") {
            Lugar_Podio = Lugar_Podio + 1;

            if (Lugar_Podio === 4) {
              Lugar_Podio = 1;
              Array_Podios_Regionais.push([
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                //"",
                ""
              ]);
            }

            Array_Podios_Regionais.push([
              "Pódios Regionais",
              linha3.peitoral,
              linha3.raking_name + " " + linha3.raking_name_last,
              Moment(linha3.time).format("DD-MM-YYYY HH:mm:ss"),
              linha3.acumulated_time,
              linha3.sexo, //Sexo_Atleta,
              linha3.club,
              linha3.escalao,
              Lugar_Podio + "º Lugar Regional",
              //Tipo_Atleta,
              linha3.country
            ]);
            //}
          });

          this.state.podios_nacionais.forEach(function(linha3) {
            //if (Mostra_Podios_Atletas_Portugal === "true") {
            Lugar_Podio = Lugar_Podio + 1;

            if (Lugar_Podio === 4) {
              Lugar_Podio = 1;
              Array_Podios_Nacionais.push([
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                //"",
                ""
              ]);
            }

            Array_Podios_Nacionais.push([
              "Pódios Nacionais",
              linha3.peitoral,
              linha3.raking_name + " " + linha3.raking_name_last,
              Moment(linha3.time).format("DD-MM-YYYY HH:mm:ss"),
              linha3.acumulated_time,
              linha3.sexo, //Sexo_Atleta,
              linha3.club,
              linha3.escalao,
              Lugar_Podio + "º Lugar Portugal",
              //Tipo_Atleta,
              linha3.country
            ]);
            //}
          });

          this.state.podios_mundo.forEach(function(linha3) {
            //if (Mostra_Podios_Atletas_Mundo === "true") {
            Lugar_Podio = Lugar_Podio + 1;

            if (Lugar_Podio === 4) {
              Lugar_Podio = 1;
              Array_Podios_Mundiais.push([
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                //"",
                ""
              ]);
            }

            Array_Podios_Mundiais.push([
              "Pódios Mundiais",
              linha3.peitoral,
              linha3.raking_name + " " + linha3.raking_name_last,
              Moment(linha3.time).format("DD-MM-YYYY HH:mm:ss"),
              linha3.acumulated_time,
              linha3.sexo, //Sexo_Atleta,
              linha3.club,
              linha3.escalao,
              Lugar_Podio + "º Lugar Geral",
              //Tipo_Atleta,
              linha3.country
            ]);
            //}
          });

          return (
            <div>
              <Drawer
                Titulo_Menu="Lista de Notificações da Prova"
                Link_Menu_Anterior={this.state.Link_Menu_Anterior}
                Link_Tabela_Anterior={this.props.location.pathname} //{this.state.Link_Tabela_Anterior}
                ID_Evento={this.state.ID_Evento}
                ID_Prova={this.state.ID_Prova}
                Nome_Evento={this.state.Nome_Evento}
                Nome_Prova={this.state.Nome_Prova}
                history={this.props.history}
              />
              <div className="novo_centra_tabelas">
                <Tabs>
                  <TabList>
                    <Tab>Tempos Errados Atletas</Tab>
                    <Tab>Previsões Chegadas Atletas</Tab>
                    <Tab>Tempos Depois Desistência Atletas</Tab>
                    <Tab>Pódios Atletas {/*Regionais/Portugal/Mundo*/}</Tab>
                    <Tab>Erros Dados Inscrições Atletas</Tab>
                    {/*<Tab>Erros nos Países</Tab>
                    <Tab>Erros nos Escalões</Tab>*/}
                  </TabList>
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  <TabPanel>
                    <tabela>
                      <thead>
                        <tr>
                          <th>Notificação</th>
                          {/*<th>Categoria</th>*/}
                          <th>Dorsal Atleta</th>
                          <th>Nome Atleta</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array_Notificacoes_Resultados_Atletas.map(linha6 => {
                          //if (Mostra_Ordem_Checkpoints === "true") {
                          return (
                            <tr>
                              <td>{linha6[0]}</td>
                              <td>{linha6[1]}</td>
                              <td>{linha6[2]}</td>
                              {/*<td>linha6[3]</td>*/}
                            </tr>
                          );
                          //}
                        })}
                      </tbody>
                    </tabela>
                  </TabPanel>
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  <TabPanel>
                    <tabela>
                      <thead>
                        <tr>
                          <th>Notificação</th>
                          {
                            //<th>Categoria</th>
                          }
                          <th>Checkpoint</th>
                          <th>Previsão 1º Atleta</th>
                          <th>Tempo 1º Atleta</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.primeiro_tempo_todos_checkpoints_prova.map(
                          // eslint-disable-next-line
                          function(linha2) {
                            const Primeiro_Tempo_Chegada_Checkpoint = Moment(
                              linha2.Tempo_Chegada_Checkpoint
                            );
                            const Previsao_Rapido = Moment(
                              linha2.previsao_rapido
                            );
                            const Diferenca_Tempo = Primeiro_Tempo_Chegada_Checkpoint.diff(
                              Previsao_Rapido
                            ); //Negativo, Primeiro_Tempo_Chegada_Checkpoint<Previsao_Rapido

                            if (Diferenca_Tempo < 0) {
                              //if (Mostra_Adiantados === "true") {
                              return (
                                <tr>
                                  <td>Atletas Antecipados</td>
                                  {
                                    //<td>Previsões Chegadas Atletas</td>
                                  }
                                  <td>{linha2.local}</td>
                                  <td>
                                    {Moment(linha2.previsao_rapido).format(
                                      "DD-MM-YYYY HH:mm:ss"
                                    )}
                                  </td>
                                  <td>
                                    {Moment(
                                      linha2.Tempo_Chegada_Checkpoint
                                    ).format("DD-MM-YYYY HH:mm:ss")}
                                  </td>
                                </tr>
                              );
                              //}
                            } else if (Diferenca_Tempo > 0) {
                              //if (Mostra_Atrasados === "true") {
                              return (
                                <tr>
                                  <td>Atletas Atrasados</td>
                                  {/*<td>Previsões Chegadas Atletas</td>*/}
                                  <td>{linha2.local}</td>
                                  <td>
                                    {Moment(linha2.previsao_rapido).format(
                                      "DD-MM-YYYY HH:mm:ss"
                                    )}
                                  </td>
                                  <td>
                                    {Moment(
                                      linha2.Tempo_Chegada_Checkpoint
                                    ).format("DD-MM-YYYY HH:mm:ss")}
                                  </td>
                                </tr>
                              );
                              //}
                            } else if (Diferenca_Tempo === 0) {
                              return (
                                <tr>
                                  <td>Atletas a Horas</td>
                                  <td>{linha2.local}</td>
                                  <td>
                                    {Moment(linha2.previsao_rapido).format(
                                      "DD-MM-YYYY HH:mm:ss"
                                    )}
                                  </td>
                                  <td>
                                    {Moment(
                                      linha2.Tempo_Chegada_Checkpoint
                                    ).format("DD-MM-YYYY HH:mm:ss")}
                                  </td>
                                </tr>
                              );
                            } else {
                              return (
                                <tr>
                                  <td>Corrigir Datas</td>
                                  {/*<td>Previsões Chegadas Atletas</td>*/}
                                  <td>{linha2.local}</td>
                                  <td>
                                    {Moment(linha2.previsao_rapido).format(
                                      "DD-MM-YYYY HH:mm:ss"
                                    )}
                                  </td>
                                  <td>
                                    {Moment(
                                      linha2.Tempo_Chegada_Checkpoint
                                    ).format("DD-MM-YYYY HH:mm:ss")}
                                  </td>
                                </tr>
                              );
                            }
                          }
                        )}
                      </tbody>
                    </tabela>
                  </TabPanel>
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  <TabPanel>
                    <tabela>
                      <thead>
                        <tr>
                          <th>Dorsal Atleta</th>
                          <th>Nome Atleta</th>
                          <th>Checkpoint</th>
                          <th>Time Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array_Notificacoes_Atletas_Com_Resultados_Depois_Desistencia.map(
                          linha7 => {
                            //if (Mostra_Tempos_Depois_Desistencia_Atletas === "true") {
                            return (
                              <tr>
                                <td>{linha7[0]}</td>
                                <td>{linha7[1]}</td>
                                <td>{linha7[2]}</td>
                                <td>{linha7[3]}</td>
                                {/*<td>{linha7[4]}</td>*/}
                                {/*<td>{linha7[5]}</td>*/}
                              </tr>
                            );
                            //}
                          }
                        )}
                      </tbody>
                    </tabela>
                  </TabPanel>
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  <TabPanel>
                    <tabela>
                      <thead>
                        <tr>
                          <th>Notificação</th>
                          <th>Dorsal</th>
                          <th>Nome</th>
                          <th>Data Chegada</th>
                          <th>Duração Prova</th>
                          <th>Sexo</th>
                          <th>Clube</th>
                          <th>Escalão</th>
                          <th>Lugar Pódio</th>
                          {/*<th>Tipo Atleta{/*Regional,Nacional, etc</th>*/}
                          <th>País</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array_Podios_Regionais.map(linha6 => {
                          //if (Mostra_Ordem_Checkpoints === "true") {
                          return (
                            <tr>
                              <td>{linha6[0]}</td>
                              <td>{linha6[1]}</td>
                              <td>{linha6[2]}</td>
                              <td>{linha6[3]}</td>
                              <td>{linha6[4]}</td>
                              <td>{linha6[5]}</td>
                              <td>{linha6[6]}</td>
                              <td>{linha6[7]}</td>
                              <td>{linha6[8]}</td>
                              <td>{linha6[9]}</td>
                              {/*<td>{linha6[10]}</td>*/}
                            </tr>
                          );
                        })}

                        {Array_Podios_Nacionais.map(linha6 => {
                          //if (Mostra_Ordem_Checkpoints === "true") {
                          return (
                            <tr>
                              <td>{linha6[0]}</td>
                              <td>{linha6[1]}</td>
                              <td>{linha6[2]}</td>
                              <td>{linha6[3]}</td>
                              <td>{linha6[4]}</td>
                              <td>{linha6[5]}</td>
                              <td>{linha6[6]}</td>
                              <td>{linha6[7]}</td>
                              <td>{linha6[8]}</td>
                              <td>{linha6[9]}</td>
                              {/*<td>{linha6[10]}</td>*/}
                            </tr>
                          );
                        })}

                        {Array_Podios_Mundiais.map(linha6 => {
                          //if (Mostra_Ordem_Checkpoints === "true") {
                          return (
                            <tr>
                              <td>{linha6[0]}</td>
                              <td>{linha6[1]}</td>
                              <td>{linha6[2]}</td>
                              <td>{linha6[3]}</td>
                              <td>{linha6[4]}</td>
                              <td>{linha6[5]}</td>
                              <td>{linha6[6]}</td>
                              <td>{linha6[7]}</td>
                              <td>{linha6[8]}</td>
                              <td>{linha6[9]}</td>
                              {/*<td>{linha6[10]}</td>*/}
                            </tr>
                          );
                        })}
                      </tbody>
                    </tabela>
                  </TabPanel>
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  <TabPanel>
                    <tabela>
                      <thead>
                        <tr>
                          <th>Atleta</th>
                          <th>Campo Errado</th>
                          <th>Valor Errado</th>
                          <th>Categoria</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array_Notificacoes_Erros_Inscricoes.map(linha6 => {
                          return (
                            <tr>
                              <td>{linha6[0]}</td>
                              <td>{linha6[1]}</td>
                              <td>{linha6[2]}</td>
                              <td>{linha6[3]}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </tabela>
                  </TabPanel>
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*
                  <TabPanel>
                    <tabela>
                      <thead>
                        <tr>
                          <th>Notificação</th>
                          <th>Dorsal Atleta</th>
                          <th>Nome Atleta</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array_Notificacoes_Resultados_Atletas.map(linha6 => {
                          return (
                            <tr>
                              <td>{linha6[0]}</td>
                              <td>{linha6[1]}</td>
                              <td>{linha6[2]}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </tabela>
                  </TabPanel>
                  */}
                </Tabs>
              </div>
            </div>
          );
        } else {
          return (
            <div>
              <Drawer
                Titulo_Menu="Lista de Notificações da Prova"
                Link_Menu_Anterior={this.state.Link_Menu_Anterior}
                Link_Tabela_Anterior={this.props.location.pathname} //{this.state.Link_Tabela_Anterior}
                ID_Evento={this.state.ID_Evento}
                Nome_Evento={this.state.Nome_Evento}
                Nome_Prova={this.state.Nome_Prova}
                history={this.props.history}
              />
              {ve_resultados()}
            </div>
          );
        }
      }
    }
  }
}

export default Tabela_Lista_Notificacoes_Prova;
