import React from "react";
//import ReactDOM from "react-dom";
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
//import "./codigos_css/Bootstrap.css";
//import "./codigos_css/font_awesome.css";
//import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "./teste_css.css";

import "./New_App.css"; //Ficheiro Principal de css. Apagar os Outros

//ReactDOM.render(<App />, document.getElementById("root"));

render(
  <BrowserRouter basename="/admin">
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
