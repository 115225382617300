import React, { Component } from "react";
import "./Componentes_Eventos_Competicoes.css";
import { Link } from "react-router-dom";
import axios from "axios";

//Imports Para as Datas:
import Moment from "moment";
import "moment/locale/pt";

//Imports Para a Paginação:
import ReactPaginate from "react-paginate";

//Imports Para Drawer e Snackbar:
import Drawer from "../Drawer/Drawer.js";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import classNames from "classnames";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import green from "@material-ui/core/colors/green";
import amber from "@material-ui/core/colors/amber";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import WarningIcon from "@material-ui/icons/Warning";

//Funções Auxiliares:
import {
  mostra_mensagem_erro
  //ve_resultados
} from "../Funcoes_Auxiliares/Funcoes_Auxiliares.js";

let REACT_APP_API_URL;

if (process.env.REACT_APP_ESTADO === "Deploy") {
  REACT_APP_API_URL = process.env.REACT_APP_API_URL_PROD;
} //Development
else {
  REACT_APP_API_URL = process.env.REACT_APP_API_URL_DEV;
}

// https://material-ui.com/demos/snackbars/

//Constantes Para Drawer e Snackbar:
const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const styles1 = theme => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.dark
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit
  },
  message: {
    display: "flex",
    alignItems: "center"
  }
});

MySnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(["success", "warning", "error", "info"]).isRequired
};

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

//Funções Para Drawer e Snackbar:
function MySnackbarContent(props) {
  const { classes, className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>
      ]}
      {...other}
    />
  );
}

//Class Principal do Componente "Eventos"
class Eventos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: undefined,
      query_success: null,

      //Variavel de Controlo Para a Mensagem de Login Com Sucesso na Snackbar:
      Fez_Login: false,
      Fez_Insercao_Evento: false,
      Fez_Edicao_Evento: false,
      Fez_Copia_Evento: false,
      Fez_Eliminacao_Evento: false,
      Erro_Fez_Eliminacao_Evento: false,

      //Variaveis Para a Paginação:
      total_items: undefined,
      offset: 0,
      perPage: 10,
      pageCount: null,

      //Variavel de Controlo de Erro na Leitura dos Props:
      Mostra_Erro: false,

      //Variavel de Controlo Para Evitar o Bug da Paginação Não Aparecer
      Faz_Refresh: false,

      //Variaveis Para o Filtro:
      Nome_Evento_Filtro: null,
      Short_Name_Filtro: null
    };

    if (props.location.state === undefined) {
      this.state.Mostra_Erro = true;
    } else {
      if (props.location.state.Fez_Login === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Fez_Login = props.location.state.Fez_Login;
      }
      if (props.location.state.Fez_Insercao_Evento === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Fez_Insercao_Evento =
          props.location.state.Fez_Insercao_Evento;
      }
      if (props.location.state.Fez_Edicao_Evento === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Fez_Edicao_Evento = props.location.state.Fez_Edicao_Evento;
      }
      if (props.location.state.Faz_Refresh === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Faz_Refresh = props.location.state.Faz_Refresh;
      }
      if (props.location.state.Fez_Copia_Evento === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Fez_Copia_Evento = props.location.state.Fez_Copia_Evento;
      }
      if (props.location.state.Fez_Eliminacao_Evento === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        //this.state.Fez_Eliminacao_Evento =props.location.state.Fez_Eliminacao_Evento;
        if (props.location.state.Fez_Eliminacao_Evento === true) {
          this.state.Fez_Eliminacao_Evento = true;
          this.state.Erro_Fez_Eliminacao_Evento = false;
        } else if (props.location.state.Fez_Eliminacao_Evento === "erro") {
          this.state.Fez_Eliminacao_Evento = false;
          this.state.Erro_Fez_Eliminacao_Evento = true;
        } else {
          this.state.Fez_Eliminacao_Evento = false;
          this.state.Erro_Fez_Eliminacao_Evento = false;
        }
      }
    }

    //Nota: No Caso das Variaveis de Controlo da Snackbar, Se Não Forem Enviadas Pelos Outros Componentes, Assume se Que São False.
    //Neste Caso Não é Activada a Mensagem de Erro

    this.handlePageClick = this.handlePageClick.bind(this);
    this.handleChange = this.handleChange.bind(this);

    //Metodos Para Ordenações da Tabela
    this.compareBy.bind(this);
    this.sortBy.bind(this);
    this.compareByNumber.bind(this);
  } //Fecha Construtor da Class

  compareBy(key) {
    return function(a, b) {
      if (a[key] < b[key]) return -1;
      if (a[key] > b[key]) return 1;
      return 0;
    };
  }

  compareByNumber(key) {
    return function(a, b) {
      return parseInt(a[key]) - parseInt(b[key]);
    };
  }

  //Ordena Data do Mais Recente ao Mais Antigo
  compareByDate(key) {
    return function(a, b) {
      if (a[key] > b[key]) return -1;
      if (a[key] < b[key]) return 1;
      return 0;
    };
  }

  sortBy(key) {
    let arrayCopy = [...this.state.items];
    if (key === "init_date" || key === "end_date") {
      arrayCopy.sort(this.compareByDate(key));
    } else {
      arrayCopy.sort(this.compareBy(key));
    }
    this.setState({ items: arrayCopy });
  }

  //Funções Para a Paginação:
  handlePageClick = data => {
    let selected1 = data.selected;
    let offset1 = Math.ceil(selected1 * this.state.perPage);

    this.setState({ offset: offset1 }, () => {
      this.Vai_Buscar_Dados();
    });
  };

  //Funções Para Extrair Dados da BD:
  Vai_Buscar_Dados() {
    const Token_Lido = localStorage.getItem("Token");

    let Nome_Evento_Filtro_Enviar;
    if (this.state.Nome_Evento_Filtro === "") {
      Nome_Evento_Filtro_Enviar = null;
    } else {
      Nome_Evento_Filtro_Enviar = this.state.Nome_Evento_Filtro;
    }

    let Short_Name_Filtro_Enviar;
    if (this.state.Short_Name_Filtro === "") {
      Short_Name_Filtro_Enviar = null;
    } else {
      Short_Name_Filtro_Enviar = this.state.Short_Name_Filtro;
    }

    if (this.state.perPage > 0) {
      axios
        .get(
          //REACT_APP_API_URL +"/eventos/" +this.state.perPage +"/" +this.state.offset,
          REACT_APP_API_URL +
            "/eventos_filtro/" +
            this.state.perPage +
            "/" +
            this.state.offset +
            "/" +
            Nome_Evento_Filtro_Enviar +
            "/" +
            Short_Name_Filtro_Enviar,
          {
            headers: { authorization: "Bearer " + Token_Lido }
          }
        )
        .then(res => {
          console.log(res);
          //this.setState({ dados: res.data });
          this.setState({ items: res.data });
          this.setState({
            pageCount: Math.ceil(this.state.total_items / this.state.perPage)
          });
          /*
          if (this.state.Nome_Evento_Filtro !== null) {
            this.setState({
              pageCount: Math.ceil(this.state.items.length / this.state.perPage)
            });
          } else {
            this.setState({
              pageCount: Math.ceil(this.state.total_items / this.state.perPage)
            });
          }
          */
        });
    } else {
      axios
        .get(
          //REACT_APP_API_URL + "/eventos",
          REACT_APP_API_URL +
            "/eventos_filtro/" +
            //this.state.Nome_Evento_Filtro,
            Nome_Evento_Filtro_Enviar +
            "/" +
            Short_Name_Filtro_Enviar,
          {
            headers: { authorization: "Bearer " + Token_Lido }
          }
        )
        .then(res => {
          console.log(res);
          this.setState({ items: res.data });
          this.setState({ pageCount: 1 });
        });
    }
  }

  Vai_Buscar_Numero_Eventos_Sistema() {
    const Token_Lido = localStorage.getItem("Token");

    let Nome_Evento_Filtro_Enviar;
    if (this.state.Nome_Evento_Filtro === "") {
      Nome_Evento_Filtro_Enviar = null;
    } else {
      Nome_Evento_Filtro_Enviar = this.state.Nome_Evento_Filtro;
    }

    let Short_Name_Filtro_Enviar;
    if (this.state.Short_Name_Filtro === "") {
      Short_Name_Filtro_Enviar = null;
    } else {
      Short_Name_Filtro_Enviar = this.state.Short_Name_Filtro;
    }

    axios
      .get(
        //REACT_APP_API_URL + "/devolve_numero_eventos_sistema", {
        REACT_APP_API_URL +
          "/devolve_numero_eventos_sistema_filtro/" +
          //this.state.Nome_Evento_Filtro,
          Nome_Evento_Filtro_Enviar +
          "/" +
          Short_Name_Filtro_Enviar,
        {
          headers: { authorization: "Bearer " + Token_Lido }
        }
      )
      .then(res => {
        console.log(res);
        this.setState({ total_items: res.data[0].Total });
      });
  }

  componentDidMount() {
    if (localStorage.getItem("Token") !== null) {
      //if (!this.state.Faz_Refresh) {
      this.Vai_Buscar_Numero_Eventos_Sistema();
      this.Vai_Buscar_Dados();
      //}
    }
  } //Fecha Procedimento Que Acontece Depois do Output do Componente Foi Rendered

  //Actualiza os Dados Depois de Acontecer um Redirect Para Este Componente
  componentDidUpdate() {
    //nextProps) {
    //if (nextProps.location.pathname !== this.props.location.pathname) {
    if (this.state.Faz_Refresh) {
      this.Vai_Buscar_Numero_Eventos_Sistema();
      this.Vai_Buscar_Dados();
      this.setState({ Faz_Refresh: false });
    }
  }

  //Funcoes Para a Mensagem de Login Com Sucesso na Snackbar
  handleClose_Login = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ Fez_Login: false });
  };

  //Funcoes Para a Mensagem de Login Com Sucesso na Snackbar
  handleClose_Edicao = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ Fez_Edicao_Evento: false });
  };

  //Funcoes Para a Mensagem de Login Com Sucesso na Snackbar
  handleClose_Insercao = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ Fez_Insercao_Evento: false });
  };

  //Funcoes Para a Mensagem de Copia de Evento Com Sucesso na Snackbar
  handleClose_Copia = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ Fez_Copia_Evento: false });
  };

  handleClose_Eliminacao = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ Fez_Eliminacao_Evento: false });
  };

  handleClose_Erro_Eliminacao = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ Erro_Fez_Eliminacao_Evento: false });
  };

  Mostra_Mensagem_Login_Sucesso = () => {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={this.state.Fez_Login}
        autoHideDuration={3000}
        onClose={this.handleClose_Login}
      >
        <MySnackbarContentWrapper
          onClose={this.handleClose_Login}
          variant="success"
          message="Login Realizado Com Sucesso!"
        />
      </Snackbar>
    );
  };

  Mostra_Mensagem_Edicao_Sucesso = () => {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={this.state.Fez_Edicao_Evento}
        autoHideDuration={3000}
        onClose={this.handleClose_Edicao}
      >
        <MySnackbarContentWrapper
          onClose={this.handleClose_Edicao}
          variant="success"
          message="Evento Editado Com Sucesso!"
        />
      </Snackbar>
    );
  };

  Mostra_Mensagem_Insercao_Sucesso = () => {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={this.state.Fez_Insercao_Evento}
        autoHideDuration={3000}
        onClose={this.handleClose_Insercao}
      >
        <MySnackbarContentWrapper
          onClose={this.handleClose_Insercao}
          variant="success"
          message="Evento Inserido Com Sucesso!"
        />
      </Snackbar>
    );
  };

  Mostra_Mensagem_Copia_Sucesso = () => {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={this.state.Fez_Copia_Evento}
        autoHideDuration={3000}
        onClose={this.handleClose_Copia}
      >
        <MySnackbarContentWrapper
          onClose={this.handleClose_Copia}
          variant="success"
          message="Evento Copiado Com Sucesso!"
        />
      </Snackbar>
    );
  };

  Mostra_Mensagem_Eliminacao_Sucesso = () => {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={this.state.Fez_Eliminacao_Evento}
        autoHideDuration={3000}
        onClose={this.handleClose_Eliminacao}
      >
        <MySnackbarContentWrapper
          onClose={this.handleClose_Eliminacao}
          variant="success"
          message="Evento Eliminado Com Sucesso!"
        />
      </Snackbar>
    );
  };

  Mostra_Mensagem_Erro_Eliminacao_Sucesso = () => {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={this.state.Erro_Fez_Eliminacao_Evento}
        autoHideDuration={3000}
        onClose={this.handleClose_Erro_Eliminacao}
      >
        <MySnackbarContentWrapper
          onClose={this.handleClose_Erro_Eliminacao}
          variant="error"
          message="Erro: Evento Tem Provas Associadas"
        />
      </Snackbar>
    );
  };

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value }, () => {
      this.Vai_Buscar_Numero_Eventos_Sistema();
      this.Vai_Buscar_Dados();
    });
  }

  render() {
    if (localStorage.getItem("Token") === null) {
      this.props.history.push({
        pathname: "/"
      });
    }
    //Verifica Se Existe Erro. Se Houver Mostra Mensagem de Erro e "Back" Para a Pagina "Eventos"
    if (this.state.Mostra_Erro === true) {
      return (
        <div>
          <Drawer
            Titulo_Menu="" //"Erro"
            history={this.props.history}
          />
          {mostra_mensagem_erro()}
        </div>
      );
      //return this.Mostra_Drawer();
    } else {
      if (
        this.state.items === undefined ||
        this.state.total_items === undefined
      ) {
        return (
          <div>
            <div className="centra_loading">
              <h1>Loading...</h1>
            </div>
          </div>
        );
      } else {
        /*
          let Tipo_Rectangulo = ""; //"evento_activo" // evento_terminado
          let Texto_Rectangulo = ""; //Evento Activo! // Evento Terminado | Evento

          //Data Actual Precisa de um Auxiliar Por Causa do Formato em Que é Gerada a Data Com o Moment()
          let Data_Actual_Aux = Moment().format("DD-MM-YYYY"); //.format("DD-MM-YYYY");
          let Data_Actual = Moment(Data_Actual_Aux, "DD-MM-YYYY"); //.format("DD-MM-YYYY");

          let Data_Inicio;
          let Data_Fim;
          let Diferenca_Inicio;
          let Diferenca_Fim;

          */

        return (
          <div>
            <Drawer
              Titulo_Menu="Eventos"
              Link_Menu_Anterior={this.props.location.pathname}
              history={this.props.history}
            />
            {/*}
              <h2 className="centra_titulos1000">
                Número Total de Eventos: {this.state.total_items}
              </h2>*/}
            <div
              className="novo_centra_tabelas" //"centra_tabelas" /*"centra_tabelas1000" /*centra_tabelas*/
            >
              <div className="novo_form_filtro">
                <input
                  autoFocus="true"
                  type="text"
                  name="Nome_Evento_Filtro"
                  id="Nome_Evento_Filtro"
                  onChange={this.handleChange}
                  defaultValue={this.state.Nome_Evento_Filtro}
                  placeholder="Evento"
                  style={{ marginLeft: "10px", width: "370px" }}
                />
                <input
                  //autoFocus="true"
                  type="text"
                  name="Short_Name_Filtro"
                  id="Short_Name_Filtro"
                  onChange={this.handleChange}
                  defaultValue={this.state.Short_Name_Filtro}
                  placeholder="Abreviatura"
                  style={{ marginLeft: "10px", width: "170px" }}
                />
              </div>

              <tabela /*style={{ marginLeft: "200px" }} */>
                <thead>
                  <tr>
                    <th colSpan="5">
                      Número Total de Eventos: {this.state.total_items}
                    </th>
                  </tr>
                </thead>
                <thead>
                  <tr>
                    <th onClick={() => this.sortBy("name")}>
                      Nome Evento{" "}
                      <i
                        className="fa fa-angle-down fa-sm" /*fa fa-sort fa-sm*/
                      />
                    </th>
                    <th onClick={() => this.sortBy("init_date")}>
                      Data Evento{" "}
                      <i
                        className="fa fa-angle-down fa-sm" /*fa fa-sort fa-sm*/
                      />
                    </th>
                    <th onClick={() => this.sortBy("short_name")}>
                      Abreviatura{" "}
                      <i
                        className="fa fa-angle-down fa-sm" /*fa fa-sort fa-sm*/
                      />
                    </th>
                    <th>Página</th>
                    <th>Acção</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.items.map(linha => {
                    /*
                      Data_Inicio = Moment(linha.init_date);
                      Data_Fim = Moment(linha.end_date);

                      Diferenca_Inicio = Data_Actual.diff(Data_Inicio); //Negativo, Data Actual<Data Inicio
                      Diferenca_Fim = Data_Actual.diff(Data_Fim);

                      if (Diferenca_Inicio >= 0 && Diferenca_Fim <= 0) {
                        //Se o Evento Estiver Activo Actualmente
                        Tipo_Rectangulo = "evento_activo";
                        Texto_Rectangulo = "Evento Activo!";
                      } else {
                        if (Diferenca_Fim > 0) {
                          //Se o Evento Ja Tiver Terminado
                          Tipo_Rectangulo = "evento_terminado";
                          Texto_Rectangulo = "Evento Terminado";
                        } else {
                          if (Diferenca_Inicio < 0) {
                            //Se o Evento Ainda Estiver Para Acontecer (Falta Mudar Isto - E Criar a Nova Class no .css)
                            Tipo_Rectangulo = "evento_terminado";
                            Texto_Rectangulo = "Evento Pendente";
                          } else {
                            Tipo_Rectangulo = "?";
                            Texto_Rectangulo = "?";
                          }
                        }
                      }
                      */

                    return (
                      <tr>
                        <td style={{ width: "370px" }}>{linha.name}</td>
                        <td
                          style={{
                            //width: "300px"
                            width: "130px"
                          }}
                        >
                          {Moment(linha.init_date).format("DD-MM-YYYY HH:mm")
                          //.locale("pt").format("LLLL")
                          }
                        </td>
                        <td style={{ width: "170px" }}>{linha.short_name}</td>
                        <td style={{ width: "300px" }}>
                          <a
                            href={linha.event_url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {linha.event_url}
                          </a>
                        </td>
                        <td>
                          <Link
                            to={{
                              pathname: "/lista_atletas_inscritos_provas",
                              state: {
                                Link_Menu_Anterior: this.props.location
                                  .pathname,
                                ID_Evento: linha.trail_event_id,
                                Fez_Edicao_Dados_Atleta_Sistema: false,
                                Fez_Edicao_Inscricao_Atleta_Sistema: false,
                                Faz_Refresh: true,
                                Fez_Lancamento_Desistencia_Sucesso: false,
                                Fez_Eliminacao_Inscricao_Sucesso: false,
                                Nome_Evento: linha.name
                              }
                            }}
                            title="Atletas Inscritos"
                          >
                            <i className="fa fa-users fa-lg espaco_icons" />
                          </Link>{" "}
                          <Link
                            //style={{ textDecoration: "none" }}
                            to={{
                              pathname: "/competicoes",
                              state: {
                                //Link_Menu_Anterior: this.props.location.pathname
                                ID_Evento: linha.trail_event_id,
                                Fez_Insercao_Competicao: false,
                                Fez_Edicao_Competicao: false,
                                Faz_Refresh: false, //true,
                                Nome_Evento: linha.name,
                                Fez_Eliminacao_Competicao: false
                              }
                            }}
                            title="Competições"
                          >
                            <i className="fa fa-list fa-lg espaco_icons" />
                          </Link>{" "}
                          <Link
                            //style={{ textDecoration: "none" }}
                            to={{
                              pathname: "/editar_evento",
                              state: {
                                ID_Evento: linha.trail_event_id,
                                Faz_Refresh: false, //true,
                                Nome_Evento: linha.name
                              }
                            }}
                            title="Definições Evento"
                          >
                            <i className="fa fa-cog fa-lg " />
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </tabela>

              <div style={{ marginTop: "10px" }}>
                <label>Resultados Por Página</label>{" "}
                <select
                  name="perPage"
                  id="perPage"
                  onChange={this.handleChange}
                >
                  <option value="10"> 10 </option>
                  <option value="25"> 25 </option>
                  <option value="50"> 50 </option>
                  <option value="100"> 100 </option>
                  <option value="0">Todos </option>
                </select>
              </div>

              <div>
                <ReactPaginate
                  previousLabel={"Anterior"}
                  nextLabel={"Próximo"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              </div>
            </div>
            {this.Mostra_Mensagem_Login_Sucesso()}
            {this.Mostra_Mensagem_Edicao_Sucesso()}
            {this.Mostra_Mensagem_Insercao_Sucesso()}
            {this.Mostra_Mensagem_Copia_Sucesso()}
            {this.Mostra_Mensagem_Eliminacao_Sucesso()}
            {this.Mostra_Mensagem_Erro_Eliminacao_Sucesso()}
          </div>
        );
      }
    }
  }
}

export default Eventos;
