import React, { Component } from "react";
import axios from "axios";
import Moment from "moment";
import Drawer from "../Drawer/Drawer.js";
import Select from "react-select";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

//Funções Auxiliares:
import {
  //mostra_mensagem_erro,
  //ve_resultados,
  Trata_Validacoes,
  isEmptyObject
} from "../Funcoes_Auxiliares/Funcoes_Auxiliares.js";

let REACT_APP_API_URL;

if (process.env.REACT_APP_ESTADO === "Deploy") {
  REACT_APP_API_URL = process.env.REACT_APP_API_URL_PROD;
} //Development
else {
  REACT_APP_API_URL = process.env.REACT_APP_API_URL_DEV;
}

const opcoes_select_estado = [
  { value: 1, label: "Activo" },
  { value: 0, label: "Inactivo" }
];

class Formulario_Adicionar_Evento extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //Link_Menu_Anterior: null,

      nome_evento: null,
      data_inicio: null,
      data_fim: null,
      descricao: null,
      estado: null,
      abreviatura_nome: null,
      //id_organizacao: null,
      url_imagem: null,
      url_evento: null,
      bgcolor: null,

      //Variaveis Para Upload de Ficheiros:
      file: null,

      //Variavel Para Controlar o Redirect
      Inserido_Sucesso: false,

      //Lista Para os Erros de Validação:
      errors: {}
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleChangeSelectEstado = estado => {
    this.setState({ estado });
  };

  handleValidation() {
    let errors = {};
    let formIsValid = false; //true;

    let Data_Inicio = Moment(this.state.data_inicio);
    let Data_Fim = Moment(this.state.data_fim);

    errors = Trata_Validacoes(
      this.state.nome_evento,
      "trail_event/name",
      errors
    );
    errors = Trata_Validacoes(
      this.state.data_inicio,
      "trail_event/init_date",
      errors
    );
    errors = Trata_Validacoes(
      this.state.data_fim,
      "trail_event/end_date",
      errors
    );
    errors = Trata_Validacoes(
      this.state.descricao,
      "trail_event/description",
      errors
    );
    errors = Trata_Validacoes(this.state.estado, "trail_event/status", errors);
    /*
    if (!this.state.estado && this.state.estado !== 0) {
      errors["trail_event/status"] = "*Escolha o Estado do Evento";
    }
    */
    errors = Trata_Validacoes(
      this.state.abreviatura_nome,
      "trail_event/short_name",
      errors
    );
    /*
    errors = Trata_Validacoes(
      this.state.url_imagem,
      "trail_event/image_url",
      errors
    );
    errors = Trata_Validacoes(
      this.state.language,
      "trail_event/url_evento",
      errors
    );
    errors = Trata_Validacoes(
      this.state.regional,
      "trail_event/bgcolor",
      errors
    );
    */

    //Verifica Coerência das Datas
    if (
      this.state.data_inicio &&
      this.state.data_fim &&
      //Moment(this.state.data_inicio).isSameorAfter(this.state.data_inicio)

      Data_Inicio.diff(Data_Fim) >= 0 //, "days"); //Negativo, Data_Inicio<Data_Fim
    ) {
      formIsValid = false;
      errors["trail_event/end_date"] = "*Data de Fim de Evento Inválida";
    }

    //if (errors.length > 0) {
    if (isEmptyObject(errors)) {
      formIsValid = true; //false;
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.handleValidation()) {
      const Token_Lido = localStorage.getItem("Token");
      axios
        .post(
          REACT_APP_API_URL + "/inserir_evento/",
          {
            nome_evento: this.state.nome_evento,
            //data_inicio: Moment(this.state.data_inicio, "YYYY-MM-DD HH:mm:ss"), //this.state.data_inicio,
            //data_fim: Moment(this.state.data_fim, "YYYY-MM-DD HH:mm:ss"), //this.state.data_fim,
            data_inicio: this.state.data_inicio,
            data_fim: this.state.data_fim,
            descricao: this.state.descricao,
            estado: this.state.estado.value, //this.state.estado,
            abreviatura_nome: this.state.abreviatura_nome,
            //id_organizacao: this.state.id_organizacao,
            url_imagem: this.state.url_imagem,
            url_evento: this.state.url_evento,
            bgcolor: this.state.bgcolor
          },
          {
            headers: { authorization: "Bearer " + Token_Lido }
          }
        )
        .then(res => {
          //console.log(res);
          this.setState({ Inserido_Sucesso: res.data.success });
        });
    }
  }
  render() {
    if (localStorage.getItem("Token") === null) {
      this.props.history.push({
        pathname: "/"
      });
    }

    //const { Inserido_Sucesso } = this.state;
    //if (Inserido_Sucesso === true) {
    if (this.state.Inserido_Sucesso === true) {
      this.props.history.push({
        pathname: "/eventos", //this.state.Link_Menu_Anterior
        state: {
          Fez_Login: false,
          Fez_Insercao_Evento: true,
          Fez_Edicao_Evento: false,
          Faz_Refresh: true,
          Fez_Copia_Evento: false,
          Fez_Eliminacao_Evento: false
        }
      });
    }

    const border_erro = "1px solid #ff0000";

    return (
      <div>
        <Drawer
          Titulo_Menu="Inserir Novo Evento"
          history={this.props.history}
        />
        <div className="novas_margens_volta_novos_forms">
          <div className="centra_novos_forms">
            <form className="login-form" onSubmit={this.handleSubmit}>
              <div className="centra_titulos_tabs">
                <Tabs>
                  <TabList>
                    <Tab
                      style={{
                        border:
                          this.state.errors["trail_event/name"] ||
                          this.state.errors["trail_event/description"] ||
                          this.state.errors["trail_event/short_name"]
                            ? border_erro
                            : ""
                      }}
                    >
                      Dados Evento
                    </Tab>
                    <Tab
                      style={{
                        border:
                          this.state.errors["trail_event/init_date"] ||
                          this.state.errors["trail_event/end_date"] ||
                          this.state.errors["trail_event/status"]
                            ? border_erro
                            : ""
                      }}
                    >
                      Datas
                    </Tab>
                    <Tab>Extras</Tab>
                    <button type="submit" style={{ marginLeft: "10px" }}>
                      Adicionar Evento
                    </button>
                  </TabList>
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  <TabPanel>
                    <div className="form">
                      <div>
                        <label>
                          Nome Evento
                          <span className="bolder" style={{ color: "#e60000" }}>
                            {" (*)"}
                          </span>
                        </label>
                        <input
                          style={{
                            border: this.state.errors["trail_event/name"]
                              ? border_erro
                              : "",
                            textAlign: "center"
                          }}
                          type="text"
                          name="nome_evento"
                          placeholder="Nome Evento"
                          onChange={this.handleChange}
                          defaultValue={this.state.nome_evento}
                        />
                        <span className="bolder" style={{ color: "#e60000" }}>
                          {this.state.errors["trail_event/name"]}
                        </span>
                        <div className="espaco_span" />
                        <label>
                          Descrição
                          <span className="bolder" style={{ color: "#e60000" }}>
                            {" (*)"}
                          </span>
                        </label>
                        <input
                          style={{
                            border: this.state.errors["trail_event/description"]
                              ? border_erro
                              : "",
                            textAlign: "center"
                          }}
                          type="text"
                          name="descricao"
                          placeholder="Descrição"
                          onChange={this.handleChange}
                          defaultValue={this.state.descricao}
                        />
                        <span className="bolder" style={{ color: "#e60000" }}>
                          {this.state.errors["trail_event/description"]}
                        </span>
                        <div className="espaco_span" />
                        <label>
                          Abreviatura Nome
                          <span className="bolder" style={{ color: "#e60000" }}>
                            {" (*)"}
                          </span>
                        </label>
                        <input
                          style={{
                            border: this.state.errors["trail_event/short_name"]
                              ? border_erro
                              : "",
                            textAlign: "center"
                          }}
                          type="text"
                          name="abreviatura_nome"
                          placeholder="Abreviatura Nome"
                          onChange={this.handleChange}
                          defaultValue={this.state.abreviatura_nome}
                        />
                        <span className="bolder" style={{ color: "#e60000" }}>
                          {this.state.errors["trail_event/short_name"]}
                        </span>
                        <div className="espaco_span" />
                        <span className="bolder" style={{ color: "#e60000" }}>
                          {" (*)"}
                        </span>{" "}
                        <span className="bolder"> Campos Obrigatórios</span>
                      </div>
                    </div>
                    {/*}
                    <div className="form">
                      <button type="submit">Adicionar Evento</button>
                    </div>
                              */}
                  </TabPanel>
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  <TabPanel>
                    <div className="form">
                      <div>
                        <label>
                          Data Inicio
                          <span className="bolder" style={{ color: "#e60000" }}>
                            {" (*)"}
                          </span>
                        </label>
                        <input
                          style={{
                            border: this.state.errors["trail_event/init_date"]
                              ? border_erro
                              : "",
                            textAlign: "center"
                          }}
                          type="datetime-local"
                          step="1"
                          name="data_inicio"
                          onChange={this.handleChange}
                          defaultValue={
                            String(
                              Moment(this.state.data_inicio).format(
                                "YYYY-MM-DD"
                              )
                            ) +
                            "T" +
                            String(
                              Moment(this.state.data_inicio).format("HH:mm:ss")
                            )
                          }
                        />
                        <span className="bolder" style={{ color: "#e60000" }}>
                          {this.state.errors["trail_event/init_date"]}
                        </span>
                        <div className="espaco_span" />
                        <label>
                          Data Fim
                          <span className="bolder" style={{ color: "#e60000" }}>
                            {" (*)"}
                          </span>
                        </label>
                        <input
                          style={{
                            border: this.state.errors["trail_event/end_date"]
                              ? border_erro
                              : "",
                            textAlign: "center"
                          }}
                          type="datetime-local"
                          step="1"
                          name="data_fim"
                          onChange={this.handleChange}
                          defaultValue={
                            String(
                              Moment(this.state.data_fim).format("YYYY-MM-DD")
                            ) +
                            "T" +
                            String(
                              Moment(this.state.data_fim).format("HH:mm:ss")
                            )
                          }
                        />
                        <span className="bolder" style={{ color: "#e60000" }}>
                          {this.state.errors["trail_event/end_date"]}
                        </span>
                        <div className="espaco_span" />
                        <label>
                          Estado:
                          <span className="bolder" style={{ color: "#e60000" }}>
                            {" (*)"}
                          </span>
                        </label>
                        <div
                          style={{
                            border: this.state.errors["trail_event/status"]
                              ? border_erro
                              : ""
                          }}
                        >
                          <Select
                            value={this.state.estado}
                            name="estado"
                            placeholder="Estado"
                            onChange={this.handleChangeSelectEstado}
                            options={opcoes_select_estado}
                          />
                        </div>
                        <span className="bolder" style={{ color: "#e60000" }}>
                          {this.state.errors["trail_event/status"]}
                        </span>
                        <div className="espaco_span" />
                        <span className="bolder" style={{ color: "#e60000" }}>
                          {" (*)"}
                        </span>{" "}
                        <span className="bolder"> Campos Obrigatórios</span>
                      </div>
                    </div>
                    {/*}
                    <div className="form">
                      <button type="submit">Adicionar Evento</button>
                    </div>
                              */}
                  </TabPanel>
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  {/*****************************************************************************/}
                  <TabPanel>
                    <div className="form">
                      <div>
                        <label>
                          URL Imagem
                          <span className="bolder" style={{ color: "#e60000" }}>
                            {
                              //" (*)"
                            }
                          </span>
                        </label>
                        <input
                          style={{
                            border: this.state.errors["trail_event/image_url"]
                              ? border_erro
                              : "",
                            textAlign: "center"
                          }}
                          type="text"
                          name="url_imagem"
                          placeholder="URL Imagem"
                          onChange={this.handleChange}
                          defaultValue={this.state.url_imagem}
                        />
                        <span className="bolder" style={{ color: "#e60000" }}>
                          {this.state.errors["trail_event/image_url"]}
                        </span>
                        <div className="espaco_span" />

                        <label>
                          URL Evento
                          <span className="bolder" style={{ color: "#e60000" }}>
                            {
                              //" (*)"
                            }
                          </span>
                        </label>
                        <input
                          style={{
                            border: this.state.errors["trail_event/event_url"]
                              ? border_erro
                              : "",
                            textAlign: "center"
                          }}
                          type="text"
                          name="url_evento"
                          placeholder="URL Evento"
                          onChange={this.handleChange}
                          defaultValue={this.state.url_evento}
                        />
                        <span className="bolder" style={{ color: "#e60000" }}>
                          {this.state.errors["trail_event/event_url"]}
                        </span>
                        <div className="espaco_span" />

                        <label>
                          BG Color
                          <span className="bolder" style={{ color: "#e60000" }}>
                            {
                              //" (*)"
                            }
                          </span>
                        </label>
                        <input
                          style={{
                            border: this.state.errors["trail_event/bgcolor"]
                              ? border_erro
                              : "",
                            textAlign: "center"
                          }}
                          type="text"
                          name="bgcolor"
                          placeholder="BGcolor"
                          onChange={this.handleChange}
                          defaultValue={this.state.bgcolor}
                        />
                        <span className="bolder" style={{ color: "#e60000" }}>
                          {this.state.errors["trail_event/bgcolor"]}
                        </span>
                      </div>
                      <div className="espaco_span">
                        <span className="bolder" style={{ color: "#e60000" }}>
                          {" (*)"}
                        </span>{" "}
                        <span className="bolder"> Campos Obrigatórios</span>
                      </div>
                    </div>
                    {/*}
                    <div className="form">
                      <button type="submit">Adicionar Evento</button>
                    </div>
                              */}
                  </TabPanel>
                </Tabs>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default Formulario_Adicionar_Evento;
