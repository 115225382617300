import React, { Component } from "react";
//import { Link } from "react-router-dom";
import axios from "axios";
import ReactPaginate from "react-paginate";

//Imports Para Drawer e Snackbar:
import Drawer from "../Drawer/Drawer.js";

//Funções Auxiliares:
import {
  mostra_mensagem_erro,
  ve_resultados
} from "../Funcoes_Auxiliares/Funcoes_Auxiliares.js";

let REACT_APP_API_URL;

if (process.env.REACT_APP_ESTADO === "Deploy") {
  REACT_APP_API_URL = process.env.REACT_APP_API_URL_PROD;
} //Development
else {
  REACT_APP_API_URL = process.env.REACT_APP_API_URL_DEV;
}

class Tabela_Lista_Checkpoints extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Link_Menu_Anterior: null,
      items: undefined, //[],
      Todos_Checkpoints: undefined,
      Headers_Colunas: [],
      ID_Evento: null,
      ID_Prova: null,
      Nome_Evento: null,

      //Variaveis Para a Paginação:
      total_items: 0,
      offset: 0,
      perPage: 10,
      pageCount: null,

      //Variavel Controlo de Erro na Leitura dos Props
      Mostra_Erro: false,

      //Variavel de Controlo Para Evitar o Bug da Paginação Não Aparecer
      Faz_Refresh: false
    };

    if (props.location.state === undefined) {
      this.state.Mostra_Erro = true;
    } else {
      if (props.location.state.Link_Menu_Anterior === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Link_Menu_Anterior = props.location.state.Link_Menu_Anterior;
      }
      if (props.location.state.ID_Evento === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.ID_Evento = props.location.state.ID_Evento;
      }
      if (props.location.state.Faz_Refresh === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Faz_Refresh = props.location.state.Faz_Refresh;
      }
      if (props.location.state.Nome_Evento === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Nome_Evento = props.location.state.Nome_Evento;
      }
    }
    this.handlePageClick = this.handlePageClick.bind(this);
    this.handleChange = this.handleChange.bind(this);

    //Metodos Para Ordenações da Tabela
    this.compareBy.bind(this);
    this.sortBy.bind(this);
    this.compareByNumber.bind(this);

    //this.Mostra_Dados.bind(this);
  } //Fecha Construtor da Class

  compareBy(key) {
    return function(a, b) {
      if (a[key] < b[key]) return -1;
      if (a[key] > b[key]) return 1;
      return 0;
    };
  }

  compareByNumber(key) {
    return function(a, b) {
      return parseInt(a[key]) - parseInt(b[key]);
    };
  }

  sortBy(key) {
    let arrayCopy = [...this.state.items];
    if (key === "checkpoint_order") {
      arrayCopy.sort(this.compareByNumber(key));
    } else {
      arrayCopy.sort(this.compareBy(key));
    }
    this.setState({ items: arrayCopy });
  }

  handlePageClick = data => {
    let selected1 = data.selected;
    let offset1 = Math.ceil(selected1 * this.state.perPage);

    this.setState({ offset: offset1 }, () => {
      this.Vai_Buscar_Dados();
    });
  };

  Vai_Buscar_Dados() {
    const Token_Lido = localStorage.getItem("Token");
    if (this.state.perPage > 0) {
      axios
        .get(
          REACT_APP_API_URL +
            "/lista_checkpoints_evento2/" +
            this.state.ID_Evento +
            "/" +
            this.state.perPage +
            "/" +
            this.state.offset,
          {
            headers: { authorization: "Bearer " + Token_Lido }
          }
        )
        .then(res => {
          console.log(res);
          this.setState({ items: res.data });
          this.setState({
            pageCount: Math.ceil(this.state.total_items / this.state.perPage)
          });
        });
    } else {
      axios
        .get(
          REACT_APP_API_URL +
            "/lista_checkpoints_evento2/" +
            this.state.ID_Evento,
          {
            headers: { authorization: "Bearer " + Token_Lido }
          }
        )
        .then(res => {
          console.log(res);
          this.setState({ items: res.data });
          this.setState({ pageCount: 1 });
        });
    }
  }

  Vai_Buscar_Todos_Checkpoints() {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .get(
        REACT_APP_API_URL +
          "/lista_checkpoints_evento2/" +
          this.state.ID_Evento,
        {
          headers: { authorization: "Bearer " + Token_Lido }
        }
      )
      .then(res => {
        console.log(res);
        this.setState({ Todos_Checkpoints: res.data });
      });
  }

  Vai_Buscar_Numero_Checkpoints_Todas_Provas() {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .get(
        REACT_APP_API_URL +
          "/devolve_numero_checkpoints_evento/" +
          this.state.ID_Evento,
        {
          headers: { authorization: "Bearer " + Token_Lido }
        }
      )
      .then(res => {
        console.log(res);
        const Soma_Total = res.data.length;

        this.setState({ total_items: Soma_Total });
      });
  }
  /*
Procedimento Com Axios */
  componentDidMount() {
    if (localStorage.getItem("Token") !== null) {
      this.Vai_Buscar_Numero_Checkpoints_Todas_Provas();
      this.Vai_Buscar_Dados();
      this.Vai_Buscar_Todos_Checkpoints();
    }
  } //Fecha Procedimento Que Acontece Depois do Output do Componente Foi Rendered

  //Actualiza os Dados Depois de Acontecer um Redirect Para Este Componente
  componentDidUpdate() {
    if (this.state.Faz_Refresh) {
      this.Vai_Buscar_Numero_Checkpoints_Todas_Provas();
      this.Vai_Buscar_Dados();
      this.Vai_Buscar_Todos_Checkpoints();
      this.setState({ Faz_Refresh: false });
    }
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value }, () => {
      this.Vai_Buscar_Dados();
    });
  }

  render() {
    if (localStorage.getItem("Token") === null) {
      this.props.history.push({
        pathname: "/"
      });
    }

    //Verifica Se Existe Erro. Se Houver Mostra Mensagem de Erro
    if (this.state.Mostra_Erro === true) {
      return (
        <div>
          <Drawer
            Titulo_Menu="" //"Erro"
            history={this.props.history}
          />
          {mostra_mensagem_erro()}
        </div>
      );
    } else {
      if (
        this.state.items === undefined ||
        this.state.Todos_Checkpoints === undefined ||
        this.state.total_items === undefined
      ) {
        return (
          <div className="centra_loading">
            <h1>Loading...</h1>
          </div>
        );
      } else {
        if (this.state.items.length > 0) {
          return (
            <div>
              <Drawer
                Titulo_Menu="Lista Checkpoints Evento"
                ID_Evento={this.state.ID_Evento}
                Link_Menu_Anterior={this.state.Link_Menu_Anterior}
                Nome_Evento={this.state.Nome_Evento}
                history={this.props.history}
              />
              <div
                className="novo_centra_tabelas" //"centra_tabelas" //"esquerda_tabelas" //"centra_tabelas"
              >
                <tabela>
                  <thead>
                    <tr>
                      <th colSpan="6">
                        Número Total de Checkpoints do Evento:{" "}
                        {this.state.total_items}
                      </th>
                    </tr>
                  </thead>
                  <thead>
                    <tr>
                      <th onClick={() => this.sortBy("local")}>
                        Nome Checkpoint{" "}
                        <i
                          className="fa fa-angle-down fa-sm" /*fa fa-sort fa-sm*/
                        />
                      </th>
                      {/*}
                      <th onClick={() => this.sortBy("checkpoint_order")}>
                        Última Passagem{" "}
                        <i
                          className="fa fa-angle-down fa-sm" /*fa fa-sort fa-sm
                        />
                      </th>*/}
                      <th onClick={() => this.sortBy("Abertura_Final")}>
                        Abertura{" "}
                        <i
                          className="fa fa-angle-down fa-sm" /*fa fa-sort fa-sm*/
                        />
                      </th>
                      <th onClick={() => this.sortBy("Rapido_Final")}>
                        Mais Rápido{" "}
                        <i
                          className="fa fa-angle-down fa-sm" /*fa fa-sort fa-sm*/
                        />
                      </th>
                      <th onClick={() => this.sortBy("Lento_Final")}>
                        Mais Lento{" "}
                        <i
                          className="fa fa-angle-down fa-sm" /*fa fa-sort fa-sm*/
                        />
                      </th>
                      <th onClick={() => this.sortBy("Fecho_Final")}>
                        Fecho{" "}
                        <i
                          className="fa fa-angle-down fa-sm" /*fa fa-sort fa-sm*/
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.items.map(linha2 => {
                      return (
                        <tr>
                          <td>{linha2.local}</td>
                          {/*}td>{"-"}</td>*/}
                          <td>{linha2.Abertura_Final}</td>
                          <td>{linha2.Rapido_Final}</td>
                          <td>{linha2.Lento_Final}</td>
                          <td>{linha2.Fecho_Final}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                  {
                    <div style={{ marginTop: "10px" }}>
                      <label>Resultados Por Página</label>{" "}
                      <select
                        name="perPage"
                        id="perPage"
                        onChange={this.handleChange}
                      >
                        <option value="10"> 10 </option>
                        <option value="25"> 25 </option>
                        <option value="50"> 50 </option>
                        <option value="100"> 100 </option>
                        <option value="0">Todos </option>
                      </select>
                    </div>
                  }
                  <div>
                    <ReactPaginate
                      previousLabel={"Anterior"}
                      nextLabel={"Próximo"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={2}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                </tabela>
              </div>
            </div>
          );
        } else {
          return (
            <div>
              <Drawer
                Titulo_Menu="Lista Checkpoints Evento"
                ID_Evento={this.state.ID_Evento}
                Link_Menu_Anterior={this.state.Link_Menu_Anterior}
                Nome_Evento={this.state.Nome_Evento}
                history={this.props.history}
              />
              {ve_resultados()}
            </div>
          );
        }
      }
    }
  }
}

export default Tabela_Lista_Checkpoints;
