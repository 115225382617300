import React, { Component } from "react";
//import { Link } from "react-router-dom";
import axios from "axios";
import Moment from "moment";
import Drawer from "../Drawer/Drawer.js";
import Select from "react-select";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

//Funções Auxiliares:
import {
  mostra_mensagem_erro,
  //ve_resultados,
  Trata_Validacoes,
  isEmptyObject
} from "../Funcoes_Auxiliares/Funcoes_Auxiliares.js";

let REACT_APP_API_URL;

if (process.env.REACT_APP_ESTADO === "Deploy") {
  REACT_APP_API_URL = process.env.REACT_APP_API_URL_PROD;
} //Development
else {
  REACT_APP_API_URL = process.env.REACT_APP_API_URL_DEV;
}

const opcoes_select_estado = [
  { value: 1, label: "Activo" },
  { value: 0, label: "Inactivo" }
];

class Formulario_Edicao_Evento extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dados: undefined,
      ID_Evento: null,
      Nome_Evento: null,

      nome_evento: null,
      data_inicio: null,
      data_fim: null,
      descricao: null,

      data_inicio_lido_bd: null,
      data_fim_lido_bd: null,

      estado_lido_bd: null,
      estado: null,

      //Nota: São Usados 2 Variáveis Porque se Houver Alterações no Campo de Select,  Faz [campo.value]. Caso Contrário Fica Com o Valor Original
      //estado_inicial: null,
      //estado_final: null,

      abreviatura_nome: null,
      //id_organizacao: null,
      url_imagem: null,
      url_evento: null,
      bgcolor: null,

      //Variavel Controlo de Erro na Leitura dos Props
      Mostra_Erro: false,

      //Variavel Para Controlar o Redirect
      Inserido_Sucesso: false,

      //Lista Para os Erros de Validação:
      errors: {}
    };

    if (props.location.state === undefined) {
      this.state.Mostra_Erro = true;
    } else {
      if (props.location.state.ID_Evento === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.ID_Evento = props.location.state.ID_Evento;
      }
      if (props.location.state.Nome_Evento === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Nome_Evento = props.location.state.Nome_Evento;
      }
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  Vai_Buscar_Dados_Evento() {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .get(REACT_APP_API_URL + "/dados_evento/" + this.state.ID_Evento, {
        headers: { authorization: "Bearer " + Token_Lido }
      })
      .then(res => {
        console.log(res);
        this.setState({ dados: res.data });
        const Dados_Lidos = res.data;
        this.setState({
          nome_evento: Dados_Lidos[0].name,
          data_inicio: Dados_Lidos[0].init_date,
          data_fim: Dados_Lidos[0].end_date,
          descricao: Dados_Lidos[0].description,

          //estado_inicial: Dados_Lidos[0].status,
          //estado_final: Dados_Lidos[0].status, //Tem de Começar Com Valor Igual ao Estado_Inicial
          estado_lido_bd: Dados_Lidos[0].status,

          abreviatura_nome: Dados_Lidos[0].short_name,
          //id_organizacao: Dados_Lidos[0].organization_id,
          url_imagem: Dados_Lidos[0].image_url,
          url_evento: Dados_Lidos[0].event_url,
          bgcolor: Dados_Lidos[0].bgcolor,

          data_inicio_lido_bd: Dados_Lidos[0].init_date,
          data_fim_lido_bd: Dados_Lidos[0].end_date
        });
      });
  }

  componentDidMount() {
    if (localStorage.getItem("Token") !== null) {
      this.Vai_Buscar_Dados_Evento();
    }
  } //Fecha Procedimento Que Acontece Depois do Output do Componente Foi Rendered

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleValidation() {
    let errors = {};
    let formIsValid = false; //true;

    let Data_Inicio = Moment(this.state.data_inicio);
    let Data_Fim = Moment(this.state.data_fim);

    /*
    var estado_enviar;
    if (
      this.state.estado_inicial !== this.state.estado_final.value &&
      this.state.estado_final.value !== undefined
    ) {
      estado_enviar = this.state.estado_final.value;
    } else {
      estado_enviar = this.state.estado_inicial;
    }
    */

    errors = Trata_Validacoes(
      this.state.nome_evento,
      "trail_event/name",
      errors
    );
    errors = Trata_Validacoes(
      this.state.data_inicio,
      "trail_event/init_date",
      errors
    );
    errors = Trata_Validacoes(
      this.state.data_fim,
      "trail_event/end_date",
      errors
    );
    errors = Trata_Validacoes(
      this.state.descricao,
      "trail_event/description",
      errors
    );
    /*
    errors = Trata_Validacoes(estado_lido_bd, "trail_event/status", errors);
    */
    if (
      !this.state.estado_lido_bd &&
      !this.state.estado &&
      this.state.estado !== 0 &&
      this.state.estado_lido_bd !== 0
      /*
      &&
      this.state.estado_lido_bd !== 0 &&
      this.state.estado !== 0
      */
    ) {
      errors["trail_event/status"] = "*Escolha o Estado do Evento";
    }
    errors = Trata_Validacoes(
      this.state.abreviatura_nome,
      "trail_event/short_name",
      errors
    );
    /*
    errors = Trata_Validacoes(
      this.state.url_imagem,
      "trail_event/image_url",
      errors
    );
    errors = Trata_Validacoes(
      this.state.url_evento,
      "trail_event/event_url",
      errors
    );
    errors = Trata_Validacoes(
      this.state.bgcolor,
      "trail_event/bgcolor",
      errors
    );
    */

    //Verifica Coerência das Datas
    if (
      this.state.data_inicio &&
      this.state.data_fim &&
      //Moment(this.state.data_inicio).isSameorAfter(this.state.data_inicio)

      Data_Inicio.diff(Data_Fim) >= 0 //, "days"); //Negativo, Data_Inicio<Data_Fim
    ) {
      formIsValid = false;
      errors["trail_event/end_date"] = "*Data de Fim de Evento Inválida";
    }

    //if (errors.length > 0) {
    if (isEmptyObject(errors)) {
      formIsValid = true; //false;
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.handleValidation()) {
      const Token_Lido = localStorage.getItem("Token");
      //Nos .patch, os Headers Tem de Ser Depois dos Dados, Nao Antes.
      /*
      var estado_enviar;
      if (
        this.state.estado_inicial !== this.state.estado_final.value &&
        this.state.estado_final.value !== undefined
      ) {
        estado_enviar = this.state.estado_final.value;
      } else {
        estado_enviar = this.state.estado_inicial;
      }
      */
      axios
        .patch(
          REACT_APP_API_URL + "/update_evento/" + this.state.ID_Evento,
          {
            nome_evento: this.state.nome_evento,
            //data_inicio:this.state.data_inicio !== this.state.data_inicio_lido_bd? Moment(this.state.data_inicio, "YYYY-MM-DD HH:mm:ss"): Moment(this.state.data_inicio, "YYYY-MM-DD HH:mm:ss").add(1,"hours"),
            //data_fim:this.state.data_fim !== this.state.data_fim_lido_bd? Moment(this.state.data_fim, "YYYY-MM-DD HH:mm:ss"): Moment(this.state.data_fim, "YYYY-MM-DD HH:mm:ss").add(1,"hours"),

            /*
            data_inicio:
              this.state.data_inicio !== this.state.data_inicio_lido_bd
                ? Moment(
                    this.state.data_inicio,
                    "YYYY-MM-DD HH:mm:ss"
                  ).subtract(1, "hours")
                : Moment(this.state.data_inicio, "YYYY-MM-DD HH:mm:ss"),
            data_fim:
              this.state.data_fim !== this.state.data_fim_lido_bd
                ? Moment(this.state.data_fim, "YYYY-MM-DD HH:mm:ss").subtract(
                    1,
                    "hours"
                  )
                : Moment(this.state.data_fim, "YYYY-MM-DD HH:mm:ss"),
                */

            data_inicio:
              this.state.data_inicio !== this.state.data_inicio_lido_bd
                ? this.state.data_inicio
                : Moment(this.state.data_inicio_lido_bd, "YYYY-MM-DD HH:mm:ss")
                    .tz("Europe/Madrid")
                    .format("YYYY-MM-DD HH:mm:ss"), //: this.state.data_inicio_lido_bd,

            data_fim:
              this.state.data_fim !== this.state.data_fim_lido_bd
                ? this.state.data_fim
                : Moment(this.state.data_fim_lido_bd, "YYYY-MM-DD HH:mm:ss")
                    .tz("Europe/Madrid")
                    .format("YYYY-MM-DD HH:mm:ss"), //this.state.data_fim_lido_bd,

            //data_inicio: Moment(this.state.data_inicio,"YYYY-MM-DD HH:mm:ss").add(1, "hours"),
            //data_fim: Moment(this.state.data_fim, "YYYY-MM-DD HH:mm:ss").add(1,"hours"),

            //data_inicio: this.state.data_inicio, // + "Z",
            //data_fim: this.state.data_fim, // + "Z",
            descricao: this.state.descricao,

            //estado: this.state.estado.value, //this.state.estado
            //estado: estado_enviar,
            estado: this.state.estado
              ? this.state.estado.value
              : this.state.estado_lido_bd,

            abreviatura_nome: this.state.abreviatura_nome,
            //id_organizacao: this.state.id_organizacao,
            url_imagem: this.state.url_imagem,
            url_evento: this.state.url_evento,
            bgcolor: this.state.bgcolor
          },
          {
            headers: { authorization: "Bearer " + Token_Lido }
          }
        )
        .then(res => {
          //console.log(res);
          this.setState({ Inserido_Sucesso: res.data.success });
        });
    }
  }

  handleChangeSelectEstado = estado => {
    this.setState({ estado });
  };

  render() {
    if (localStorage.getItem("Token") === null) {
      this.props.history.push({
        pathname: "/"
      });
    }

    if (this.state.Inserido_Sucesso === true) {
      this.props.history.push({
        pathname: "/eventos",
        state: {
          Fez_Login: false,
          Fez_Insercao_Evento: false,
          Fez_Edicao_Evento: true,
          Faz_Refresh: true,
          Fez_Copia_Evento: false,
          Fez_Eliminacao_Evento: false
        }
      });
    }

    //Verifica Se Existe Erro. Se Houver Mostra Mensagem de Erro
    if (this.state.Mostra_Erro === true) {
      return (
        <div>
          <Drawer
            Titulo_Menu="" //"Erro"
            history={this.props.history}
          />
          {mostra_mensagem_erro()}
        </div>
      );
    } else {
      if (this.state.dados === undefined) {
        return (
          <div className="centra_loading">
            <h1>Loading...</h1>
          </div>
        );
      } else {
        const border_erro = "1px solid #ff0000";

        return (
          <div>
            <Drawer
              Titulo_Menu="Editar Evento"
              Nome_Evento={this.state.Nome_Evento}
              history={this.props.history}
              ID_Evento={this.state.ID_Evento}
            />
            {this.state.dados.map(linha => {
              return (
                <div>
                  <div className="novas_margens_volta_novos_forms">
                    <div className="centra_novos_forms">
                      <form className="login-form" onSubmit={this.handleSubmit}>
                        <div className="centra_titulos_tabs">
                          <Tabs>
                            <TabList>
                              <Tab
                                style={{
                                  border:
                                    this.state.errors["trail_event/name"] ||
                                    this.state.errors[
                                      "trail_event/description"
                                    ] ||
                                    this.state.errors["trail_event/short_name"]
                                      ? border_erro
                                      : ""
                                }}
                              >
                                Dados Evento
                              </Tab>
                              <Tab
                                style={{
                                  border:
                                    this.state.errors[
                                      "trail_event/init_date"
                                    ] ||
                                    this.state.errors["trail_event/end_date"] ||
                                    this.state.errors["trail_event/status"]
                                      ? border_erro
                                      : ""
                                }}
                              >
                                Datas
                              </Tab>
                              <Tab>Extras</Tab>
                              <button
                                type="submit"
                                style={{ marginLeft: "10px" }}
                              >
                                Alterar Evento
                              </button>
                            </TabList>
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            <TabPanel>
                              <div className="form">
                                <div>
                                  <label>
                                    Nome Evento
                                    <span
                                      className="bolder"
                                      style={{ color: "#e60000" }}
                                    >
                                      {" (*)"}
                                    </span>
                                  </label>
                                  <input
                                    style={{
                                      border: this.state.errors[
                                        "trail_event/name"
                                      ]
                                        ? border_erro
                                        : "",
                                      textAlign: "center"
                                    }}
                                    type="text"
                                    name="nome_evento"
                                    placeholder="Nome Evento"
                                    onChange={this.handleChange}
                                    defaultValue={this.state.nome_evento}
                                  />
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {this.state.errors["trail_event/name"]}
                                  </span>
                                  <div className="espaco_span" />
                                  <label>
                                    Descrição
                                    <span
                                      className="bolder"
                                      style={{ color: "#e60000" }}
                                    >
                                      {" (*)"}
                                    </span>
                                  </label>
                                  <input
                                    style={{
                                      border: this.state.errors[
                                        "trail_event/description"
                                      ]
                                        ? border_erro
                                        : "",
                                      textAlign: "center"
                                    }}
                                    type="text"
                                    name="descricao"
                                    placeholder="Descrição"
                                    onChange={this.handleChange}
                                    defaultValue={this.state.descricao}
                                  />
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {
                                      this.state.errors[
                                        "trail_event/description"
                                      ]
                                    }
                                  </span>
                                  <div className="espaco_span" />
                                  <label>
                                    Abreviatura Nome
                                    <span
                                      className="bolder"
                                      style={{ color: "#e60000" }}
                                    >
                                      {" (*)"}
                                    </span>
                                  </label>
                                  <input
                                    style={{
                                      border: this.state.errors[
                                        "trail_event/short_name"
                                      ]
                                        ? border_erro
                                        : "",
                                      textAlign: "center"
                                    }}
                                    type="text"
                                    name="abreviatura_nome"
                                    placeholder="Abreviatura Nome"
                                    onChange={this.handleChange}
                                    defaultValue={this.state.abreviatura_nome}
                                  />
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {
                                      this.state.errors[
                                        "trail_event/short_name"
                                      ]
                                    }
                                  </span>
                                  <div className="espaco_span" />
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {" (*)"}
                                  </span>{" "}
                                  <span className="bolder">
                                    {" "}
                                    Campos Obrigatórios
                                  </span>
                                </div>
                              </div>
                              {/*}
                              <div className="form">
                                <button type="submit">Alterar Evento</button>
                              </div>
                              */}
                            </TabPanel>
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}

                            <TabPanel>
                              <div className="form">
                                <div>
                                  <label>
                                    Data Inicio
                                    <span
                                      className="bolder"
                                      style={{ color: "#e60000" }}
                                    >
                                      {" (*)"}
                                    </span>
                                  </label>
                                  <input
                                    style={{
                                      border: this.state.errors[
                                        "trail_event/init_date"
                                      ]
                                        ? border_erro
                                        : "",
                                      textAlign: "center"
                                    }}
                                    type="datetime-local"
                                    step="1"
                                    name="data_inicio"
                                    placeholder="Data Inicio"
                                    onChange={this.handleChange}
                                    defaultValue={
                                      String(
                                        Moment(this.state.data_inicio).format(
                                          "YYYY-MM-DD"
                                        )
                                      ) +
                                      "T" +
                                      String(
                                        Moment(this.state.data_inicio).format(
                                          "HH:mm:ss"
                                        )
                                      )
                                    }
                                  />
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {this.state.errors["trail_event/init_date"]}
                                  </span>
                                  <div className="espaco_span" />
                                  <label>
                                    Data Fim
                                    <span
                                      className="bolder"
                                      style={{ color: "#e60000" }}
                                    >
                                      {" (*)"}
                                    </span>
                                  </label>
                                  <input
                                    style={{
                                      border: this.state.errors[
                                        "trail_event/end_date"
                                      ]
                                        ? border_erro
                                        : "",
                                      textAlign: "center"
                                    }}
                                    type="datetime-local"
                                    step="1"
                                    name="data_fim"
                                    placeholder="Data Fim"
                                    onChange={this.handleChange}
                                    defaultValue={
                                      //No "datetime-local" Nao Consegue Ler Formatos de Data Sem o "T" Entre Dia e Hora e Nao Pode Ter o "Z" no Final
                                      String(
                                        Moment(this.state.data_fim).format(
                                          "YYYY-MM-DD"
                                        )
                                      ) +
                                      "T" +
                                      String(
                                        Moment(this.state.data_fim).format(
                                          "HH:mm:ss"
                                        )
                                      )
                                    }
                                  />
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {this.state.errors["trail_event/end_date"]}
                                  </span>
                                  <div className="espaco_span" />
                                  <label>
                                    Estado:
                                    <span
                                      className="bolder"
                                      style={{ color: "#e60000" }}
                                    >
                                      {" (*)"}
                                    </span>
                                  </label>
                                  <div
                                    style={{
                                      border: this.state.errors[
                                        "trail_event/status"
                                      ]
                                        ? border_erro
                                        : ""
                                    }}
                                  >
                                    <Select
                                      name="estado"
                                      placeholder="Estado do Evento"
                                      onChange={this.handleChangeSelectEstado}
                                      options={opcoes_select_estado}
                                      //value={this.opcoes_select_estado.find(estado=>{return estado.value===})}

                                      defaultValue={{
                                        value: linha.status,
                                        label: linha.status
                                          ? "Activo"
                                          : "Inactivo" //this.Converte_Value_Sexo_Para_Label(linha.status)
                                      }}
                                      //value={linha2.gender}
                                    />
                                  </div>
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {this.state.errors["trail_event/status"]}
                                  </span>
                                  <div className="espaco_span" />
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {" (*)"}
                                  </span>{" "}
                                  <span className="bolder">
                                    {" "}
                                    Campos Obrigatórios
                                  </span>
                                </div>
                              </div>
                              {/*}
                              <div className="form">
                                <button type="submit">Alterar Evento</button>
                              </div>
                              */}
                            </TabPanel>
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}

                            <TabPanel>
                              <div className="form">
                                <div>
                                  <label>
                                    URL Imagem
                                    <span
                                      className="bolder"
                                      style={{ color: "#e60000" }}
                                    >
                                      {
                                        //" (*)"
                                      }
                                    </span>
                                  </label>
                                  <input
                                    style={{
                                      border: this.state.errors[
                                        "trail_event/image_url"
                                      ]
                                        ? border_erro
                                        : "",
                                      textAlign: "center"
                                    }}
                                    type="text"
                                    name="url_imagem"
                                    placeholder="URL Imagem"
                                    onChange={this.handleChange}
                                    defaultValue={this.state.url_imagem}
                                  />
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {this.state.errors["trail_event/image_url"]}
                                  </span>
                                  <div className="espaco_span" />

                                  <label>
                                    URL Evento
                                    <span
                                      className="bolder"
                                      style={{ color: "#e60000" }}
                                    >
                                      {
                                        //" (*)"
                                      }
                                    </span>
                                  </label>
                                  <input
                                    style={{
                                      border: this.state.errors[
                                        "trail_event/event_url"
                                      ]
                                        ? border_erro
                                        : "",
                                      textAlign: "center"
                                    }}
                                    type="text"
                                    name="url_evento"
                                    placeholder="URL Evento"
                                    onChange={this.handleChange}
                                    defaultValue={this.state.url_evento}
                                  />
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {this.state.errors["trail_event/event_url"]}
                                  </span>
                                  <div className="espaco_span" />

                                  <label>
                                    BG Color
                                    <span
                                      className="bolder"
                                      style={{ color: "#e60000" }}
                                    >
                                      {
                                        //" (*)"
                                      }
                                    </span>
                                  </label>
                                  <input
                                    style={{
                                      border: this.state.errors[
                                        "trail_event/bgcolor"
                                      ]
                                        ? border_erro
                                        : "",
                                      textAlign: "center"
                                    }}
                                    type="text"
                                    name="bgcolor"
                                    placeholder="BGcolor"
                                    onChange={this.handleChange}
                                    defaultValue={this.state.bgcolor}
                                  />
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {this.state.errors["trail_event/bgcolor"]}
                                  </span>
                                </div>
                                <div className="espaco_span">
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {" (*)"}
                                  </span>{" "}
                                  <span className="bolder">
                                    {" "}
                                    Campos Obrigatórios
                                  </span>
                                </div>
                              </div>
                              {/*}
                              <div className="form">
                                <button type="submit">Alterar Evento</button>
                              </div>
                              */}
                            </TabPanel>
                          </Tabs>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        );
      }
    }
  }
}

export default Formulario_Edicao_Evento;
