import React, { Component } from "react";
//import { Link, Redirect } from "react-router-dom";
import axios from "axios";
//import { isNull, isUndefined } from "util";
import Drawer from "../Drawer/Drawer.js";
import Select from "react-select";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

//Funções Auxiliares:
import {
  mostra_mensagem_erro,
  //ve_resultados,
  Trata_Validacoes,
  isEmptyObject
} from "../Funcoes_Auxiliares/Funcoes_Auxiliares.js";

let REACT_APP_API_URL;

if (process.env.REACT_APP_ESTADO === "Deploy") {
  REACT_APP_API_URL = process.env.REACT_APP_API_URL_PROD;
} //Development
else {
  REACT_APP_API_URL = process.env.REACT_APP_API_URL_DEV;
}

class Formulario_Registo_User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dados: undefined, //[],
      username: null,
      id_inscricao: null,
      //nome_atleta: null, //"", //null,
      dorsal: null,
      escalao: null,
      clube_atleta: null,
      competicao: null,
      Nome_Evento: null,
      Nome_Prova: null,
      Nome_Atleta: null,

      id_atleta: null,

      teste1: "", // null,

      escalao_lido_bd: null,
      estado_atleta_lido_bd: null,
      codigo_estado_inscricao_lido_bd: null,

      Link_Tabela_Anterior: null,
      Link_Menu_Anterior: null,
      ID_Evento: null,
      ID_Prova: null,

      Escaloes_Lidos: undefined, //null,
      Estados_Inscricao_Lidos: undefined, //null,
      //Competicoes_Lidas: undefined, //null,

      estado_atleta: null,
      rf_id: null,
      rf_id2: null,
      rf_id3: null,
      rf_id4: null,
      patrocinador: null,
      //sigla_clube: null,
      codigo_estado_inscricao: null,

      //Variavel Controlo de Erro na Leitura dos Props
      Mostra_Erro: false,

      //Variavel Para Controlar o Redirect
      Inserido_Sucesso: false,

      //Lista Para os Erros de Validação:
      errors: {}
    };

    if (props.location.state === undefined) {
      this.state.Mostra_Erro = true;
    } else {
      if (props.location.state.Link_Tabela_Anterior === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Link_Tabela_Anterior =
          props.location.state.Link_Tabela_Anterior;
      }
      if (props.location.state.Link_Menu_Anterior === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Link_Menu_Anterior = props.location.state.Link_Menu_Anterior;
      }
      if (props.location.state.id_inscricao === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.id_inscricao = props.location.state.id_inscricao;
      }
      if (props.location.state.ID_Evento === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.ID_Evento = props.location.state.ID_Evento;
      }
      if (props.location.state.ID_Prova === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.ID_Prova = props.location.state.ID_Prova;
      }
      if (props.location.state.Nome_Evento === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Nome_Evento = props.location.state.Nome_Evento;
      }
      if (props.location.state.Nome_Prova === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Nome_Prova = props.location.state.Nome_Prova;
      }
      if (props.location.state.Nome_Atleta === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Nome_Atleta = props.location.state.Nome_Atleta;
      }
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  Vai_Buscar_Dados_Inscricao_Atleta() {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .get(REACT_APP_API_URL + "/inscricao_atleta/" + this.state.id_inscricao, {
        headers: { authorization: "Bearer " + Token_Lido }
      })
      .then(res => {
        console.log(res);
        this.setState({ dados: res.data });
        const Dados_Lidos = res.data;
        this.setState({
          //nome_atleta: Dados_Lidos[0].athlete_name,
          dorsal: Dados_Lidos[0].dorsal_number,
          //escalao: Dados_Lidos[0].category,
          escalao_lido_bd: Dados_Lidos[0].category,
          clube_atleta: Dados_Lidos[0].club,
          competicao: Dados_Lidos[0].long_name, //.competition_id,

          id_atleta: Dados_Lidos[0].athlete_id,

          //estado_atleta: Dados_Lidos[0].athlete_status,
          estado_atleta_lido_bd: Dados_Lidos[0].athlete_status,
          rf_id: Dados_Lidos[0].rf_id_number,
          rf_id2: Dados_Lidos[0].rf_id_number2,
          rf_id3: Dados_Lidos[0].rf_id_number3,
          rf_id4: Dados_Lidos[0].rf_id_number4,
          patrocinador: Dados_Lidos[0].sponsor,
          //sigla_clube: Dados_Lidos[0].club_sigla,
          //codigo_estado_inscricao: Dados_Lidos[0].inscription_status_code
          codigo_estado_inscricao_lido_bd:
            Dados_Lidos[0].inscription_status_code
        });
      });
  }

  Vai_Buscar_Dados_Escaloes() {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .get(REACT_APP_API_URL + "/devolve_escaloes", {
        headers: { authorization: "Bearer " + Token_Lido }
      })
      .then(res => {
        console.log(res);
        this.setState({ Escaloes_Lidos: res.data });
      });
  }

  /*
  Vai_Buscar_Dados_Competicoes() {
    axios
      .get(REACT_APP_API_URL+"/competicoes/" + this.state.ID_Evento)
      .then(res => {
        console.log(res);
        this.setState({ Competicoes_Lidas: res.data });
      });
  }
  */

  Vai_Buscar_Estados_Inscricao() {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .get(REACT_APP_API_URL + "/devolve_estados_inscricao", {
        headers: { authorization: "Bearer " + Token_Lido }
      })
      .then(res => {
        console.log(res);
        this.setState({ Estados_Inscricao_Lidos: res.data });
      });
  }

  /*
Procedimento Com Axios */
  componentDidMount() {
    if (localStorage.getItem("Token") !== null) {
      this.Vai_Buscar_Dados_Escaloes();
      this.Vai_Buscar_Estados_Inscricao();
      //this.Vai_Buscar_Dados_Competicoes();
      this.Vai_Buscar_Dados_Inscricao_Atleta();
    }
  } //Fecha Procedimento Que Acontece Depois do Output do Componente Foi Rendered

  /*
  componentDidUpdate() {
    this.Vai_Buscar_Dados_Inscricao_Atleta();
  } //Fecha Procedimento Que Acontece Depois do Output do Componente Foi Rendered
  */

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleChangeSelectEscalao = escalao => {
    this.setState({ escalao });
  };

  handleChangeSelectCodigoEstadoInscricao = codigo_estado_inscricao => {
    this.setState({ codigo_estado_inscricao });
  };

  handleChangeSelectEstadoAtleta = estado_atleta => {
    this.setState({ estado_atleta });
  };

  handleValidation() {
    let errors = {};
    let formIsValid = false; //true;

    errors = Trata_Validacoes(
      this.state.dorsal,
      "inscription/dorsal_number",
      errors
    );
    /*
    errors = Trata_Validacoes(
      this.state.escalao,
      "inscription/category",
      errors
    );
    */
    if (!this.state.escalao_lido_bd && !this.state.escalao) {
      errors["inscription/category"] = "*Escolha o Escalão do Atleta";
    }

    errors = Trata_Validacoes(
      this.state.clube_atleta,
      "inscription/club",
      errors
    );
    /*
    errors = Trata_Validacoes(
      this.state.estado_atleta,
      "inscription/athlete_status",
      errors
    );
    */
    errors = Trata_Validacoes(
      this.state.rf_id,
      "inscription/rf_id_number",
      errors
    );
    errors = Trata_Validacoes(
      this.state.rf_id2,
      "inscription/rf_id_number2",
      errors
    );
    errors = Trata_Validacoes(
      this.state.rf_id3,
      "inscription/rf_id_number3",
      errors
    );
    errors = Trata_Validacoes(
      this.state.rf_id4,
      "inscription/rf_id_number4",
      errors
    );
    /*
    errors = Trata_Validacoes(
      this.state.patrocinador,
      "inscription/sponsor",
      errors
    );
    errors = Trata_Validacoes(
      this.state.sigla_clube,
      "inscription/club_sigla",
      errors
    );
    */

    if (
      !this.state.codigo_estado_inscricao_lido_bd &&
      !this.state.codigo_estado_inscricao
    ) {
      errors["inscription/inscription_status_code"] =
        "*Escolha o Estado da Inscrição do Atleta";
    }
    /*
    errors = Trata_Validacoes(
      this.state.codigo_estado_inscricao,
      "inscription/inscription_status_code",
      errors
    );
    */

    if (!this.state.estado_atleta_lido_bd && !this.state.estado_atleta) {
      errors["inscription/inscription_status_code"] =
        "*Escolha o Estado do Atleta";
    }

    //if (errors.length > 0) {
    if (isEmptyObject(errors)) {
      formIsValid = true; //false;
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.handleValidation()) {
      /*
      if (
        isNull(this.state.nome_atleta) ||
        this.state.nome_atleta === "" ||
        isUndefined(this.state.nome_atleta)
      ) {
        this.state.nome_atleta = this.state.teste1;
      }
      */

      const Token_Lido = localStorage.getItem("Token");
      axios
        .patch(
          REACT_APP_API_URL + "/update_inscricao/" + this.state.id_inscricao,
          {
            //nome_atleta: this.state.nome_atleta,
            dorsal: this.state.dorsal,
            escalao: this.state.escalao
              ? this.state.escalao.value
              : this.state.escalao_lido_bd, //escalao
            clube_atleta: this.state.clube_atleta,
            competicao: this.state.competicao,
            id_atleta: this.state.id_atleta,

            //estado_atleta: this.state.estado_atleta, //.value,//estado_atleta

            estado_atleta: this.state.estado_atleta
              ? this.state.estado_atleta.value
              : this.state.estado_atleta_lido_bd,

            rf_id: this.state.rf_id,
            rf_id2: this.state.rf_id2,
            rf_id3: this.state.rf_id3,
            rf_id4: this.state.rf_id4,
            patrocinador: this.state.patrocinador,
            //sigla_clube: this.state.sigla_clube,
            codigo_estado_inscricao: this.state.codigo_estado_inscricao
              ? this.state.codigo_estado_inscricao.value
              : this.state.codigo_estado_inscricao_lido_bd //codigo_estado_inscricao
          },
          {
            headers: { authorization: "Bearer " + Token_Lido }
          }
        )
        .then(res => {
          //console.log(res);
          this.setState({ Inserido_Sucesso: res.data.success });
        });
    }
  }

  render() {
    if (localStorage.getItem("Token") === null) {
      this.props.history.push({
        pathname: "/"
      });
    }

    if (this.state.Inserido_Sucesso === true) {
      this.props.history.push({
        pathname: this.state.Link_Tabela_Anterior,
        state: {
          //id_inscricao: this.state.id_inscricao,
          //Link_Tabela_Anterior: this.state.Link_Tabela_Anterior,
          Link_Menu_Anterior: this.state.Link_Menu_Anterior,
          ID_Evento: this.state.ID_Evento,
          ID_Prova: this.state.ID_Prova,
          Fez_Insercao_Inscricao_Atleta_Sistema: false,
          Fez_Edicao_Dados_Atleta_Sistema: false,
          Fez_Edicao_Inscricao_Atleta_Sistema: true,
          Fez_Lancamento_Desistencia_Sucesso: false,
          Fez_Eliminacao_Inscricao_Sucesso: false,
          Faz_Refresh: true,
          Nome_Evento: this.state.Nome_Evento,
          Nome_Prova: this.state.Nome_Prova
        }
      });
    }

    //Verifica Se Existe Erro. Se Houver Mostra Mensagem de Erro
    if (this.state.Mostra_Erro === true) {
      return (
        <div>
          <Drawer
            Titulo_Menu="" //"Erro"
            history={this.props.history}
          />
          {mostra_mensagem_erro()}
        </div>
      );
    } else {
      if (
        this.state.dados === undefined ||
        this.state.Escaloes_Lidos === undefined ||
        this.state.Estados_Inscricao_Lidos === undefined
      ) {
        return (
          <div className="centra_loading">
            <h1>Loading...</h1>
          </div>
        );
      } else {
        var Opcoes_Escalao = [];
        //this.state.Escaloes_Lidos.map(linha => {
        this.state.Escaloes_Lidos.forEach(function(linha) {
          var Linha = {
            value: linha.categorie_id,
            label: linha.categorie_id
          };
          Opcoes_Escalao.push(Linha);
        });
        var Opcoes_Estado_Inscricao = [];
        //this.state.Estados_Inscricao_Lidos.map(linha2 => {
        this.state.Estados_Inscricao_Lidos.forEach(function(linha2) {
          var Linha2 = {
            value: linha2.code,
            label: linha2.description + " (" + linha2.code + ")"
          };
          Opcoes_Estado_Inscricao.push(Linha2);
        });

        const border_erro = "1px solid #ff0000";

        return (
          <div>
            <Drawer
              Titulo_Menu="Editar Inscrição Atleta"
              Link_Tabela_Anterior2={this.props.location.pathname}
              Link_Tabela_Anterior={this.state.Link_Tabela_Anterior}
              Link_Menu_Anterior={this.state.Link_Menu_Anterior}
              id_inscricao={this.state.id_inscricao}
              ID_Evento={this.state.ID_Evento}
              ID_Prova={this.state.ID_Prova}
              ID_Atleta={this.state.id_atleta}
              Nome_Evento={this.state.Nome_Evento}
              Nome_Prova={this.state.Nome_Prova}
              Nome_Atleta={this.state.Nome_Atleta}
              Dorsal_Atleta={this.state.dorsal}
              Escalao_Atleta={this.state.escalao ? this.state.escalao : "-"}
              Clube_Atleta={this.state.clube_atleta}
              history={this.props.history}
            />
            {this.state.dados.map(linha => {
              return (
                <div>
                  <div className="novas_margens_volta_novos_forms">
                    <div className="centra_novos_forms">
                      <form className="login-form" onSubmit={this.handleSubmit}>
                        <div className="centra_titulos_tabs">
                          <Tabs>
                            <TabList>
                              <Tab
                                style={{
                                  border:
                                    this.state.errors[
                                      "inscription/dorsal_number"
                                    ] ||
                                    this.state.errors[
                                      "inscription/inscription_status_code"
                                    ] ||
                                    this.state.errors["inscription/category"] ||
                                    this.state.errors["inscription/club"] ||
                                    this.state.errors[
                                      "inscription/athlete_status"
                                    ]
                                      ? border_erro
                                      : ""
                                }}
                              >
                                Informação do Atleta
                              </Tab>
                              <Tab
                                style={{
                                  border:
                                    this.state.errors[
                                      "inscription/rf_id_number"
                                    ] ||
                                    this.state.errors[
                                      "inscription/rf_id_number2"
                                    ] ||
                                    this.state.errors[
                                      "inscription/rf_id_number3"
                                    ] ||
                                    this.state.errors[
                                      "inscription/rf_id_number4"
                                    ]
                                      ? border_erro
                                      : ""
                                }}
                              >
                                Identificadores
                              </Tab>
                              <button
                                type="submit"
                                style={{ marginLeft: "10px" }}
                              >
                                Alterar Inscrição
                              </button>
                            </TabList>
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            <TabPanel>
                              <div className="form">
                                <div>
                                  <label>
                                    Dorsal
                                    <span
                                      className="bolder"
                                      style={{ color: "#e60000" }}
                                    >
                                      {" (*)"}
                                    </span>
                                  </label>
                                  <input
                                    style={{
                                      border: this.state.errors[
                                        "inscription/dorsal_number"
                                      ]
                                        ? border_erro
                                        : "",
                                      textAlign: "center"
                                    }}
                                    type="number"
                                    name="dorsal"
                                    placeholder="Dorsal"
                                    onChange={this.handleChange}
                                    defaultValue={this.state.dorsal}
                                  />
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {
                                      this.state.errors[
                                        "inscription/dorsal_number"
                                      ]
                                    }
                                  </span>
                                  <div className="espaco_span" />
                                  <label>
                                    Estado da Inscrição:
                                    <span
                                      className="bolder"
                                      style={{ color: "#e60000" }}
                                    >
                                      {" (*)"}
                                    </span>
                                  </label>
                                  <div
                                    style={{
                                      border: this.state.errors[
                                        "inscription/inscription_status_code"
                                      ]
                                        ? border_erro
                                        : ""
                                    }}
                                  >
                                    <Select
                                      name="codigo_estado_inscricao"
                                      placeholder="Estado da Inscrição"
                                      onChange={
                                        this
                                          .handleChangeSelectCodigoEstadoInscricao
                                      }
                                      options={Opcoes_Estado_Inscricao}
                                      defaultValue={{
                                        value: linha.inscription_status_code,
                                        label: linha.inscription_status_code //linha.description
                                      }}
                                    />
                                  </div>
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {
                                      this.state.errors[
                                        "inscription/inscription_status_code"
                                      ]
                                    }
                                  </span>
                                  <div className="espaco_span" />
                                  <label>
                                    Escalão:
                                    <span
                                      className="bolder"
                                      style={{ color: "#e60000" }}
                                    >
                                      {" (*)"}
                                    </span>
                                  </label>
                                  <div
                                    style={{
                                      border: this.state.errors[
                                        "inscription/category"
                                      ]
                                        ? border_erro
                                        : "",
                                      textAlign: "center"
                                    }}
                                  >
                                    <Select
                                      name="escalao"
                                      placeholder="Escalão"
                                      onChange={this.handleChangeSelectEscalao}
                                      options={Opcoes_Escalao}
                                      defaultValue={{
                                        value: linha.category,
                                        label: linha.category
                                      }}
                                    />
                                  </div>
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {this.state.errors["inscription/category"]}
                                  </span>

                                  <div className="espaco_span" />
                                  <label>
                                    Clube Atleta
                                    <span
                                      className="bolder"
                                      style={{ color: "#e60000" }}
                                    >
                                      {" (*)"}
                                    </span>
                                  </label>
                                  <input
                                    style={{
                                      border: this.state.errors[
                                        "inscription/club"
                                      ]
                                        ? border_erro
                                        : "",
                                      textAlign: "center"
                                    }}
                                    type="text"
                                    name="clube_atleta"
                                    placeholder="Clube"
                                    onChange={this.handleChange}
                                    defaultValue={this.state.clube_atleta}
                                  />
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {this.state.errors["inscription/club"]}
                                  </span>
                                  {/*
                                  <div className="espaco_span" />
                                  <label>Sigla Clube</label>
                                  <input
                                    style={{ textAlign: "center" }}
                                    type="text"
                                    name="sigla_clube"
                                    placeholder="Sigla do Clube"
                                    onChange={this.handleChange}
                                    defaultValue={this.state.sigla_clube}
                                  />
                                  */}
                                  <div className="espaco_span" />
                                  <label>Patrocinador</label>
                                  <input
                                    style={{ textAlign: "center" }}
                                    type="text"
                                    name="patrocinador"
                                    placeholder="Patrocinador"
                                    onChange={this.handleChange}
                                    defaultValue={this.state.patrocinador}
                                  />
                                  <div className="espaco_span" />
                                  <label>
                                    Estado na Competição
                                    <span
                                      className="bolder"
                                      style={{ color: "#e60000" }}
                                    >
                                      {" (*)"}
                                    </span>
                                  </label>
                                  <div
                                    style={{
                                      border: this.state.errors[
                                        "inscription/athlete_status"
                                      ]
                                        ? border_erro
                                        : ""
                                    }}
                                  >
                                    <Select
                                      name="estado_atleta"
                                      placeholder="Estado na Competição"
                                      onChange={
                                        this.handleChangeSelectEstadoAtleta
                                      }
                                      options={Opcoes_Estado_Inscricao}
                                      defaultValue={{
                                        value: linha.athlete_status,
                                        label: linha.athlete_status //linha.description
                                      }}
                                    />
                                  </div>
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {
                                      this.state.errors[
                                        "inscription/athlete_status"
                                      ]
                                    }
                                  </span>
                                  <div className="espaco_span" />
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {" (*)"}
                                  </span>
                                  <span className="bolder">
                                    {" "}
                                    Campos Obrigatórios
                                  </span>
                                </div>
                              </div>
                              {/*
                              <div className="form">
                                <button type="submit">Alterar Inscrição</button>
                              </div>
                              */}
                            </TabPanel>
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}

                            <TabPanel>
                              <div className="form">
                                <div>
                                  <label>
                                    RF ID
                                    <span
                                      className="bolder"
                                      style={{ color: "#e60000" }}
                                    >
                                      {" (*)"}
                                    </span>
                                  </label>
                                  <input
                                    style={{
                                      border: this.state.errors[
                                        "inscription/rf_id_number"
                                      ]
                                        ? border_erro
                                        : "",
                                      textAlign: "center"
                                    }}
                                    type="text"
                                    name="rf_id"
                                    placeholder="RF ID"
                                    onChange={this.handleChange}
                                    defaultValue={this.state.rf_id}
                                  />
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {
                                      this.state.errors[
                                        "inscription/rf_id_number"
                                      ]
                                    }
                                  </span>
                                  <div className="espaco_span" />

                                  <label>
                                    RF ID 2
                                    <span
                                      className="bolder"
                                      style={{ color: "#e60000" }}
                                    >
                                      {" (*)"}
                                    </span>
                                  </label>
                                  <input
                                    style={{
                                      border: this.state.errors[
                                        "inscription/rf_id_number2"
                                      ]
                                        ? border_erro
                                        : "",
                                      textAlign: "center"
                                    }}
                                    type="text"
                                    name="rf_id2"
                                    placeholder="RF ID 2"
                                    onChange={this.handleChange}
                                    defaultValue={this.state.rf_id2}
                                  />
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {
                                      this.state.errors[
                                        "inscription/rf_id_number2"
                                      ]
                                    }
                                  </span>
                                  <div className="espaco_span" />

                                  <label>
                                    RF ID 3
                                    <span
                                      className="bolder"
                                      style={{ color: "#e60000" }}
                                    >
                                      {" (*)"}
                                    </span>
                                  </label>
                                  <input
                                    style={{
                                      border: this.state.errors[
                                        "inscription/rf_id_number3"
                                      ]
                                        ? border_erro
                                        : "",
                                      textAlign: "center"
                                    }}
                                    type="text"
                                    name="rf_id3"
                                    placeholder="RF ID 3"
                                    onChange={this.handleChange}
                                    defaultValue={this.state.rf_id3}
                                  />
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {
                                      this.state.errors[
                                        "inscription/rf_id_number3"
                                      ]
                                    }
                                  </span>
                                  <div className="espaco_span" />

                                  <label>
                                    RF ID 4
                                    <span
                                      className="bolder"
                                      style={{ color: "#e60000" }}
                                    >
                                      {" (*)"}
                                    </span>
                                  </label>
                                  <input
                                    style={{
                                      border: this.state.errors[
                                        "inscription/rf_id_number4"
                                      ]
                                        ? border_erro
                                        : "",
                                      textAlign: "center"
                                    }}
                                    type="text"
                                    name="rf_id4"
                                    placeholder="RF ID 4"
                                    onChange={this.handleChange}
                                    defaultValue={this.state.rf_id4}
                                  />
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {
                                      this.state.errors[
                                        "inscription/rf_id_number4"
                                      ]
                                    }
                                  </span>

                                  <div className="espaco_span" />
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {" (*)"}
                                  </span>
                                  <span className="bolder">
                                    {" "}
                                    Campos Obrigatórios
                                  </span>
                                </div>
                              </div>
                              {/*}
                              <div className="form">
                                <button type="submit">Alterar Inscrição</button>
                              </div>
                            */}
                            </TabPanel>
                          </Tabs>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        );
      }
    }
  }
}

export default Formulario_Registo_User;
