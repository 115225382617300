import React, { Component } from "react";
//import { Link } from "react-router-dom";
import axios from "axios";
import Moment from "moment";
import Drawer from "../Drawer/Drawer.js";
import Select from "react-select";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

//Funções Auxiliares:
import {
  mostra_mensagem_erro,
  //ve_resultados,
  Trata_Validacoes,
  isEmptyObject
} from "../Funcoes_Auxiliares/Funcoes_Auxiliares.js";

let REACT_APP_API_URL;

if (process.env.REACT_APP_ESTADO === "Deploy") {
  REACT_APP_API_URL = process.env.REACT_APP_API_URL_PROD;
} //Development
else {
  REACT_APP_API_URL = process.env.REACT_APP_API_URL_DEV;
}

const opcoes_select_gender = [
  { value: "M", label: "Masculino" },
  { value: "F", label: "Feminino" }
];

const Opcoes_Regional = [
  { value: 1, label: "Sim" },
  { value: 0, label: "Não" }
];

class Formulario_Editar_Atleta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: undefined,
      paises: undefined,

      Link_Menu_Anterior: null,
      Link_Tabela_Anterior: null,
      ID_Atleta: null,

      nome: null,
      email: null,
      birthday: null,
      gender: null,
      nacionality: null,
      regional: null,
      language: null,
      identification_type: null,
      identification_id: null,
      phone_number1: null,
      phone_number2: null,
      phone_number3: null,
      postal_code: null,
      address: null,
      city: null,
      country: null,
      previous_participated_events: null,
      nif: null,
      atrp_number: null,
      raking_name: null,
      extra_info: null,
      previous_participated_event: null,
      previous_participated_event_link: null,
      extra_info2: null,
      raking_name_last: null,
      fcmp_number: null,
      name_phone_number3: null,
      password: null,
      isf_number: null,

      //Variavel Controlo de Erro na Leitura dos Props
      Mostra_Erro: false,

      Opcao_Escolhida_Select_Sexo: null,
      Opcao_Escolhida_Select_Nacionalidade: null,
      Opcao_Escolhida_Select_Regional: null,
      birthday_lido_bd: null,

      //Variavel Para Controlar o Redirect
      Inserido_Sucesso: false,

      //Lista Para os Erros de Validação:
      errors: {}
    };

    if (props.location.state === undefined) {
      this.state.Mostra_Erro = true;
    } else {
      if (props.location.state.Link_Menu_Anterior === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Link_Menu_Anterior = props.location.state.Link_Menu_Anterior;
      }
      if (props.location.state.Link_Tabela_Anterior === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Link_Tabela_Anterior =
          props.location.state.Link_Tabela_Anterior;
      }
      if (props.location.state.ID_Atleta === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.ID_Atleta = props.location.state.ID_Atleta;
      }

      if (props.location.state.ID_Evento === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.ID_Evento = props.location.state.ID_Evento;
      }
      if (props.location.state.ID_Prova === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.ID_Prova = props.location.state.ID_Prova;
      }
      if (props.location.state.Nome_Evento === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Nome_Evento = props.location.state.Nome_Evento;
      }
      if (props.location.state.Nome_Prova === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Nome_Prova = props.location.state.Nome_Prova;
      }
      if (props.location.state.Nome_Atleta === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Nome_Atleta = props.location.state.Nome_Atleta;
      }
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  Vai_Buscar_Dados() {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .get(
        REACT_APP_API_URL + "/devolve_dados_atleta/" + this.state.ID_Atleta,
        {
          headers: { authorization: "Bearer " + Token_Lido }
        }
      )
      .then(res => {
        console.log(res);
        this.setState({ items: res.data });
        const Dados_Lidos = res.data;
        this.setState({
          nome: Dados_Lidos[0].name,
          email: Dados_Lidos[0].email,
          birthday: Dados_Lidos[0].birthday,
          birthday_lido_bd: Dados_Lidos[0].birthday,
          gender: Dados_Lidos[0].gender,
          nacionality: Dados_Lidos[0].nacionality,
          regional: Dados_Lidos[0].regional,
          language: Dados_Lidos[0].language,
          identification_type: Dados_Lidos[0].identification_type,
          identification_id: Dados_Lidos[0].identification_id,
          phone_number1: Dados_Lidos[0].phone_number1,
          phone_number2: Dados_Lidos[0].phone_number2,
          phone_number3: Dados_Lidos[0].phone_number3,
          postal_code: Dados_Lidos[0].postal_code,
          address: Dados_Lidos[0].address,
          city: Dados_Lidos[0].city,
          country: Dados_Lidos[0].country,
          previous_participated_events:
            Dados_Lidos[0].previous_participated_events,
          nif: Dados_Lidos[0].nif,
          atrp_number: Dados_Lidos[0].atrp_number,
          raking_name: Dados_Lidos[0].raking_name,
          extra_info: Dados_Lidos[0].extra_info,
          previous_participated_event:
            Dados_Lidos[0].previous_participated_event,
          previous_participated_event_link:
            Dados_Lidos[0].previous_participated_event_link,
          extra_info2: Dados_Lidos[0].extra_info2,
          raking_name_last: Dados_Lidos[0].raking_name_last,
          fcmp_number: Dados_Lidos[0].fcmp_number,
          name_phone_number3: Dados_Lidos[0].name_phone_number3,
          password: Dados_Lidos[0].password,
          isf_number: Dados_Lidos[0].isf_number
        });
      });
  }

  componentDidMount() {
    if (localStorage.getItem("Token") !== null) {
      this.Vai_Buscar_Dados();
      this.Vai_Buscar_Paises();
    }
  } //Fecha Procedimento Que Acontece Depois do Output do Componente Foi Rendered

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleChangeSelectSexo = Opcao_Escolhida_Select_Sexo => {
    this.setState({ Opcao_Escolhida_Select_Sexo });
  };

  handleChangeSelectNacionalidade = Opcao_Escolhida_Select_Nacionalidade => {
    this.setState({ Opcao_Escolhida_Select_Nacionalidade });
  };

  handleChangeSelectRegional = Opcao_Escolhida_Select_Regional => {
    this.setState({ Opcao_Escolhida_Select_Regional });
  };

  Converte_Value_Regional_Para_Label(Value) {
    if (Value === 1) {
      return "Sim";
    } else {
      return "Não";
    }
  }

  Converte_Value_Sexo_Para_Label(Value) {
    if (Value === "F") {
      return "Feminino";
    } else {
      return "Masculino";
    }
  }

  handleValidation() {
    let errors = {};
    let formIsValid = false; //true;

    //Para Cada Campo Invocar o Trata_Validacoes
    errors = Trata_Validacoes(this.state.nome, "athlete/nome", errors);
    errors = Trata_Validacoes(this.state.email, "athlete/email", errors);
    errors = Trata_Validacoes(this.state.birthday, "athlete/birthday", errors);
    errors = Trata_Validacoes(this.state.gender, "athlete/gender", errors);
    errors = Trata_Validacoes(
      this.state.raking_name,
      "athlete/raking_name",
      errors
    );
    errors = Trata_Validacoes(
      this.state.raking_name_last,
      "athlete/raking_name_last",
      errors
    );
    //errors = Trata_Validacoes(this.state.regional, "athlete/regional", errors);
    //errors = Trata_Validacoes(this.state.language, "athlete/language", errors);
    //errors = Trata_Validacoes(this.state.identification_type,"athlete/identification_type",errors);
    //errors = Trata_Validacoes(this.state.identification_id,"athlete/identification_id",errors);
    //errors = Trata_Validacoes(this.state.phone_number1,"athlete/phone_number1",errors);
    //errors = Trata_Validacoes(this.state.phone_number2,"athlete/phone_number2",errors);
    errors = Trata_Validacoes(
      this.state.phone_number3,
      "athlete/phone_number3",
      errors
    );
    //errors = Trata_Validacoes(this.state.postal_code,"athlete/postal_code",errors);
    //errors = Trata_Validacoes(this.state.address, "athlete/address", errors);
    errors = Trata_Validacoes(this.state.city, "athlete/city", errors);
    //errors = Trata_Validacoes(this.state.previous_participated_events,"athlete/previous_participated_events",errors);
    //errors = Trata_Validacoes(this.state.nif, "athlete/nif", errors);
    //errors = Trata_Validacoes(this.state.atrp_number,"athlete/atrp_number",errors);
    //errors = Trata_Validacoes(this.state.extra_info,"athlete/extra_info",errors);
    //errors = Trata_Validacoes(this.state.previous_participated_event,"athlete/previous_participated_event",errors);
    //errors = Trata_Validacoes(this.state.previous_participated_event_link,"athlete/previous_participated_event_link",errors);
    //errors = Trata_Validacoes(this.state.extra_info2,"athlete/extra_info2",errors);
    //errors = Trata_Validacoes(this.state.fcmp_number,"athlete/fcmp_number",errors);
    errors = Trata_Validacoes(
      this.state.name_phone_number3,
      "athlete/name_phone_number3",
      errors
    );
    //errors = Trata_Validacoes(this.state.password, "athlete/password", errors);
    //errors = Trata_Validacoes(this.state.isf_number,"athlete/isf_number",errors);
    /*
    errors = Trata_Validacoes(
      this.state.linha_nacionalidade,
      "athlete/linha_nacionalidade",
      errors
    );
    */
    if (!this.state.linha_nacionalidade && !this.state.nacionality) {
      errors["athlete/linha_nacionalidade"] =
        "*Escolha o País de Nascença do Atleta";
    }

    //if (errors.length > 0) {
    if (isEmptyObject(errors)) {
      formIsValid = true; //false;
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleSubmit(event) {
    event.preventDefault(); //Impede o Redirect

    if (this.handleValidation()) {
      const Token_Lido = localStorage.getItem("Token");
      axios
        .patch(
          REACT_APP_API_URL + "/update_atleta/" + this.state.ID_Atleta,
          {
            nome: this.state.nome,
            email: this.state.email,
            //birthday: Moment(this.state.birthday, "YYYY-MM-DD"), //this.state.birthday,
            birthday:
              this.state.birthday !== this.state.birthday_lido_bd
                ? this.state.birthday
                : Moment(this.state.birthday_lido_bd, "YYYY-MM-DD")
                    //.add(1, "hours")
                    .tz("Europe/Madrid")
                    .format("YYYY-MM-DD"), //this.state.birthday_lido_bd, //Moment(this.state.birthday_lido_bd, "YYYY-MM-DD"), //.format("YYYY-MM-DD"),
            gender: this.state.Opcao_Escolhida_Select_Sexo
              ? this.state.Opcao_Escolhida_Select_Sexo.value
              : this.state.gender,

            //Talvez Guardar os Valores Iniciais Destas 2 Variaveis. Se Houver Alterações, Faz Isto, Caso Contrário Envia os Valores Iniciais.
            //O Que Esta em Causa é o Valor Por Defeito, Que Pode Nao Enviar as Coisas Como Esperado
            nacionality: this.state.Opcao_Escolhida_Select_Nacionalidade
              ? //.Valor_name_pt
                this.state.Opcao_Escolhida_Select_Nacionalidade.Valor_ioc //Valor_name_pt
              : this.state.nacionality, //this.state.nacionality,
            country: this.state.Opcao_Escolhida_Select_Nacionalidade //.Valor_ioc
              ? this.state.Opcao_Escolhida_Select_Nacionalidade.Valor_ioc
              : this.state.country, //this.state.country,0
            regional: this.state.Opcao_Escolhida_Select_Regional
              ? this.state.Opcao_Escolhida_Select_Regional.value
              : this.state.regional,
            language: this.state.language,
            identification_type: this.state.identification_type,
            identification_id: this.state.identification_id,
            phone_number1: this.state.phone_number1,
            phone_number2: this.state.phone_number2,
            phone_number3: this.state.phone_number3,
            postal_code: this.state.postal_code,
            address: this.state.address,
            city: this.state.city,
            previous_participated_events: this.state
              .previous_participated_events,
            nif: this.state.nif,
            atrp_number: this.state.atrp_number,
            raking_name: this.state.raking_name,
            extra_info: this.state.extra_info,
            previous_participated_event: this.state.previous_participated_event,
            previous_participated_event_link: this.state
              .previous_participated_event_link,
            extra_info2: this.state.extra_info2,
            raking_name_last: this.state.raking_name_last,
            fcmp_number: this.state.fcmp_number,
            name_phone_number3: this.state.name_phone_number3,
            password: this.state.password,
            isf_number: this.state.isf_number
          },
          {
            headers: { authorization: "Bearer " + Token_Lido }
          }
        )
        .then(res => {
          console.log(res);
          this.setState({ Inserido_Sucesso: res.data.success });
        });
    }
  }

  Vai_Buscar_Paises() {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .get(REACT_APP_API_URL + "/devolve_paises_sistema", {
        headers: { authorization: "Bearer " + Token_Lido }
      })
      .then(res => {
        console.log(res);
        this.setState({ paises: res.data });
      });
  }

  render() {
    if (localStorage.getItem("Token") === null) {
      this.props.history.push({
        pathname: "/"
      });
    }

    if (this.state.Inserido_Sucesso === true) {
      this.props.history.push({
        pathname: "/lista_atletas_inscritos_prova",
        state: {
          Link_Menu_Anterior: this.state.Link_Menu_Anterior,
          Fez_Insercao_Atleta_Sistema: false,
          Fez_Edicao_Atleta_Sistema: true,
          Faz_Refresh: true,

          ID_Evento: this.state.ID_Evento,
          ID_Prova: this.state.ID_Prova,
          Nome_Evento: this.state.Nome_Evento,
          Nome_Prova: this.state.Nome_Prova,
          Fez_Insercao_Inscricao_Atleta_Sistema: false,
          Fez_Edicao_Dados_Atleta_Sistema: true,
          Fez_Edicao_Inscricao_Atleta_Sistema: false,
          Fez_Lancamento_Desistencia_Sucesso: false,
          Fez_Eliminacao_Inscricao_Sucesso: false
        }
      });
    }

    //Verifica Se Existe Erro. Se Houver Mostra Mensagem de Erro
    if (this.state.Mostra_Erro === true) {
      return (
        <div>
          <Drawer
            Titulo_Menu="" //"Erro"
            history={this.props.history}
          />
          {mostra_mensagem_erro()}
        </div>
      );
    } else {
      if (this.state.items === undefined || this.state.paises === undefined) {
        return (
          <div className="centra_loading">
            <h1>Loading...</h1>
          </div>
        );
      } else {
        var Opcoes_Nacionalidade = [];
        //this.state.paises.map(linha2 => {
        this.state.paises.forEach(function(linha2) {
          var Linha = {
            Valor_ioc: linha2.name_en, //linha2.ioc,
            Valor_name_pt: linha2.name_pt,
            label: linha2.name_pt
          };
          Opcoes_Nacionalidade.push(Linha);
        });

        const border_erro = "1px solid #ff0000";

        return (
          <div>
            <Drawer
              Titulo_Menu="Editar Atleta"
              Link_Tabela_Anterior={this.state.Link_Tabela_Anterior}
              Link_Menu_Anterior={this.state.Link_Menu_Anterior}
              ID_Evento={this.state.ID_Evento}
              ID_Prova={this.state.ID_Prova}
              Nome_Evento={this.state.Nome_Evento}
              Nome_Prova={this.state.Nome_Prova}
              Nome_Atleta={this.state.Nome_Atleta}
              history={this.props.history}
            />
            {this.state.items.map(linha2 => {
              return (
                <div>
                  <div className="novas_margens_volta_novos_forms">
                    <div className="centra_novos_forms">
                      <form className="login-form" onSubmit={this.handleSubmit}>
                        <div className="centra_titulos_tabs">
                          <Tabs>
                            <TabList>
                              <Tab
                                style={{
                                  border:
                                    this.state.errors["athlete/nome"] ||
                                    this.state.errors["athlete/email"] ||
                                    this.state.errors["athlete/birthday"] ||
                                    this.state.errors["athlete/gender"]
                                      ? border_erro
                                      : ""
                                }}
                              >
                                Informação do Atleta
                              </Tab>
                              <Tab
                                style={{
                                  border:
                                    this.state.errors[
                                      "athlete/linha_nacionalidade"
                                    ] || this.state.errors["athlete/city"]
                                      ? border_erro
                                      : ""
                                }}
                              >
                                Nacionalidade
                              </Tab>
                              <Tab
                                style={{
                                  border:
                                    this.state.errors["athlete/raking_name"] ||
                                    this.state.errors[
                                      "athlete/raking_name_last"
                                    ]
                                      ? border_erro
                                      : ""
                                }}
                              >
                                Dorsal/Peitoral
                              </Tab>
                              <Tab
                                style={{
                                  border:
                                    this.state.errors[
                                      "athlete/phone_number3"
                                    ] ||
                                    this.state.errors[
                                      "athlete/name_phone_number3"
                                    ]
                                      ? border_erro
                                      : ""
                                }}
                              >
                                Contactos
                              </Tab>
                              <Tab>Identificação do Atleta</Tab>
                              {/*<Tab>Extra</Tab>*/}
                              <button
                                type="submit"
                                style={{ marginLeft: "10px" }}
                              >
                                Alterar Atleta
                              </button>
                            </TabList>
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            <TabPanel>
                              <div className="form">
                                <div>
                                  <label>
                                    {
                                      //Nome Atleta
                                    }
                                    Nome Completo
                                    <span
                                      className="bolder"
                                      style={{ color: "#e60000" }}
                                    >
                                      {" (*)"}
                                    </span>
                                  </label>
                                  <input
                                    style={{
                                      border: this.state.errors["athlete/nome"]
                                        ? border_erro
                                        : "",
                                      textAlign: "center"
                                    }}
                                    type="text"
                                    name="nome"
                                    placeholder="Nome Atleta"
                                    onChange={this.handleChange}
                                    defaultValue={this.state.nome}
                                  />
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {this.state.errors["athlete/nome"]}
                                  </span>
                                  <div className="espaco_span" />
                                  <label>
                                    Email
                                    <span
                                      className="bolder"
                                      style={{ color: "#e60000" }}
                                    >
                                      {" (*)"}
                                    </span>
                                  </label>
                                  <input
                                    style={{
                                      border: this.state.errors["athlete/email"]
                                        ? border_erro
                                        : "",
                                      textAlign: "center"
                                    }}
                                    type="text"
                                    name="email"
                                    placeholder="Email"
                                    onChange={this.handleChange}
                                    defaultValue={this.state.email}
                                  />
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {this.state.errors["athlete/email"]}
                                  </span>
                                  <div className="espaco_span" />
                                  <label>
                                    Data Nascimento
                                    <span
                                      className="bolder"
                                      style={{ color: "#e60000" }}
                                    >
                                      {" (*)"}
                                    </span>
                                  </label>
                                  <input
                                    style={{
                                      border: this.state.errors[
                                        "athlete/birthday"
                                      ]
                                        ? border_erro
                                        : "",
                                      textAlign: "center"
                                    }}
                                    type="date"
                                    name="birthday"
                                    onChange={this.handleChange}
                                    //defaultValue={linha2.birthday}
                                    defaultValue={String(
                                      Moment(this.state.birthday).format(
                                        "YYYY-MM-DD"
                                      )
                                    )}
                                  />
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {this.state.errors["athlete/birthday"]}
                                  </span>
                                  <div className="espaco_span" />
                                  <label>
                                    Sexo
                                    <span
                                      className="bolder"
                                      style={{ color: "#e60000" }}
                                    >
                                      {" (*)"}
                                    </span>
                                  </label>
                                  <div
                                    style={{
                                      border: this.state.errors[
                                        "athlete/gender"
                                      ]
                                        ? border_erro
                                        : "",
                                      textAlign: "center"
                                    }}
                                  >
                                    <Select
                                      //value={Opcao_Escolhida_Select_Sexo}
                                      name="gender"
                                      placeholder="Sexo"
                                      onChange={this.handleChangeSelectSexo}
                                      options={opcoes_select_gender}
                                      defaultValue={{
                                        value: linha2.gender,
                                        label: this.Converte_Value_Sexo_Para_Label(
                                          linha2.gender
                                        )
                                      }}
                                      //value={linha2.gender}
                                    />
                                  </div>
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {this.state.errors["athlete/gender"]}
                                  </span>
                                  <div className="espaco_span" />
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {" (*)"}
                                  </span>{" "}
                                  <span className="bolder">
                                    {" "}
                                    Campos Obrigatórios
                                  </span>
                                </div>
                              </div>
                              {/*}
                              <div className="form">
                                <button type="submit">Alterar Atleta</button>
                              </div>
                              */}
                            </TabPanel>
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            <TabPanel>
                              <div className="form">
                                <div>
                                  <label>Morada</label>
                                  <input
                                    style={{ textAlign: "center" }}
                                    type="text"
                                    name="address"
                                    placeholder="Morada"
                                    onChange={this.handleChange}
                                    defaultValue={this.state.address}
                                  />
                                  <div className="espaco_span" />
                                  <label>Código Postal</label>
                                  <input
                                    style={{ textAlign: "center" }}
                                    type="text"
                                    name="postal_code"
                                    placeholder="Código Postal"
                                    onChange={this.handleChange}
                                    defaultValue={this.state.postal_code}
                                  />
                                  <div className="espaco_span" />
                                  <label>
                                    Cidade
                                    <span
                                      className="bolder"
                                      style={{ color: "#e60000" }}
                                    >
                                      {" (*)"}
                                    </span>
                                  </label>
                                  <input
                                    style={{
                                      border: this.state.errors["athlete/city"]
                                        ? border_erro
                                        : "",
                                      textAlign: "center"
                                    }}
                                    type="text"
                                    name="city"
                                    placeholder="Cidade"
                                    onChange={this.handleChange}
                                    defaultValue={this.state.city}
                                  />
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {this.state.errors["athlete/city"]}
                                  </span>
                                  <div className="espaco_span" />
                                  <label>
                                    Nacionalidade:
                                    <span
                                      className="bolder"
                                      style={{ color: "#e60000" }}
                                    >
                                      {" (*)"}
                                    </span>
                                  </label>
                                  <div
                                    style={{
                                      border: this.state.errors[
                                        "athlete/linha_nacionalidade"
                                      ]
                                        ? border_erro
                                        : "",
                                      textAlign: "center"
                                    }}
                                  >
                                    <Select
                                      //value={Opcao_Escolhida_Select_Nacionalidade}
                                      name="linha_nacionalidade"
                                      placeholder="Nacionalidade"
                                      onChange={
                                        this.handleChangeSelectNacionalidade
                                      }
                                      options={Opcoes_Nacionalidade}
                                      //value={linha2.nacionality}
                                      defaultValue={{
                                        value: linha2.nacionality,
                                        Valor_name_pt: linha2.nacionality,
                                        Valor_ioc: linha2.country,
                                        label: linha2.nacionality
                                      }}
                                    />
                                  </div>
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {
                                      this.state.errors[
                                        "athlete/linha_nacionalidade"
                                      ]
                                    }
                                  </span>
                                  <div className="espaco_span" />
                                  {/*}
                                  <label>Linguagem</label>
                                  <input
                                    style={{ textAlign: "center" }}
                                    type="text"
                                    name="language"
                                    placeholder="Linguagem"
                                    onChange={this.handleChange}
                                    defaultValue={this.state.language}
                                  />
                                  <div className="espaco_span" />
                                  */}
                                  <label>
                                    Atleta Regional?
                                    {/*<span className="bolder" style={{ color: "#e60000" }}>{" (*)"}</span>*/}
                                  </label>
                                  <div /*
                                    style={{
                                      border: this.state.errors[
                                        "athlete/regional"
                                      ]
                                        ? border_erro
                                        : ""
                                    }}*/
                                  >
                                    <Select
                                      name="regional"
                                      placeholder="Regional"
                                      onChange={this.handleChangeSelectRegional}
                                      options={Opcoes_Regional}
                                      //value={linha2.regional}
                                      defaultValue={{
                                        value: linha2.regional,
                                        label: this.Converte_Value_Regional_Para_Label(
                                          linha2.regional
                                        )
                                      }}
                                    />
                                  </div>
                                  {/*
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {this.state.errors["athlete/regional"]}
                                  </span>
                                  */}
                                  <div className="espaco_span" />
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {" (*)"}
                                  </span>{" "}
                                  <span className="bolder">
                                    {" "}
                                    Campos Obrigatórios
                                  </span>
                                </div>
                              </div>
                              {/*}
                              <div className="form">
                                <button type="submit">Alterar Atleta</button>
                              </div>
                              */}
                            </TabPanel>
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            <TabPanel>
                              <div className="form">
                                <div>
                                  <label>
                                    {
                                      //Nome Ranking
                                    }
                                    Nome
                                    <span
                                      className="bolder"
                                      style={{ color: "#e60000" }}
                                    >
                                      {" (*)"}
                                    </span>
                                  </label>
                                  <input
                                    style={{
                                      border: this.state.errors[
                                        "athlete/raking_name"
                                      ]
                                        ? border_erro
                                        : "",
                                      textAlign: "center"
                                    }}
                                    type="text"
                                    name="raking_name"
                                    placeholder="Nome Ranking"
                                    onChange={this.handleChange}
                                    defaultValue={this.state.raking_name}
                                  />
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {this.state.errors["athlete/raking_name"]}
                                  </span>
                                  <div className="espaco_span" />
                                  <label>
                                    {
                                      //Último Nome Ranking
                                    }
                                    Sobrenome
                                    <span
                                      className="bolder"
                                      style={{ color: "#e60000" }}
                                    >
                                      {" (*)"}
                                    </span>
                                  </label>
                                  <input
                                    style={{
                                      border: this.state.errors[
                                        "athlete/raking_name_last"
                                      ]
                                        ? border_erro
                                        : "",
                                      textAlign: "center"
                                    }}
                                    type="text"
                                    name="raking_name_last"
                                    placeholder="Último Nome Ranking"
                                    onChange={this.handleChange}
                                    defaultValue={this.state.raking_name_last}
                                  />
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {
                                      this.state.errors[
                                        "athlete/raking_name_last"
                                      ]
                                    }
                                  </span>
                                  <div className="espaco_span" />
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {" (*)"}
                                  </span>{" "}
                                  <span className="bolder">
                                    {" "}
                                    Campos Obrigatórios
                                  </span>
                                </div>
                              </div>
                              {/*}
                              <div className="form">
                                <button type="submit">Alterar Atleta</button>
                              </div>
                              */}
                            </TabPanel>
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            <TabPanel>
                              <div className="form">
                                <div>
                                  <label>Telefone do Atleta</label>
                                  <input
                                    style={{ textAlign: "center" }}
                                    type="text"
                                    name="phone_number1"
                                    placeholder="Telefone do Atleta"
                                    onChange={this.handleChange}
                                    defaultValue={this.state.phone_number1}
                                  />
                                  <div className="espaco_span" />
                                  <label>Telefone da Prova</label>
                                  <input
                                    style={{ textAlign: "center" }}
                                    type="text"
                                    name="phone_number2"
                                    placeholder="Telefone da Prova"
                                    onChange={this.handleChange}
                                    defaultValue={this.state.phone_number2}
                                  />
                                  <div className="espaco_span" />
                                  <label>
                                    Telefone de Emergência
                                    <span
                                      className="bolder"
                                      style={{ color: "#e60000" }}
                                    >
                                      {" (*)"}
                                    </span>
                                  </label>
                                  <input
                                    style={{
                                      border: this.state.errors[
                                        "athlete/phone_number3"
                                      ]
                                        ? border_erro
                                        : "",
                                      textAlign: "center"
                                    }}
                                    type="text"
                                    name="phone_number3"
                                    placeholder="Telefone 3"
                                    onChange={this.handleChange}
                                    defaultValue={this.state.phone_number3}
                                  />
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {this.state.errors["athlete/phone_number3"]}
                                  </span>
                                  <div className="espaco_span" />
                                  <label>
                                    Nome do Contato de Emergência
                                    <span
                                      className="bolder"
                                      style={{ color: "#e60000" }}
                                    >
                                      {" (*)"}
                                    </span>
                                  </label>
                                  <input
                                    style={{
                                      border: this.state.errors[
                                        "athlete/name_phone_number3"
                                      ]
                                        ? border_erro
                                        : "",
                                      textAlign: "center"
                                    }}
                                    type="text"
                                    name="name_phone_number3"
                                    placeholder="Nome do Contato de Emergência"
                                    onChange={this.handleChange}
                                    defaultValue={this.state.name_phone_number3}
                                  />
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {
                                      this.state.errors[
                                        "athlete/name_phone_number3"
                                      ]
                                    }
                                  </span>
                                  <div className="espaco_span" />
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {" (*)"}
                                  </span>{" "}
                                  <span className="bolder">
                                    {" "}
                                    Campos Obrigatórios
                                  </span>
                                </div>
                              </div>
                              {/*}
                              <div className="form">
                                <button type="submit">Alterar Atleta</button>
                              </div>
                              */}
                            </TabPanel>
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            <TabPanel>
                              <div className="form">
                                <div>
                                  <label>Número ATRP</label>
                                  <input
                                    style={{ textAlign: "center" }}
                                    type="text"
                                    name="atrp_number"
                                    placeholder="Número ATRP"
                                    onChange={this.handleChange}
                                    defaultValue={this.state.atrp_number}
                                  />
                                  <div className="espaco_span" />
                                  <label>Número FCMP</label>
                                  <input
                                    style={{ textAlign: "center" }}
                                    type="text"
                                    name="fcmp_number"
                                    placeholder="Número FCMP"
                                    onChange={this.handleChange}
                                    defaultValue={this.state.fcmp_number}
                                  />
                                  <div className="espaco_span" />
                                  <label>Número ISF</label>
                                  <input
                                    style={{ textAlign: "center" }}
                                    type="text"
                                    name="isf_number"
                                    placeholder="Número ISF"
                                    onChange={this.handleChange}
                                    defaultValue={this.state.isf_number}
                                  />
                                  <div className="espaco_span" />
                                  <label>Tipo Identificação</label>
                                  <input
                                    style={{ textAlign: "center" }}
                                    type="text"
                                    name="identification_type"
                                    placeholder="Tipo Identificação"
                                    onChange={this.handleChange}
                                    defaultValue={
                                      this.state.identification_type
                                    }
                                  />
                                  <div className="espaco_span" />
                                  <label>ID Identificação</label>
                                  <input
                                    style={{ textAlign: "center" }}
                                    type="text"
                                    name="identification_id"
                                    placeholder="ID Identificação"
                                    onChange={this.handleChange}
                                    defaultValue={this.state.identification_id}
                                  />
                                  <div className="espaco_span" />
                                  <label>
                                    {/*NIF*/}Número de Identificação Fiscal
                                  </label>
                                  <input
                                    style={{ textAlign: "center" }}
                                    type="text"
                                    name="nif"
                                    placeholder="Número de Identificação Fiscal"
                                    onChange={this.handleChange}
                                    defaultValue={this.state.nif}
                                  />
                                  <div className="espaco_span" />
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {" (*)"}
                                  </span>{" "}
                                  <span className="bolder">
                                    {" "}
                                    Campos Obrigatórios
                                  </span>
                                </div>
                              </div>
                              {/*}
                              <div className="form">
                                <button type="submit">Alterar Atleta</button>
                              </div>
                              */}
                            </TabPanel>
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*}
                            <TabPanel>
                              <div className="form">
                                <div>
                                  <label>
                                    Evento Participado Anteriormente
                                  </label>
                                  <input
                                    style={{ textAlign: "center" }}
                                    type="text"
                                    name="previous_participated_event"
                                    placeholder="Evento Participado Anteriormente"
                                    onChange={this.handleChange}
                                    defaultValue={
                                      this.state.previous_participated_event
                                    }
                                  />
                                  <div className="espaco_span" />

                                  <label>
                                    Link Evento Participado Anteriormente
                                  </label>
                                  <input
                                    style={{ textAlign: "center" }}
                                    type="text"
                                    name="previous_participated_event_link"
                                    placeholder="Link Evento Participado Anteriormente"
                                    onChange={this.handleChange}
                                    defaultValue={
                                      this.state
                                        .previous_participated_event_link
                                    }
                                  />
                                  <div className="espaco_span" />

                                  <label>
                                    Eventos Participados Anteriormente
                                  </label>
                                  <input
                                    style={{ textAlign: "center" }}
                                    type="text"
                                    name="previous_participated_events"
                                    placeholder="Eventos Participados Anteriormente"
                                    onChange={this.handleChange}
                                    defaultValue={
                                      this.state.previous_participated_events
                                    }
                                  />
                                  <div className="espaco_span" />

                                  <label>Informação Extra</label>
                                  <input
                                    style={{ textAlign: "center" }}
                                    type="text"
                                    name="extra_info"
                                    placeholder="Informação Extra"
                                    onChange={this.handleChange}
                                    defaultValue={this.state.extra_info}
                                  />
                                  <div className="espaco_span" />

                                  <label>Informação Extra 2</label>
                                  <input
                                    style={{ textAlign: "center" }}
                                    type="text"
                                    name="extra_info2"
                                    placeholder="Informação Extra 2"
                                    onChange={this.handleChange}
                                    defaultValue={this.state.extra_info2}
                                  />
                                  <div className="espaco_span" />

                                  <label>Password</label>
                                  <input
                                    style={{ textAlign: "center" }}
                                    type="text"
                                    name="password"
                                    placeholder="Password"
                                    onChange={this.handleChange}
                                    defaultValue={this.state.password}
                                  />
                                </div>
                                <div className="espaco_span">
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {" (*)"}
                                  </span>{" "}
                                  <span className="bolder">
                                    {" "}
                                    Campos Obrigatórios
                                  </span>
                                </div>
                              </div>
                            </TabPanel>
                            */}
                          </Tabs>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        );
      }
    }
  }
}

export default Formulario_Editar_Atleta;
