import React, { Component } from "react";
//import { Link } from "react-router-dom";
import axios from "axios";
import Moment from "moment";
import Drawer from "../Drawer/Drawer.js";
import Select from "react-select";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

//Funções Auxiliares:
import {
  mostra_mensagem_erro,
  //ve_resultados,
  Trata_Validacoes,
  isEmptyObject
} from "../Funcoes_Auxiliares/Funcoes_Auxiliares.js";

let REACT_APP_API_URL;

if (process.env.REACT_APP_ESTADO === "Deploy") {
  REACT_APP_API_URL = process.env.REACT_APP_API_URL_PROD;
} //Development
else {
  REACT_APP_API_URL = process.env.REACT_APP_API_URL_DEV;
}

class Formulario_Adicionar_Checkpoint_Prova extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Link_Tabela_Anterior: null,
      Link_Menu_Anterior: null,
      ID_Evento: null,
      ID_Prova: null,
      //Nome_Evento: null,
      //Nome_Prova: null,

      Nome_Evento: undefined, //null,
      Nome_Prova: undefined, //null,
      Checkpoints_Lidos: undefined, //[],
      Numeros_Checkpoints_Lidos_Prova: undefined,

      checkpoint_id: null,
      checkpoint_order: null,
      tipo_controlo: null,
      previsao_rapido: null,
      distancia_mapa: null,
      distancia: null,
      distancia_acumulada: null,
      abertura: null,
      desnivel_positivo: null,
      desnivel_positivo_acumulado: null,
      desnivel_negativo: null,
      desnivel_negativo_acumulado: null,
      previsao_lento: null,
      fecho: null,
      abastecimento: null,

      //Variavel Controlo de Erro na Leitura dos Props
      Mostra_Erro: false,

      //Variaveis Para 2º Metodo
      local2: null,
      coordinate2: null,
      altitude2: null,
      /*
      checkpoint_order2: null,
      tipo_controlo2: null,
      previsao_rapido2: null,
      distancia_mapa2: null,
      distancia2: null,
      distancia_acumulada2: null,
      abertura2: null,
      desnivel_positivo2: null,
      desnivel_positivo_acumulado2: null,
      desnivel_negativo2: null,
      desnivel_negativo_acumulado2: null,
      previsao_lento2: null,
      fecho2: null,
      abastecimento2: null,
      */

      //Variavel Para Controlar o Redirect
      Inserido_Sucesso: false,

      //Lista Para os Erros de Validação:
      errors: {}
      //errors2: {}
    };

    if (props.location.state === undefined) {
      this.state.Mostra_Erro = true;
    } else {
      if (props.location.state.Link_Tabela_Anterior === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Link_Tabela_Anterior =
          props.location.state.Link_Tabela_Anterior;
      }
      if (props.location.state.Link_Menu_Anterior === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Link_Menu_Anterior = props.location.state.Link_Menu_Anterior;
      }
      if (props.location.state.ID_Evento === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.ID_Evento = props.location.state.ID_Evento;
      }
      if (props.location.state.ID_Prova === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.ID_Prova = props.location.state.ID_Prova;
      }
      if (props.location.state.Nome_Evento === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Nome_Evento = props.location.state.Nome_Evento;
      }
      if (props.location.state.Nome_Prova === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Nome_Prova = props.location.state.Nome_Prova;
      }
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  Vai_Buscar_Checkpoints_Sistema() {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .get(REACT_APP_API_URL + "/devolve_checkpoints_sistema", {
        headers: { authorization: "Bearer " + Token_Lido }
      })
      .then(res => {
        console.log(res);
        this.setState({ Checkpoints_Lidos: res.data });
      });
  }

  Vai_Buscar_Nome_Competicao_e_Evento() {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .get(
        REACT_APP_API_URL +
          "/devolve_nome_prova_e_evento/" +
          this.state.ID_Prova,
        {
          headers: { authorization: "Bearer " + Token_Lido }
        }
      )
      .then(res => {
        console.log(res);
        this.setState({
          Nome_Evento: res.data[0].name,
          Nome_Prova: res.data[0].long_name
        });
      });
  }

  Vai_Buscar_Checkpoints_Prova() {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .get(
        REACT_APP_API_URL + "/lista_checkpoints_prova/" + this.state.ID_Prova,
        {
          headers: { authorization: "Bearer " + Token_Lido }
        }
      )
      .then(res => {
        console.log(res);
        this.setState({ Numeros_Checkpoints_Lidos_Prova: res.data });
      });
  }

  Verifica_Existencia_Checkpoint_Order(Numero_Checkpoint_Order) {
    var Array_Numeros_Checkpoints = [];

    var Numero_Checkpoint_Order2 = Numero_Checkpoint_Order;
    var Numero_Checkpoint_Order_Aux = Numero_Checkpoint_Order;

    Numero_Checkpoint_Order2 = Numero_Checkpoint_Order_Aux * 1; //*1 Passa de String Para Int

    //Talvez Tirar Daqui e Fazer Isto Depois de Ler da BD
    this.state.Numeros_Checkpoints_Lidos_Prova.forEach(function(linha) {
      Array_Numeros_Checkpoints.push(linha.checkpoint_order * 1);
    });

    if (Array_Numeros_Checkpoints.indexOf(Numero_Checkpoint_Order2) > -1) {
      //!==-1)
      return true;
    } else {
      return false;
    }
  }

  componentDidMount() {
    if (localStorage.getItem("Token") !== null) {
      this.Vai_Buscar_Nome_Competicao_e_Evento();
      this.Vai_Buscar_Checkpoints_Prova();
      this.Vai_Buscar_Checkpoints_Sistema();
    }
  } //Fecha Procedimento Que Acontece Depois do Output do Componente Foi Rendered

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleChangeSelectCheckpoint = checkpoint_id => {
    this.setState({ checkpoint_id });
  };

  handleValidation() {
    let errors = {};
    let formIsValid = false; //true;
    //let aux = 0;

    //Para Cada Campo Invocar o Trata_Validacoes
    if (
      !this.state.local2 &&
      !this.state.coordinate2 &&
      !this.state.altitude2
    ) {
      errors = Trata_Validacoes(
        this.state.checkpoint_id,
        "competition_checkpoint/checkpoint_checkpoint_id",
        errors
      );
    }
    if (!this.state.checkpoint_id) {
      //errors = Trata_Validacoes(this.state.local2, "checkpoint/local2", errors);
      //errors = Trata_Validacoes(this.state.coordinate2,"checkpoint/coordinate2",errors);
      //errors = Trata_Validacoes(this.state.altitude2,"checkpoint/altitude2",errors);

      if (!this.state.local2) {
        errors["checkpoint/local2"] = "*Insira o Nome do Checkpoint";
      }
      if (!this.state.coordinate2) {
        errors["checkpoint/coordinate2"] =
          "*Insira as Coordenadas do Checkpoint";
      }
      if (!this.state.altitude2) {
        errors["checkpoint/altitude2"] = "*Insira a Altitude do Checkpoint";
      }
    }
    errors = Trata_Validacoes(
      this.state.checkpoint_order,
      "competition_checkpoint/checkpoint_order",
      errors
    );

    if (
      this.Verifica_Existencia_Checkpoint_Order(this.state.checkpoint_order)
    ) {
      errors["competition_checkpoint/checkpoint_order"] =
        "*Já Existe um Checkpoint Com Esse Número";
    }

    errors = Trata_Validacoes(
      this.state.abertura,
      "competition_checkpoint/abertura",
      errors
    );
    errors = Trata_Validacoes(
      this.state.previsao_rapido,
      "competition_checkpoint/previsao_rapido",
      errors
    );
    errors = Trata_Validacoes(
      this.state.previsao_lento,
      "competition_checkpoint/previsao_lento",
      errors
    );
    errors = Trata_Validacoes(
      this.state.fecho,
      "competition_checkpoint/fecho",
      errors
    );
    errors = Trata_Validacoes(
      this.state.tipo_controlo,
      "competition_checkpoint/tipo_controlo",
      errors
    );
    errors = Trata_Validacoes(
      this.state.distancia_mapa,
      "competition_checkpoint/distancia_mapa",
      errors
    );
    errors = Trata_Validacoes(
      this.state.distancia,
      "competition_checkpoint/distancia",
      errors
    );
    errors = Trata_Validacoes(
      this.state.distancia_acumulada,
      "competition_checkpoint/distancia_acumulada",
      errors
    );
    errors = Trata_Validacoes(
      this.state.desnivel_positivo,
      "competition_checkpoint/desnivel_positivo",
      errors
    );
    errors = Trata_Validacoes(
      this.state.desnivel_positivo_acumulado,
      "competition_checkpoint/desnivel_positivo_acumulado",
      errors
    );
    errors = Trata_Validacoes(
      this.state.desnivel_negativo,
      "competition_checkpoint/desnivel_negativo",
      errors
    );
    errors = Trata_Validacoes(
      this.state.desnivel_negativo_acumulado,
      "competition_checkpoint/desnivel_negativo_acumulado",
      errors
    );
    errors = Trata_Validacoes(
      this.state.abastecimento,
      "competition_checkpoint/abastecimento",
      errors
    );

    //if (errors.length > 0) {
    if (isEmptyObject(errors)) {
      formIsValid = true; //false;
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.handleValidation()) {
      const Token_Lido = localStorage.getItem("Token");
      axios
        .post(
          REACT_APP_API_URL +
            "/insere_checkpoint_prova_final/" +
            this.state.ID_Prova,
          {
            checkpoint_id: this.state.checkpoint_id
              ? this.state.checkpoint_id.value
              : null,
            checkpoint_order: this.state.checkpoint_order,
            local2: this.state.local2,
            coordinate2: this.state.coordinate2,
            altitude2: this.state.altitude2,

            tipo_controlo: this.state.tipo_controlo,
            previsao_rapido: this.state.previsao_rapido,
            distancia_mapa: this.state.distancia_mapa,
            distancia: this.state.distancia,
            distancia_acumulada: this.state.distancia_acumulada,
            abertura: this.state.abertura,
            desnivel_positivo: this.state.desnivel_positivo,
            desnivel_positivo_acumulado: this.state.desnivel_positivo_acumulado,
            desnivel_negativo: this.state.desnivel_negativo,
            desnivel_negativo_acumulado: this.state.desnivel_negativo_acumulado,
            previsao_lento: this.state.previsao_lento,
            fecho: this.state.fecho,
            abastecimento: this.state.abastecimento
          },
          {
            headers: { authorization: "Bearer " + Token_Lido }
          }
        )
        .then(res => {
          this.setState({ Inserido_Sucesso: res.data.success });
        });
    }
  }

  render() {
    if (localStorage.getItem("Token") === null) {
      this.props.history.push({
        pathname: "/"
      });
    }

    if (this.state.Inserido_Sucesso === true) {
      this.props.history.push({
        pathname: this.state.Link_Tabela_Anterior,
        state: {
          Link_Menu_Anterior: this.state.Link_Menu_Anterior,
          ID_Evento: this.state.ID_Evento,
          ID_Prova: this.state.ID_Prova,
          Fez_Insercao_Checkpoint_Prova: true,
          Fez_Edicao_Checkpoint_Prova: false,
          Faz_Refresh: true,
          Nome_Evento: this.state.Nome_Evento,
          Nome_Prova: this.state.Nome_Prova
        }
      });
    }

    //Verifica Se Existe Erro. Se Houver Mostra Mensagem de Erro
    if (this.state.Mostra_Erro === true) {
      return (
        <div>
          <Drawer
            Titulo_Menu="" //"Erro"
            history={this.props.history}
          />
          {mostra_mensagem_erro()}
        </div>
      );
    } else {
      if (
        this.state.Checkpoints_Lidos === undefined ||
        this.state.Nome_Prova === undefined ||
        this.state.Nome_Evento === undefined ||
        this.state.Numeros_Checkpoints_Lidos_Prova === undefined
      ) {
        return (
          <div className="centra_loading">
            <h1>Loading...</h1>
          </div>
        );
      } else {
        var Opcoes_Checkpoints = [];
        //this.state.Checkpoints_Lidos.map(linha => {
        this.state.Checkpoints_Lidos.forEach(function(linha) {
          var Linha = {
            value: linha.checkpoint_id,
            label: linha.local
          };
          Opcoes_Checkpoints.push(Linha);
        });

        const border_erro = "1px solid #ff0000";

        return (
          <div>
            <Drawer
              Titulo_Menu="Inserir Novo Checkpoint Prova"
              Link_Tabela_Anterior={this.state.Link_Tabela_Anterior}
              Link_Menu_Anterior={this.state.Link_Menu_Anterior}
              ID_Evento={this.state.ID_Evento}
              ID_Prova={this.state.ID_Prova}
              Nome_Evento={this.state.Nome_Evento}
              Nome_Prova={this.state.Nome_Prova}
              history={this.props.history}
            />
            <div className="novas_margens_volta_novos_forms">
              <div className="centra_novos_forms">
                <form className="login-form" onSubmit={this.handleSubmit}>
                  <div className="centra_titulos_tabs">
                    <Tabs>
                      <TabList>
                        <Tab
                          style={{
                            border: this.state.errors[
                              "competition_checkpoint/checkpoint_order"
                            ]
                              ? border_erro
                              : ""
                          }}
                        >
                          Dados Checkpoint
                        </Tab>
                        <Tab
                          style={{
                            border:
                              this.state.errors[
                                "competition_checkpoint/abertura"
                              ] ||
                              this.state.errors[
                                "competition_checkpoint/previsao_rapido"
                              ] ||
                              this.state.errors[
                                "competition_checkpoint/previsao_lento"
                              ] ||
                              this.state.errors["competition_checkpoint/fecho"]
                                ? border_erro
                                : ""
                          }}
                        >
                          Datas Previsões
                        </Tab>
                        <Tab
                          style={{
                            border:
                              this.state.errors[
                                "competition_checkpoint/desnivel_positivo"
                              ] ||
                              this.state.errors[
                                "competition_checkpoint/desnivel_positivo_acumulado"
                              ] ||
                              this.state.errors[
                                "competition_checkpoint/desnivel_negativo"
                              ] ||
                              this.state.errors[
                                "competition_checkpoint/desnivel_negativo_acumulado"
                              ]
                                ? border_erro
                                : ""
                          }}
                        >
                          Desniveis
                        </Tab>
                        <Tab
                          style={{
                            border:
                              this.state.errors[
                                "competition_checkpoint/distancia"
                              ] ||
                              this.state.errors[
                                "competition_checkpoint/distancia_acumulada"
                              ] ||
                              this.state.errors[
                                "competition_checkpoint/distancia_mapa"
                              ]
                                ? border_erro
                                : ""
                          }}
                        >
                          Distâncias
                        </Tab>
                        <Tab
                          style={{
                            border:
                              this.state.errors[
                                "competition_checkpoint/tipo_controlo"
                              ] ||
                              this.state.errors[
                                "competition_checkpoint/abastecimento"
                              ]
                                ? border_erro
                                : ""
                          }}
                        >
                          Tipo Checkpoint
                        </Tab>
                        <button type="submit" style={{ marginLeft: "10px" }}>
                          Adicionar Checkpoint à Prova
                        </button>
                      </TabList>
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      <TabPanel>
                        <div className="form">
                          <div>
                            <label>
                              Número Checkpoint
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              >
                                {" (*)"}
                              </span>
                            </label>
                            <input
                              style={{
                                border: this.state.errors[
                                  "competition_checkpoint/checkpoint_order"
                                ]
                                  ? border_erro
                                  : "",
                                textAlign: "center"
                              }}
                              type="number"
                              name="checkpoint_order"
                              placeholder="Número do Checkpoint"
                              onChange={this.handleChange}
                              min="0"
                              max="99"
                              defaultValue={this.state.checkpoint_order}
                            />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {
                                this.state.errors[
                                  "competition_checkpoint/checkpoint_order"
                                ]
                              }
                            </span>
                            <hr />
                            <h4 className="titulos">
                              Escolha um Checkpoint Existente:
                            </h4>
                            <hr />
                            <label>
                              Checkpoint
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              >
                                {" (*)"}
                              </span>
                            </label>
                            <div
                              style={{
                                border: this.state.errors[
                                  "competition_checkpoint/checkpoint_checkpoint_id"
                                ]
                                  ? border_erro
                                  : ""
                              }}
                            >
                              <Select
                                name="checkpoint_id"
                                placeholder="Checkpoint"
                                onChange={this.handleChangeSelectCheckpoint}
                                options={Opcoes_Checkpoints}
                                defaultValue={{
                                  value: this.state.checkpoint_id
                                    ? this.state.checkpoint_id.value
                                    : "",
                                  label: this.state.checkpoint_id
                                    ? this.state.checkpoint_id.label
                                    : ""
                                }}
                              />
                            </div>
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {
                                this.state.errors[
                                  "competition_checkpoint/checkpoint_checkpoint_id"
                                ]
                              }
                            </span>
                            <div className="espaco_span" />
                            <hr />
                            <h4 className="titulos">
                              Ou Crie um Checkpoint Novo:
                            </h4>
                            <hr />
                            <label>
                              Local Checkpoint
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              >
                                {" (*)"}
                              </span>
                            </label>
                            <input
                              style={{
                                border: this.state.errors["checkpoint/local2"]
                                  ? border_erro
                                  : "",
                                textAlign: "center"
                              }}
                              type="text"
                              name="local2"
                              placeholder="Local do Checkpoint"
                              onChange={this.handleChange}
                              defaultValue={this.state.local2}
                            />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {this.state.errors["checkpoint/local2"]}
                            </span>
                            <div className="espaco_span" />
                            <label>
                              Coordenadas Checkpoint
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              >
                                {" (*)"}
                              </span>
                            </label>
                            <input
                              style={{
                                border: this.state.errors[
                                  "checkpoint/coordinate2"
                                ]
                                  ? border_erro
                                  : "",
                                textAlign: "center"
                              }}
                              type="text"
                              name="coordinate2"
                              placeholder="Coordenadas do Checkpoint"
                              onChange={this.handleChange}
                              defaultValue={this.state.coordinate2}
                            />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {this.state.errors["checkpoint/coordinate2"]}
                            </span>
                            <div className="espaco_span" />
                            <label>
                              Altitude Checkpoint
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              >
                                {" (*)"}
                              </span>
                            </label>
                            <input
                              style={{
                                border: this.state.errors[
                                  "checkpoint/altitude2"
                                ]
                                  ? border_erro
                                  : "",
                                textAlign: "center"
                              }}
                              type="text"
                              name="altitude2"
                              placeholder="Altitude do Checkpoint"
                              onChange={this.handleChange}
                              defaultValue={this.state.altitude2}
                            />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {this.state.errors["checkpoint/altitude2"]}
                            </span>
                            <div className="espaco_span" />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {" (*)"}
                            </span>{" "}
                            <span className="bolder"> Campos Obrigatórios</span>
                          </div>
                        </div>
                        {/*}
                        <div className="form">
                          <button type="submit">
                            Adicionar Checkpoint à Prova
                          </button>
                        </div>
                              */}
                      </TabPanel>
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      <TabPanel>
                        <div className="form">
                          <div>
                            <label>
                              Data de Abertura
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              >
                                {" (*)"}
                              </span>
                            </label>
                            <input
                              style={{
                                border: this.state.errors[
                                  "competition_checkpoint/abertura"
                                ]
                                  ? border_erro
                                  : "",
                                textAlign: "center"
                              }}
                              type="datetime-local"
                              step="1"
                              name="abertura"
                              placeholder="Abertura Posto"
                              onChange={this.handleChange}
                              defaultValue={
                                String(
                                  Moment(this.state.abertura).format(
                                    "YYYY-MM-DD"
                                  )
                                ) +
                                "T" +
                                String(
                                  Moment(this.state.abertura).format("HH:mm:ss")
                                )
                              }
                            />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {
                                this.state.errors[
                                  "competition_checkpoint/abertura"
                                ]
                              }
                            </span>
                            <div className="espaco_span" />
                            <label>
                              Previsão 1º Atleta
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              >
                                {" (*)"}
                              </span>
                            </label>
                            <input
                              style={{
                                border: this.state.errors[
                                  "competition_checkpoint/previsao_rapido"
                                ]
                                  ? border_erro
                                  : "",
                                textAlign: "center"
                              }}
                              type="datetime-local"
                              step="1"
                              name="previsao_rapido"
                              placeholder="Previsao 1º Atleta"
                              onChange={this.handleChange}
                              defaultValue={
                                String(
                                  Moment(this.state.previsao_rapido).format(
                                    "YYYY-MM-DD"
                                  )
                                ) +
                                "T" +
                                String(
                                  Moment(this.state.previsao_rapido).format(
                                    "HH:mm:ss"
                                  )
                                )
                              }
                            />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {
                                this.state.errors[
                                  "competition_checkpoint/previsao_rapido"
                                ]
                              }
                            </span>
                            <div className="espaco_span" />
                            <label>
                              Previsão Último Atleta
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              >
                                {" (*)"}
                              </span>
                            </label>
                            <input
                              style={{
                                border: this.state.errors[
                                  "competition_checkpoint/previsao_lento"
                                ]
                                  ? border_erro
                                  : "",
                                textAlign: "center"
                              }}
                              type="datetime-local"
                              step="1"
                              name="previsao_lento"
                              placeholder="Previsão Último Atleta"
                              onChange={this.handleChange}
                              defaultValue={
                                String(
                                  Moment(this.state.previsao_lento).format(
                                    "YYYY-MM-DD"
                                  )
                                ) +
                                "T" +
                                String(
                                  Moment(this.state.previsao_lento).format(
                                    "HH:mm:ss"
                                  )
                                )
                              }
                            />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {
                                this.state.errors[
                                  "competition_checkpoint/previsao_lento"
                                ]
                              }
                            </span>
                            <div className="espaco_span" />
                            <label>
                              Data de Fecho
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              >
                                {" (*)"}
                              </span>
                            </label>
                            <input
                              style={{
                                border: this.state.errors[
                                  "competition_checkpoint/fecho"
                                ]
                                  ? border_erro
                                  : "",
                                textAlign: "center"
                              }}
                              type="datetime-local"
                              step="1"
                              name="fecho"
                              placeholder="Fecho Posto"
                              onChange={this.handleChange}
                              defaultValue={
                                String(
                                  Moment(this.state.fecho).format("YYYY-MM-DD")
                                ) +
                                "T" +
                                String(
                                  Moment(this.state.fecho).format("HH:mm:ss")
                                )
                              }
                            />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {
                                this.state.errors[
                                  "competition_checkpoint/fecho"
                                ]
                              }
                            </span>
                            <div className="espaco_span" />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {" (*)"}
                            </span>{" "}
                            <span className="bolder"> Campos Obrigatórios</span>
                          </div>
                        </div>
                        {/*}
                        <div className="form">
                          <button type="submit">
                            Adicionar Checkpoint à Prova
                          </button>
                        </div>
                              */}
                      </TabPanel>
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      <TabPanel>
                        <div className="form">
                          <div>
                            <label>
                              Desnivel Positivo
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              >
                                {" (*)"}
                              </span>
                            </label>
                            <input
                              style={{
                                border: this.state.errors[
                                  "competition_checkpoint/desnivel_positivo"
                                ]
                                  ? border_erro
                                  : "",
                                textAlign: "center"
                              }}
                              type="text"
                              name="desnivel_positivo"
                              placeholder="Desnivel Positivo"
                              onChange={this.handleChange}
                              defaultValue={this.state.desnivel_positivo}
                            />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {
                                this.state.errors[
                                  "competition_checkpoint/desnivel_positivo"
                                ]
                              }
                            </span>
                            <div className="espaco_span" />
                            <label>
                              Desnivel Positivo Acumulado
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              >
                                {" (*)"}
                              </span>
                            </label>
                            <input
                              style={{
                                border: this.state.errors[
                                  "competition_checkpoint/desnivel_positivo_acumulado"
                                ]
                                  ? border_erro
                                  : "",
                                textAlign: "center"
                              }}
                              type="text"
                              name="desnivel_positivo_acumulado"
                              placeholder="Desnivel Positivo Acumulado"
                              onChange={this.handleChange}
                              defaultValue={
                                this.state.desnivel_positivo_acumulado
                              }
                            />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {
                                this.state.errors[
                                  "competition_checkpoint/desnivel_positivo_acumulado"
                                ]
                              }
                            </span>
                            <div className="espaco_span" />
                            <label>
                              Desnivel Negativo
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              >
                                {" (*)"}
                              </span>
                            </label>
                            <input
                              style={{
                                border: this.state.errors[
                                  "competition_checkpoint/desnivel_negativo"
                                ]
                                  ? border_erro
                                  : "",
                                textAlign: "center"
                              }}
                              type="text"
                              name="desnivel_negativo"
                              placeholder="Desnivel Negativo"
                              onChange={this.handleChange}
                              defaultValue={this.state.desnivel_negativo}
                            />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {
                                this.state.errors[
                                  "competition_checkpoint/desnivel_negativo"
                                ]
                              }
                            </span>
                            <div className="espaco_span" />
                            <label>
                              Desnivel Negativo Acumulado
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              >
                                {" (*)"}
                              </span>
                            </label>
                            <input
                              style={{
                                border: this.state.errors[
                                  "competition_checkpoint/desnivel_negativo_acumulado"
                                ]
                                  ? border_erro
                                  : "",
                                textAlign: "center"
                              }}
                              type="text"
                              name="desnivel_negativo_acumulado"
                              placeholder="Desnivel Negativo Acumulado"
                              onChange={this.handleChange}
                              defaultValue={
                                this.state.desnivel_negativo_acumulado
                              }
                            />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {
                                this.state.errors[
                                  "competition_checkpoint/desnivel_negativo_acumulado"
                                ]
                              }
                            </span>
                            <div className="espaco_span" />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {" (*)"}
                            </span>{" "}
                            <span className="bolder"> Campos Obrigatórios</span>
                          </div>
                        </div>
                        {/*}
                        <div className="form">
                          <button type="submit">
                            Adicionar Checkpoint à Prova
                          </button>
                        </div>
                              */}
                      </TabPanel>
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      <TabPanel>
                        <div className="form">
                          <div>
                            <label>
                              Distância
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              >
                                {" (*)"}
                              </span>
                            </label>
                            <input
                              style={{
                                border: this.state.errors[
                                  "competition_checkpoint/distancia"
                                ]
                                  ? border_erro
                                  : "",
                                textAlign: "center"
                              }}
                              type="text"
                              name="distancia"
                              placeholder="Distância"
                              onChange={this.handleChange}
                              defaultValue={this.state.distancia}
                            />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {
                                this.state.errors[
                                  "competition_checkpoint/distancia"
                                ]
                              }
                            </span>
                            <div className="espaco_span" />
                            <label>
                              Distância Acumulada
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              >
                                {" (*)"}
                              </span>
                            </label>
                            <input
                              style={{
                                border: this.state.errors[
                                  "competition_checkpoint/distancia_acumulada"
                                ]
                                  ? border_erro
                                  : "",
                                textAlign: "center"
                              }}
                              type="text"
                              name="distancia_acumulada"
                              placeholder="Distância Acumulada"
                              onChange={this.handleChange}
                              defaultValue={this.state.distancia_acumulada}
                            />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {
                                this.state.errors[
                                  "competition_checkpoint/distancia_acumulada"
                                ]
                              }
                            </span>
                            <div className="espaco_span" />
                            <label>
                              Distância Mapa
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              >
                                {" (*)"}
                              </span>
                            </label>
                            <input
                              style={{
                                border: this.state.errors[
                                  "competition_checkpoint/distancia_mapa"
                                ]
                                  ? border_erro
                                  : "",
                                textAlign: "center"
                              }}
                              type="text"
                              name="distancia_mapa"
                              placeholder="Distância Mapa"
                              onChange={this.handleChange}
                              defaultValue={this.state.distancia_mapa}
                            />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {
                                this.state.errors[
                                  "competition_checkpoint/distancia_mapa"
                                ]
                              }
                            </span>
                            <div className="espaco_span" />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {" (*)"}
                            </span>{" "}
                            <span className="bolder"> Campos Obrigatórios</span>
                          </div>
                        </div>
                        {/*}
                        <div className="form">
                          <button type="submit">
                            Adicionar Checkpoint à Prova
                          </button>
                        </div>
                              */}
                      </TabPanel>
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      {/*****************************************************************************/}
                      <TabPanel>
                        <div className="form">
                          <div>
                            <label>
                              Tipo de Controlo
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              >
                                {" (*)"}
                              </span>
                            </label>
                            <input
                              style={{
                                border: this.state.errors[
                                  "competition_checkpoint/tipo_controlo"
                                ]
                                  ? border_erro
                                  : "",
                                textAlign: "center"
                              }}
                              type="text"
                              name="tipo_controlo"
                              placeholder="Tipo de Controlo"
                              onChange={this.handleChange}
                              defaultValue={this.state.tipo_controlo}
                            />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {
                                this.state.errors[
                                  "competition_checkpoint/tipo_controlo"
                                ]
                              }
                            </span>
                            <div className="espaco_span" />

                            <label>
                              Abastecimento
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              >
                                {" (*)"}
                              </span>
                            </label>
                            <input
                              style={{
                                border: this.state.errors[
                                  "competition_checkpoint/abastecimento"
                                ]
                                  ? border_erro
                                  : "",
                                textAlign: "center"
                              }}
                              type="text"
                              name="abastecimento"
                              placeholder="Abastecimento"
                              onChange={this.handleChange}
                              defaultValue={this.state.abastecimento}
                            />
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {
                                this.state.errors[
                                  "competition_checkpoint/abastecimento"
                                ]
                              }
                            </span>
                          </div>
                          <div className="espaco_span">
                            <span
                              className="bolder"
                              style={{ color: "#e60000" }}
                            >
                              {" (*)"}
                            </span>{" "}
                            <span className="bolder"> Campos Obrigatórios</span>
                            {/*

                  <div className="espaco_span" />
                </div>
                <button onClick={this.handleInsereNovoCheckpointProva}>
                  Adicionar Checkpoint à Prova
                </button>
              </form>
            </div>
          </div>
                  */}
                          </div>
                        </div>
                        {/*}
                        <div className="form">
                          <button type="submit">
                            Adicionar Checkpoint à Prova
                          </button>
                        </div>
                              */}
                      </TabPanel>
                    </Tabs>
                  </div>
                </form>
              </div>
            </div>
          </div>
        );
      }
    }
  }
}

export default Formulario_Adicionar_Checkpoint_Prova;
