import React, { Component } from "react";
//import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import Moment from "moment";
//import { isNull, isUndefined } from "util";
import Drawer from "../Drawer/Drawer.js";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

//Funções Auxiliares:
import {
  mostra_mensagem_erro,
  //ve_resultados,
  Trata_Validacoes,
  isEmptyObject
} from "../Funcoes_Auxiliares/Funcoes_Auxiliares.js";

let REACT_APP_API_URL;

if (process.env.REACT_APP_ESTADO === "Deploy") {
  REACT_APP_API_URL = process.env.REACT_APP_API_URL_PROD;
} //Development
else {
  REACT_APP_API_URL = process.env.REACT_APP_API_URL_DEV;
}

class Formulario_Editar_Resultado_Atleta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dados: undefined, //[],
      id_checkpoint: null,
      id_competicao: null,
      ID_Atleta: null,
      Nome_Evento: null,
      Nome_Prova: null,
      Nome_Atleta: null,
      Nome_Checkpoint: null,
      ID_Competition_Checkpoint: null,

      data_passagem_partida: null, //Data da Passagem na Partida

      time_stamp: null,
      acumulated_time: null,
      data_passagem_checkpoint: null,
      time_penalty: null,
      time_status: null,

      Link_Tabela_Anterior2: null,
      Link_Tabela_Anterior: null,
      Link_Menu_Anterior: null,
      ID_Evento: null,
      ID_Prova: null,

      //Variavel Controlo de Erro na Leitura dos Props
      Mostra_Erro: false,

      //Variavel Para Controlar o Redirect
      Inserido_Sucesso: false,

      //Lista Para os Erros de Validação:
      errors: {}

      //Hora_Inicio_Prova: null //Vai Buscar a Hora da 1a Linha (Como Esta Ordenado Pela Ordem dos Checkpoints Nessa Prova <=> Buscar a Hora Que Comecou a Prova)
    };

    if (props.location.state === undefined) {
      this.state.Mostra_Erro = true;
    } else {
      if (props.location.state.Link_Tabela_Anterior2 === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Link_Tabela_Anterior2 =
          props.location.state.Link_Tabela_Anterior2;
      }
      if (props.location.state.Link_Tabela_Anterior === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Link_Tabela_Anterior =
          props.location.state.Link_Tabela_Anterior;
      }
      if (props.location.state.Link_Menu_Anterior === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Link_Menu_Anterior = props.location.state.Link_Menu_Anterior;
      }
      if (props.location.state.ID_Evento === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.ID_Evento = props.location.state.ID_Evento;
      }
      if (props.location.state.ID_Prova === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.ID_Prova = props.location.state.ID_Prova;
      }
      if (props.location.state.data_passagem_partida === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.data_passagem_partida =
          props.location.state.data_passagem_partida;
      }
      if (props.location.state.id_checkpoint === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.id_checkpoint = props.location.state.id_checkpoint;
      }
      if (props.location.state.ID_Atleta === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.ID_Atleta = props.location.state.ID_Atleta;
      }
      if (props.location.state.Nome_Evento === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Nome_Evento = props.location.state.Nome_Evento;
      }
      if (props.location.state.Nome_Prova === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Nome_Prova = props.location.state.Nome_Prova;
      }
      if (props.location.state.Nome_Atleta === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Nome_Atleta = props.location.state.Nome_Atleta;
      }
      if (props.location.state.Nome_Checkpoint === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Nome_Checkpoint = props.location.state.Nome_Checkpoint;
      }
      if (props.location.state.ID_Competition_Checkpoint === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.ID_Competition_Checkpoint =
          props.location.state.ID_Competition_Checkpoint;
      }
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  /*
  Falta Limitar! Neste Momento Esta Funcao Vai Buscar Todos os Resultados.
  No Entanto, Para a Edicao do Resultado do Atleta, Apenas Queremos 1 Desses Resultados
  Logo É Preciso Enviar o ID do "time_checkpoint.competition_checkpoint_checkpoint_checkpoint_id"
  */
  Vai_Buscar_Dados() {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .get(
        REACT_APP_API_URL +
          "/devolve_resultado_atleta/" +
          this.state.ID_Atleta +
          "/" +
          this.state.id_checkpoint +
          "/" +
          this.state.ID_Evento,
        {
          headers: { authorization: "Bearer " + Token_Lido }
        }
      )
      .then(res => {
        console.log(res);
        this.setState({ dados: res.data });
        const Dados_Lidos = res.data;
        this.setState({
          nome_atleta: Dados_Lidos[0].athlete_name,
          dorsal: Dados_Lidos[0].dorsal_number,
          escalao: Dados_Lidos[0].category,
          clube_atleta: Dados_Lidos[0].club,
          competicao: Dados_Lidos[0].long_name, //.competition_id,

          time_stamp: Dados_Lidos[0].time_stamp,
          acumulated_time: Dados_Lidos[0].acumulated_time,
          data_passagem_checkpoint: Dados_Lidos[0].checkpoint_point_time,
          time_penalty: Dados_Lidos[0].time_penalty,
          time_status: Dados_Lidos[0].time_status

          //id_atleta: Dados_Lidos[0].athlete_id,
          /*

          estado_atleta: Dados_Lidos[0].athlete_status,
          rf_id: Dados_Lidos[0].rf_id_number,
          rf_id2: Dados_Lidos[0].rf_id_number2,
          rf_id3: Dados_Lidos[0].rf_id_number3,
          rf_id4: Dados_Lidos[0].rf_id_number4,
          patrocinador: Dados_Lidos[0].sponsor,
          sigla_clube: Dados_Lidos[0].club_sigla,
          codigo_estado_inscricao: Dados_Lidos[0].inscription_status_code,*/

          //Hora_Inicio_Prova: Dados_Lidos[0].checkpoint_point_time
        });
      });
  }

  /*
Procedimento Com Axios */
  componentDidMount() {
    if (localStorage.getItem("Token") !== null) {
      this.Vai_Buscar_Dados();
    }
  } //Fecha Procedimento Que Acontece Depois do Output do Componente Foi Rendered

  //Actualiza os Dados Depois de Acontecer um Redirect Para Esta Tabela
  componentDidUpdate() {
    //this.Vai_Buscar_Dados();
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleValidation() {
    let errors = {};
    let formIsValid = false; //true;

    //Para Cada Campo Invocar o Trata_Validacoes
    errors = Trata_Validacoes(
      this.state.time_stamp,
      "time_checkpoint/time_stamp",
      errors
    );
    errors = Trata_Validacoes(
      this.state.acumulated_time,
      "time_checkpoint/acumulated_time",
      errors
    );
    errors = Trata_Validacoes(
      this.state.data_passagem_checkpoint,
      "time_checkpoint/checkpoint_point_time",
      errors
    );
    errors = Trata_Validacoes(
      this.state.time_penalty,
      "time_checkpoint/time_penalty",
      errors
    );
    errors = Trata_Validacoes(
      this.state.time_status,
      "time_checkpoint/time_status",
      errors
    );

    //if (errors.length > 0) {
    if (isEmptyObject(errors)) {
      formIsValid = true; //false;
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.handleValidation()) {
      const Token_Lido = localStorage.getItem("Token");
      axios
        .patch(
          REACT_APP_API_URL + "/update_resultado_atleta/",
          {
            id_checkpoint: this.state.id_checkpoint,
            id_competicao: this.state.ID_Prova,
            id_atleta: this.state.ID_Atleta,

            time_stamp: this.state.time_stamp,
            acumulated_time: this.state.acumulated_time,
            data_passagem_checkpoint: this.state.data_passagem_checkpoint,
            time_penalty: this.state.time_penalty,
            time_status: this.state.time_status
          },
          {
            headers: { authorization: "Bearer " + Token_Lido }
          }
        )
        .then(res => {
          this.setState({ Inserido_Sucesso: res.data.success });
        });
    }
  }

  render() {
    if (localStorage.getItem("Token") === null) {
      this.props.history.push({
        pathname: "/"
      });
    }

    if (this.state.Inserido_Sucesso === true) {
      this.props.history.push({
        pathname: this.state.Link_Tabela_Anterior2, //this.state.Link_Tabela_Anterior,
        state: {
          //id_inscricao: this.state.id_inscricao,
          Link_Menu_Anterior: this.state.Link_Menu_Anterior,
          Link_Tabela_Anterior: this.state.Link_Tabela_Anterior,
          ID_Evento: this.state.ID_Evento,
          ID_Prova: this.state.ID_Prova,
          ID_Atleta: this.state.ID_Atleta,
          Fez_Insercao_Resultado: false,
          Fez_Edicao_Resultado: true,
          Fez_Insercao_Inscricao_Atleta_Sistema: false,
          Fez_Edicao_Dados_Atleta_Sistema: false,
          Fez_Edicao_Inscricao_Atleta_Sistema: false,
          Faz_Refresh: true,
          Nome_Evento: this.state.Nome_Evento,
          Nome_Prova: this.state.Nome_Prova,
          Nome_Atleta: this.state.Nome_Atleta,
          ID_Competition_Checkpoint: this.state.ID_Competition_Checkpoint,
          Nome_Checkpoint: this.state.Nome_Checkpoint
        }
      });
    }

    //Verifica Se Existe Erro. Se Houver Mostra Mensagem de Erro
    if (this.state.Mostra_Erro === true) {
      return (
        <div>
          <Drawer
            Titulo_Menu="" //"Erro"
            //Link_Menu_Anterior={this.props.location.pathname}
            history={this.props.history}
          />
          {mostra_mensagem_erro()}
        </div>
      );
    } else {
      if (this.state.dados === undefined) {
        return (
          <div className="centra_loading">
            <h1>Loading...</h1>
          </div>
        );
      } else {
        let Hora_Passagem_Checkpoint;
        //let Data_Passagem;
        let Aux1;
        let Aux2;
        let Aux3;
        let Aux4;

        const border_erro = "1px solid #ff0000";
        return (
          <div>
            <Drawer
              Titulo_Menu="Editar Resultado Atleta Checkpoint"
              Link_Menu_Anterior={this.state.Link_Menu_Anterior}
              Link_Tabela_Anterior={this.state.Link_Tabela_Anterior}
              Link_Tabela_Anterior2={this.state.Link_Tabela_Anterior2}
              ID_Evento={this.state.ID_Evento}
              ID_Prova={this.state.ID_Prova}
              ID_Atleta={this.state.ID_Atleta}
              //ID_Competition_Checkpoint={this.state.id_checkpoint}
              Nome_Evento={this.state.Nome_Evento}
              Nome_Prova={this.state.Nome_Prova}
              Nome_Atleta={this.state.Nome_Atleta}
              Nome_Checkpoint={this.state.Nome_Checkpoint}
              ID_Competition_Checkpoint={this.state.ID_Competition_Checkpoint}
              history={this.props.history}
            />
            {this.state.dados.map(linha => {
              Aux1 = Moment(this.state.data_passagem_partida);
              Aux2 = Moment(linha.checkpoint_point_time);
              //Aux3=
              //Hora_Passagem_Checkpoint =Aux2.diff(Aux1, "HH:mm:ss"); //"hours");
              Aux3 = Aux2.diff(Aux1);
              Aux4 = Moment.duration(Aux3);

              Hora_Passagem_Checkpoint =
                Math.floor(Aux4.asHours()) + Moment.utc(Aux3).format(":mm:ss");

              //Data_Passagem = Moment(linha.checkpoint_point_time).format("DD-MM-YYYY HH:mm:ss");

              return (
                <div>
                  <div className="novas_margens_volta_novos_forms">
                    <div className="centra_novos_forms">
                      <form className="login-form" onSubmit={this.handleSubmit}>
                        <div className="centra_titulos_tabs">
                          <Tabs>
                            <TabList>
                              <Tab>Dados Atleta</Tab>
                              <Tab
                                style={{
                                  border:
                                    this.state.errors[
                                      "time_checkpoint/checkpoint_point_time"
                                    ] ||
                                    this.state.errors[
                                      "time_checkpoint/time_stamp"
                                    ] ||
                                    this.state.errors[
                                      "time_checkpoint/acumulated_time"
                                    ] ||
                                    this.state.errors[
                                      "time_checkpoint/time_penalty"
                                    ] ||
                                    this.state.errors[
                                      "time_checkpoint/time_status"
                                    ]
                                      ? border_erro
                                      : ""
                                }}
                              >
                                Resultado
                              </Tab>
                              <button
                                type="submit"
                                style={{ marginLeft: "10px" }}
                              >
                                Alterar Resultado
                              </button>
                            </TabList>
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            <TabPanel>
                              <div className="form">
                                <div>
                                  {/*}
                                  <label>Nome Atleta</label>
                                  <input
                                    style={{
                                      backgroundColor: "#b3b3cc",
                                      textAlign: "center"
                                    }}
                                    type="text"
                                    name="nome_atleta"
                                    placeholder="Nome"
                                    defaultValue={linha.athlete_name}
                                    readOnly
                                  />
                                  <div className="espaco_span" />
                                  */}

                                  <label>Dorsal</label>
                                  <input
                                    style={{
                                      backgroundColor: "#b3b3cc",
                                      textAlign: "center"
                                    }}
                                    type="number"
                                    name="dorsal"
                                    placeholder="Dorsal"
                                    defaultValue={linha.dorsal_number}
                                    readOnly
                                  />
                                  <div className="espaco_span" />

                                  <label>Escalão</label>
                                  <input
                                    style={{
                                      backgroundColor: "#b3b3cc",
                                      textAlign: "center"
                                    }}
                                    name="escalao"
                                    defaultValue={linha.category}
                                    readOnly
                                  />
                                  <div className="espaco_span" />
                                  <label>Checkpoint</label>
                                  <input
                                    style={{
                                      backgroundColor: "#b3b3cc",
                                      textAlign: "center"
                                    }}
                                    type="text"
                                    name="checkpoint"
                                    placeholder="Checkpoint"
                                    defaultValue={linha.local}
                                    readOnly
                                  />
                                  <div className="espaco_span" />
                                  <label>Tempo de Prova</label>
                                  <input
                                    style={{
                                      backgroundColor: "#b3b3cc",
                                      textAlign: "center"
                                    }}
                                    type="text"
                                    name="tempo_prova"
                                    placeholder="Tempo de Prova"
                                    defaultValue={Hora_Passagem_Checkpoint}
                                    readOnly
                                  />
                                </div>
                              </div>
                              {/*}
                              <div className="form">
                                <button type="submit">Alterar Resultado</button>
                              </div>
                              */}
                            </TabPanel>
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}
                            {/*****************************************************************************/}

                            <TabPanel>
                              <div className="form">
                                <div>
                                  <label>
                                    Data de Passagem
                                    <span
                                      className="bolder"
                                      style={{ color: "#e60000" }}
                                    >
                                      {" (*)"}
                                    </span>
                                  </label>
                                  <input
                                    style={{
                                      border: this.state.errors[
                                        "time_checkpoint/checkpoint_point_time"
                                      ]
                                        ? border_erro
                                        : "",
                                      textAlign: "center"
                                    }}
                                    type="datetime-local"
                                    step="1"
                                    name="data_passagem_checkpoint"
                                    placeholder="Data de Passagem"
                                    onChange={this.handleChange}
                                    //defaultValue={Data_Passagem}
                                    defaultValue={
                                      //No "datetime-local" Nao Consegue Ler Formatos de Data Sem o "T" Entre Dia e Hora e Nao Pode Ter o "Z" no Final
                                      String(
                                        Moment(
                                          this.state.data_passagem_checkpoint
                                        ).format("YYYY-MM-DD")
                                      ) +
                                      "T" +
                                      String(
                                        Moment(
                                          this.state.data_passagem_checkpoint
                                        ).format("HH:mm:ss")
                                      )
                                    }
                                  />
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {
                                      this.state.errors[
                                        "time_checkpoint/checkpoint_point_time"
                                      ]
                                    }
                                  </span>
                                  <div className="espaco_span" />
                                  <label>
                                    Time Stamp
                                    <span
                                      className="bolder"
                                      style={{ color: "#e60000" }}
                                    >
                                      {" (*)"}
                                    </span>
                                  </label>
                                  <input
                                    style={{
                                      border: this.state.errors[
                                        "time_checkpoint/time_stamp"
                                      ]
                                        ? border_erro
                                        : "",
                                      textAlign: "center"
                                    }}
                                    type="datetime-local"
                                    step="1"
                                    name="time_stamp"
                                    placeholder="Time Stamp"
                                    onChange={this.handleChange}
                                    defaultValue={
                                      //No "datetime-local" Nao Consegue Ler Formatos de Data Sem o "T" Entre Dia e Hora e Nao Pode Ter o "Z" no Final
                                      String(
                                        Moment(this.state.time_stamp).format(
                                          "YYYY-MM-DD"
                                        )
                                      ) +
                                      "T" +
                                      String(
                                        Moment(this.state.time_stamp).format(
                                          "HH:mm:ss"
                                        )
                                      )
                                    }
                                  />
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {
                                      this.state.errors[
                                        "time_checkpoint/time_stamp"
                                      ]
                                    }
                                  </span>
                                  <div className="espaco_span" />
                                  <label>
                                    Acumulated Time
                                    <span
                                      className="bolder"
                                      style={{ color: "#e60000" }}
                                    >
                                      {" (*)"}
                                    </span>
                                  </label>
                                  <input
                                    style={{
                                      border: this.state.errors[
                                        "time_checkpoint/acumulated_time"
                                      ]
                                        ? border_erro
                                        : "",
                                      textAlign: "center"
                                    }}
                                    type="time" //type="text" //type="time"
                                    step="1"
                                    name="acumulated_time"
                                    placeholder="Acumulated Time"
                                    onChange={this.handleChange}
                                    defaultValue={
                                      //No "datetime-local" Nao Consegue Ler Formatos de Data Sem o "T" Entre Dia e Hora e Nao Pode Ter o "Z" no Final
                                      this.state.acumulated_time
                                      //"T" +
                                      /*
                            String(
                              Moment(linha.acumulated_time).format("HH:mm:ss")
                            )
                            */
                                    }
                                  />
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {
                                      this.state.errors[
                                        "time_checkpoint/acumulated_time"
                                      ]
                                    }
                                  </span>
                                  <div className="espaco_span" />

                                  <label>
                                    Time Penalty
                                    <span
                                      className="bolder"
                                      style={{ color: "#e60000" }}
                                    >
                                      {" (*)"}
                                    </span>
                                  </label>
                                  <input
                                    style={{
                                      border: this.state.errors[
                                        "time_checkpoint/time_penalty"
                                      ]
                                        ? border_erro
                                        : "",
                                      textAlign: "center"
                                    }}
                                    type="time" //type="text" //type="time"
                                    step="1"
                                    name="time_penalty"
                                    placeholder="Tempo de Prova"
                                    onChange={this.handleChange}
                                    defaultValue={
                                      //No "datetime-local" Nao Consegue Ler Formatos de Data Sem o "T" Entre Dia e Hora e Nao Pode Ter o "Z" no Final
                                      this.state.time_penalty
                                      //"T" +
                                      /*
                            String(
                              Moment(linha.time_penalty).format("HH:mm:ss")
                            )
                            */
                                    }
                                  />
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {
                                      this.state.errors[
                                        "time_checkpoint/time_penalty"
                                      ]
                                    }
                                  </span>
                                  <div className="espaco_span" />

                                  <label>
                                    Time Status
                                    <span
                                      className="bolder"
                                      style={{ color: "#e60000" }}
                                    >
                                      {" (*)"}
                                    </span>
                                  </label>
                                  <input
                                    style={{
                                      border: this.state.errors[
                                        "time_checkpoint/time_status"
                                      ]
                                        ? border_erro
                                        : "",
                                      textAlign: "center"
                                    }}
                                    type="text"
                                    name="time_status"
                                    placeholder="Time Status"
                                    onChange={this.handleChange}
                                    defaultValue={this.state.time_status}
                                  />
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {
                                      this.state.errors[
                                        "time_checkpoint/time_status"
                                      ]
                                    }
                                  </span>
                                  <div className="espaco_span" />
                                </div>
                                <span
                                  className="bolder"
                                  style={{ color: "#e60000" }}
                                >
                                  {" (*)"}
                                </span>{" "}
                                <span className="bolder">
                                  {" "}
                                  Campos Obrigatórios
                                </span>
                              </div>
                              {/*}
                              <div className="form">
                                <button type="submit">Alterar Resultado</button>
                              </div>
                              */}
                            </TabPanel>
                          </Tabs>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        );
      }
    }
  }
}

export default Formulario_Editar_Resultado_Atleta;
