import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Moment from "moment";
import ReactPaginate from "react-paginate";

//Imports Para Drawer e Snackbar:
import Drawer from "../Drawer/Drawer.js";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import classNames from "classnames";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import green from "@material-ui/core/colors/green";
import amber from "@material-ui/core/colors/amber";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import WarningIcon from "@material-ui/icons/Warning";

//imports Para o Dialog de Logout
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
//import DialogContent from '@material-ui/core/DialogContent';
//import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from "@material-ui/core/DialogTitle";

//Funções Auxiliares:
import {
  mostra_mensagem_erro,
  ve_resultados
} from "../Funcoes_Auxiliares/Funcoes_Auxiliares.js";

let REACT_APP_API_URL;

if (process.env.REACT_APP_ESTADO === "Deploy") {
  REACT_APP_API_URL = process.env.REACT_APP_API_URL_PROD;
} //Development
else {
  REACT_APP_API_URL = process.env.REACT_APP_API_URL_DEV;
}

//Constantes Para Drawer e Snackbar:
const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const styles1 = theme => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.dark
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit
  },
  message: {
    display: "flex",
    alignItems: "center"
  }
});

MySnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(["success", "warning", "error", "info"]).isRequired
};

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

//Funções Para Drawer e Snackbar:
function MySnackbarContent(props) {
  const { classes, className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>
      ]}
      {...other}
    />
  );
}

class Tabela_Resultados_Atleta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: undefined, //[],
      Link_Menu_Anterior: null,
      Link_Tabela_Anterior: null,
      ID_Evento: null,
      ID_Prova: null,
      Headers_Colunas: [],
      ID_Atleta: null,
      //Nome_Atleta: null,
      Nome_Evento: null,
      Nome_Prova: null,
      Nome_Atleta: null,

      Hora_Inicio_Prova: undefined, //Vai Buscar a Hora da 1a Linha (Como Esta Ordenado Pela Ordem dos Checkpoints Nessa Prova <=> Buscar a Hora Que Comecou a Prova)
      Nome_Escolhido: "",
      Dorsal_Escolhido: "",
      Escalao_Escolhido: "",
      Evento_Escolhido: "",
      Competicao_Escolhida: "",

      //Variaveis Para a Paginação:
      total_items: 0,
      offset: 0,
      perPage: 10,
      pageCount: null,

      //Variavel Controlo de Erro na Leitura dos Props
      Mostra_Erro: false,

      //Variavel de Controlo Para a Mensagem de Login Com Sucesso na Snackbar:
      Fez_Edicao_Resultado: false,
      Fez_Insercao_Resultado: false,
      Fez_Eliminacao_Resultado: false,

      //Variaveis de Controlo dos Dialogs:
      open_dialog_elimina_resultado: false,

      //Variavel de Controlo Para Evitar o Bug da Paginação Não Aparecer
      Faz_Refresh: false,

      ID_Checkpoint_Eliminar: null
    };

    if (props.location.state === undefined) {
      this.state.Mostra_Erro = true;
    } else {
      if (props.location.state.Link_Menu_Anterior === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Link_Menu_Anterior = props.location.state.Link_Menu_Anterior;
      }
      if (props.location.state.Link_Tabela_Anterior === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Link_Tabela_Anterior =
          props.location.state.Link_Tabela_Anterior;
      }
      if (props.location.state.ID_Evento === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.ID_Evento = props.location.state.ID_Evento;
      }
      if (props.location.state.ID_Prova === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.ID_Prova = props.location.state.ID_Prova;
      }
      if (props.location.state.ID_Atleta === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.ID_Atleta = props.location.state.ID_Atleta;
      }
      if (props.location.state.Fez_Edicao_Resultado === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Fez_Edicao_Resultado =
          props.location.state.Fez_Edicao_Resultado;
      }
      if (props.location.state.Fez_Insercao_Resultado === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Fez_Insercao_Resultado =
          props.location.state.Fez_Insercao_Resultado;
      }
      if (props.location.state.Faz_Refresh === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Faz_Refresh = props.location.state.Faz_Refresh;
      }

      if (props.location.state.Nome_Evento === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Nome_Evento = props.location.state.Nome_Evento;
      }
      if (props.location.state.Nome_Prova === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Nome_Prova = props.location.state.Nome_Prova;
      }
      if (props.location.state.Nome_Atleta === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Nome_Atleta = props.location.state.Nome_Atleta;
      }
    }

    this.handlePageClick = this.handlePageClick.bind(this);
    this.handleChange = this.handleChange.bind(this);

    //Metodos Para Ordenações da Tabela
    this.compareBy.bind(this);
    this.sortBy.bind(this);
    this.compareByNumber.bind(this);
  } //Fecha Construtor da Class

  compareBy(key) {
    return function(a, b) {
      if (a[key] < b[key]) return -1;
      if (a[key] > b[key]) return 1;
      return 0;
    };
  }

  compareByNumber(key) {
    return function(a, b) {
      return parseInt(a[key]) - parseInt(b[key]);
    };
  }

  sortBy(key) {
    let arrayCopy = [...this.state.items];
    if (key === "dorsal_number") {
      arrayCopy.sort(this.compareByNumber(key));
    } else {
      arrayCopy.sort(this.compareBy(key));
    }
    this.setState({ items: arrayCopy });
  }

  handlePageClick = data => {
    let selected1 = data.selected;
    let offset1 = Math.ceil(selected1 * this.state.perPage);

    this.setState({ offset: offset1 }, () => {
      this.Vai_Buscar_Dados();
    });
  };

  handleClickOpenDialogEliminaResultado = () => {
    this.setState({ open_dialog_elimina_resultado: true });
  };

  handleCloseDialogEliminaResultado = () => {
    this.setState({ open_dialog_elimina_resultado: false });
  };

  handleEliminaResultado = () => {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .delete(
        REACT_APP_API_URL +
          "/delete_resultado_atleta/" +
          this.state.ID_Atleta +
          "/" +
          this.state.ID_Checkpoint_Eliminar +
          "/" +
          this.state.ID_Prova,
        //{},
        {
          headers: { authorization: "Bearer " + Token_Lido }
        }
      )
      .then(res => {
        this.setState({
          open_dialog_elimina_resultado: false,
          Faz_Refresh: true,
          Fez_Eliminacao_Resultado: true
        });
      });
    /*
    this.setState({
      open_dialog_elimina_resultado: false //, Logout_Realizado: true
    });
    */
  };

  //Funcoes Para a Mensagem de Login Com Sucesso na Snackbar
  handleClose_Edicao = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ Fez_Edicao_Resultado: false });
  };

  //Funcoes Para a Mensagem de Login Com Sucesso na Snackbar
  handleClose_Insercao = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ Fez_Insercao_Resultado: false });
  };

  handleCloseEliminacao = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ Fez_Eliminacao_Resultado: false });
  };

  Mostra_Mensagem_Edicao_Sucesso = () => {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={this.state.Fez_Edicao_Resultado}
        autoHideDuration={3000}
        onClose={this.handleClose_Edicao}
      >
        <MySnackbarContentWrapper
          onClose={this.handleClose_Edicao}
          variant="success"
          message="Resultado Editado Com Sucesso!"
        />
      </Snackbar>
    );
  };

  Mostra_Mensagem_Insercao_Sucesso = () => {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={this.state.Fez_Insercao_Resultado}
        autoHideDuration={3000}
        onClose={this.handleClose_Insercao}
      >
        <MySnackbarContentWrapper
          onClose={this.handleClose_Insercao}
          variant="success"
          message="Resultado Inserido Com Sucesso!"
        />
      </Snackbar>
    );
  };

  Mostra_Mensagem_Eliminacao_Sucesso = () => {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={this.state.Fez_Eliminacao_Resultado}
        autoHideDuration={3000}
        onClose={this.handleCloseEliminacao}
      >
        <MySnackbarContentWrapper
          onClose={this.handleCloseEliminacao}
          variant="success"
          message="Resultado Eliminado Com Sucesso!"
        />
      </Snackbar>
    );
  };

  Vai_Buscar_Dados() {
    const Token_Lido = localStorage.getItem("Token");
    if (this.state.perPage > 0) {
      axios
        .get(
          REACT_APP_API_URL +
            "/lista_resultados_atleta/" +
            this.state.ID_Atleta +
            "/" +
            this.state.ID_Prova +
            "/" +
            this.state.perPage +
            "/" +
            this.state.offset,
          {
            headers: { authorization: "Bearer " + Token_Lido }
          }
        )
        .then(res => {
          console.log(res);
          this.setState({ items: res.data });
          this.setState({
            pageCount: Math.ceil(this.state.total_items / this.state.perPage)
          });

          if (
            this.state.items.length > 0 &&
            this.state.Hora_Inicio_Prova === undefined
          ) {
            //Tem Dados Para Mostrar
            const Dados_Lidos = res.data;
            this.setState({
              Hora_Inicio_Prova: Dados_Lidos[0].checkpoint_point_time,
              Nome_Escolhido: Dados_Lidos[0].athlete_name,
              Dorsal_Escolhido: Dados_Lidos[0].dorsal_number,
              Escalao_Escolhido: Dados_Lidos[0].category,
              Evento_Escolhido: Dados_Lidos[0].name,
              Competicao_Escolhida: Dados_Lidos[0].long_name
            });
          }
        });
    } else {
      axios
        .get(
          REACT_APP_API_URL +
            "/lista_resultados_atleta/" +
            this.state.ID_Atleta +
            "/" +
            this.state.ID_Prova,
          {
            headers: { authorization: "Bearer " + Token_Lido }
          }
        )
        .then(res => {
          console.log(res);
          this.setState({ items: res.data });
          this.setState({ pageCount: 1 });
        });
    }
  }

  Vai_Buscar_Numero_Resultados_Atleta() {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .get(
        REACT_APP_API_URL +
          "/devolve_numero_resultados_atleta/" +
          this.state.ID_Atleta +
          "/" +
          this.state.ID_Prova,
        {
          headers: { authorization: "Bearer " + Token_Lido }
        }
      )
      .then(res => {
        console.log(res);
        this.setState({ total_items: res.data[0].Total });
      });
  }
  /*
Procedimento Com Axios */
  componentDidMount() {
    if (localStorage.getItem("Token") !== null) {
      this.Vai_Buscar_Numero_Resultados_Atleta();
      this.Vai_Buscar_Dados();
    }
  } //Fecha Procedimento Que Acontece Depois do Output do Componente Foi Rendered

  //Actualiza os Dados Depois de Acontecer um Redirect Para Este Componente
  componentDidUpdate() {
    //nextProps) {
    //if (nextProps.location.pathname !== this.props.location.pathname) {
    if (this.state.Faz_Refresh) {
      this.Vai_Buscar_Numero_Resultados_Atleta();
      this.Vai_Buscar_Dados();
      this.setState({ Faz_Refresh: false });
    }
  }

  /*
  //Actualiza os Dados Depois de Acontecer um Redirect Para Esta Tabela
  componentDidUpdate() {
    this.Vai_Buscar_Numero_Resultados_Atleta();
    this.Vai_Buscar_Dados();
  }
  */

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value }, () => {
      this.Vai_Buscar_Dados();
    });
  }

  render() {
    if (localStorage.getItem("Token") === null) {
      this.props.history.push({
        pathname: "/"
      });
    }

    //Verifica Se Existe Erro. Se Houver Mostra Mensagem de Erro
    if (this.state.Mostra_Erro === true) {
      return (
        <div>
          <Drawer
            Titulo_Menu="" //"Erro"
            //Link_Menu_Anterior={this.props.location.pathname}
            history={this.props.history}
          />
          {mostra_mensagem_erro()}
        </div>
      );
    } else {
      if (
        this.state.items === undefined ||
        this.state.total_items === undefined
      ) {
        return (
          <div className="centra_loading">
            <h1>Loading...</h1>
          </div>
        );
      } else {
        if (this.state.items.length > 0) {
          //Tem Dados Para Mostrar
          //let Data_Inicio;
          //let Diferenca_Inicio;
          let Hora_Passagem_Checkpoint;
          let Data_Passagem;
          //let Hora_Passagem_Checkpoint_Aux;
          let Aux1;
          let Aux2;
          let Aux3;
          let Aux4;
          //let Aux5;

          return (
            <div>
              <Dialog
                open={this.state.open_dialog_elimina_resultado}
                onClose={this.handleCloseDialogEliminaResultado}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Tem a Certeza Que Deseja Eliminar o Resultado?"}
                </DialogTitle>
                <DialogActions>
                  <Button
                    onClick={this.handleCloseDialogEliminaResultado}
                    color="primary"
                  >
                    Cancelar
                  </Button>
                  <Button
                    onClick={this.handleEliminaResultado}
                    color="primary"
                    autoFocus
                  >
                    Sim
                  </Button>
                </DialogActions>
              </Dialog>

              <Drawer
                Titulo_Menu="Lista Resultados Atleta"
                ID_Evento={this.state.ID_Evento}
                ID_Prova={this.state.ID_Prova}
                ID_Atleta={this.state.ID_Atleta}
                Link_Menu_Anterior={this.state.Link_Menu_Anterior}
                Link_Tabela_Anterior={this.state.Link_Tabela_Anterior}
                Link_Tabela_Anterior2={this.props.location.pathname}
                Nome_Evento={this.state.Nome_Evento}
                Nome_Prova={this.state.Nome_Prova}
                Nome_Atleta={this.state.Nome_Atleta}
                history={this.props.history}
              />
              <div
                className="novo_centra_tabelas" //"esquerda_tabelas" //"centra_tabelas"
              >
                <tabela>
                  <thead>
                    <tr>
                      <th colSpan="9">
                        Número Total de Resultados: {this.state.total_items}
                      </th>
                    </tr>
                  </thead>
                  <thead>
                    <tr>
                      {/*
                      <th>Nome</th>
                      <th>Dorsal</th>
                      <th>Escalão</th>
                      <th>Evento</th>
                      <th>Competição</th>
                      */}
                      <th onClick={() => this.sortBy("local")}>
                        Checkpoint{" "}
                        <i
                          className="fa fa-angle-down fa-sm" /*fa fa-sort fa-sm*/
                        />
                      </th>
                      <th onClick={() => this.sortBy("checkpoint_order")}>
                        Nº CP{" "}
                        <i
                          className="fa fa-angle-down fa-sm" /*fa fa-sort fa-sm*/
                        />
                      </th>
                      <th onClick={() => this.sortBy("checkpoint_point_time")}>
                        Data Passagem{" "}
                        <i
                          className="fa fa-angle-down fa-sm" /*fa fa-sort fa-sm*/
                        />
                      </th>
                      <th>Tempo Prova</th>
                      <th>Estado Atleta</th>
                      <th>Acção</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.items.map(linha2 => {
                      /*
                Aux2 = linha2.checkpoint_point_time;
                /*
                Aux1 = Moment(this.state.Hora_Inicio_Prova)
                  .format("DD-MM-YYYY HH:mm:ss")
                  .diff(Moment(Aux2).format("DD-MM-YYYY HH:mm:ss"));
                Aux3 = Moment.duration(Aux1);

                Hora_Passagem_Checkpoint = Math.floor(
                  Aux3.asHours() + Moment.utc(Aux1).format(":mm:ss")
                );*/
                      /*
                Aux1 = Moment(this.state.Hora_Inicio_Prova).format(
                  "DD-MM-YYYY HH:mm:ss"
                );
                Aux4 = Moment(Aux2).format("DD-MM-YYYY HH:mm:ss");
                Aux3 = Aux1.diff(Aux4);*/
                      /*
                Aux3 = Moment(this.state.Hora_Inicio_Prova)
                  .format("DD-MM-YYYY HH:mm:ss")
                  .diff(Moment(Aux2).format("DD-MM-YYYY HH:mm:ss"));

                Aux5 = Moment.duration(Aux3);

                Hora_Passagem_Checkpoint = Math.floor(
                  Aux5.asHours() + Moment.utc(Aux3).format(":mm:ss")
                );
                */

                      Aux1 = Moment(this.state.Hora_Inicio_Prova);
                      Aux2 = Moment(linha2.checkpoint_point_time);
                      //Aux3=
                      //Hora_Passagem_Checkpoint =Aux2.diff(Aux1, "HH:mm:ss"); //"hours");
                      Aux3 = Aux2.diff(Aux1);
                      Aux4 = Moment.duration(Aux3);

                      Hora_Passagem_Checkpoint =
                        Math.floor(Aux4.asHours()) +
                        Moment.utc(Aux3).format(":mm:ss");

                      Data_Passagem = Moment(
                        linha2.checkpoint_point_time
                      ).format("DD-MM-YYYY HH:mm:ss");

                      //Criar Formulario Para Editar Tempo do Resultado (Apenas Esse Campo da Data Chegada! Resto Fica Como ReadOnly)
                      return (
                        <tr
                          onClick={() =>
                            this.setState({
                              ID_Checkpoint_Eliminar:
                                linha2.competition_checkpoint_checkpoint_checkpoint_id
                            })
                          }
                        >
                          {/*
                          <td>{linha2.athlete_name}</td>
                          <td>{linha2.dorsal_number}</td>
                          <td>{linha2.category}</td>
                          <td>{linha2.name}</td>
                          <td>{linha2.long_name}</td>
                          */}
                          <td style={{ width: "300px" }}>{linha2.local}</td>
                          <td>{linha2.checkpoint_order}</td>
                          <td style={{ width: "200px" }}>{Data_Passagem}</td>
                          <td>{Hora_Passagem_Checkpoint}</td>
                          <td>{linha2.time_status}</td>
                          <td>
                            <Link
                              to={{
                                pathname: "/editar_resultados_atleta", // this.state.Link_Tabela_Anterior, //Link_Menu_Anterior,
                                state: {
                                  ID_Evento: this.state.ID_Evento,
                                  ID_Prova: this.state.ID_Prova,
                                  Link_Menu_Anterior: this.state
                                    .Link_Menu_Anterior,
                                  Link_Tabela_Anterior: this.state
                                    .Link_Tabela_Anterior,
                                  Link_Tabela_Anterior2: this.props.location
                                    .pathname,
                                  ID_Atleta: this.state.ID_Atleta,
                                  id_checkpoint:
                                    linha2.competition_checkpoint_checkpoint_checkpoint_id,
                                  data_passagem_partida: this.state
                                    .Hora_Inicio_Prova,
                                  Faz_Refresh: false, //true,
                                  Nome_Evento: this.state.Nome_Evento,
                                  Nome_Prova: this.state.Nome_Prova,
                                  Nome_Atleta: this.state.Nome_Atleta //linha2.name
                                }
                              }}
                              title="Editar"
                            >
                              <i className="fa fa-edit fa-lg espaco_icons" />
                            </Link>{" "}
                            <span
                              title="Remover Resultado"
                              onClick={
                                this.handleClickOpenDialogEliminaResultado
                              }
                            >
                              <i className="fa fa-trash fa-lg" />
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </tabela>
                <div
                  style={{ marginTop: "10px" }}
                  className="centra_dados_tabela"
                >
                  <label>Resultados Por Página</label>{" "}
                  <select
                    name="perPage"
                    id="perPage"
                    onChange={this.handleChange}
                  >
                    <option value="10"> 10 </option>
                    <option value="25"> 25 </option>
                    <option value="50"> 50 </option>
                    <option value="100"> 100 </option>
                    <option value="0">Todos </option>
                  </select>
                </div>

                <div className="centra_dados_tabela">
                  <ReactPaginate
                    previousLabel={"Anterior"}
                    nextLabel={"Próximo"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
              {this.Mostra_Mensagem_Edicao_Sucesso()}
              {this.Mostra_Mensagem_Insercao_Sucesso()}
              {this.Mostra_Mensagem_Eliminacao_Sucesso()}

              {/*}
              <div className="direita_form">
                <label>
                  Nome Atleta:
                  <font color="white">
                    {" "}
                    {this.state.Nome_Escolhido }
                  </font>
                </label>
                <div className="espaco_span" />

                <label>
                  Dorsal Atleta:
                  <font color="white">
                    {" "}
                    {this.state.Dorsal_Escolhido }
                  </font>
                </label>
                <div className="espaco_span" />

                <label>
                  Escalão Atleta:
                  <font color="white">
                    {" "}
                    {this.state.Escalao_Escolhido }
                  </font>
                </label>
                <div className="espaco_span" />

                <label>
                  Evento:
                  <font color="white">
                    {" "}
                    {this.state.Evento_Escolhido }
                  </font>
                </label>
                <div className="espaco_span" />

                <label>
                  Prova:
                  <font color="white">
                    {" "}
                    {this.state.Competicao_Escolhida }
                  </font>
                </label>
                <div className="espaco_span" />
              </div>
              */}
            </div>
          );
        } else {
          return (
            <div>
              <Drawer
                Titulo_Menu="Lista Resultados Atleta"
                ID_Evento={this.state.ID_Evento}
                ID_Prova={this.state.ID_Prova}
                ID_Atleta={this.state.ID_Atleta}
                Link_Menu_Anterior={this.state.Link_Menu_Anterior}
                Link_Tabela_Anterior={this.state.Link_Tabela_Anterior}
                Link_Tabela_Anterior2={this.props.location.pathname}
                Nome_Evento={this.state.Nome_Evento}
                Nome_Prova={this.state.Nome_Prova}
                Nome_Atleta={this.state.Nome_Atleta}
                history={this.props.history}
              />
              {ve_resultados()}
            </div>
          );
        }
      }
    }
  }
}

export default Tabela_Resultados_Atleta;
