import React, { Component } from "react";
import axios from "axios";
//import Moment from "moment";
import Moment from "moment-timezone";
import Drawer from "../Drawer/Drawer.js";
import Select from "react-select";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import "./Teste_Novos_Forms.css";

//Funções Auxiliares:
import {
  mostra_mensagem_erro,
  //ve_resultados,
  Trata_Validacoes,
  isEmptyObject
} from "../Funcoes_Auxiliares/Funcoes_Auxiliares.js";

let REACT_APP_API_URL;

if (process.env.REACT_APP_ESTADO === "Deploy") {
  REACT_APP_API_URL = process.env.REACT_APP_API_URL_PROD;
} //Development
else {
  REACT_APP_API_URL = process.env.REACT_APP_API_URL_DEV;
}

const opcoes_select_activo = [
  { value: 1, label: "Sim" },
  { value: 0, label: "Não" }
];

class Formulario_Edicao_Competicao extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dados: undefined, //[],

      //Link_Tabela_Anterior: null,
      Link_Menu_Anterior: null,
      ID_Evento: null,
      ID_Prova: null,
      Nome_Evento: null,
      Nome_Prova: null,

      nome_competicao: null,
      abreviatura_competicao: null,
      evento: null,
      data_inicio: null,
      data_fim: null,
      distancia_total: null,
      competicao_activa: null,
      codigo_cor: null,
      nome_ficheiro_kml: null,
      numero_maximo_inscricoes: null,
      //certificado: null,
      //cert_tpl: null,
      //codigo_certificado: null,

      //certificado_lido_bd: null,
      competicao_activa_lido_bd: null,

      alterar_atletas: null,
      alterar_atletas_lido_bd: null,

      //Nota: São Usados 2 Variáveis Porque se Houver Alterações no Campo de Select,  Faz [campo.value]. Caso Contrário Fica Com o Valor Original
      /*
      competicao_activa_inicial: null,
      competicao_activa_final: null,
      certificado_inicial: null,
      certificado_final: null,
      */

      //Variavel Controlo de Erro na Leitura dos Props
      Mostra_Erro: false,

      //Variavel Para Controlar o Redirect
      Inserido_Sucesso: false,

      //Lista Para os Erros de Validação:
      errors: {}
    };

    if (props.location.state === undefined) {
      this.state.Mostra_Erro = true;
    } else {
      if (props.location.state.Link_Menu_Anterior === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Link_Menu_Anterior = props.location.state.Link_Menu_Anterior;
      }
      if (props.location.state.ID_Evento === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.ID_Evento = props.location.state.ID_Evento;
      }
      if (props.location.state.ID_Prova === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.ID_Prova = props.location.state.ID_Prova;
      }
      if (props.location.state.Nome_Evento === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Nome_Evento = props.location.state.Nome_Evento;
      }
      if (props.location.state.Nome_Prova === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Nome_Prova = props.location.state.Nome_Prova;
      }
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.handleApagaCompeticao = this.handleApagaCompeticao.bind(this);
  }

  Converte_Value_Activo_Para_Label(Value) {
    if (Value === 0) {
      return "Não";
    } else {
      return "Sim";
    }
  }

  handleApagaCompeticao(event) {
    event.preventDefault();
    this.Apaga_Competicao();

    this.props.history.push({
      pathname: this.state.Link_Menu_Anterior, //Link_Tabela_Anterior,
      state: {
        //Link_Menu_Anterior: this.state.Link_Menu_Anterior,
        //Link_Tabela_Anterior: this.state.Link_Tabela_Anterior,
        ID_Evento: this.state.ID_Evento,
        //ID_Prova: this.state.ID_Prova,
        Faz_Refresh: true,
        Nome_Evento: this.state.Nome_Evento
      }
    });
  }

  Apaga_Competicao() {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .delete(REACT_APP_API_URL + "/delete_competicao/" + this.state.ID_Prova, {
        headers: { authorization: "Bearer " + Token_Lido }
      })
      .then(res => {
        console.log(res);
      });
  }

  Vai_Buscar_Dados_Competicao() {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .get(REACT_APP_API_URL + "/dados_competicao/" + this.state.ID_Prova, {
        headers: { authorization: "Bearer " + Token_Lido }
      })
      .then(res => {
        console.log(res);
        this.setState({ dados: res.data });
        const Dados_Lidos = res.data;
        this.setState({
          nome_competicao: Dados_Lidos[0].long_name,
          abreviatura_competicao: Dados_Lidos[0].name,
          evento: Dados_Lidos[0].Nome_Evento, //trail_event_trail_event_id,
          data_inicio: Dados_Lidos[0].init_date,
          data_fim: Dados_Lidos[0].end_date,
          distancia_total: Dados_Lidos[0].total_distance,
          //competicao_activa: Dados_Lidos[0].active,
          competicao_activa_lido_bd: Dados_Lidos[0].active,
          codigo_cor: Dados_Lidos[0].color_code,
          nome_ficheiro_kml: Dados_Lidos[0].kml_file_name,
          numero_maximo_inscricoes: Dados_Lidos[0].max_inscriptions,
          //certificado: Dados_Lidos[0].certification,
          //certificado_lido_bd: Dados_Lidos[0].certification,
          //cert_tpl: Dados_Lidos[0].cert_tpl,
          //codigo_certificado: Dados_Lidos[0].cert_code,
          alterar_atletas_lido_bd: Dados_Lidos[0].alterar_atletas,

          data_inicio_lido_bd: Dados_Lidos[0].init_date,
          data_fim_lido_bd: Dados_Lidos[0].end_date
        });
      });
  }

  /*
  Procedimento Com Axios */
  componentDidMount() {
    if (localStorage.getItem("Token") !== null) {
      this.Vai_Buscar_Dados_Competicao();
    }
  } //Fecha Procedimento Que Acontece Depois do Output do Componente Foi Rendered

  /*
    componentDidUpdate() {
      this.Vai_Buscar_Dados_Competicao();
    } //Fecha Procedimento Que Acontece Depois do Output do Componente Foi Rendered
    */

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  /*
  handleChangeSelectActivo = activo => {
    this.setState({ activo });
  };
  */
  handleChangeSelectActivo = competicao_activa => {
    this.setState({ competicao_activa });
  };

  handleChangeSelectCertificado = certificado => {
    this.setState({ certificado });
  };

  handleChangeSelectAlterarAtletas = alterar_atletas => {
    this.setState({ alterar_atletas });
  };

  handleValidation() {
    let errors = {};
    let formIsValid = false; //true;

    let Data_Inicio = Moment(this.state.data_inicio);
    let Data_Fim = Moment(this.state.data_fim);

    /*
    var competicao_activa_enviar;
    if (
      this.state.competicao_activa_inicial !==
        this.state.competicao_activa_final.value &&
      this.state.competicao_activa_final.value !== undefined
    ) {
      competicao_activa_enviar = this.state.competicao_activa_final.value;
    } else {
      competicao_activa_enviar = this.state.competicao_activa_inicial;
    }

    var certificado_enviar;
    if (
      this.state.certificado_inicial !== this.state.certificado_final.value &&
      this.state.certificado_final.value !== undefined
    ) {
      certificado_enviar = this.state.certificado_final.value;
    } else {
      certificado_enviar = this.state.certificado_inicial;
    }
    */

    //Para Cada Campo Invocar o Trata_Validacoes
    errors = Trata_Validacoes(
      this.state.nome_competicao,
      "competition/long_name",
      errors
    );
    errors = Trata_Validacoes(
      this.state.abreviatura_competicao,
      "competition/name",
      errors
    );
    errors = Trata_Validacoes(
      this.state.data_inicio,
      "competition/init_date",
      errors
    );
    errors = Trata_Validacoes(
      this.state.data_fim,
      "competition/end_date",
      errors
    );

    //Verifica Coerência das Datas
    if (
      this.state.data_inicio &&
      this.state.data_fim &&
      //Moment(this.state.data_inicio).isSameorAfter(this.state.data_inicio)

      Data_Inicio.diff(Data_Fim) >= 0 //, "days"); //Negativo, Data_Inicio<Data_Fim
    ) {
      formIsValid = false;
      errors["competition/end_date"] = "*Data de Fim de Competição Inválida";
    }

    errors = Trata_Validacoes(
      this.state.distancia_total,
      "competition/total_distance",
      errors
    );
    /*
    errors = Trata_Validacoes(
      competicao_activa_enviar,
      "competition/active",
      errors
    );
    */
    if (
      !this.state.competicao_activa_lido_bd &&
      !this.state.competicao_activa &&
      this.state.competicao_activa_lido_bd !== 0 &&
      this.state.competicao_activa !== 0
    ) {
      errors["competition/active"] = "*Escolha o Estado da Competição";
    }
    /*
    errors = Trata_Validacoes(
      this.state.codigo_cor,
      "competition/color_code",
      errors
    );
    errors = Trata_Validacoes(
      this.state.nome_ficheiro_kml,
      "competition/kml_file_name",
      errors
    );
    errors = Trata_Validacoes(
      this.state.numero_maximo_inscricoes,
      "competition/max_inscriptions",
      errors
    );
    */
    /*
    errors = Trata_Validacoes(
      certificado_enviar,
      "competition/certification",
      errors
    );
    */

    /*
    if (
      !this.state.certificado_lido_bd &&
      !this.state.certificado &&
      this.state.certificado_lido_bd !== 0 &&
      this.state.certificado !== 0
    ) {
      errors["competition/certification"] =
        "*Escolha se Deve Ser Emitido Certificado";
    }

    errors = Trata_Validacoes(
      this.state.cert_tpl,
      "competition/cert_tpl",
      errors
    );
    errors = Trata_Validacoes(
      this.state.codigo_certificado,
      "competition/cert_code",
      errors
    );
    */

    if (
      !this.state.alterar_atletas_lido_bd &&
      !this.state.alterar_atletas &&
      this.state.alterar_atletas_lido_bd !== 0 &&
      this.state.alterar_atletas !== 0
    ) {
      errors["competition/alterar_atletas"] =
        "*Escolha se Deve Ser Possível Alterar Atletas";
    }

    //if (errors.length > 0) {
    if (isEmptyObject(errors)) {
      formIsValid = true; //false;
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.handleValidation()) {
      const Token_Lido = localStorage.getItem("Token");

      axios
        .patch(
          REACT_APP_API_URL + "/update_competicao/" + this.state.ID_Prova,
          {
            nome_competicao: this.state.nome_competicao,
            abreviatura_competicao: this.state.abreviatura_competicao,
            //evento: this.state.evento,

            /*
            data_inicio:
              this.state.data_inicio !== this.state.data_inicio_lido_bd
                ? Moment(
                    this.state.data_inicio,
                    "YYYY-MM-DD HH:mm:ss"
                  ).subtract(1, "hours")
                : Moment(this.state.data_inicio, "YYYY-MM-DD HH:mm:ss"),
                
            data_fim:
              this.state.data_fim !== this.state.data_fim_lido_bd
                ? Moment(this.state.data_fim, "YYYY-MM-DD HH:mm:ss").subtract(
                    1,
                    "hours"
                  )
                : Moment(this.state.data_fim, "YYYY-MM-DD HH:mm:ss"),
                */

            data_inicio:
              this.state.data_inicio !== this.state.data_inicio_lido_bd
                ? this.state.data_inicio
                : Moment(this.state.data_inicio_lido_bd, "YYYY-MM-DD HH:mm:ss")
                    .tz("Europe/Madrid")
                    .format("YYYY-MM-DD HH:mm:ss"), //: this.state.data_inicio_lido_bd,

            data_fim:
              this.state.data_fim !== this.state.data_fim_lido_bd
                ? this.state.data_fim
                : Moment(this.state.data_fim_lido_bd, "YYYY-MM-DD HH:mm:ss")
                    .tz("Europe/Madrid")
                    .format("YYYY-MM-DD HH:mm:ss"), //this.state.data_fim_lido_bd,

            distancia_total: this.state.distancia_total,
            //competicao_activa: competicao_activa_enviar, // this.state.competicao_activa,
            competicao_activa: this.state.competicao_activa
              ? this.state.competicao_activa.value
              : this.state.competicao_activa_lido_bd,
            codigo_cor: this.state.codigo_cor,
            nome_ficheiro_kml: this.state.nome_ficheiro_kml,
            numero_maximo_inscricoes: this.state.numero_maximo_inscricoes,
            //certificado: certificado_enviar, //this.state.certificado,
            //certificado: this.state.certificado ? this.state.certificado.value : this.state.certificado_lido_bd,
            //cert_tpl: this.state.cert_tpl,
            //codigo_certificado: this.state.codigo_certificado,
            alterar_atletas: this.state.alterar_atletas
              ? this.state.alterar_atletas.value
              : this.state.alterar_atletas_lido_bd
          },
          {
            headers: { authorization: "Bearer " + Token_Lido }
          }
        )
        .then(res => {
          //console.log(res);
          this.setState({ Inserido_Sucesso: res.data.success });
        });
    }
  }

  render() {
    if (localStorage.getItem("Token") === null) {
      this.props.history.push({
        pathname: "/"
      });
    }

    if (this.state.Inserido_Sucesso === true) {
      this.props.history.push({
        pathname: this.state.Link_Menu_Anterior, //Link_Tabela_Anterior,
        state: {
          //Link_Menu_Anterior: this.state.Link_Menu_Anterior,
          //Link_Tabela_Anterior: this.state.Link_Tabela_Anterior,
          //ID_Prova: this.state.ID_Prova
          ID_Evento: this.state.ID_Evento,
          Fez_Insercao_Competicao: false,
          Fez_Edicao_Competicao: true,
          Faz_Refresh: true,
          Nome_Evento: this.state.Nome_Evento,
          Nome_Prova: this.state.Nome_Prova,
          Fez_Eliminacao_Competicao: false
        }
      });
    }

    //Verifica Se Existe Erro. Se Houver Mostra Mensagem de Erro
    if (this.state.Mostra_Erro === true) {
      return (
        <div>
          <Drawer
            Titulo_Menu="" //"Erro"
            history={this.props.history}
          />
          {mostra_mensagem_erro()}
        </div>
      );
    } else {
      if (this.state.dados === undefined) {
        return (
          <div className="centra_loading">
            <h1>Loading...</h1>
          </div>
        );
      } else {
        const border_erro = "1px solid #ff0000";

        return (
          <div>
            <Drawer
              //Titulo_Menu="Editar Competição"
              Titulo_Menu="Editar Prova"
              ID_Evento={this.state.ID_Evento}
              Link_Menu_Anterior={this.state.Link_Menu_Anterior}
              Nome_Evento={this.state.Nome_Evento}
              Nome_Prova={this.state.Nome_Prova}
              history={this.props.history}
              ID_Prova={this.state.ID_Prova}
            />
            {this.state.dados.map(linha => {
              //let Data_Inicio = Moment(linha.init_date).format("DD-MM-YYYY HH:mm:ss");
              //let Data_Fim = Moment(linha.end_date).format("DD-MM-YYYY HH:mm:ss");

              return (
                <div className="novas_margens_volta_novos_forms">
                  <div className="centra_novos_forms">
                    <form className="login-form" onSubmit={this.handleSubmit}>
                      <div className="centra_titulos_tabs">
                        <Tabs>
                          <TabList>
                            <Tab
                              style={{
                                border:
                                  this.state.errors["competition/long_name"] ||
                                  this.state.errors["competition/name"] ||
                                  this.state.errors[
                                    "competition/total_distance"
                                  ] ||
                                  this.state.errors[
                                    "competition/max_inscriptions"
                                  ]
                                    ? border_erro
                                    : ""
                              }}
                            >
                              Informações Competição
                            </Tab>
                            <Tab
                              style={{
                                border:
                                  this.state.errors["competition/init_date"] ||
                                  this.state.errors["competition/end_date"]
                                    ? border_erro
                                    : ""
                              }}
                            >
                              Datas
                            </Tab>
                            <Tab
                              style={{
                                border:
                                  this.state.errors["competition/active"] ||
                                  this.state.errors[
                                    "competition/alterar_atletas"
                                  ]
                                    ? border_erro
                                    : ""
                              }}
                            >
                              Diversos
                            </Tab>
                            {/*<Tab>Certificados</Tab>*/}
                            <button
                              type="submit"
                              style={{ marginLeft: "10px" }}
                            >
                              Alterar Competição
                            </button>
                          </TabList>
                          {/*****************************************************************************/}
                          {/*****************************************************************************/}
                          {/*****************************************************************************/}
                          {/*****************************************************************************/}
                          {/*****************************************************************************/}
                          {/*****************************************************************************/}
                          {/*****************************************************************************/}
                          {/*****************************************************************************/}
                          {/*****************************************************************************/}
                          <TabPanel>
                            <div className="form">
                              <div>
                                <label>
                                  Nome Competição
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {" (*)"}
                                  </span>
                                </label>
                                <input
                                  style={{
                                    border: this.state.errors[
                                      "competition/long_name"
                                    ]
                                      ? border_erro
                                      : "",
                                    textAlign: "center"
                                  }}
                                  type="text"
                                  name="nome_competicao"
                                  placeholder="Nome Competição"
                                  onChange={this.handleChange}
                                  defaultValue={this.state.nome_competicao}
                                />
                                <span
                                  className="bolder"
                                  style={{ color: "#e60000" }}
                                >
                                  {this.state.errors["competition/long_name"]}
                                </span>
                                <div className="espaco_span" />
                                <label>
                                  Abreviatura Competição
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {" (*)"}
                                  </span>
                                </label>
                                <input
                                  style={{
                                    border: this.state.errors[
                                      "competition/name"
                                    ]
                                      ? border_erro
                                      : "",
                                    textAlign: "center"
                                  }}
                                  type="text"
                                  name="abreviatura_competicao"
                                  placeholder="Abreviatura Competição"
                                  onChange={this.handleChange}
                                  defaultValue={
                                    this.state.abreviatura_competicao
                                  }
                                />
                                <span
                                  className="bolder"
                                  style={{ color: "#e60000" }}
                                >
                                  {this.state.errors["competition/name"]}
                                </span>
                                <div className="espaco_span" />
                                <label>
                                  Distância Total
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {" (*)"}
                                  </span>
                                </label>
                                <input
                                  style={{
                                    border: this.state.errors[
                                      "competition/total_distance"
                                    ]
                                      ? border_erro
                                      : "",
                                    textAlign: "center"
                                  }}
                                  type="text"
                                  name="distancia_total"
                                  placeholder="Distância Total"
                                  onChange={this.handleChange}
                                  defaultValue={this.state.distancia_total}
                                />
                                <span
                                  className="bolder"
                                  style={{ color: "#e60000" }}
                                >
                                  {
                                    this.state.errors[
                                      "competition/total_distance"
                                    ]
                                  }
                                </span>
                                <div className="espaco_span" />
                                <label>
                                  Numero Máximo Inscrições
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {" (*)"}
                                  </span>
                                </label>
                                <input
                                  style={{
                                    border: this.state.errors[
                                      "competition/max_inscriptions"
                                    ]
                                      ? border_erro
                                      : "",
                                    textAlign: "center"
                                  }}
                                  type="number"
                                  name="numero_maximo_inscricoes"
                                  placeholder="Numero Máximo Inscrições"
                                  onChange={this.handleChange}
                                  defaultValue={
                                    this.state.numero_maximo_inscricoes
                                  }
                                  min="0"
                                />
                                <span
                                  className="bolder"
                                  style={{ color: "#e60000" }}
                                >
                                  {
                                    this.state.errors[
                                      "competition/max_inscriptions"
                                    ]
                                  }
                                </span>
                                <div className="espaco_span" />
                                <span
                                  className="bolder"
                                  style={{ color: "#e60000" }}
                                >
                                  {" (*)"}
                                </span>{" "}
                                <span className="bolder">
                                  {" "}
                                  Campos Obrigatórios
                                </span>
                              </div>
                            </div>
                            {/*}
                            <div className="form">
                              <button type="submit">Alterar Competição</button>
                            </div>
                            */}
                          </TabPanel>
                          {/*****************************************************************************/}
                          {/*****************************************************************************/}
                          {/*****************************************************************************/}
                          {/*****************************************************************************/}
                          {/*****************************************************************************/}
                          {/*****************************************************************************/}
                          {/*****************************************************************************/}
                          {/*****************************************************************************/}
                          {/*****************************************************************************/}
                          <TabPanel>
                            <div className="form">
                              <div>
                                <label>
                                  Data Início
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {" (*)"}
                                  </span>
                                </label>
                                <input
                                  style={{
                                    border: this.state.errors[
                                      "competition/init_date"
                                    ]
                                      ? border_erro
                                      : "",
                                    textAlign: "center"
                                  }}
                                  type="datetime-local"
                                  step="1"
                                  name="data_inicio"
                                  onChange={this.handleChange}
                                  defaultValue={
                                    String(
                                      Moment(this.state.data_inicio).format(
                                        "YYYY-MM-DD"
                                      )
                                    ) +
                                    "T" +
                                    String(
                                      Moment(this.state.data_inicio).format(
                                        "HH:mm:ss"
                                      )
                                    )
                                  }
                                />
                                <span
                                  className="bolder"
                                  style={{ color: "#e60000" }}
                                >
                                  {this.state.errors["competition/init_date"]}
                                </span>
                                <div className="espaco_span" />
                                <label>
                                  Data Fim
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {" (*)"}
                                  </span>
                                </label>
                                <input
                                  style={{
                                    border: this.state.errors[
                                      "competition/end_date"
                                    ]
                                      ? border_erro
                                      : "",
                                    textAlign: "center"
                                  }}
                                  type="datetime-local"
                                  step="1"
                                  name="data_fim"
                                  onChange={this.handleChange}
                                  defaultValue={
                                    String(
                                      Moment(this.state.data_fim).format(
                                        "YYYY-MM-DD"
                                      )
                                    ) +
                                    "T" +
                                    String(
                                      Moment(this.state.data_fim).format(
                                        "HH:mm:ss"
                                      )
                                    )
                                  }
                                />
                                <span
                                  className="bolder"
                                  style={{ color: "#e60000" }}
                                >
                                  {this.state.errors["competition/end_date"]}
                                </span>
                                <div className="espaco_span" />
                                <span
                                  className="bolder"
                                  style={{ color: "#e60000" }}
                                >
                                  {" (*)"}
                                </span>{" "}
                                <span className="bolder">
                                  {" "}
                                  Campos Obrigatórios
                                </span>
                              </div>
                            </div>
                            {/*}
                            <div className="form">
                              <button type="submit">Alterar Competição</button>
                            </div>
                                */}
                          </TabPanel>
                          {/*****************************************************************************/}
                          {/*****************************************************************************/}
                          {/*****************************************************************************/}
                          {/*****************************************************************************/}
                          {/*****************************************************************************/}
                          {/*****************************************************************************/}
                          {/*****************************************************************************/}
                          {/*****************************************************************************/}
                          {/*****************************************************************************/}
                          <TabPanel>
                            <div className="form">
                              <div>
                                <label>
                                  Código Cor
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {
                                      //" (*)"
                                    }
                                  </span>
                                </label>
                                <input
                                  style={{
                                    border: this.state.errors[
                                      "competition/color_code"
                                    ]
                                      ? border_erro
                                      : "",
                                    textAlign: "center"
                                  }}
                                  type="text"
                                  name="codigo_cor"
                                  placeholder="Código Cor"
                                  onChange={this.handleChange}
                                  defaultValue={this.state.codigo_cor}
                                />
                                <span
                                  className="bolder"
                                  style={{ color: "#e60000" }}
                                >
                                  {this.state.errors["competition/color_code"]}
                                </span>
                                <div className="espaco_span" />
                                <label>
                                  Nome Ficheiro kml
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {
                                      //" (*)"
                                    }
                                  </span>
                                </label>
                                <input
                                  style={{
                                    border: this.state.errors[
                                      "competition/kml_file_name"
                                    ]
                                      ? border_erro
                                      : "",
                                    textAlign: "center"
                                  }}
                                  type="text"
                                  name="nome_ficheiro_kml"
                                  placeholder="Nome Ficheiro kml"
                                  onChange={this.handleChange}
                                  defaultValue={this.state.nome_ficheiro_kml}
                                />
                                <span
                                  className="bolder"
                                  style={{ color: "#e60000" }}
                                >
                                  {
                                    this.state.errors[
                                      "competition/kml_file_name"
                                    ]
                                  }
                                </span>
                                <div className="espaco_span" />
                                <label>
                                  Competição Activa:
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {" (*)"}
                                  </span>
                                </label>
                                <div
                                  style={{
                                    border: this.state.errors[
                                      "competition/active"
                                    ]
                                      ? border_erro
                                      : ""
                                  }}
                                >
                                  <Select
                                    //value={this.state.competicao_activa}
                                    name="competicao_activa"
                                    placeholder="Competição Activa"
                                    onChange={this.handleChangeSelectActivo}
                                    options={opcoes_select_activo}
                                    defaultValue={{
                                      value: this.state.competicao_activa
                                        ? this.state.competicao_activa
                                        : this.state.competicao_activa_lido_bd, //linha.active,
                                      label: this.Converte_Value_Activo_Para_Label(
                                        this.state.competicao_activa
                                          ? this.state.competicao_activa
                                          : this.state.competicao_activa_lido_bd //linha.active
                                      )
                                    }}
                                  />
                                </div>
                                <span
                                  className="bolder"
                                  style={{ color: "#e60000" }}
                                >
                                  {this.state.errors["competition/active"]}
                                </span>
                                <div className="espaco_span" />
                                <label>
                                  Permitir Alterar Atletas:
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {" (*)"}
                                  </span>
                                </label>
                                <div
                                  style={{
                                    border: this.state.errors[
                                      "competition/alterar_atletas"
                                    ]
                                      ? border_erro
                                      : ""
                                  }}
                                >
                                  <Select
                                    //value={this.state.alterar_atletas}
                                    name="alterar_atletas"
                                    placeholder="Alterar Atletas"
                                    onChange={
                                      this.handleChangeSelectAlterarAtletas
                                    }
                                    options={opcoes_select_activo}
                                    defaultValue={{
                                      value: this.state.alterar_atletas
                                        ? this.state.alterar_atletas
                                        : this.state.alterar_atletas_lido_bd, //linha.active,
                                      label: this.Converte_Value_Activo_Para_Label(
                                        this.state.alterar_atletas
                                          ? this.state.alterar_atletas
                                          : this.state.alterar_atletas_lido_bd //linha.active
                                      )
                                    }}
                                  />
                                </div>
                                <span
                                  className="bolder"
                                  style={{ color: "#e60000" }}
                                >
                                  {
                                    this.state.errors[
                                      "competition/alterar_atletas"
                                    ]
                                  }
                                </span>
                                <div className="espaco_span" />
                                <span
                                  className="bolder"
                                  style={{ color: "#e60000" }}
                                >
                                  {" (*)"}
                                </span>{" "}
                                <span className="bolder">
                                  {" "}
                                  Campos Obrigatórios
                                </span>
                              </div>
                            </div>
                            {/*}
                            <div className="form">
                              <button type="submit">Alterar Competição</button>
                            </div>
                                */}
                          </TabPanel>
                          {/*****************************************************************************/}
                          {/*****************************************************************************/}
                          {/*****************************************************************************/}
                          {/*****************************************************************************/}
                          {/*****************************************************************************/}
                          {/*****************************************************************************/}
                          {/*****************************************************************************/}
                          {/*****************************************************************************/}
                          {/*****************************************************************************/}
                          {/*
                          <TabPanel>
                            <div className="form">
                              <div>
                                <label>
                                  Certificado
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {" (*)"}
                                  </span>
                                </label>
                                <div
                                  style={{
                                    border: this.state.errors[
                                      "competition/certification"
                                    ]
                                      ? border_erro
                                      : ""
                                  }}
                                >
                                  <Select
                                    //value={this.state.certificado}
                                    name="certificado"
                                    placeholder="Certificado"
                                    onChange={
                                      this.handleChangeSelectCertificado
                                    }
                                    options={opcoes_select_activo}
                                    defaultValue={{
                                      /*
                                      value: this.state.certificado_lido_bd, //linha.certification,
                                      label: this.Converte_Value_Activo_Para_Label(
                                        this.state.certificado_lido_bd //linha.certification
                                      )
                                      

                                      value: this.state.certificado
                                        ? this.state.certificado.value
                                        : this.state.certificado_lido_bd, //linha.certification,
                                      label: this.Converte_Value_Activo_Para_Label(
                                        this.state.certificado
                                          ? this.state.certificado.value
                                          : this.state.certificado_lido_bd //linha.certification
                                      )
                                    }}
                                  />
                                </div>
                                <span
                                  className="bolder"
                                  style={{ color: "#e60000" }}
                                >
                                  {
                                    this.state.errors[
                                      "competition/certification"
                                    ]
                                  }
                                </span>
                                <div className="espaco_span" />

                                <label>
                                  Template Certificado
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {" (*)"}
                                  </span>
                                </label>
                                <input
                                  style={{
                                    border: this.state.errors[
                                      "competition/cert_tpl"
                                    ]
                                      ? border_erro
                                      : "",
                                    textAlign: "center"
                                  }}
                                  type="text"
                                  name="cert_tpl"
                                  placeholder="Certificado TPL"
                                  onChange={this.handleChange}
                                  defaultValue={this.state.cert_tpl}
                                />
                                <span
                                  className="bolder"
                                  style={{ color: "#e60000" }}
                                >
                                  {this.state.errors["competition/cert_tpl"]}
                                </span>
                                <div className="espaco_span" />

                                <label>
                                  Código Certificado
                                  <span
                                    className="bolder"
                                    style={{ color: "#e60000" }}
                                  >
                                    {" (*)"}
                                  </span>
                                </label>
                                <input
                                  style={{
                                    border: this.state.errors[
                                      "competition/cert_code"
                                    ]
                                      ? border_erro
                                      : "",
                                    textAlign: "center"
                                  }}
                                  type="text"
                                  name="codigo_certificado"
                                  placeholder="Código Certificado"
                                  onChange={this.handleChange}
                                  defaultValue={this.state.codigo_certificado}
                                />
                                <span
                                  className="bolder"
                                  style={{ color: "#e60000" }}
                                >
                                  {this.state.errors["competition/cert_code"]}
                                </span>
                              </div>
                              <div className="espaco_span" />
                              <span
                                className="bolder"
                                style={{ color: "#e60000" }}
                              >
                                {" (*)"}
                              </span>{" "}
                              <span className="bolder">
                                {" "}
                                Campos Obrigatórios
                              </span>
                            </div>
                          </TabPanel>
                          */}
                        </Tabs>
                      </div>
                    </form>
                  </div>
                </div>
              );
            })}
          </div>
        );
      }
    }
  }
}

export default Formulario_Edicao_Competicao;
