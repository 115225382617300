import React, { Component } from "react";
import axios from "axios";

let REACT_APP_API_URL;

if (process.env.REACT_APP_ESTADO === "Deploy") {
  REACT_APP_API_URL = process.env.REACT_APP_API_URL_PROD;
} //Development
else {
  REACT_APP_API_URL = process.env.REACT_APP_API_URL_DEV;
}

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: null,
      password: null,
      Dados_Validados: "2", // null, //false,

      //Lista Para os Erros de Validação:
      errors: {}
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  } //Fecha Construtor da Class

  handleChange(event) {
    this.setState(
      { [event.target.name]: event.target.value },

      //Sempre Que Acontecer o onChange, Vai Acontecer Esta Funcao de Callback (Para Tornar Isto o Mais Sincrono Possivel).
      //Nao Pode Conter "event.preventDefault()". Nem "()"
      this.handleValidation
    );
  }

  handleSubmit(event) {
    event.preventDefault();

    this.Verifica_Dados_Login();
  }

  //Verifica se os Dados de Login Estao Certos. Se Estiverem, Dados_Validados=true e Faz Re-Render (Como Alterou o State Desta Variavel)
  Verifica_Dados_Login() {
    axios
      .post(REACT_APP_API_URL + "/login/", {
        username: this.state.username,
        password: this.state.password
      })
      .then(res => {
        console.log(res);
        if (res.data.token !== undefined) {
          localStorage.setItem("Token", res.data.token); //localStorage.setItem("Token", res.data.token);
          localStorage.setItem("Tipo", res.data.tipo);
          //localStorage.setItem("ID", res.data.id);
          /*
          localStorage.setItem("Login", true);
          localStorage.setItem("Mostrar_Notificacoes_Atletas_Adiantados", true);
          localStorage.setItem("Mostrar_Notificacoes_Atletas_Atrasados", true);
          localStorage.setItem("Mostrar_Notificacoes_Ordem_Checkpoints", true);
          localStorage.setItem("Mostrar_Notificacoes_Tempos_Depois_Desistencia_Atletas",true);
          localStorage.setItem("Mostrar_Notificacoes_Podio_Atletas_Regionais",true);
          localStorage.setItem("Mostrar_Notificacoes_Podio_Atletas_Portugal",true);
          localStorage.setItem("Mostrar_Notificacoes_Podio_Atletas_Mundo",true);
          */
        }
        this.setState({ Dados_Validados: res.data.success });
        if (res.data.success === false) {
          let errors = {};
          errors["dados_login"] = "*Erro: Dados de Login Inválidos";
          this.setState({ errors: errors });
        }
      });
  }

  render() {
    if (this.state.Dados_Validados === true) {
      this.props.history.push({
        pathname: "/eventos",
        state: {
          Fez_Login: true,
          Fez_Insercao_Evento: false,
          Fez_Edicao_Evento: false,
          Faz_Refresh: true,
          Fez_Copia_Evento: false,
          Fez_Eliminacao_Evento: false
        }
      });
    } else {
      if (
        localStorage.getItem("Token") !== null &&
        localStorage.getItem("Token") !== undefined
      ) {
        this.props.history.push({
          pathname: "/eventos",
          state: {
            Fez_Login: false,
            Fez_Insercao_Evento: false,
            Fez_Edicao_Evento: false,
            Faz_Refresh: true,
            Fez_Copia_Evento: false,
            Fez_Eliminacao_Evento: false
          }
        });
      }
    }

    const border_erro = "1px solid #ff0000";
    let border_dados_login = "";

    if (this.state.errors["dados_login"]) {
      border_dados_login = border_erro;
    }

    return (
      <div>
        {/*<h1 className="titulos">Login</h1>*/}
        {/*<div className="centra_form_login"></div>*/}
        <div className="form_login">
          <form className="login-form" onSubmit={this.handleSubmit}>
            <div>
              <label>Nome Utilizador</label>
              <input
                style={{ border: border_dados_login, textAlign: "center" }}
                type="text"
                name="username"
                //placeholder="Nome Utilizador"
                onChange={this.handleChange}
              />
              <div className="espaco_span" />
              <label>Password</label>
              <input
                style={{ border: border_dados_login, textAlign: "center" }}
                type="password"
                name="password"
                //placeholder="Password"
                onChange={this.handleChange}
              />
              <span className="bolder" style={{ color: "#e60000" }}>
                {this.state.errors["dados_login"]}
              </span>
            </div>
            <div className="espaco_span" />
            <button type="submit">Login</button>
          </form>
        </div>
      </div>
    );
  }
}

export default Login;
