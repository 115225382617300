import React, { Component } from "react";
import axios from "axios";
import Moment from "moment";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

//Funções Auxiliares:
import {
  //mostra_mensagem_erro,
  //ve_resultados,
  //Trata_Validacoes,
  isEmptyObject
} from "../Funcoes_Auxiliares/Funcoes_Auxiliares.js";

//Imports Para Drawer e Snackbar:
import Drawer from "../Drawer/Drawer.js";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import classNames from "classnames";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import green from "@material-ui/core/colors/green";
import amber from "@material-ui/core/colors/amber";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import WarningIcon from "@material-ui/icons/Warning";

let REACT_APP_API_URL;

if (process.env.REACT_APP_ESTADO === "Deploy") {
  REACT_APP_API_URL = process.env.REACT_APP_API_URL_PROD;
} //Development
else {
  REACT_APP_API_URL = process.env.REACT_APP_API_URL_DEV;
}

//Constantes Para Drawer e Snackbar:
const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const styles1 = theme => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.dark
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit
  },
  message: {
    display: "flex",
    alignItems: "center"
  }
});

MySnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(["success", "warning", "error", "info"]).isRequired
};

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

//Funções Para Drawer e Snackbar:
function MySnackbarContent(props) {
  const { classes, className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>
      ]}
      {...other}
    />
  );
}

class Ver_Perfil_Utilizador extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //Variaveis dos Props Recebidos:
      Link_Menu_Anterior: null,

      Dados_Utilizador: undefined,

      //Variaveis dos Dados do Formulário:
      nome: null,
      email: null,
      //tipo_utilizador: null,
      //id_organizacao: null,
      data_inscricao: null,

      nova_password: null,

      //Variavel Para Controlar o Redirect:
      Inserido_Sucesso: false, //false
      Fez_Alteracao_Password: false,

      //Lista Para os Erros de Validação:
      errors: {}
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  Mostra_Mensagem_Alteracao_Password_Sucesso = () => {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={this.state.Fez_Alteracao_Password}
        autoHideDuration={3000}
        onClose={this.handleClose_Alteracao_Password}
      >
        <MySnackbarContentWrapper
          onClose={this.handleClose_Alteracao_Password}
          variant="success"
          message="Password Alterada Com Sucesso!"
        />
      </Snackbar>
    );
  };

  //Funcoes Para a Mensagem de Login Com Sucesso na Snackbar
  handleClose_Alteracao_Password = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ Fez_Alteracao_Password: false });
  };

  handleValidation() {
    let errors = {};
    let formIsValid = false; //true;

    if (
      this.state.nova_password === null ||
      this.state.nova_password.length <= 0
    ) {
      errors["password"] = "*Password Inválida";
    }

    //if (errors.length > 0) {
    if (isEmptyObject(errors)) {
      formIsValid = true; //false;
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.handleValidation()) {
      const Token_Lido = localStorage.getItem("Token");
      axios
        .patch(
          REACT_APP_API_URL + "/update_utilizador_sistema2/", // +localStorage.getItem("ID"),
          {
            password: this.state.nova_password
          },
          {
            headers: { authorization: "Bearer " + Token_Lido }
          }
        )
        .then(res => {
          //console.log(res);
          this.setState({ Fez_Alteracao_Password: res.data.success });
        });
    }
  }

  Vai_Buscar_Dados() {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .get(
        REACT_APP_API_URL + "/devolve_dados_utilizador",
        // +localStorage.getItem("ID"),
        {
          headers: { authorization: "Bearer " + Token_Lido }
        }
      )
      .then(res => {
        this.setState({ Dados_Utilizador: res.data });
        const Dados_Utilizador_Lidos = res.data;
        this.setState({
          nome: Dados_Utilizador_Lidos[0].nome,
          email: Dados_Utilizador_Lidos[0].email,
          data_inscricao: Moment(
            Dados_Utilizador_Lidos[0].data_inscricao
          ).format("DD-MM-YYYY")
        });
      });
  }

  componentDidMount() {
    if (localStorage.getItem("Token") !== null) {
      this.Vai_Buscar_Dados();
    }
  }

  render() {
    if (localStorage.getItem("Token") === null) {
      this.props.history.push({
        pathname: "/"
      });
    }

    if (this.state.Dados_Utilizador === undefined) {
      return (
        <div className="centra_loading">
          <h1>Loading...</h1>
        </div>
      );
    } else {
      const border_erro = "1px solid #ff0000";

      return (
        <div>
          <Drawer
            Titulo_Menu="Meu Perfil"
            //Link_Menu_Anterior={this.state.Link_Menu_Anterior}
            history={this.props.history}
          />
          <div className="novas_margens_volta_novos_forms">
            <div className="centra_novos_forms">
              <form className="login-form" onSubmit={this.handleSubmit}>
                <div className="centra_titulos_tabs">
                  <Tabs>
                    <TabList>
                      <Tab
                        style={{
                          border: this.state.errors["password"]
                            ? border_erro
                            : ""
                        }}
                      >
                        Dados Utilizador
                      </Tab>
                      <button type="submit" style={{ marginLeft: "10px" }}>
                        Alterar Password
                      </button>
                    </TabList>
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    {/*****************************************************************************/}
                    <TabPanel>
                      <div className="form">
                        <div>
                          <label>Nova Password</label>

                          <input
                            style={{
                              border: this.state.errors["password"]
                                ? border_erro
                                : "",
                              textAlign: "center"
                            }}
                            type="password"
                            name="nova_password"
                            placeholder="Nova Password"
                            onChange={this.handleChange}
                          />
                          <span className="bolder" style={{ color: "#e60000" }}>
                            {this.state.errors["password"]}
                          </span>

                          <div className="espaco_span" />
                          <label>Nome Completo</label>
                          <input
                            style={{
                              backgroundColor: "#b3b3cc",
                              textAlign: "center"
                            }}
                            type="text"
                            defaultValue={this.state.nome}
                            readOnly
                          />
                          <div className="espaco_span" />
                          <label>Email</label>
                          <input
                            style={{
                              backgroundColor: "#b3b3cc",
                              textAlign: "center"
                            }}
                            type="text"
                            defaultValue={this.state.email}
                            readOnly
                          />
                          <div className="espaco_span" />
                          <label>Tipo de Utilizador</label>
                          <input
                            style={{
                              backgroundColor: "#b3b3cc",
                              textAlign: "center"
                            }}
                            type="text"
                            defaultValue={localStorage.getItem("Tipo")}
                            readOnly
                          />
                          <div className="espaco_span" />

                          <label>Data de Inscrição</label>
                          <input
                            style={{
                              backgroundColor: "#b3b3cc",
                              textAlign: "center"
                            }}
                            type="text"
                            defaultValue={this.state.data_inscricao}
                            readOnly
                          />
                        </div>
                      </div>
                      {/*}
                        <div className="form">
                          <button type="submit">Adicionar Resultado</button>
                        </div>
                              */}
                    </TabPanel>
                  </Tabs>
                </div>
              </form>
            </div>
          </div>
          {this.Mostra_Mensagem_Alteracao_Password_Sucesso()}
        </div>
      );
    }
  }
}

export default Ver_Perfil_Utilizador;
