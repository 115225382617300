import React, { Component } from "react";
//import { Link } from "react-router-dom";
import axios from "axios";
import ReactPaginate from "react-paginate";
import Drawer from "../Drawer/Drawer.js";

//Funções Auxiliares:
import {
  mostra_mensagem_erro,
  ve_resultados
} from "../Funcoes_Auxiliares/Funcoes_Auxiliares.js";

let REACT_APP_API_URL;

if (process.env.REACT_APP_ESTADO === "Deploy") {
  REACT_APP_API_URL = process.env.REACT_APP_API_URL_PROD;
} //Development
else {
  REACT_APP_API_URL = process.env.REACT_APP_API_URL_DEV;
}

class Tabela_Lista_Erros_Inscricoes_Atletas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Link_Tabela_Anterior: null,
      Link_Menu_Anterior: null,
      items: undefined, //[],
      Headers_Colunas: [],
      ID_Evento: null,
      ID_Prova: null,
      Nome_Competicao: null,
      Nome_Evento: null,
      Nome_Prova: null,

      //Variaveis Para a Paginação:
      total_items: 0,
      offset: 0,
      perPage: 10,
      pageCount: null,

      //Variavel Controlo de Erro na Leitura dos Props
      Mostra_Erro: false,

      //Variavel de Controlo Para Evitar o Bug da Paginação Não Aparecer
      Faz_Refresh: false
    };

    if (props.location.state === undefined) {
      this.state.Mostra_Erro = true;
    } else {
      if (props.location.state.Link_Tabela_Anterior === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Link_Tabela_Anterior =
          props.location.state.Link_Tabela_Anterior;
      }
      if (props.location.state.Link_Menu_Anterior === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Link_Menu_Anterior = props.location.state.Link_Menu_Anterior;
      }
      if (props.location.state.ID_Evento === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.ID_Evento = props.location.state.ID_Evento;
      }
      if (props.location.state.ID_Prova === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.ID_Prova = props.location.state.ID_Prova;
      }
      if (props.location.state.Faz_Refresh === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Faz_Refresh = props.location.state.Faz_Refresh;
      }
      if (props.location.state.Nome_Evento === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Nome_Evento = props.location.state.Nome_Evento;
      }
      if (props.location.state.Nome_Prova === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Nome_Prova = props.location.state.Nome_Prova;
      }
    }

    this.handlePageClick = this.handlePageClick.bind(this);
    this.handleChange = this.handleChange.bind(this);

    //Metodos Para Ordenações da Tabela
    this.compareBy.bind(this);
    this.sortBy.bind(this);
    this.compareByNumber.bind(this);
  } //Fecha Construtor da Class

  compareBy(key) {
    return function(a, b) {
      if (a[key] < b[key]) return -1;
      if (a[key] > b[key]) return 1;
      return 0;
    };
  }

  compareByNumber(key) {
    return function(a, b) {
      return parseInt(a[key]) - parseInt(b[key]);
    };
  }

  sortBy(key) {
    let arrayCopy = [...this.state.items];
    if (key === "numero_linha_ficheiro") {
      arrayCopy.sort(this.compareByNumber(key));
    } else {
      arrayCopy.sort(this.compareBy(key));
    }
    this.setState({ items: arrayCopy });
  }

  handlePageClick = data => {
    let selected1 = data.selected;
    let offset1 = Math.ceil(selected1 * this.state.perPage);

    this.setState({ offset: offset1 }, () => {
      this.Vai_Buscar_Dados();
    });
  };

  Vai_Buscar_Dados() {
    const Token_Lido = localStorage.getItem("Token");
    if (this.state.perPage > 0) {
      axios
        .get(
          REACT_APP_API_URL +
            "/lista_erros_inscricoes_atletas/" +
            this.state.ID_Prova +
            "/" +
            this.state.perPage +
            "/" +
            this.state.offset,
          {
            headers: { authorization: "Bearer " + Token_Lido }
          }
        )
        .then(res => {
          console.log(res);
          this.setState({ items: res.data });
          this.setState({
            pageCount: Math.ceil(this.state.total_items / this.state.perPage)
          });
        });
    } else {
      axios
        .get(
          REACT_APP_API_URL +
            "/lista_erros_inscricoes_atletas/" +
            this.state.ID_Prova,
          {
            headers: { authorization: "Bearer " + Token_Lido }
          }
        )
        .then(res => {
          console.log(res);
          this.setState({ items: res.data });
          this.setState({ pageCount: 1 });
        });
    }
  }

  Vai_Buscar_Nome_Competicao() {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .get(REACT_APP_API_URL + "/devolve_nome_prova/" + this.state.ID_Prova, {
        headers: { authorization: "Bearer " + Token_Lido }
      })
      .then(res => {
        console.log(res);
        this.setState({ Nome_Competicao: res.data[0].long_name });
      });
  }

  Vai_Buscar_Numero_Erros_Inscricoes_Atletas() {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .get(
        REACT_APP_API_URL +
          "/devolve_numero_erros_inscricoes_atletas/" +
          this.state.ID_Prova,
        {
          headers: { authorization: "Bearer " + Token_Lido }
        }
      )
      .then(res => {
        console.log(res);
        this.setState({ total_items: res.data[0].Total });
      });
  }

  componentDidMount() {
    if (localStorage.getItem("Token") !== null) {
    this.Vai_Buscar_Nome_Competicao();
    this.Vai_Buscar_Numero_Erros_Inscricoes_Atletas();
    this.Vai_Buscar_Dados();}
  } //Fecha Procedimento Que Acontece Depois do Output do Componente Foi Rendered

  //Actualiza os Dados Depois de Acontecer um Redirect Para Este Componente
  componentDidUpdate() {
    //nextProps) {
    //if (nextProps.location.pathname !== this.props.location.pathname) {
    if (this.state.Faz_Refresh) {
      this.Vai_Buscar_Nome_Competicao();
      this.Vai_Buscar_Numero_Erros_Inscricoes_Atletas();
      this.Vai_Buscar_Dados();
      this.setState({ Faz_Refresh: false });
    }
  }

  /*
  //Actualiza os Dados Depois de Acontecer um Redirect Para Esta Tabela
  componentDidUpdate() {
    this.Vai_Buscar_Numero_Erros_Inscricoes_Atletas();
    this.Vai_Buscar_Dados();
  }
  */

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value }, () => {
      this.Vai_Buscar_Dados();
    });
  }

  render() {
    if (localStorage.getItem("Token") === null) {
      this.props.history.push({
        pathname: "/"
      });
    }

    //Verifica Se Existe Erro. Se Houver Mostra Mensagem de Erro
    if (this.state.Mostra_Erro === true) {
      return (
        <div>
          <Drawer
            Titulo_Menu="" //"Erro"
            //Link_Menu_Anterior={this.props.location.pathname}
              history={this.props.history}
          />
          {mostra_mensagem_erro()}
        </div>
      );
    } else {
      if (
        this.state.items === undefined ||
        this.state.Nome_Competicao === undefined ||
        this.state.total_items === undefined
      ) {
        return (
          <div className="centra_loading">
            <h1>Loading...</h1>
          </div>
        );
      } else {
        if (this.state.items.length > 0) {
          return (
            <div>
              <Drawer
                Titulo_Menu="Lista Erros Importação Inscrições Atletas"
                Link_Tabela_Anterior={this.state.Link_Tabela_Anterior}
                Link_Menu_Anterior={this.state.Link_Menu_Anterior}
                ID_Evento={this.state.ID_Evento}
                ID_Prova={this.state.ID_Prova}
                Nome_Evento={this.state.Nome_Evento}
                Nome_Prova={this.state.Nome_Prova}
                history={this.props.history}
              />
              <div className="novo_centra_tabelas">
                <tabela>
                  <thead>
                    <tr>
                      <th colSpan="2">
                        Número Total de Erros das Inscrições dos Atletas:{" "}
                        {this.state.total_items}
                      </th>
                    </tr>
                  </thead>
                  <thead>
                    <tr>
                      <th onClick={() => this.sortBy("numero_linha_ficheiro")}>
                        Numero Linha Ficheiro{" "}
                        <i
                          className="fa fa-angle-down fa-sm" /*fa fa-sort fa-sm*/
                        />
                      </th>
                      <th onClick={() => this.sortBy("erro")}>
                        Erro{" "}
                        <i
                          className="fa fa-angle-down fa-sm" /*fa fa-sort fa-sm*/
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.items.map(linha2 => {
                      return (
                        <tr>
                          <td>{linha2.numero_linha_ficheiro}</td>
                          <td>{linha2.erro}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </tabela>
                {
                  <div style={{ marginTop: "10px" }}>
                    <label>Resultados Por Página</label>{" "}
                    <select
                      name="perPage"
                      id="perPage"
                      onChange={this.handleChange}
                    >
                      <option value="10"> 10 </option>
                      <option value="25"> 25 </option>
                      <option value="50"> 50 </option>
                      <option value="100"> 100 </option>
                      <option value="0">Todos </option>
                    </select>
                  </div>
                }
                <div>
                  <ReactPaginate
                    previousLabel={"Anterior"}
                    nextLabel={"Próximo"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            </div>
          );
        } else {
          return (
            <div>
              <Drawer
                Titulo_Menu="Lista Erros Importação Inscrições Atletas"
                Link_Tabela_Anterior={this.state.Link_Tabela_Anterior}
                Link_Menu_Anterior={this.state.Link_Menu_Anterior}
                ID_Evento={this.state.ID_Evento}
                ID_Prova={this.state.ID_Prova}
                Nome_Evento={this.state.Nome_Evento}
                Nome_Prova={this.state.Nome_Prova}
                history={this.props.history}
              />
              {ve_resultados()}
            </div>
          );
        }
      }
    }
  }
}

export default Tabela_Lista_Erros_Inscricoes_Atletas;
