import React, { Component } from "react";
//import { Link } from "react-router-dom";
import axios from "axios";
import ReactPaginate from "react-paginate";

//Imports Para Drawer e Snackbar:
import Drawer from "../Drawer/Drawer.js";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import classNames from "classnames";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import green from "@material-ui/core/colors/green";
import amber from "@material-ui/core/colors/amber";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import WarningIcon from "@material-ui/icons/Warning";

//imports Para o Dialog de Logout
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
//import DialogContent from '@material-ui/core/DialogContent';
//import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from "@material-ui/core/DialogTitle";

//Funções Auxiliares:
import {
  mostra_mensagem_erro
  //ve_resultados
} from "../Funcoes_Auxiliares/Funcoes_Auxiliares.js";

let REACT_APP_API_URL;

if (process.env.REACT_APP_ESTADO === "Deploy") {
  REACT_APP_API_URL = process.env.REACT_APP_API_URL_PROD;
} //Development
else {
  REACT_APP_API_URL = process.env.REACT_APP_API_URL_DEV;
}

//Constantes Para Drawer e Snackbar:
const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const styles1 = theme => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.dark
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit
  },
  message: {
    display: "flex",
    alignItems: "center"
  }
});

MySnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(["success", "warning", "error", "info"]).isRequired
};

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

//Funções Para Drawer e Snackbar:
function MySnackbarContent(props) {
  const { classes, className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>
      ]}
      {...other}
    />
  );
}

class Tabela_Lista_Atletas_Inscritos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Link_Menu_Anterior: null,
      ID_Evento: null,
      items: undefined, //[],
      Headers_Colunas: [],
      Nome_Evento: null,

      Dados_Nome_Prova: null,

      //Variavel de Controlo Para a Mensagem de Inscrição Com Sucesso na Snackbar:
      Fez_Edicao_Inscricao_Atleta_Sistema: false,
      Fez_Lancamento_Desistencia_Sucesso: false,
      Fez_Eliminacao_Inscricao_Sucesso: false,

      //Variaveis Para a Paginação:
      total_items: 0,
      offset: 0,
      perPage: 10,
      pageCount: null,

      //Variaveis de Controlo dos Dialogs:
      open_dialog_desistencia: false,
      open_dialog_elimina_inscricao: false,

      //Variavel de Controlo Para Evitar o Bug da Paginação Não Aparecer
      Faz_Refresh: false,

      //Variaveis Para o Filtro:
      Nome_Atleta_Filtro: null,
      Dorsal_Filtro: null,
      Clube_Filtro: null,
      Escalao_Filtro: null,
      Pais_Filtro: null,
      Competicao_Filtro: null,
      Estado_Filtro: null,

      //Variaveis Para os Selects dos Filtros:
      Escaloes_Lista_Atletas_Inscritos_Evento: undefined,
      Paises_Lista_Atletas_Inscritos_Evento: undefined,
      Competicoes_Lista_Atletas_Inscritos_Evento: undefined,
      Estados_Lista_Atletas_Inscritos_Evento: undefined
    };

    if (props.location.state === undefined) {
      this.state.Mostra_Erro = true;
    } else {
      if (props.location.state.Link_Menu_Anterior === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Link_Menu_Anterior = props.location.state.Link_Menu_Anterior;
      }
      if (props.location.state.ID_Evento === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.ID_Evento = props.location.state.ID_Evento;
      }
      if (
        props.location.state.Fez_Edicao_Inscricao_Atleta_Sistema === undefined
      ) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Fez_Edicao_Inscricao_Atleta_Sistema =
          props.location.state.Fez_Edicao_Inscricao_Atleta_Sistema;
      }
      if (props.location.state.Faz_Refresh === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Faz_Refresh = props.location.state.Faz_Refresh;
      }
      if (
        props.location.state.Fez_Lancamento_Desistencia_Sucesso === undefined
      ) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Fez_Lancamento_Desistencia_Sucesso =
          props.location.state.Fez_Lancamento_Desistencia_Sucesso;
      }
      if (props.location.state.Fez_Eliminacao_Inscricao_Sucesso === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Fez_Eliminacao_Inscricao_Sucesso =
          props.location.state.Fez_Eliminacao_Inscricao_Sucesso;
      }
      if (props.location.state.Nome_Evento === undefined) {
        this.state.Mostra_Erro = true;
      } else {
        this.state.Nome_Evento = props.location.state.Nome_Evento;
      }
    }

    this.handleChange = this.handleChange.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);

    //Metodos Para Ordenações da Tabela
    this.compareBy.bind(this);
    this.sortBy.bind(this);
    this.compareByNumber.bind(this);
  } //Fecha Construtor da Class

  /*
  handleClickOpenDialogDesistencia = (
    id_atleta_escolhido,
    id_prova_escolhida
  ) => {
    this.setState({
      open_dialog_desistencia: true,
      ID_Atleta_Escolhido: id_atleta_escolhido,
      ID_Prova_Escolhida: id_prova_escolhida
    });
  };

  handleClickOpenDialogEliminaInscricao = () => {
    this.setState({ open_dialog_elimina_inscricao: true });
  };

  handleCloseDialogDesistencia = () => {
    this.setState({ open_dialog_desistencia: false });
  };

  handleCloseDialogEliminaInscricao = () => {
    this.setState({ open_dialog_elimina_inscricao: false });
  };

  //Funcoes Para a Mensagem de Login Com Sucesso na Snackbar
  handleClose_Lancamento_Desistencia = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ Lancamento_Desistencia_Sucesso: false });
  };

  handleDesistencia = () => {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .patch(
        REACT_APP_API_URL +
          "/lancamento_desistencia_atleta/" +
          this.state.ID_Atleta_Escolhido +
          "/" +
          this.state.ID_Prova_Escolhida,
        {}, //Mesmo Que Nao Sejam Enviados Parametros no Body, Tem de Ser Incluido "{},"
        {
          headers: { authorization: "Bearer " + Token_Lido }
        }
      )
      .then(res => {
        this.setState({
          open_dialog_desistencia: false,
          Lancamento_Desistencia_Sucesso: res.data.success
        });
      });
  };

  handleEliminaInscricao = () => {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .patch(
        REACT_APP_API_URL +
          "/lancamento_desistencia_atleta/" +
          this.state.ID_Atleta +
          "/" +
          this.state.ID_Competicao,
        {
          headers: { authorization: "Bearer " + Token_Lido }
        }
      )
      .then(res => {
        this.setState({
          open_dialog_elimina_inscricao: false,
          Lancamento_Desistencia_Sucesso: res.data.success
        });
      });
  };
  */

  compareBy(key) {
    return function(a, b) {
      if (a[key] < b[key]) return -1;
      if (a[key] > b[key]) return 1;
      return 0;
    };
  }

  compareByNumber(key) {
    return function(a, b) {
      return parseInt(a[key]) - parseInt(b[key]);
    };
  }

  sortBy(key) {
    //event.preventDefault();
    let arrayCopy = [...this.state.items];
    if (key === "dorsal_number") {
      arrayCopy.sort(this.compareByNumber(key));
      //arrayCopy.sort(function(a, b) {return parseInt(a.dorsal_number) - parseInt(b.dorsal_number);});
    } else {
      arrayCopy.sort(this.compareBy(key));
    }
    this.setState({ items: arrayCopy });
  }

  handlePageClick = data => {
    let selected1 = data.selected;
    let offset1 = Math.ceil(selected1 * this.state.perPage);

    this.setState({ offset: offset1 }, () => {
      this.Vai_Buscar_Dados();
    });
  };

  Vai_Buscar_Dados() {
    const Token_Lido = localStorage.getItem("Token");

    let Nome_Atleta_Filtro_Enviar;
    let Dorsal_Filtro_Enviar;
    let Clube_Filtro_Enviar;
    let Escalao_Filtro_Enviar;
    let Pais_Filtro_Enviar;
    let Competicao_Filtro_Enviar;
    let Estado_Filtro_Enviar;

    if (this.state.Nome_Atleta_Filtro === "") {
      Nome_Atleta_Filtro_Enviar = null;
    } else {
      Nome_Atleta_Filtro_Enviar = this.state.Nome_Atleta_Filtro;
    }
    if (this.state.Dorsal_Filtro === "") {
      Dorsal_Filtro_Enviar = null;
    } else {
      Dorsal_Filtro_Enviar = this.state.Dorsal_Filtro;
    }
    if (this.state.Clube_Filtro === "") {
      Clube_Filtro_Enviar = null;
    } else {
      Clube_Filtro_Enviar = this.state.Clube_Filtro;
    }
    if (this.state.Escalao_Filtro === "") {
      Escalao_Filtro_Enviar = null;
    } else {
      Escalao_Filtro_Enviar = this.state.Escalao_Filtro;
    }
    if (this.state.Pais_Filtro === "") {
      Pais_Filtro_Enviar = null;
    } else {
      Pais_Filtro_Enviar = this.state.Pais_Filtro;
    }
    if (this.state.Competicao_Filtro === "") {
      Competicao_Filtro_Enviar = null;
    } else {
      Competicao_Filtro_Enviar = this.state.Competicao_Filtro;
    }
    if (this.state.Estado_Filtro === "") {
      Estado_Filtro_Enviar = null;
    } else {
      Estado_Filtro_Enviar = this.state.Estado_Filtro;
    }

    if (this.state.perPage > 0) {
      axios
        .get(
          REACT_APP_API_URL +
            "/lista_atletas_inscritos_evento_filtro/" +
            this.state.ID_Evento +
            "/" +
            this.state.perPage +
            "/" +
            this.state.offset +
            "/" +
            Nome_Atleta_Filtro_Enviar +
            "/" +
            //this.state.Dorsal_Filtro +
            Dorsal_Filtro_Enviar +
            "/" +
            //this.state.Clube_Filtro +
            Clube_Filtro_Enviar +
            "/" +
            //this.state.Escalao_Filtro +
            Escalao_Filtro_Enviar +
            "/" +
            //this.state.Pais_Filtro,
            Pais_Filtro_Enviar +
            "/" +
            //this.state.Pais_Filtro,
            Competicao_Filtro_Enviar +
            "/" +
            //this.state.Estado_Filtro +
            Estado_Filtro_Enviar,
          {
            headers: { authorization: "Bearer " + Token_Lido }
          }
        )
        .then(res => {
          console.log(res);
          this.setState({ items: res.data });
          this.setState({
            pageCount: Math.ceil(this.state.total_items / this.state.perPage)
          });
        });
    } else {
      axios
        .get(
          REACT_APP_API_URL +
            "/lista_atletas_inscritos_evento_filtro/" +
            this.state.ID_Evento +
            "/" +
            Nome_Atleta_Filtro_Enviar +
            "/" +
            //this.state.Dorsal_Filtro +
            Dorsal_Filtro_Enviar +
            "/" +
            //this.state.Clube_Filtro +
            Clube_Filtro_Enviar +
            "/" +
            //this.state.Escalao_Filtro +
            Escalao_Filtro_Enviar +
            "/" +
            //this.state.Pais_Filtro,
            Pais_Filtro_Enviar +
            "/" +
            //this.state.Pais_Filtro,
            Competicao_Filtro_Enviar +
            "/" +
            //this.state.Estado_Filtro +
            Estado_Filtro_Enviar,
          {
            headers: { authorization: "Bearer " + Token_Lido }
          }
        )
        .then(res => {
          console.log(res);
          this.setState({ items: res.data });
          this.setState({ pageCount: 1 });
        });
    }
  }

  Vai_Buscar_Numero_Atletas_Inscritos_Evento() {
    const Token_Lido = localStorage.getItem("Token");

    let Nome_Atleta_Filtro_Enviar;
    let Dorsal_Filtro_Enviar;
    let Clube_Filtro_Enviar;
    let Escalao_Filtro_Enviar;
    let Pais_Filtro_Enviar;
    let Competicao_Filtro_Enviar;
    let Estado_Filtro_Enviar;

    if (this.state.Nome_Atleta_Filtro === "") {
      Nome_Atleta_Filtro_Enviar = null;
    } else {
      Nome_Atleta_Filtro_Enviar = this.state.Nome_Atleta_Filtro;
    }
    if (this.state.Dorsal_Filtro === "") {
      Dorsal_Filtro_Enviar = null;
    } else {
      Dorsal_Filtro_Enviar = this.state.Dorsal_Filtro;
    }
    if (this.state.Clube_Filtro === "") {
      Clube_Filtro_Enviar = null;
    } else {
      Clube_Filtro_Enviar = this.state.Clube_Filtro;
    }
    if (this.state.Escalao_Filtro === "") {
      Escalao_Filtro_Enviar = null;
    } else {
      Escalao_Filtro_Enviar = this.state.Escalao_Filtro;
    }
    if (this.state.Pais_Filtro === "") {
      Pais_Filtro_Enviar = null;
    } else {
      Pais_Filtro_Enviar = this.state.Pais_Filtro;
    }
    if (this.state.Competicao_Filtro === "") {
      Competicao_Filtro_Enviar = null;
    } else {
      Competicao_Filtro_Enviar = this.state.Competicao_Filtro;
    }
    if (this.state.Estado_Filtro === "") {
      Estado_Filtro_Enviar = null;
    } else {
      Estado_Filtro_Enviar = this.state.Estado_Filtro;
    }

    axios
      .get(
        REACT_APP_API_URL +
          "/devolve_numero_atletas_inscritos_evento/" +
          this.state.ID_Evento +
          "/" +
          Nome_Atleta_Filtro_Enviar +
          "/" +
          //this.state.Dorsal_Filtro +
          Dorsal_Filtro_Enviar +
          "/" +
          //this.state.Clube_Filtro +
          Clube_Filtro_Enviar +
          "/" +
          //this.state.Escalao_Filtro +
          Escalao_Filtro_Enviar +
          "/" +
          //this.state.Pais_Filtro,
          Pais_Filtro_Enviar +
          "/" +
          //this.state.Pais_Filtro,
          Competicao_Filtro_Enviar +
          "/" +
          //this.state.Estado_Filtro +
          Estado_Filtro_Enviar,
        {
          headers: { authorization: "Bearer " + Token_Lido }
        }
      )
      .then(res => {
        console.log(res);
        this.setState({ total_items: res.data[0].Total });
      });
  }

  //Versao em Que a Data de Inicio da Prova=init_date
  Vai_Buscar_Escaloes_Lista_Atletas_Inscritos_Evento() {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .get(
        REACT_APP_API_URL +
          "/devolve_escaloes_lista_atletas_inscritos_evento/" +
          this.state.ID_Evento, //ID_Checkpoint
        {
          headers: { authorization: "Bearer " + Token_Lido }
        }
      )
      .then(res => {
        console.log(res);
        this.setState({ Escaloes_Lista_Atletas_Inscritos_Evento: res.data });
      });
  }

  //Versao em Que a Data de Inicio da Prova=init_date
  Vai_Buscar_Paises_Lista_Atletas_Inscritos_Evento() {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .get(
        REACT_APP_API_URL +
          "/devolve_paises_lista_atletas_inscritos_evento/" +
          this.state.ID_Evento, //ID_Checkpoint
        {
          headers: { authorization: "Bearer " + Token_Lido }
        }
      )
      .then(res => {
        console.log(res);
        this.setState({ Paises_Lista_Atletas_Inscritos_Evento: res.data });
      });
  }

  //Versao em Que a Data de Inicio da Prova=init_date
  Vai_Buscar_Competicoes_Lista_Atletas_Inscritos_Evento() {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .get(
        REACT_APP_API_URL +
          "/devolve_competicoes_lista_atletas_inscritos_evento/" +
          this.state.ID_Evento, //ID_Checkpoint
        {
          headers: { authorization: "Bearer " + Token_Lido }
        }
      )
      .then(res => {
        console.log(res);
        this.setState({ Competicoes_Lista_Atletas_Inscritos_Evento: res.data });
      });
  }

  //Versao em Que a Data de Inicio da Prova=init_date
  Vai_Buscar_Estados_Lista_Atletas_Inscritos_Evento() {
    const Token_Lido = localStorage.getItem("Token");
    axios
      .get(
        REACT_APP_API_URL +
          "/devolve_estados_lista_atletas_inscritos_evento/" +
          this.state.ID_Evento, //ID_Checkpoint
        {
          headers: { authorization: "Bearer " + Token_Lido }
        }
      )
      .then(res => {
        console.log(res);
        this.setState({ Estados_Lista_Atletas_Inscritos_Evento: res.data });
      });
  }

  componentDidMount() {
    if (localStorage.getItem("Token") !== null) {
      this.Vai_Buscar_Numero_Atletas_Inscritos_Evento();
      this.Vai_Buscar_Dados();

      this.Vai_Buscar_Escaloes_Lista_Atletas_Inscritos_Evento();
      this.Vai_Buscar_Paises_Lista_Atletas_Inscritos_Evento();
      this.Vai_Buscar_Competicoes_Lista_Atletas_Inscritos_Evento();
      this.Vai_Buscar_Estados_Lista_Atletas_Inscritos_Evento();
    }
  } //Fecha Procedimento Que Acontece Depois do Output do Componente Foi Rendered

  //Actualiza os Dados Depois de Acontecer um Redirect Para Este Componente
  componentDidUpdate() {
    //nextProps) {
    //if (nextProps.location.pathname !== this.props.location.pathname) {
    if (this.state.Faz_Refresh) {
      this.Vai_Buscar_Numero_Atletas_Inscritos_Evento();
      this.Vai_Buscar_Dados();
      this.setState({ Faz_Refresh: false });
    }
  }

  /*
  //Actualiza os Dados Depois de Acontecer um Redirect Para Esta Tabela
  componentDidUpdate() {
    this.Vai_Buscar_Numero_Atletas_Inscritos_Evento();
    this.Vai_Buscar_Dados();
  }
  */

  //Funcoes Para a Mensagem de Edicao Com Sucesso na Snackbar
  handleCloseFezEdicao = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ Fez_Edicao_Inscricao_Atleta_Sistema: false });
  };

  Mostra_Mensagem_Edicao_Sucesso = () => {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={this.state.Fez_Edicao_Inscricao_Atleta_Sistema}
        autoHideDuration={3000}
        onClose={this.handleCloseFezEdicao}
      >
        <MySnackbarContentWrapper
          onClose={this.handleCloseFezEdicao}
          variant="success"
          message="Inscrição Atleta Editada Com Sucesso!"
        />
      </Snackbar>
    );
  };

  //Funcoes Para a Mensagem de Edicao Com Sucesso na Snackbar
  handleClose_Lancamento_Desistencia = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ Fez_Lancamento_Desistencia_Sucesso: false });
  };

  Mostra_Mensagem_Lancamento_Desistencia_Sucesso = () => {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={this.state.Fez_Lancamento_Desistencia_Sucesso}
        autoHideDuration={3000}
        onClose={this.handleClose_Lancamento_Desistencia}
      >
        <MySnackbarContentWrapper
          onClose={this.handleClose_Lancamento_Desistencia}
          variant="success"
          message="Desistência Lançada Com Sucesso!"
        />
      </Snackbar>
    );
  };

  //Funcoes Para a Mensagem de Edicao Com Sucesso na Snackbar
  handleClose_Eliminacao_Inscricao = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ Fez_Eliminacao_Inscricao_Sucesso: false });
  };

  Mostra_Mensagem_Eliminacao_Inscricao_Sucesso = () => {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={this.state.Fez_Eliminacao_Inscricao_Sucesso}
        autoHideDuration={3000}
        onClose={this.handleClose_Eliminacao_Inscricao}
      >
        <MySnackbarContentWrapper
          onClose={this.handleClose_Eliminacao_Inscricao}
          variant="success"
          message="Inscrição Eliminada Com Sucesso!"
        />
      </Snackbar>
    );
  };

  handleChange(event) {
    /*
    this.setState({ [event.target.name]: event.target.value });
    this.Vai_Buscar_Dados();
    */
    this.setState({ [event.target.name]: event.target.value }, () => {
      this.Vai_Buscar_Numero_Atletas_Inscritos_Evento();
      this.Vai_Buscar_Dados();
    });
  }

  render() {
    if (localStorage.getItem("Token") === null) {
      this.props.history.push({
        pathname: "/"
      });
    }
    //Verifica Se Existe Erro. Se Houver Mostra Mensagem de Erro
    if (this.state.Mostra_Erro === true) {
      return (
        <div>
          <Drawer
            Titulo_Menu="" //"Erro"
            history={this.props.history}
          />
          {mostra_mensagem_erro()}
        </div>
      );
    } else {
      if (
        this.state.items === undefined ||
        this.state.total_items === undefined ||
        this.state.Escaloes_Lista_Atletas_Inscritos_Evento === undefined ||
        this.state.Paises_Lista_Atletas_Inscritos_Evento === undefined ||
        this.state.Competicoes_Lista_Atletas_Inscritos_Evento === undefined ||
        this.state.Estados_Lista_Atletas_Inscritos_Evento === undefined
      ) {
        return (
          <div className="centra_loading">
            <h1>Loading...</h1>
          </div>
        );
      } else {
        //if (this.state.items.length > 0) {
        return (
          <div>
            <Dialog
              open={this.state.open_dialog_elimina_inscricao}
              onClose={this.handleCloseDialogEliminaInscricao}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Tem a Certeza Que Deseja Eliminar a Inscrição?"}
              </DialogTitle>
              <DialogActions>
                <Button
                  onClick={this.handleCloseDialogEliminaInscricao}
                  color="primary"
                >
                  Cancelar
                </Button>
                <Button
                  onClick={this.handleEliminaInscricao}
                  color="primary"
                  autoFocus
                >
                  Eliminar
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={this.state.open_dialog_desistencia}
              onClose={this.handleCloseDialogDesistencia}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Tem a Certeza Que Deseja Lançar a Desistência?"}
              </DialogTitle>
              <DialogActions>
                <Button
                  onClick={this.handleCloseDialogDesistencia}
                  color="primary"
                >
                  Cancelar
                </Button>
                <Button
                  onClick={this.handleDesistencia}
                  color="primary"
                  autoFocus
                >
                  Sim
                </Button>
              </DialogActions>
            </Dialog>

            <Drawer
              Titulo_Menu="Lista Atletas Inscritos Evento"
              Link_Menu_Anterior={this.state.Link_Menu_Anterior}
              ID_Evento={this.state.ID_Evento}
              //ID_Prova={this.state.ID_Prova}
              Nome_Evento={this.state.Nome_Evento}
              history={this.props.history}
            />
            <div className="novo_centra_tabelas">
              <div
                className="novo_form_filtro"
                /*style={{ marginLeft: "10px" }}*/
              >
                <input
                  type="text"
                  name="Nome_Atleta_Filtro"
                  id="Nome_Atleta_Filtro"
                  onChange={this.handleChange}
                  defaultValue={this.state.Nome_Atleta_Filtro}
                  placeholder="Nome Atleta"
                  style={{ width: "290px" }}
                />
                <input
                  type="text"
                  name="Dorsal_Filtro"
                  id="Dorsal_Filtro"
                  onChange={this.handleChange}
                  defaultValue={this.state.Dorsal_Filtro}
                  placeholder="Dorsal"
                  style={{ width: "60px" }}
                />
                <input
                  type="text"
                  name="Clube_Filtro"
                  id="Clube_Filtro"
                  onChange={this.handleChange}
                  defaultValue={this.state.Clube_Filtro}
                  placeholder="Clube"
                  style={{ width: "280px" }}
                />
                {/*
                  <input
                    type="text"
                    name="Escalao_Filtro"
                    id="Escalao_Filtro"
                    onChange={this.handleChange}
                    defaultValue={this.state.Escalao_Filtro}
                    placeholder="Escalão"
                    style={{ width: "65px" }}
                  />
                  <input
                    type="text"
                    name="Pais_Filtro"
                    id="Pais_Filtro"
                    onChange={this.handleChange}
                    defaultValue={this.state.Pais_Filtro}
                    placeholder="País"
                    style={{ width: "130px" }}
                  />
                  <input
                    type="text"
                    name="Competicao_Filtro"
                    id="Competicao_Filtro"
                    onChange={this.handleChange}
                    defaultValue={this.state.Competicao_Filtro}
                    placeholder="Competição"
                    style={{ width: "130px" }}
                  />
                  <input
                    type="text"
                    name="Estado_Filtro"
                    id="Estado_Filtro"
                    onChange={this.handleChange}
                    defaultValue={this.state.Estado_Filtro}
                    placeholder="Estado"
                    style={{ width: "60px" }}
                  />
                  */}

                <select
                  value={this.state.Escalao_Filtro}
                  onChange={this.handleChange}
                  name="Escalao_Filtro"
                  id="Escalao_Filtro"
                >
                  <option value="" disabled selected>
                    Escalão
                  </option>
                  {this.state.Escaloes_Lista_Atletas_Inscritos_Evento.map(
                    escalao => {
                      return (
                        <option key={escalao.category} value={escalao.category}>
                          {escalao.category}
                        </option>
                      );
                    }
                  )}
                </select>
                <select
                  value={this.state.Pais_Filtro}
                  onChange={this.handleChange}
                  name="Pais_Filtro"
                  id="Pais_Filtro"
                >
                  <option value="" disabled selected>
                    País
                  </option>
                  {this.state.Paises_Lista_Atletas_Inscritos_Evento.map(
                    pais => {
                      return (
                        <option key={pais.country} value={pais.country}>
                          {pais.country}
                        </option>
                      );
                    }
                  )}
                </select>
                <select
                  value={this.state.Competicao_Filtro}
                  onChange={this.handleChange}
                  name="Competicao_Filtro"
                  id="Competicao_Filtro"
                >
                  <option value="" disabled selected>
                    Competição
                  </option>
                  {this.state.Competicoes_Lista_Atletas_Inscritos_Evento.map(
                    competicao => {
                      return (
                        <option
                          key={competicao.competition_name}
                          value={competicao.competition_name}
                        >
                          {competicao.competition_name}
                        </option>
                      );
                    }
                  )}
                </select>
                <select
                  value={this.state.Estado_Filtro}
                  onChange={this.handleChange}
                  name="Estado_Filtro"
                  id="Estado_Filtro"
                >
                  <option value="" disabled selected>
                    Estado
                  </option>
                  {this.state.Estados_Lista_Atletas_Inscritos_Evento.map(
                    estado => {
                      return (
                        <option
                          key={estado.athlete_status}
                          value={estado.athlete_status}
                        >
                          {estado.athlete_status}
                        </option>
                      );
                    }
                  )}
                </select>
              </div>

              <tabela /*style={{ marginLeft: "200px" }}*/>
                <thead>
                  <tr>
                    <th colSpan="8">
                      Número Total de Atletas Inscritos no Evento:{" "}
                      {this.state.total_items}
                    </th>
                  </tr>
                </thead>
                <thead>
                  <tr>
                    <th onClick={() => this.sortBy("athlete_name")}>
                      Nome{" "}
                      <i
                        className="fa fa-angle-down fa-sm" /*fa fa-sort fa-sm*/
                      />
                    </th>
                    <th onClick={() => this.sortBy("dorsal_number")}>
                      Dorsal{" "}
                      <i
                        className="fa fa-angle-down fa-sm" /*fa fa-sort fa-sm*/
                      />
                    </th>
                    <th onClick={() => this.sortBy("club")}>
                      Clube{" "}
                      <i
                        className="fa fa-angle-down fa-sm" /*fa fa-sort fa-sm*/
                      />
                    </th>
                    <th onClick={() => this.sortBy("category")}>
                      Escalão{" "}
                      <i
                        className="fa fa-angle-down fa-sm" /*fa fa-sort fa-sm*/
                      />
                    </th>
                    <th onClick={() => this.sortBy("country")}>
                      País{" "}
                      <i
                        className="fa fa-angle-down fa-sm" /*fa fa-sort fa-sm*/
                      />
                    </th>
                    {/*<th onClick={() => this.sortBy("long_name")}>*/}
                    <th onClick={() => this.sortBy("name")}>
                      Competição{" "}
                      <i
                        className="fa fa-angle-down fa-sm" /*fa fa-sort fa-sm*/
                      />
                    </th>
                    <th onClick={() => this.sortBy("insc_status")}>
                      Estado{" "}
                      <i
                        className="fa fa-angle-down fa-sm" /*fa fa-sort fa-sm*/
                      />
                    </th>
                    {/*<th>Acção</th>*/}
                  </tr>
                </thead>
                <tbody>
                  {this.state.items.map(linha2 => {
                    return (
                      <tr>
                        <td style={{ width: "300px" }}>
                          {linha2.athlete_name}
                        </td>
                        <td>{linha2.dorsal_number}</td>
                        <td style={{ width: "300px" }}>{linha2.club}</td>
                        <td>{linha2.category}</td>
                        <td style={{ width: "150px" }}>{linha2.country}</td>
                        <td style={{ width: "150px" }}>
                          {
                            //linha2.long_name
                            linha2.name
                            //linha2.competition_id
                            //this.Vai_Buscar_Nome_Prova(linha2.competition_id) //linha2.competition_id
                          }
                        </td>
                        <td>{linha2.insc_status}</td>
                        {/*<td>
                            <Link
                              to={{
                                pathname: "/lista_resultados_atleta",
                                state: {
                                  Link_Menu_Anterior: this.state
                                    .Link_Menu_Anterior,
                                  Link_Tabela_Anterior: this.props.location
                                    .pathname,
                                  ID_Evento: this.state.ID_Evento,
                                  ID_Prova: linha2.competition_id, //null,
                                  ID_Atleta: linha2.athlete_id,
                                  Fez_Edicao_Resultado: false,
                                  Fez_Insercao_Resultado: false,
                                  Faz_Refresh: true,
                                  Nome_Evento: this.state.Nome_Evento,
                                  Nome_Prova: linha2.long_name,
                                  Nome_Atleta: linha2.athlete_name
                                }
                              }}
                              title="Resultados"
                            >
                              <i className="fa fa-list-ul fa-lg espaco_icons" />
                            </Link>{" "}
                            <Link
                              to={{
                                pathname: "/editar_inscricao_atleta",
                                state: {
                                  id_inscricao: linha2.inscription_id,
                                  Link_Tabela_Anterior: this.props.location
                                    .pathname,
                                  Link_Menu_Anterior: this.state
                                    .Link_Menu_Anterior,
                                  ID_Evento: this.state.ID_Evento,
                                  ID_Prova: null,
                                  Nome_Evento: this.state.Nome_Evento,
                                  Nome_Prova: linha2.long_name,
                                  Nome_Atleta: linha2.athlete_name
                                }
                              }}
                              title="Editar Inscrição"
                            >
                              <i className="fa fa-edit fa-lg espaco_icons" />
                            </Link>
                          </td>*/}
                      </tr>
                    );
                  })}
                </tbody>
              </tabela>

              <div style={{ marginTop: "10px" }}>
                <label>Resultados Por Página</label>{" "}
                <select
                  name="perPage"
                  id="perPage"
                  onChange={this.handleChange}
                >
                  <option value="10"> 10 </option>
                  <option value="25"> 25 </option>
                  <option value="50"> 50 </option>
                  <option value="100"> 100 </option>
                  <option value="0">Todos </option>
                </select>
              </div>

              <div>
                <ReactPaginate
                  previousLabel={"Anterior"}
                  nextLabel={"Próximo"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              </div>
            </div>
            {this.Mostra_Mensagem_Edicao_Sucesso()}
            {this.Mostra_Mensagem_Lancamento_Desistencia_Sucesso()}
            {this.Mostra_Mensagem_Eliminacao_Inscricao_Sucesso()}
          </div>
        );
        /*} else {
          return (
            <div>
              <Drawer
                Titulo_Menu="Lista Atletas Inscritos Evento"
                Link_Menu_Anterior={this.state.Link_Menu_Anterior}
                ID_Evento={this.state.ID_Evento}
                //ID_Prova={this.state.ID_Prova}
                Nome_Evento={this.state.Nome_Evento}
                history={this.props.history}
              />
              {
                //ve_resultados()
              }

              <div className="centra_tabelas">
                <tabela>
                  <thead>
                    <tr>
                      <th colSpan="7">
                        Número Total de Atletas Inscritos no Evento:{" "}
                        {this.state.total_items}
                      </th>
                    </tr>
                  </thead>
                  <thead>
                    <tr>
                      <th onClick={() => this.sortBy("athlete_name")}>
                        Nome{" "}
                        <i
                          className="fa fa-angle-down fa-sm" /*fa fa-sort fa-sm*
                        />
                      </th>
                      <th onClick={() => this.sortBy("dorsal_number")}>
                        Dorsal{" "}
                        <i
                          className="fa fa-angle-down fa-sm" /*fa fa-sort fa-sm*
                        />
                      </th>
                      <th onClick={() => this.sortBy("club")}>
                        Clube{" "}
                        <i
                          className="fa fa-angle-down fa-sm" /*fa fa-sort fa-sm*
                        />
                      </th>
                      <th onClick={() => this.sortBy("category")}>
                        Escalão{" "}
                        <i
                          className="fa fa-angle-down fa-sm" /*fa fa-sort fa-sm*
                        />
                      </th>
                      <th onClick={() => this.sortBy("country")}>
                        País{" "}
                        <i
                          className="fa fa-angle-down fa-sm" /*fa fa-sort fa-sm*
                        />
                      </th>
                      {/*<th onClick={() => this.sortBy("long_name")}>*}
                      <th onClick={() => this.sortBy("name")}>
                        Competição{" "}
                        <i
                          className="fa fa-angle-down fa-sm" /*fa fa-sort fa-sm*
                        />
                      </th>
                      <th onClick={() => this.sortBy("insc_status")}>
                        Estado{" "}
                        <i
                          className="fa fa-angle-down fa-sm" /*fa fa-sort fa-sm*
                        />
                      </th>
                      {/*<th>Acção</th>*}
                    </tr>
                  </thead>
                </tabela>
              </div>
            </div>
          );
        }
        */
      }
    }
  }
}

export default Tabela_Lista_Atletas_Inscritos;
